import React from "react";

export default {
  "title": "NATIONAL INSURANCE UNDERWRITERS",
  "description": (
    <React.Fragment>
      <h3>CLIENT CENTER</h3>
    </React.Fragment>
  )
}
