import React, {useEffect, useState} from 'react'
import classNames from "classnames";
import {Typography} from "@material-ui/core";
import {useTheme, withStyles} from '@material-ui/core/styles';
import Fade from "react-reveal/Fade";
import {Link} from "react-router-dom";
import useMediaQuery from "@material-ui/core/useMediaQuery/useMediaQuery";
import Grid from "@material-ui/core/Grid";
import each from 'lodash/each';
import multiItemSectionStyle from '../../../assets/jss/material-kit-react/components/multiItemSection'

function MultiItemSection({...props}) {
  const theme = useTheme();
  const {classes, title, items, altRow, productArea} = props;
  const mobile = useMediaQuery(theme.breakpoints.down('md'));
  const [displayData, setDisplayData] = useState([])

  const buildItem = (content, index) => {
    return (
      <React.Fragment>
        <Fade bottom>
          <div className={classNames({[classes.secondItem]: (index % 2 === 1)})}>
            <h2 className={classes.description}>
              {content.title || content.name || ''}
              <Typography
                variant="overline"
                display="block"
                gutterBottom>
                <a href={`/${productArea}/product-details/${content.id}`}>learn more</a>
              </Typography>
            </h2>
            <h3 className={classes.description}>
              <img
                height="225"
                style={{maxWidth: '90%'}}
                className={classNames({
                  [classes.raised]: true,
                  [classes.leftImg]: index % 2 === 0,
                  [classes.rightImg]: index % 2 === 1
                })}
                alt={content.imageAlt || ''}
                src={content.image || null}
              />
              {content.description && content.description.map((item, index) => (
                <React.Fragment>{item}<br/></React.Fragment>
              ))}
              {content.note && <Typography variant="caption" display="block" gutterBottom>{content.note}</Typography>}
              {content.noteTwo || null}
            </h3>
          </div>
        </Fade>
      </React.Fragment>
    )
  }

  useEffect(() => {
    let newDisplayData = [];
    each(items, (item, itemIndex) => {
      let DisplayComponent = buildItem(item, itemIndex)
      newDisplayData.push(DisplayComponent);
    })
    setDisplayData(newDisplayData);
  }, [items])

  return (
    <div className={classNames({[classes.section]: true, [classes.altSection]: altRow})}>
      <div>
        <Grid container className={classes.mainGridContainer}>
          <Grid item xs={12} sm={12} md={12}>
            <h2 className={classes.title}>{title}</h2>
            <Grid container>
              {
                displayData && displayData.map((item, index) => {
                  if (index % 2 === 0) {
                    return (
                      <Grid item lg={6} sm={12}
                            style={{padding: 50, textAlign: 'left'}}>
                        {item || null}
                      </Grid>
                    )
                  }
                  return (
                    <Grid item lg={6} sm={12} style={{padding: 50, textAlign: 'left'}}>
                      {item || null}
                    </Grid>
                  )
                })
              }
            </Grid>
          </Grid>
        </Grid>
      </div>
    </div>
  )
}

export default withStyles(multiItemSectionStyle)(MultiItemSection)
