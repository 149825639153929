import React from "react";

export default {
  image: require('../../../assets/img/AAMGA.png'),
  description: [
    (
      <React.Fragment>
        NIU is a proud member of the <a href={"http://www.aamga.org/"} target={"_blank"}>AAMGA</a>. Since 1926, the
        AAMGA has served as the trade association to international
        wholesale insurance professionals, and as a leader representing the interests of its members before the federal,
        state and local governmental and regulatory agencies, to elected officials and other industry trade associations
        in the US, Canada and Europe.
      </React.Fragment>
    )
  ]
}
