import React from "react";

export default {
  title: "Assistance",
  items: [
    {
      id: "rv-technical-assistance",
      image: require('assets/img/AdobeStock_306949286.jpg'),
      detailImage: require('assets/img/rv-repair_color.png'),
      title: "RV Technical Assistance",
      name: "RV Technical Assistance",
      description: [
        "Our RV Technical Assistance is beyond compare within the industry. NSD has accumulated the industry's most talented and recognized RV Technicians to provide our clients with over 85 years of RV repair and service expertise. Our clients and partners will benefit through increased consumer confidence in the products and services they are offered."
      ],
      mainContent: (
        <React.Fragment>
          Our RV Technical Assistance is beyond compare within the industry. NSD has accumulated the industry's most
          talented and recognized RV Technicians to provide our clients with over 85 years of RV repair and service
          expertise. Our clients and partners will benefit through increased consumer confidence in the products and
          services they are offered.
          <br/>
          <br/>
          Today, the RV industry is expanding into a new frontier of enhanced customer service. The RV Technical
          Assistance Line is part of our commitment to support customer service within the industry.
          <br/>
          <br/>
          NSD RV Master Certified Technicians are continually expanding on their education, training and field
          knowledge. They are the most up to date team of repair and service professionals in the industry. In 2006 the
          NSD RV Team was recognized by The Dometic Corporation through their 2006 MVP Award for "outstanding
          performance in exceeding customer expectations for quality maintenance and repair".
          <br/>
          <br/>
          The NSD RV Technician team has further been acknowledged by RV Pro Magazine in April 2006, TRVA Scanner in
          Winter 2005, RV Trade Digest, RV Technician and Family Motor Coach Magazine.
          <br/>
          <br/>
          For our service contract and warranty partners, you will see increased consumer confidence translates into
          more RV sales and more RV service contract sales. Additionally, with NSD techs involved, your claims frequency
          and severity will be lower.
          <br/>
          <br/>
          NSD is committed to the growth of the RV Industry through the support of the RVDA and RVIA to ensure the
          proper training and industry integrity is built within our rapidly growing industry.
        </React.Fragment>
      ),
      brochures: [
        {
          title: "RV Technical Assistance",
          link: "brochures/rv-technical-assistance.pdf"
        }
      ],
      centerItems: [
        {
          title: "The Product",
          content:
            (
              <ul>
                <li>Staffed by Master Certified and Certified RV Technicians as recognized by the RVDA-RVIA Technician
                  Certification Governing Board.
                </li>
                <li>Over 85 years combined RV repair and service knowledge.</li>
                <li>All technicians have hands-on involvement with current RV and Service trends.</li>
                <li>Over 87 combined industry specific certifications</li>
                <li>24-Hour RV Technical Assistance</li>
              </ul>
            )
        },
        {
          title: "Guidance for Common RV Issues",
          content:
            (
              <ul>
                <dt>- 12 VDC & 110VAC</dt>
                <dt>- LP Gas</dt>
                <dt>- Appliances</dt>
                <dt>- Fresh Water System</dt>
                <dt>- Leveling</dt>
                <dt>- Slide Outs</dt>
              </ul>
            )
        },
        {
          title: "Benefits",
          content:
            (
              <React.Fragment>
                <ul>
                  <li>Customer Peace of Mind</li>
                  <li>Consumer Confidence through product support</li>
                  <li>Fewer on-site claims for minor issues</li>
                  <li>Greater Customer Satisfaction</li>
                  <li>Used as a Sales Tool for the Dealer</li>
                  <li>Reduced claims frequency and cost</li>
                </ul>
                (Please see contract for the specific terms and conditions of coverage.)
              </React.Fragment>
            )
        }
      ]
    },
    {
      id: "roadside-assistance",
      "title": "Roadside Assistance",
      "name": "Roadside Assistance",
      "image": require("assets/img/Roadside-Assistance.jpg"),
      "detailImage": require("assets/img/roadside-assistance_color.png"),
      "description": [
        "The Nation Safe Drivers Auto Club provides real peace-of-mind at affordable rates. When on the road you'll know that you are covered and protected by an organization that cares and has been caring since 1962! Our customer representatives respond quickly to provide you the best service and protection available anywhere. We at NSD have earned a solid reputation for fast and courteous service. You can count on the company with \"Safe\" as its middle name!"
      ],
      mainContent: "The Nation Safe Drivers Auto Club provides real peace-of-mind at affordable rates. When on the road you'll know that you are covered and protected by an organization that cares and has been caring since 1962! Our customer representatives respond quickly to provide you the best service and protection available anywhere. We at NSD have earned a solid reputation for fast and courteous service. You can count on the company with \"Safe\" as its middle name!",
      brochures: [
        {
          title: "Motor Club",
          link: "brochures/motor-club.pdf"
        },
        {
          title: "Auto Club Spanish",
          link: "brochures/auto-club-spanish.pdf"
        },
        {
          title: "Motor Club Spanish",
          link: "brochures/motor-club-spanish.pdf"
        },
        {
          title: "Towbusters Motor Club",
          link: "brochures/towbusters-motor-club.pdf"
        }
      ],
      centerItems: [
        {
          title: "Benefits",
          content: (
            <React.Fragment>
              <b>24 Hour emergency towing service</b>
              Your vehicle may be towed to the destination of your choice.
              <b>24 Hour road service</b>
              You are covered for emergency road service throughout the U.S. and Canada.
              <b>24 Hour emergency delivery</b>
              A service truck will deliver emergency supplies of gasoline, water, oil, or other necessary supplies. You
              only pay for the cost of the supplies.
              <b>24 Hour emergency battery service</b>
              You are covered to boost batteries and perform minor adjustments to alternators, starters, etc. while on
              the road.
              <b>Auto rental reimbursement</b>
              If repairs are needed to your vehicle due to an accident with another vehicle, NSD will reimburse you for
              the cost of renting a car during the repair period. Not available on all plans in all states.
              <h6 style={{
                fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                bottom: "10px",
                color: "#c0c1c2",
                display: "block",
                fontWeight: "400",
                fontSize: "13px",
                lineHeight: "13px"
              }}>
                (Please see contract for the specific terms and conditions of coverage.)
              </h6>
            </React.Fragment>
          )
        },
        {
          title: "Additional Benefits",
          content: (
            <React.Fragment>
              <b>Lost key or lockout service</b>
              If you are accidentally locked out of your vehicle, you will be covered and entitled to the service
              specified in your contract.
              <b>Rent-a-car discounts</b>
              You are entitled to discount cards for vehicle rentals, which are honored at thousands of locations
              throughout the US and abroad.
              <b>Map routing service</b>
              We will, at no charge, provide you with a map and travel route to the destination of your choice.
              <b>Theft and hit & run protection</b>
              A $500 reward is offered for information leading to the arrest and conviction of anyone responsible for
              hit & run damages or stealing your vehicle.
              <br/>
              Whenever you travel, you'll like the secure feeling knowing that you are protected by the Nation Safe
              Drivers Auto Club. Fast response and excellent service are just two reasons why millions of motorists rely
              on Nation Safe Drivers for on-the-road protection.

            </React.Fragment>
          )
        }
      ]
    }
  ]

}
