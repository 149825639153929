import React from "react";

export default {
  "title": "NIU OF FLORIDA, INC.",
  "subTitle": "The Road More Profitable Starts Here",
  "subTitleTwo": (
    <React.Fragment>
      Let our experts help you customize the perfect product offering to fit the specific needs of your clientele.
      <br/>
      NIU of Florida, Inc.'s commitment to the highest overall quality of our service will ensure that your client's
      expectations are exceeded with every opportunity.
    </React.Fragment>
  ),
  form: {
    title: "Licensing Form",
    link: "/files/forms/NIUFL_2020.pdf"
  }
}
