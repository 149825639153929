import React from 'react';
import productsStyle from "assets/jss/material-kit-react/views/claimCentersSections/productsStyle.jsx";

export default {
  "products": [
    {
      "name": "Tire & Wheel",
      "img": require("assets/img/tire-wheel-product.png"),
      "description": (
        <React.Fragment>
          Repair or replacement of tires and/or wheels damaged due to impact with a road hazard.
          <br/>
          <br/>
          Administered by NIU of Florida, Inc.
        </React.Fragment>
      ),
      forms: [
        {
          title: "Standard Form",
          link: "/files/forms/TirewheelPOL8_20_08.pdf"
        },
        {
          title: "Fillable Form",
          link: "/files/forms/TirewheelPOL8_20_08_fillable.pdf"
        }
      ],
      mainContent: (
        <React.Fragment>
          <h3>
            To report a claim, credentialed dealerships should use the NSDClaims Mobile App which is available for both
            Apple and Android OS. Or to access our online claims center, click on the “Access Claims” button above and
            select “Producers”. Customers and repair facilities can also report a claim by calling 1-888-684-9327 Monday
            through Friday from 8:30 a.m. to 8:00 p.m. Eastern Time. Authorization is required <b>PRIOR</b> to repair or
            replacement of any wheel, and prior to the replacement of any tire. For authorized claims, we will provide a
            claim number AND a separate authorization number. Please note that we reserve the right to deny any claim
            submitted without prior authorization. All claims must be reported to us within 60 days from the date that
            damage occurs. Every attempt should be made to repair rather than replace damaged property. We will only pay
            for the reasonable cost of repairs unless it can be demonstrated that the damaged tire(s) or wheel(s) could
            not be repaired.
          </h3>
        </React.Fragment>
      ),
      fullText: [
        {
          title: (
            <div style={{
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
              textAlign: 'center'
            }}>
              Procedure for Filing<br/>
              <small>Working hours are Monday - Friday from 9:00 a.m. to 8:00 p.m. eastern time</small>
            </div>
          ),
          steps: [
            {
              title: "What You Need",
              icon: "CollectInformationIcon",
              details: (
                <React.Fragment>
                  <ul>
                    <li>
                      Year, make, model & mileage of vehicle
                    </li>
                    <li>
                      Position of the damaged tire(s) and/or wheel(s). Example, right rear tire
                    </li>
                    <li>
                      Tread depth of damaged tire
                    </li>
                    <li>
                      Make, model, size, load capacity, and speed rating of damaged tire. Example, Continental
                      ProContact 245/55R17 99H
                    </li>
                    <li>
                      Explanation of how tire(s) or wheel(s) was/were damaged. Note: a blowout is not a cause of damage
                      but rather the result. We will need to know what caused the damage
                    </li>
                    <li>
                      Name of repair facility
                    </li>
                    <li>
                      A copy of the repair order signed by the vehicle owner
                    </li>
                  </ul>
                </React.Fragment>
              )
            },
            {
              title: "How to File Your Claim",
              icon: 'FileClaimIcon',
              details: (
                <React.Fragment>
                  <ul>
                    <li>
                      Vehicle Owners
                      <br/>
                      <b>During Business Hours</b>
                      <ol>
                        <li>
                          Take your vehicle to the repair facility of your choice. We recommend that you return to the
                          dealership where you purchased the vehicle, but you are free to choose your own repair
                          facility.
                        </li>
                        <li>
                          Make sure that the repair facility secures our authorization PRIOR to the repair or
                          replacement
                          of any wheel, and PRIOR to the replacement of any tire. Tire repair does not require prior
                          authorization.
                        </li>
                        <li>
                          If you have questions, need help, or wish to report the claim by phone, call us toll free at
                          at
                          1-888-684-9327. Our normal business hours are Monday through Friday from 8:30 a.m. to 8:00
                          p.m.
                          Eastern Time.
                        </li>
                      </ol>
                      <b>After Business Hours</b>
                      <ol>
                        <li>
                          Take your vehicle to the repair facility of your choice. We recommend that you return to the
                          dealership where you purchased the vehicle as they can usually obtain authorization online;
                          however, you are free to choose your own repair facility.
                        </li>
                        <li>
                          Make sure that you or the repair facility keeps the damaged tire(s) and/or wheel(s) in case we
                          want photographs or an inspection.
                        </li>
                        <li>
                          Make sure that you or the repair facility calls the Claims Center at 1-888-684-9327 on the
                          NEXT
                          BUSINESS DAY for instructions.
                        </li>
                      </ol>
                    </li>
                    <li>
                      Repair Facilities
                      <br/>
                      <b>During Business Hours</b>
                      <ul>
                        <li>
                          *Credentialed dealerships can log in to the NSDClaims Mobile App, OR click on the “Access
                          Claims”
                          button above and select “Producers” to access the claim portal. Log in and click “Start a New
                          Claim”. Follow the prompts to secure authorization and/or further instructions.
                        </li>
                      </ul>
                      OR
                      <ul>
                        <li>
                          *Any repair facility may report the claim by calling 1-888-684-9327 Monday through Friday from
                          8:30 a.m. to 8:00 p.m. Eastern Time.
                        </li>
                      </ul>
                      <b>After Business Hours</b>
                      <ul>
                        <li>
                          Credentialed dealerships must log in to the NSDClaims Mobile App, OR click on the “Access
                          Claims”
                          button above and select “Producers” to access the claim portal. Log in and click “Start a New
                          Claim”. Follow the prompts to secure authorization and/or further instructions. If portal does
                          not
                          authorize claim, follow instructions for “All other repair facilities” below.
                        </li>
                        <li>
                          All other repair facilities:
                          -BE SURE TO SAVE ALL DAMAGED TIRE(S) AND/OR WHEEL(S) FOR OUR INSPECTION.
                          -Call the Claims Center at 1-888-684-9327 on the NEXT BUSINESS DAY for instructions.
                          IMPORTANT NOTE: Failure to follow these procedures may result in claim declination.
                        </li>
                      </ul>
                    </li>
                  </ul>
                </React.Fragment>
              )
            },
            {
              title: "What to Expect",
              icon: 'ReviewIcon',
              details: (
                <React.Fragment>
                  <ul>
                    <li>
                      Vehicle Owners:
                      <ol>
                        <li>
                          If you call to report a claim, you will be given a claim number, instructions, and you will be
                          advised to take the vehicle to a repair facility. You will be given the option to receive
                          automated messages regarding your claim, or you will receive credentials to log into the
                          Member
                          Claims Portal. Please provide the claim number to the repair facility, and make sure they call
                          1-888-684-9327 to secure our authorization PRIOR to the repair or replacement of any wheel,
                          and
                          prior to the replacement of any tire.
                        </li>
                        <li>
                          We may ask you to complete a proof of loss form to provide us with the facts and circumstances
                          of your claim. You may obtain a copy of the proof of loss form by clicking on the link at the
                          bottom of this page.
                        </li>
                        <li>
                          Prior to authorization, we may ask the repair facility to provide us with digital photographs.
                          In other circumstances, we may send someone out to inspect the damage.
                        </li>
                        <li>
                          If we authorize the claim, we will provide the repair facility with a claim number AND a
                          separate authorization number. We will also send the repair facility an authorization and post
                          it
                          on our digital Claims Portal. This authorization will include all amounts that we agree to pay
                          if
                          coverage applies. Please make sure that your repair facility has obtained our authorization
                          and
                          has agreed to our pricing before they complete the work.
                        </li>
                        <li>
                          To validate the claim, we require a copy of the repair order signed by the vehicle owner.
                        </li>
                        <li>
                          For covered claims, the terms of your contract require you to pay for the work, and then
                          submit
                          your claim for reimbursement consideration. In some circumstances, we may at our exclusive
                          option
                          agree to pay the repair facility directly.
                        </li>
                        <li>
                          You can check the status of your claim and/or upload documents to us by logging in to our
                          Member Claims Portal. Just click the “Access Claims” button at the top of this page and select
                          “Members Area”. Once you log in, you will be given the option to receive automated messages
                          regarding your claim, and we highly recommend that you use this free service.
                        </li>
                        <li>
                          Uploading your documents through the Member Claims Portal is the fastest and most reliable way
                          to provide us with your documents. If you do not wish to use this convenient tool, you may
                          email
                          them to twclaims@nsdmc.com.
                        </li>
                        <li>
                          Once we have all documentation, the claim will be reviewed and a decision rendered, typically
                          within 5 business days.
                        </li>
                        <li>
                          If we agree to pay the repair facility directly, payment will be issued by credit card. If we
                          agree to reimburse you, payment will be issued by check.
                        </li>
                      </ol>
                    </li>
                    <li>
                      Repair Facilities:
                      <ol>
                        <li>
                          Credentialed dealerships should use the NSDClaims Mobile App which is available for both Apple
                          and Android OS. Or you can access our online claims center by clicking on the “Access Claims”
                          button above and selecting “Producers”. Please use these tools for loss reporting, status
                          checks,
                          and uploading documents/photos. Once you log in, you will be given the option to receive
                          automated
                          messages regarding your claim, and we highly recommend that you use this free service.
                        </li>
                        <li>
                          Please check the portal frequently. If a claim is incomplete, you will see the word YES in the
                          Action Required column. Click “YES” to see what is still needed.
                        </li>
                        <li>
                          When you use the portal to start a new claim, you will be asked for basic claim information.
                          Most fields are completed with drop down lists, radio buttons, or check boxes.
                        </li>
                        <li>
                          Most claims reported through the app or portal receive automated authorization.
                        </li>
                        <li>
                          If the claim is not authorized online, you will be given further instructions which may
                          include
                          a request for digital photos, a proof of loss signed by the customer, or other documentation.
                        </li>
                        <li>
                          You may opt-in to receive automated status updates by text or email.
                        </li>
                        <li>
                          Repair facilities who are not credentialed must call 1-888-684-9327 to report all claims. If
                          you call to report a claim, you will be given a claim number and instructions. If the claim is
                          authorized, you will also be given an authorization number.
                        </li>
                        <li>
                          You will be given the option to receive automated messages regarding your customer’s claim,
                          and
                          you will receive credentials to log into the Member Claims Portal.
                        </li>
                        <li>
                          All authorizations will be posted on the Claims Portal with agreed pricing.
                        </li>
                        <li>
                          Documents and photos should be uploaded through the Claims Portal and will receive priority.
                        </li>
                        <li>
                          Uploading your documents through the Member Claims Portal is the fastest and most reliable way
                          to provide us with your documents. If you do not wish to use this convenient tool, you may
                          email
                          them to twclaims@nsdmc.com. Documents sent via email must go through our clerical process, and
                          this may delay your claim.
                        </li>
                        <li>
                          Covered claims may be paid directly to the repair facility at our exclusive option.
                        </li>
                        <li>
                          Once claim is complete, payment will be issued within 3 business days.
                        </li>
                        <li>
                          If we are paying the repair facility directly, a single use credit card will be sent via email
                          for the agreed amount.
                        </li>
                        <li>
                          If you require a check, please let us know at the outset of the claim.
                        </li>
                        <li>
                          Customer reimbursements will be made by check sent directly to the customer’s address on file.
                        </li>
                      </ol>
                    </li>
                  </ul>
                </React.Fragment>
              )
            },
            // {
            //   title: "Important Notes",
            //   icon: 'ImportantIcon',
            //   details: (
            //     <React.Fragment>
            //       <ul>
            //         <li>
            //           Please note that alloy wheel damage can often times be repaired. In the
            //           event that
            //           damaged wheels are replaced, your repair facility must document the reasons
            //           why wheel repair was
            //           not possible. They should then attempt to obtain a remanufactured wheel to
            //           replace the damaged
            //           wheel. If this is not possible, your repair facility may replace the damaged
            //           wheel(s) with new
            //           wheels provided that the cost of the wheel does not exceed $400. If the
            //           wheel cost exceeds $400,
            //           you must contact our claims center during regular business hours for
            //           authorization and follow the
            //           procedures in section A. Again, wheels must be retained for our inspection,
            //           and failure to do so
            //           may result in denial of the claim.
            //         </li>
            //         <li>
            //           We reserve the right to inspect any damaged tire(s) and/or wheel(s) prior to
            //           its repair
            //           or disposal. Failure to allow inspection may result in claim denial.
            //         </li>
            //         <li>
            //           We reserve the right to request additional documents in support of your
            //           claim
            //         </li>
            //         <li>
            //           Make sure that your chosen repair facility retains the damaged tire(s)
            //           and/or wheel(s) for our
            //           inspection. Failure to retain the damaged material may result in claim
            //           denial.
            //         </li>
            //       </ul>
            //     </React.Fragment>
            //   )
            // }
          ]
        }
      ],
      bottomContent: [
        (<h3>
          Please be advised that this is a generic claim instruction site and contains instructions regarding
          several
          types of coverages. Information displayed here does not imply or suggest that your contract contains
          these
          coverages and provisions. Should you have any questions regarding coverage, please read your
          contract
          carefully and/or consult your dealer/agent. Please see your contract for specific terms and
          conditions.
        </h3>)
      ]
    },
    {
      "name": "Towing, Roadside & Travel Assistance",
      "img": require("assets/img/roadside-assistance.png"),
      "description": "Dispatch or Reimbursement for towing &/or emergency roadside assistance such as lockout, tire change, fuel delivery, or jumpstart. Reimbursement for Emergency Travel expenses.",
      forms: [
        {
          title: "Towing, Roadside & Travel",
          link: "/files/forms/OnlineTowingLockoutEmergencyTravelClaimform.pdf"
        }
      ],
      mainContent: (
        <React.Fragment>
          <h3>
            <h2 style={productsStyle.title}>Towing & Roadside Assistance</h2>
            Covers the reasonable cost of towing or emergency road service in the event that the covered vehicle is
            unable to safely proceed under its own power. Emergency road services include battery service, delivery
            service (gasoline, water, oil, or any supplies necessary to send your vehicle on its way), and installation
            of your good spare tire in the event of a flat. Please see your contract for specific details of coverage.
            <br/>
            <br/>
            <h2 style={productsStyle.title}>Lockout Service</h2>
            <br/>
            Covers the services of a locksmith if you are accidentally locked out of your vehicle. Please see your
            contract for specific details of coverage.
            <br/>
            <br/>
            <h2 style={productsStyle.title}>Emergency Travel Protection</h2>
            <br/>
            If your vehicle is disabled due to a covered event, Members will be reimbursed for the costs of local
            lodging and meals. Certain limitations apply. Please see your contract for specific details of coverage.
          </h3>
        </React.Fragment>
      ),
      fullText: [
        {
          title: (
            <div style={{
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
              textAlign: 'center'
            }}>
              Procedure for Filing<br/>
              <small>
                Dispatch hours are 24/7/365. Check your contract for our toll free number, producer code, and plan
                letter.
                <br/>
                For claim reimbursements including all Emergency Travel Protection claims, call 888-684-9327 to report
                your claim. Working hours are Monday – Friday from 8:30 a.m. to 5:00 p.m. eastern time. We will provide
                you with instructions and access to our claims portal where you can upload documents and check the
                status of your claim.
              </small>
              <h3 style={{textDecoration: 'underline'}}>
                <b>
                  You can email documents to GC@nsdmc.com.
                </b>
              </h3>
            </div>
          ),
          steps: [
            {
              title: "What You Need",
              icon: "CollectInformationIcon",
              details: (
                <React.Fragment>
                  <ul>
                    <li>
                      For dispatch claims:
                      <ul>
                        <li>Locate our toll free number on your contract along with:</li>
                        <li>Producer code; and</li>
                        <li>Plan letter</li>
                      </ul>
                    </li>
                    <li>
                      For reimbursement claims:
                      <ul>
                        <li>Proof of loss form (click Proof of Loss form above)</li>
                        <li>Paid receipts</li>
                        <li>Police report (if claim is accident related)</li>
                        <li>Body shop repair bill (Emergency Travel Protection claims only) including repair facility
                          name, address, and phone number, & date repairs were initiated and completed.
                        </li>
                      </ul>
                    </li>
                  </ul>
                </React.Fragment>
              )
            },
            {
              title: "How to File Your Claim",
              icon: 'FileClaimIcon',
              details: (
                <React.Fragment>
                  <b>Towing and Roadside Assistance:</b>
                  <ul>
                    <li>
                      If your agreement provides dispatch service, locate the toll free number, producer code, and
                      plan letter on your contract. Call the toll free number 24/7/365 and one of our professional
                      service agents will assist you. In most cases, we will dispatch a service provider to help get you
                      back on the road.
                    </li>
                    <li>
                      If for some reason a service provider cannot be dispatched, see above for a claim form.
                    </li>
                    <li>
                      Submit the claim form along with your paid receipts for reimbursement consideration. You may
                      upload your documents through our claim portal, you may email them to GC@nsdmc.com, or you may
                      mail them to our Claims Center 800 W. Yamato Rd. Suite 100 Boca Raton, FL 33431. Please note that
                      terms and conditions apply. Please see your contract for specific details of coverage.
                    </li>
                    <li>
                      If your agreement provides for reimbursement only, see above for a claim form. Submit the
                      claim form along with your paid receipts, and a copy of the police report (if incident was
                      accident related) for reimbursement consideration. You may upload your documents through our claim
                      portal, you may email them to GC@nsdmc.com, or you may mail them to our Claims Center 800 W.
                      Yamato Rd. Suite 100 Boca Raton, FL 33431. Please note that terms and conditions apply. Please see
                      your contract for specific details of coverage.
                    </li>
                  </ul>
                  <b>Emergency Travel Protection:</b>
                  <ul>
                    <li>
                      See above for a claim form. Submit the claim form along with your paid receipts, body shop
                      repair bill, and policy report (if claim is accident related) for reimbursement consideration. You
                      may upload your documents through our claim portal, you may email them to GC@nsdmc.com, or you may
                      mail them to our Claims Center 800 W. Yamato Rd. Suite 100 Boca Raton, FL 33431. Please note that
                      terms and conditions apply. Please see your contract for specific details of coverage.
                    </li>
                  </ul>
                </React.Fragment>
              )
            },
            {
              title: "What to Expect",
              icon: 'ReviewIcon',
              details: (
                <React.Fragment>
                  <ul>
                    <li>
                      For dispatch claims, we will locate a service provider in your area and send them to your location
                      to provide the covered service(s).
                    </li>
                    <li>
                      For reimbursement claims, you will be reimbursed for covered services not to exceed your plan
                      limit.
                    </li>
                    <li>
                      You can check the status of your claim and upload documents to us by logging in to our Member
                      Claims Portal. Just click the “Access Claims” button at the top of this page and select “Members
                      Area”. If you reported your claim by phone, you can log in with the credentials that we provided
                      to you at the outset of the claim. These credentials are also listed on any letter that we sent to
                      you. Once you log in, you will be given the option to receive automated messages regarding your
                      claim, and we highly recommend that you use this free service.
                    </li>
                    <li>
                      Uploading your documents through the Member Claims Portal is the fastest and most reliable way to
                      provide us with your documents. If you do not wish to use this convenient tool, you may email them
                      to GC@nsdmc.com, or mail them to our Claims Center 800 W. Yamato Road Suite 100 Boca Raton, FL
                      33431.
                    </li>
                    <li>
                      We will process your documents, usually within 5 business days of the day we receive them. That
                      means that we will review them and record them in our claim file. If the documentation submitted
                      is incomplete, we will send you a written request for the missing items.
                    </li>
                    <li>
                      If the documentation is complete and the claim is payable, we will send a check to the address we
                      have for you on file. If the claim is declined, you will be notified in writing.

                    </li>
                  </ul>
                </React.Fragment>
              )
            },
            {
              title: "Important Notes",
              icon: 'ImportantIcon',
              details: (
                <React.Fragment>
                  <ul>
                    <li>
                      All documentation must be submitted to our offices within 60 days of the date of loss.
                    </li>
                    <li>
                      No action taken in the investigation of any claim should be construed as an admission of coverage
                      or liability.
                    </li>
                    <li>
                      We reserve the right to deny any claim for any valid reasons as described in your contract.
                    </li>
                  </ul>
                </React.Fragment>
              )
            }
          ]
        }
      ],
      bottomContent: [
        (
          <React.Fragment>
            <h3>
              This is a generic claim instruction site and contains instructions regarding several types
              of coverages.
              This does not imply or suggest that your contract contains these coverages. Should you have
              any questions
              regarding coverage, please read your contract carefully and/or consult your agent.
            </h3>
          </React.Fragment>
        )
      ]
    },
    {
      "name": "GAP Protection",
      "img": require("assets/img/gap-insurance.png"),
      "description": "Debt cancellation agreement in which your dealer/creditor (or by assignment your lender) agrees to waive a portion of your outstanding balance in the event of a total loss. PLEASE read your contract for important terms and conditions.",
      forms: [
        {
          title: "GAP Cancellation Form",
          link: "/files/forms/OnlineGAP-CancellationForm091212.pdf"
        },
        {
          title: "GAP Document Checklist",
          link: "/files/forms/GAP Document Checklist.pdf"
        },
        {
          title: "Accident Affidavit",
          link: "/files/forms/Accident Affidavit.pdf"
        },
        {
          title: "Theft & Fire Affidavit",
          link: "/files/forms/Theft and Fire Affidavit.pdf"
        }
      ],
      mainContent: (
        <React.Fragment>
          <h3>
            GAP is not insurance, and the GAP Administrator does not provide you with insurance coverage of any kind.
            GAP is a contract that changes the terms of your loan agreement. Because the GAP agreement appends to the
            loan agreement, the contract is called an Addendum. If the financed vehicle becomes a total loss, the dealer
            who arranged the vehicle financing agrees to cancel your remaining balance as of the date of loss (subject
            to the terms and conditions of the GAP Addendum). If the dealer has assigned the loan to a bank, credit
            union, or other financial institution, the obligation to cancel the remaining balance extends to that
            lender. The dealer (or by assignment the lender) is the one and only party responsible for providing you
            with the GAP benefit (if applicable).
            <br/>
            The GAP Administrator is responsible for the collection of the documentation necessary to support your
            claim. Due to federal privacy legislation, it is not possible for us to secure the documents for you, so it
            is your responsibility to provide us with all of the required items.
          </h3>
        </React.Fragment>
      ),
      fullText: [
        {
          stepSize: 4,
          title: (
            <div style={{
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
              textAlign: 'center'
            }}>
              Procedure for Filing<br/>
              <small>
                Claims must be reported to the GAP Administrator within ninety (90) days of the date that the damage to
                the covered vehicle occurs. To report a claim, call 888-272-5517 Monday through Friday from 8:30 a.m. to
                5:00 p.m. Eastern Time. Make sure that you provide us with your current mailing address, email address,
                and phone number. We will provide you with a claim number along with instructions for filing the claim.
                We will also provide you with access to our claim portal where you can upload documents and check the
                status of your claim. You will be required to submit a number of documents. Please be sure to submit all
                of the documents within ninety (90) days of the date that the insurance company pays the total loss
                claim (or if there was no insurance, within ninety (90) days of the date of loss). Failure to submit all
                documents within this timeframe may result in delay or declination of your claim.
              </small>
            </div>
          ),
          steps: [
            {
              title: "What You Need",
              icon: "CollectInformationIcon",
              details: (
                <React.Fragment>
                  All documents must be legible. See above for a copy of the documents checklist which includes the
                  following:
                  <ul>
                    <li>
                      GAP Cancellation Form - See above for a copy of the form. Complete, sign, and return this form to
                      us.
                    </li>
                    <li>
                      Affidavit-complete this form in its entirety, sign, notarize, and return to us. If your vehicle
                      was totaled as a result of a collision, see above for a copy of the form. If your vehicle was
                      totaled as a result of theft or fire, see above for a copy of the form. Your signature MUST BE
                      NOTARIZED or the form will be rejected.
                    </li>
                    <li>
                      Total loss valuation report-you can get this from the insurance company who paid your claim. This
                      is a document that shows how the insurance company determined the value of your vehicle. Some
                      common sources are “CCC”, “Audatex”, “Mitchell”, and “NADA”.
                    </li>
                    <li>
                      Total loss settlement letter-you can get this from the insurance company who paid your claim. This
                      is a letter that shows a line by line breakdown of how they calculated the total loss payment.
                    </li>
                    <li>
                      Claim settlement check-you can get this from the insurance company who paid your claim.
                    </li>
                    <li>
                      Police report-we need the full report including the officer’s narrative and all attachments. You
                      can get this from the insurance company who paid your claim, or you can get it directly from the
                      police department who responded to your claim. NOTE: if there was no police report, please provide
                      us with a letter explaining why no police responded to the incident scene.
                    </li>
                    <li>
                      Your complete payment history-you can obtain this from your lender.
                    </li>
                    <li>
                      A front and back copy of your GAP Addendum. You can get this from the dealership where you
                      purchased your vehicle.
                    </li>
                    <li>
                      Manufacturer’s Invoice-we only need this if you purchased the vehicle new. You can get this from
                      the dealership where you purchased your vehicle.
                    </li>
                    <li>
                      Purchase Order (also known as a bill of sale). You can get this from the dealership where you
                      purchased your vehicle.
                    </li>
                    <li>
                      Finance Agreement-also called TILA (truth in lending act) form. You can get this from the
                      dealership where you purchased your vehicle.
                    </li>
                    <li>
                      Product Agreements-if you bought any additional products like an extended warranty, prepaid
                      maintenance agreement, theft protection product, etc., we will need all pages of each one of the
                      agreements. When your vehicle becomes a total loss, you will be entitled to cancel these
                      agreements. The unearned enrollment fee(s) will be refunded by the dealership to the lender. You
                      can get this from the dealership where you purchased your vehicle.
                    </li>
                    <li>
                      Refund Check(s) for any product agreements-you can get this from the dealership where you
                      purchased your vehicle.
                    </li>
                  </ul>Please see your particular contract for specific terms of coverage.
                  If you are unable to get any of these documents, you must send us a written explanation as to why.
                  Please note that we reserve the right to request additional documents.

                </React.Fragment>
              )
            },
            {
              title: "How to File Your Claim",
              icon: 'FileClaimIcon',
              details: (
                <React.Fragment>
                  <ul>
                    <li>
                      Report your claim as soon as possible by calling our Claims Center at 888-684-9327.
                    </li>
                    <li>
                      Working hours are Monday – Friday 8:30 a.m. to 5:00 p.m. Eastern Time.
                    </li>
                    <li>
                      Provide us with all of the documents listed in the Documents Checklist above within 90 days of
                      the date that the insurance company pays the total loss claim (or if there was no insurance,
                      within ninety (90) days of the date of loss).
                    </li>
                  </ul>
                </React.Fragment>
              )
            },
            {
              title: "What to Expect",
              icon: 'ReviewIcon',
              details: (
                <React.Fragment>
                  You can check the status of your claim and upload documents to us by logging in to our Member Claims
                  Portal. Just click the “Access Claims” button at the top of this page and select “Members Area”. You
                  can log in with the credentials that we provided to you at the outset of the claim. These credentials
                  are also listed on any letter that we sent to you.
                  <br/>
                  If you do not wish to upload your documents through the Member Claims Portal, you may email them to
                  gapclaims@nsdmc.com, or mail them to Nation Motor Club 800 W. Yamato Road Suite 100 Boca Raton, FL
                  33431. WE DO NOT ACCEPT FAX TRANSMISSIONS.
                  <br/>
                  We will process your documents within 5 business days of the day we receive them. That means that we
                  will review them and record them in our claim file. If the documentation submitted is incomplete, we
                  will send you a written request for the missing items.
                  <br/>
                  Once legible copies of all documents have been submitted to the GAP Administrator, we will submit your
                  claim to the program underwriter. This is an insurance company who covers the liability of the dealer
                  and/or financial institution who is obligated to cancel your debt under the terms of the GAP Addendum.
                  <br/>
                  The program underwriter will usually notify us of their decision within 5-7 business days. If the
                  claim is to be paid, payment will be issued directly to the lender, and a breakdown of the GAP
                  calculation will be posted on the claim portal and/or sent to you.
                  <br/>
                  If additional documentation is required, or in the event that the claim is declined, we will post the
                  reason on the claim portal and/or notify you in writing.
                </React.Fragment>
              )
            },
            {
              title: "Important Notes",
              icon: 'ImportantIcon',
              details: (
                <React.Fragment>
                  <ul>
                    <li>
                      No action taken in the investigation of any claim should be construed as an
                      admission of coverage or liability.
                    </li>
                    <li>
                      We reserve the right to deny any claim for any valid reasons
                      as described in the contract.
                    </li>
                    <li>
                      Once you log in, you will be given the option to receive automated messages regarding your claim,
                      and we highly recommend that you use this free service.
                    </li>
                    <li>
                      Uploading your documents through the Member Claims Portal is the fastest and most reliable way to
                      provide us with your documents. We strongly encourage you to use this free utility.
                    </li>
                  </ul>
                </React.Fragment>
              )
            }
          ],
        }
      ],
      bottomContent: [
        (
          <React.Fragment>
            <h3>
              In the event that you are unable to procure any of the required documents, you must provide
              us with a
              written explanation as to why the document(s) could not be obtained. This explanation will
              be submitted to
              the underwriters for consideration.
            </h3>
          </React.Fragment>
        ),
        (
          <React.Fragment>
            <h3>
              This is a generic claim instruction site and contains instructions regarding several types
              of coverages.
              This does not imply or suggest that your contract contains these coverages. Should you have
              any questions
              regarding coverage, please read your contract carefully and/or consult your dealer.
            </h3>
          </React.Fragment>
        )
      ]
    },
    {
      "name": "Key Replacement",
      "img": require("assets/img/key-replacement.png"),
      "description": (
        <React.Fragment>
          If the key to the covered vehicle is lost, stolen or destroyed, we will pay for a replacement key/remote for
          an amount not to exceed your plan limit. Be sure to review your contract for specific terms of coverage.
          <br/>
          <br/>
          Administered by NIU of Florida, Inc.
        </React.Fragment>
      ),
      forms: [
        {
          title: "Key",
          link: "/files/forms/OnlineKeyReplacementClaimForm091212.pdf"
        }
      ],
      mainContent: (
        <React.Fragment>
          <h3>
            Please note that your coverage is fully explained in your Key Replacement Agreement. This
            website is
            provided as a convenience to our customers, and nothing herein should be construed so as to
            alter any terms
            or conditions of the Agreement.
          </h3>
        </React.Fragment>
      ),
      fullText: [
        {
          title: (
            <div style={{
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
              textAlign: 'center'
            }}>
              Procedure for Filing<br/>
              <small>
                All claims must be reported as soon as possible. You may call 888-684-9327 to report your claim, or the
                dealership where you purchased the key contract may report the claim online.
                <br/>
                Working hours are Monday – Friday 8:30 a.m. to 8:00 p.m. Eastern Time.
              </small>
            </div>
          ),
          steps: [
            {
              title: "What You Need",
              icon: "CollectInformationIcon",
              details: (
                <React.Fragment>
                  <ul>
                    <li>
                      If you have the damaged keys in your possession, bring them with you to the repair facility for
                      inspection.
                    </li>
                  </ul>
                </React.Fragment>
              )
            },
            {
              title: "How to File Your Claim",
              icon: 'FileClaimIcon',
              details: (
                <React.Fragment>
                  <ul>
                    <li>
                      If you are within 25 miles of the dealership where you purchased your Key Replacement contract,
                      you must return to that dealership to obtain the replacement key.
                    </li>
                    <li>
                      The dealer must obtain our authorization either online, or by calling 888-684-9327
                    </li>
                    <li>
                      Covered claims will be paid directly to the original selling dealer.
                    </li>
                    <li>
                      If you are outside of the 25 mile radius, or if your dealer is out of business, you may go to any
                      appropriate franchise dealer.
                    </li>
                    <li>
                      You must have the repair facility call our Claims Center at 888-684-9327 to report the claim and
                      secure our authorization PRIOR to replacement of the key.
                    </li>
                    <li>
                      Once we authorize the claim, you must pay the repair facility and submit your paid receipt and a
                      completed proof of loss form to our Claims Center for reimbursement consideration. See above for
                      a proof of loss form.
                    </li>
                  </ul>
                </React.Fragment>
              )
            },
            {
              title: "What to Expect",
              icon: 'ReviewIcon',
              details: (
                <React.Fragment>
                  <ul>
                    <li>
                      For covered claims at the original selling dealership, the dealer will replace your key, and we
                      will pay them directly.
                    </li>
                    <li>
                      For covered claims at a repair facility other than your original selling dealership, you must pay
                      the repair facility for the replacement key, and submit your claim for reimbursement
                      consideration.
                    </li>
                    <li>
                      You can check the status of your claim and upload documents to us by logging in to our Member
                      Claims Portal. Just click the “Access Claims” button at the top of this page and select “Members
                      Area”. If you reported your claim by phone, you can log in with the credentials that we provided
                      to you at the outset of the claim. These credentials are also listed on any letter that we sent to
                      you.
                    </li>
                    <li>
                      If you do not wish to upload your documents through the Member Claims Portal, you may email them
                      to TWclaims@nsdmc.com, or mail them to our Claims Center 800 W. Yamato Road Suite 100 Boca Raton,
                      FL 33431.
                    </li>
                    <li>
                      We will process your documents, usually within 5 business days of the date we receive them. That
                      means that we will review them and record them in our claim file. If the documentation submitted
                      is incomplete, we will send you a written request for the missing items.
                    </li>
                    <li>
                      If the documentation is complete and the claim is payable, we will pay the original selling
                      dealership directly, or for customer reimbursement claims, we will send a check to the address we
                      have for you on file. If the claim is declined, you will be notified in writing.
                    </li>
                  </ul>
                </React.Fragment>
              )
            },
            {
              title: "Important Notes",
              icon: 'ImportantIcon',
              details: (
                <React.Fragment>
                  <ul>
                    <li>
                      Your coverage is subject to an annual maximum limit. See your contract for details.
                    </li>
                    <li>
                      After hours claims-in the event that the loss occurs after our regular business hours or on
                      a weekend or holiday, and in the event that replacement must be effected immediately, you must
                      follow these instructions:
                      <ul>
                        <li>
                          See above for a proof of loss form or call the Claims Center at 888-684-9327 the next
                          business day.
                        </li>
                        <li>
                          If you are within 25 miles of the original selling dealership, you must return to that
                          dealership. If they can secure authorization online, we will pay them directly for your
                          claim.
                          Otherwise, you must pay them for the key replacement and submit the paid receipt and proof
                          of
                          loss form for reimbursement consideration.
                        </li>
                        <li>
                          If you are more than 25 miles from the original selling dealership, or if they are out of
                          business, you may go to any appropriate franchise dealership for key replacement. You must
                          pay
                          them for the key replacement and submit the paid receipt and proof of loss form for
                          reimbursement consideration. See above for a proof of loss form or call the Claims Center
                          at
                          888-684-9327 the next business day.
                        </li>
                        <li>
                          Regardless of where the key is replaced, you MUST call the Claims Center at 888-684-9327
                          the NEXT BUSINESS DAY to report your claim.
                        </li>
                        <li>
                          Note that you may be required to submit additional documentation for our review. Please
                          see
                          your contract for details.
                        </li>
                      </ul>
                    </li>
                  </ul>
                </React.Fragment>
              )
            }
          ],
        }
      ],
      bottomContent: []
    },
    {
      "name": "Deductible Reimbursement",
      "img": require("assets/img/reimbursement.png"),
      "description": "Covers your auto insurance deductible. Limitations apply so please be sure to review your contract for specific terms of coverage.",
      forms:
        [
          {
            title: "Collision",
            link: "/files/forms/OnlineDeductibleReimbursementCollisionForm091212.pdf"
          },
          {
            title: "Comprehensive & Theft",
            link: "/files/forms/OnlineDeductibleReimbursementComprehensiveForm091212.pdf"
          },
          {
            title: "Accident Affidavit",
            link: "/files/forms/Accident Affidavit.pdf"
          },
          {
            title: "Theft and Fire Affidavit",
            link: "/files/forms/Theft and Fire Affidavit.pdf"
          }
        ],

      mainContent:
        (
          <React.Fragment>
            <h2 style={productsStyle.title}>
              Deductible Reimbursement
            </h2>
            <h3>
              Covers your insurance deductible due to Collision, Comprehensive and/or theft.
            </h3>
          </React.Fragment>
        ),
      fullText:
        [
          {
            title: (
              <div style={{
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'column',
                textAlign: 'center'
              }}>
                Procedure for Filing<br/>
                <small>Report your claim as soon as possible by calling our Claims Center at 888-684-9327.
                  <br/>
                  Working hours are Monday – Friday 8:30 a.m. to 5:00 p.m. Eastern Time.
                </small>
              </div>
            ),
            steps: [
              {
                title: "What You Need",
                icon: "CollectInformationIcon",
                details: (
                  <React.Fragment>
                    <ul>
                      <li>
                        Please make sure that all documents provided to our office include the claim number.
                      </li>
                      <li>
                        Complete and return the Deductible Reimbursement Proof of Loss form, Accident Affidavit, and
                        Deductible Payment Affidavit. See Accident Affidavit above for collision claims
                        (impact of your vehicle with another vehicle or object). See Theft and Fire Affidavit above for
                        comprehensive claims (theft, vandalism, fire, water damage, etc).
                      </li>
                      <li>
                        A copy of your auto insurance declarations (this is the page of your policy that describes the
                        vehicle(s) covered, the coverage limits, and the deductible(s).
                      </li>
                      <li>
                        A copy of the police report (if applicable)
                      </li>
                      <li>
                        For vehicles declared a total loss, we also need:
                        <ul>
                          <li>
                            A copy of your auto insurance company total loss settlement letter showing the settlement
                            amount and the application of deductible to settlement amount.
                          </li>
                          <li>
                            Note that for total losses, the Deductible Payment Affidavit form is not needed.
                          </li>
                        </ul>
                      </li>
                      <li>
                        For repairable vehicles, we also need:
                        <ul>
                          <li>
                            A copy of your auto insurance company repair estimate.
                          </li>
                          <li>
                            Proof of your payment to the repair facility for the listed damage (canceled check or
                            credit/debit card receipt)
                          </li>
                          <li>
                            Deductible Payment Affidavit
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </React.Fragment>
                )
              },
              {
                title: "How to File Your Claim",
                icon: 'FileClaimIcon',
                details: (
                  <React.Fragment>
                    <ul>
                      <li>
                        You must report your claim within sixty (60) days of the accident/incident date.
                      </li>
                      <li>
                        Call our Claims Center at 888-684-9327 Monday through Friday from 8:30 a.m. to 5:00 p.m. Eastern
                        Time.
                      </li>
                      <li>
                        You will be provided with a claim number, instructions, and credentials to access our online
                        claims portal.
                      </li>
                    </ul>
                  </React.Fragment>
                )
              },
              {
                title: "What to Expect",
                icon: 'ReviewIcon',
                details: (
                  <React.Fragment>
                    <ul>
                      <li>
                        You must complete and return the required forms along with your supporting documentation as
                        soon as possible.
                      </li>
                      <li>
                        Incomplete documentation could result in significant claim settlement delays.
                      </li>
                      <li>
                        You can check the status of your claim and upload documents to us by logging in to our Member
                        Claims Portal. Just click the “Access Claims” button at the top of this page and select “Members
                        Area”. If you reported your claim by phone, you can log in with the credentials that we provided
                        to you at the outset of the claim. These credentials are also listed on any letter that we sent
                        to you.
                      </li>
                      <li>
                        If you do not wish to upload your documents through the Member Claims Portal, you may email
                        them to GC@nsdmc.com, or mail them to our Claims Center 800 W. Yamato Road Suite 100 Boca Raton,
                        FL 33431.
                      </li>
                      <li>
                        We will process your documents, usually within 5 business days of the date we receive them.
                        That means that we will review them and record them in our claim file. If the documentation
                        submitted is incomplete, we will send you a written request for the missing items.
                      </li>
                      <li>
                        If the documentation is complete and the claim is payable, we will send a check to the address
                        we have for you on file. If the claim is declined, you will be notified in writing.
                      </li>
                    </ul>
                  </React.Fragment>
                )
              },
              {
                title: "Important Notes",
                icon: 'ImportantIcon',
                details: (
                  <React.Fragment>
                    <ul>
                      <li>
                        Once you log in to the Claim Portal, you will be given the option to receive automated messages
                        regarding your claim, and we highly recommend that you use this free service.
                      </li>
                      <li>
                        Uploading your documents through the Member Claims Portal is the fastest and most reliable way
                        to provide us with your documents. We strongly encourage you to use this free utility.
                      </li>
                    </ul>
                  </React.Fragment>
                )
              }
            ],
          }
        ]
    },
    {
      "name": "Windshield Protection",
      "img": require("assets/img/windshield-protection.png"),
      "description": "Repair of certain minor damages to the front windshield of covered vehicle.",
      mainContent:
        (
          <React.Fragment>
            <h3>
              We will reimburse you or arrange for the repair of certain minor damages to the front windshield of your
              covered vehicle. Some contracts will also provide for the replacement of the front windshield if it cannot
              be repaired. Limitations apply so please be sure to review your contract for specific terms of coverage.
            </h3>
          </React.Fragment>
        ),
      fullText:
        [
          {
            title: (
              <div style={{
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'column',
                textAlign: 'center'
              }}>
                Procedure for Filing<br/>
                <small>Glass damage has a tendency to spread, so it is imperative that you report your claim as soon as
                  possible. IMPORTANT: claims that are not reported within 15 days of the damage date may be denied.
                  Report your claim as soon as possible by calling our Claims Center at 888-684-9327.
                  <br/>
                  Working hours are Monday – Friday 8:30 a.m. to 5:00 p.m. Eastern Time.
                </small>
              </div>
            ),
            steps: [
              {
                title: "What You Need",
                icon: "CollectInformationIcon",
                details: (
                  <React.Fragment>
                    <ul>
                      <li>
                        Cause of loss-how did the windshield become damaged?
                      </li>
                      <li>
                        Location of damage.
                      </li>
                      <li>
                        Is the damage larger than a dollar bill?
                      </li>
                    </ul>
                  </React.Fragment>
                )
              },
              {
                title: "How to File Your Claim",
                icon: 'FileClaimIcon',
                details: (
                  <React.Fragment>
                    <ul>
                      <li>
                        Call our Claims Center at 888-684-9327 Monday through Friday from 8:30 a.m. to 5:00 p.m. Eastern
                        Time.
                      </li>
                      <li>
                        You will be provided with a claim number, instructions, and credentials to access our online
                        claims portal.
                      </li>
                    </ul>
                  </React.Fragment>
                )
              },
              {
                title: "What to Expect",
                icon: 'ReviewIcon',
                details: (
                  <React.Fragment>
                    <ul>
                      <li>
                        If your windshield is repairable, you have two options:
                        You may have the windshield repaired by a vendor of your choosing and submit the receipt to us
                        for reimbursement consideration. We will reimburse up to $60 for covered claims; or,
                        We can dispatch a service provider at our expense who can repair your windshield at your home
                        or place of business.
                      </li>
                      <li>
                        If your windshield is not repairable and you have windshield replacement coverage, we will
                        dispatch a service provider to replace your windshield at our expense.
                      </li>
                      <li>
                        You can check the status of your claim and upload documents to us by logging in to our Member
                        Claims Portal. Just click the “Access Claims” button at the top of this page and select “Members
                        Area”. If you reported your claim by phone, you can log in with the credentials that we provided
                        to you at the outset of the claim. These credentials are also listed on any letter that we sent
                        to
                        you.
                      </li>
                      <li>
                        If you do not wish to upload your documents through the Member Claims Portal, you may email them
                        to GC@nsdmc.com, or mail them to our Claims Center 800 W. Yamato Road Suite 100 Boca Raton, FL
                        33431.
                      </li>
                      <li>
                        We will process your documents, usually within 5 business days of the date we receive them. That
                        means that we will review them and record them in our claim file. If the documentation submitted
                        is incomplete, we will send you a written request for the missing items.
                      </li>
                      <li>
                        If the documentation is complete and the claim is payable, we will send a check for
                        reimbursements to the address we have for you on file. If we dispatch a service provider, we
                        will
                        pay the service provider directly.
                      </li>
                      <li>
                        If the claim is declined, you will be notified in writing.
                      </li>
                    </ul>
                  </React.Fragment>
                )
              },
              {
                title: "Important Notes",
                icon: 'ImportantIcon',
                details: (
                  <React.Fragment>
                    <ul>
                      <li>
                        Kindly note that we will not consider payment for services rendered by
                        unauthorized glass repair
                        contractors.
                      </li>
                    </ul>
                  </React.Fragment>
                )
              }
            ],
          }
        ],
      bottomContent:
        [
          (
            <React.Fragment>
              <h3>
                Please be advised that no action taken in the investigation of any claim should be construed
                as an
                admission of coverage or liability. We reserve the right to deny any claim for any valid
                reasons as
                described in the contract. Please be further advised that this is a generic claim
                instruction site and
                contains instructions regarding several types of coverages. This does not imply or suggest
                that your
                contract contains these coverages. Should you have any questions regarding coverage, please
                read your
                contract carefully and/or consult your agent.
              </h3>
            </React.Fragment>
          )
        ]
    },
    {
      "name": "Medical, Hospital Indemnity, and Accidental Death",
// filelink: "/files/forms/OnlineMedicalAndADDForms091212.pdf",
      "img": require("assets/img/medical-accidental-death.png"),
      "description": "Provides reimbursement of certain expenses if you are injured in a motor vehicle accident. Limitations apply so please be sure to review your contract for specific terms of coverage.",
      forms:
        [
          {
            title: "Medical and ADD",
            link: "/files/forms/OnlineMedicalAndADDForms091212.pdf"
          }
        ],
      mainContent:
        (
          <React.Fragment>
            <h3>
            </h3>
          </React.Fragment>
        ),
      fullText:
        [
          {
            title: (
              <div style={{
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'column',
                textAlign: 'center'
              }}>
                Procedure for Filing<br/>
                <small>Report your claim as soon as possible by calling our Claims Center at 888-684-9327.
                  <br/>
                  Working hours are Monday – Friday 8:30 a.m. to 5:00 p.m. Eastern Time.
                </small>
              </div>
            ),
            steps: [
              {
                title: "What You Need",
                icon: "CollectInformationIcon",
                details: (
                  <React.Fragment>
                    <ul>
                      <li>
                        Please make sure that all documents provided to our office include the claim number.
                      </li>
                      <li>
                        Complete and return all forms in the Medical & Accidental Death Proof of Loss packet which you
                        can get by downloading the form above. Note that the Affidavit must be notarized.
                      </li>
                      <li>
                        A copy of your auto insurance declarations (this is the page of your policy that describes the
                        vehicle(s) covered, the coverage limits, the deductible(s), etc.
                      </li>
                      <li>
                        A copy of the complete police report including the narrative description of the accident.
                      </li>
                      <li>
                        Name, address, phone number, and policy number of any other health insurance or auto insurance
                        available to you.
                      </li>
                      <li>
                        Copies of all itemized medical bills related to the accident. For Hospital Indemnity claims, we
                        will need the complete hospital bill itemizing each daily room charge.
                      </li>
                      <li>
                        Copies of all Explanation of Benefits (EOB) forms from any applicable health insurance and/or
                        automobile insurance carrier for each medical bill submitted.
                      </li>
                      <li>
                        Completed CMS form which is included in the Medical & Accidental Death Proof of Loss packet.
                      </li>
                      <li>
                        For Accidental Death claims only: an original death certificate with raised seal, and a copy of
                        the autopsy report if an autopsy was performed.
                      </li>
                    </ul>
                  </React.Fragment>
                )
              },
              {
                title: "How to File Your Claim",
                icon: 'FileClaimIcon',
                details: (
                  <React.Fragment>
                    <ul>
                      <li>
                        You must report your claim within sixty (60) days of the accident/incident date.
                      </li>
                      <li>
                        Call our Claims Center at 888-684-9327 Monday through Friday from 8:30 a.m. to 5:00 p.m. Eastern
                        Time.
                      </li>
                      <li>
                        You will be provided with a claim number, instructions, and credentials to access our online
                        claims portal.
                      </li>
                    </ul>
                  </React.Fragment>
                )
              },
              {
                title: "What to Expect",
                icon: 'ReviewIcon',
                details: (
                  <React.Fragment>
                    <ul>
                      <li>
                        You must complete and return the required forms along with your supporting documentation as soon
                        as possible.
                      </li>
                      <li>
                        Incomplete documentation could result in significant claim settlement delays.
                      </li>
                      <li>
                        You can check the status of your claim and upload documents to us by logging in to our Member
                        Claims Portal. Just click the “Access Claims” button at the top of this page and select “Members
                        Area”. If you reported your claim by phone, you can log in with the credentials that we provided
                        to you at the outset of the claim. These credentials are also listed on any letter that we sent
                        to
                        you.
                      </li>
                      <li>
                        If you do not wish to upload your documents through the Member Claims Portal, you may email them
                        to GC@nsdmc.com, or mail them to our Claims Center 800 W. Yamato Road Suite 100 Boca Raton, FL
                        33431.
                      </li>
                      <li>
                        We will process your documents, usually within 5 business days of the date we receive them. That
                        means that we will review them and record them in our claim file. If the documentation submitted
                        is incomplete, we will send you a written request for the missing items.
                      </li>
                      <li>
                        If the documentation is complete and the claim is payable, we will send a check to the address
                        we
                        have for you on file. Accidental Death claims will be paid to the named beneficiary if
                        applicable.
                        If there is no named beneficiary, payment will be issued to the estate of the deceased. If the
                        claim is declined, you will be notified in writing.
                      </li>
                    </ul>
                  </React.Fragment>
                )
              },
              {
                title: "Important Notes",
                icon: 'ImportantIcon',
                details: (
                  <React.Fragment>
                    <ul>
                      <li>
                        Once you log in, you will be given the option to receive automated messages regarding your
                        claim, and we highly recommend that you use this free service.
                      </li>
                      <li>
                        Uploading your documents through the Member Claims Portal is the fastest and most reliable way
                        to provide us with your documents. We strongly encourage you to use this free utility.
                      </li>
                    </ul>
                  </React.Fragment>
                )
              }
            ],
          }
        ],
      bottomContent:
        [
          (
            <React.Fragment>
              {/*<h3>*/}
              {/*  Please be advised that no action taken in the investigation of any claim should be construed*/}
              {/*  as an*/}
              {/*  admission of coverage or liability. We reserve the right to deny any claim for any valid*/}
              {/*  reasons as*/}
              {/*  described in the contract. Please be further advised that this is a generic claim*/}
              {/*  instruction site and*/}
              {/*  contains instructions regarding several types of coverages. This does not imply or suggest*/}
              {/*  that your*/}
              {/*  contract contains these coverages. Should you have any questions regarding coverage, please*/}
              {/*  read your*/}
              {/*  contract carefully and/or consult your agent.*/}
              {/*</h3>*/}
            </React.Fragment>
          )
        ]
    },
    {
      "name": "RV on Site Repair",
      "img": require("assets/img/rv-repair.png"),
      "description": "RV only on site repair claim form and Trip Interruption Reimbursement.",
      forms:
        [
          {
            title: "RV",
            link: "/files/forms/OnlineRVOnSiteRepairAndTripInterruptionReimbursement091212.pdf"
          }
        ],
      mainContent:
        (
          <React.Fragment>
            <h3>
              Please find the necessary claim form for your review and completion. We would be most
              appreciative if you
              would take a few minutes to complete the applicable form in detail and return them to us
              promptly. Your
              cooperation in this regard is part of our claim investigation and will assist us in our
              evaluation of this
              loss. We respectfully remind you that your cooperation in our investigation is a post-loss
              requirement of
              your contract, and that all claims must be submitted to our offices within 60 days of the date
              of loss.
            </h3>
          </React.Fragment>
        ),
      fullText:
        [
          {
            title: (
              <div style={{
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'column',
                textAlign: 'center'
              }}>
                Procedure for Filing<br/>
                <small>Working hours are Monday - Friday from 9:00 a.m. to 8:00 p.m. eastern time</small>
              </div>
            ),
            steps: [
              {
                title: "What You Need",
                icon: "CollectInformationIcon",
                details: (
                  <React.Fragment>
                    <ul>
                      <li>Legible copy of RV Shield Service Agreement.</li>
                    </ul>
                  </React.Fragment>
                )
              },
              {
                title: "How to File Your Claim",
                icon: 'FileClaimIcon',
                details: (
                  <React.Fragment>
                    <ul>
                      <li>Complete, sign and date Claim Form.</li>
                      <li>Send the form to</li>
                    </ul>
                  </React.Fragment>
                )
              },
              {
                title: "What to Expect",
                icon: 'ReviewIcon',
                details: (
                  <React.Fragment>
                    <ul>
                      <li>
                        Once you have returned the applicable form(s) along with the supporting
                        documentation specified
                        thereon, we will process your claim in the order it was received, typically
                        within 14 business
                        days.
                      </li>
                    </ul>
                  </React.Fragment>
                )
              },
              {
                title: "Important Notes",
                icon: 'ImportantIcon',
                details: (
                  <React.Fragment>
                    <ul>
                      <li>
                      </li>
                    </ul>
                  </React.Fragment>
                )
              }
            ],
          }
        ],
      bottomContent:
        [
          (
            <React.Fragment>
              <h3>
                This is a generic claim instruction site and contains instructions regarding several types
                of coverages.
                This does not imply or suggest that your contract contains these coverages. Should you have
                any questions
                regarding coverage, please read your contract carefully and/or consult your dealer.
              </h3>
            </React.Fragment>
          )
        ]
    },
    {
      "name": "Rental Reimbursement",
      "img": require("assets/img/reimbursement.png"),
      "description": "Provides reimbursement of certain auto rental expenses if your vehicle is disabled. Limitations apply so please be sure to review your contract for specific terms of coverage.",
      forms:
        [
          {
            title: "Collision",
            link: "/files/forms/OnlineAccidentAffidavit091212.pdf"
          }, {
          title: "Comprehensive/Theft",
          link: "/files/forms/OnlineAffidavitOfVehicleTheft-Incident091212.pdf"
        }, {
          title: "Mechanical Breakdown",
          link: "/files/forms/MechanicalBreakdownAffidavit091212.pdf"
        },
        ],
      mainContent:
        (
          <React.Fragment>
            <h3>
            </h3>
          </React.Fragment>
        ),
      fullText:
        [
          {
            title: (
              <div style={{
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'column',
                textAlign: 'center'
              }}>
                Procedure for Filing<br/>
                <small>
                  Report your claim as soon as possible by calling our Claims Center at 888-684-9327.
                  <br/>
                  Working hours are Monday – Friday 8:30 a.m. to 5:00 p.m. Eastern Time.
                </small>
              </div>
            ),
            steps: [
              {
                title: "What You Need",
                icon: "CollectInformationIcon",
                details: (
                  <React.Fragment>
                    <ul>
                      <li>
                        Please make sure that all documents provided to our office include the claim number.
                      </li>
                      <li>
                        Complete and return the applicable Affidavit and Rental Reimbursement Proof of Loss form :
                        <ul>
                          <li>
                            See above for collision claims (impact of your vehicle with another vehicle or object).
                          </li>
                          <li>
                            See above for comprehensive claims (theft, vandalism, fire, water damage, etc).
                          </li>
                          <li>
                            See above for mechanical breakdown claims.
                            *NOTE: The affidavit must be notarized
                          </li>
                        </ul>
                      </li>
                      <li>
                        A copy of your paid auto rental invoice (must include dates of rental and daily and/or weekly
                        rates).
                      </li>
                      <li>
                        A copy of your auto insurance declarations (this is the page of your policy that describes the
                        vehicle(s) covered, the coverage limits, the deductible(s), etc.
                      </li>
                      <li>
                        A copy of the police report (if applicable). If there was no police report, please provide a
                        written explanation.
                      </li>
                      <li>
                        For vehicles declared a total loss, we need a copy of your auto insurance company total loss
                        settlement letter showing the settlement amount.
                      </li>
                      <li>
                        For repairable vehicles, we need a copy of your auto insurance company repair estimate including
                        any supplemental estimates.
                      </li>
                      <li>
                        NOTE: some products provide for the daily rental car charge only and do not include collision
                        damage waivers, taxes, other insurance, or any other fees or surcharges that may be incurred at
                        the time of rental.
                      </li>
                    </ul>
                  </React.Fragment>
                )
              },
              {
                title: "How to File Your Claim",
                icon: 'FileClaimIcon',
                details: (
                  <React.Fragment>
                    <ul>
                      <li>
                        You must report your claim within sixty (60) days of the accident/incident date.
                      </li>
                      <li>
                        Call our Claims Center at 888-684-9327 Monday through Friday from 8:30 a.m. to 5:00 p.m. Eastern
                        Time.
                      </li>
                      <li>
                        You will be provided with a claim number, instructions, and credentials to access our online
                        claims portal.
                      </li>
                    </ul>
                  </React.Fragment>
                )
              },
              {
                title: "What to Expect",
                icon: 'ReviewIcon',
                details: (
                  <React.Fragment>
                    <ul>
                      <li>
                        All claims are paid on a reimbursement basis only. That means you must arrange for your rental
                        vehicle, pay the bill, and then submit the paid bill and supporting documents to us for review.
                      </li>
                      <li>
                        You must complete and return the required forms along with your supporting documentation as soon
                        as possible.
                      </li>
                      <li>
                        Incomplete documentation could result in significant claim settlement delays.
                      </li>
                      <li>
                        You can check the status of your claim and upload documents to us by logging in to our Member
                        Claims Portal. Just click the “Access Claims” button at the top of this page and select “Members
                        Area”. If you reported your claim by phone, you can log in with the credentials that we provided
                        to you at the outset of the claim. These credentials are also listed on any letter that we sent
                        to
                        you.
                      </li>
                      <li>
                        If you do not wish to upload your documents through the Member Claims Portal, you may email them
                        to GC@nsdmc.com, or mail them to our Claims Center 800 W. Yamato Road Suite 100 Boca Raton, FL
                        33431.
                      </li>
                      <li>
                        We will process your documents, usually within 5 business days of the date we receive them. That
                        means that we will review them and record them in our claim file. If the documentation submitted
                        is incomplete, we will send you a written request for the missing items.
                      </li>
                      <li>
                        If the documentation is complete and the claim is payable, we will send a check to the address
                        we
                        have for you on file. If the claim is declined, you will be notified in writing.
                      </li>
                    </ul>
                  </React.Fragment>
                )
              },
              {
                title: "Important Notes",
                icon: 'ImportantIcon',
                details: (
                  <React.Fragment>
                    <ul>
                      <li>
                        Once you log in, you will be given the option to receive automated messages regarding your
                        claim, and we highly recommend that you use this free service.
                      </li>
                      <li>
                        Uploading your documents through the Member Claims Portal is the fastest and most reliable way
                        to provide us with your documents. We strongly encourage you to use this free utility.
                      </li>
                    </ul>
                  </React.Fragment>
                )
              }
            ],
          }
        ],
      bottomContent:
        [
          (
            <React.Fragment>
              <h3>
                Note: some products provide for the daily rental car charge only and do not include
                collision damage
                waivers, taxes, other insurance, or any other fees or surcharges that may be incurred at the
                time of
                rental. Please see your contract for specific coverage terms and conditions.
              </h3>
            </React.Fragment>
          )
        ]
    },
    {
      "name": "Dent & Ding Protection",
      "img": require("assets/img/car-dent.png"),
      "description": "Repair of minor dents and dings on covered vehicle.",
      mainContent:
        (
          <React.Fragment>
            <h3>
              We will arrange for the repair of certain minor dents and/or dings to your covered vehicle. Limitations
              apply so please be sure to review your contract for specific terms of coverage
            </h3>
          </React.Fragment>
        ),
      fullText:
        [
          {
            title: (
              <div style={{
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'column',
                textAlign: 'center'
              }}>
                Procedure for Filing<br/>
                <small>Report your claim as soon as possible by calling our Claims Center at 888-684-9327.
                  <br/>
                  Working hours are Monday – Friday 8:30 a.m. to 5:00 p.m. Eastern Time.
                </small>
              </div>
            ),
            steps: [
              {
                title: "What You Need",
                icon: "CollectInformationIcon",
                details: (
                  <React.Fragment>
                    <ul>
                      <li>
                        Cause of loss-how did the vehicle become damaged?
                      </li>
                      <li>
                        Location of all dents/dings.
                      </li>
                    </ul>
                  </React.Fragment>
                )
              },
              {
                title: "How to File Your Claim",
                icon: 'FileClaimIcon',
                details: (
                  <React.Fragment>
                    <ul>
                      <li>
                        Call our Claims Center at 888-684-9327 Monday through Friday from 8:30 a.m. to 5:00 p.m. Eastern
                        Time.
                      </li>
                      <li>
                        You will be provided with a claim number, instructions, and credentials to access our online
                        claims portal.
                      </li>
                    </ul>
                  </React.Fragment>
                )
              },
              {
                title: "What to Expect",
                icon: 'ReviewIcon',
                details: (
                  <React.Fragment>
                    <ul>
                      <li>
                        We will take some information from you, and for covered claims, we will dispatch a service
                        provider from our Network.
                      </li>
                      <li>
                        The service provider will arrange a mutually convenient appointment to repair covered dents.
                      </li>
                      <li>
                        Please note that there is no coverage for large or deep dents, creased metal, or any area with
                        paint damage.
                      </li>
                      <li>
                        You can check the status of your claim and upload any required documents to us by logging in to
                        our Member Claims Portal. Just click the “Access Claims” button at the top of this page and
                        select
                        “Members Area”. If you reported your claim by phone, you can log in with the credentials that we
                        provided to you at the outset of the claim. These credentials are also listed on any letter that
                        we sent to you.
                      </li>
                      <li>
                        If you do not wish to upload your documents through the Member Claims Portal, you may email them
                        to GC@nsdmc.com, or mail them to our Claims Center 800 W. Yamato Road Suite 100 Boca Raton, FL
                        33431.
                      </li>
                      <li>
                        We will process your documents, usually within 5 business days of the date we receive them. That
                        means that we will review them and record them in our claim file. If the documentation submitted
                        is incomplete, we will send you a written request for the missing items.
                      </li>
                      <li>
                        For covered claims, we will pay the service provider directly.
                      </li>
                      <li>
                        If the claim is declined, you will be notified in writing.
                      </li>
                    </ul>
                  </React.Fragment>
                )
              },
              {
                title: "Important Notes",
                icon: 'ImportantIcon',
                details: (
                  <React.Fragment>
                    <ul>
                      <li>
                        Kindly note that we will not consider payment for services rendered by
                        unauthorized dent & ding
                        repair contractors.
                      </li>
                    </ul>
                  </React.Fragment>
                )
              }
            ],
          }
        ],
      bottomContent:
        [
          (
            <React.Fragment>
              <h3>
                Please be advised that no action taken in the investigation of any claim should be construed
                as an
                admission of coverage or liability. We reserve the right to deny any claim for any valid
                reasons as
                described in the contract. Please be further advised that this is a generic claim
                instruction site and
                contains instructions regarding several types of coverages. This does not imply or suggest
                that your
                contract contains these coverages. Should you have any questions regarding coverage, please
                read your
                contract carefully and/or consult your agent.
              </h3>
            </React.Fragment>
          )
        ]
    },
    {
      "name": "Theft & Total Loss Protection",
      "img": require("assets/img/theft-protection.png"),
      "description": (
        <React.Fragment>
          Provides benefits in the event that your vehicle is stolen. Some contracts also provide benefits in the event
          that your vehicle becomes a total loss due to causes other than theft. Limitations apply so please be sure to
          review your contract for specific terms of coverage.
          for
          an amount not to exceed your plan limit. Be sure to review your contract for specific terms of coverage.
          <br/>
          <br/>
          Administered by NIU of Florida, Inc.
        </React.Fragment>
      ),
      mainContent:
        (
          <React.Fragment>
            <h3>
              In order to promptly resolve claim, it is imperative that we be provided with all of the
              following
              documentation. Failure to submit legible copies of all requested documents may significantly
              delay
              processing of your claim. ALL DOCUMENTS MUST BE SUMITTED WITHIN CONTRACTUAL TIMEFRAME. SEE
              CONTRACT FOR
              DETAILS.
            </h3>
          </React.Fragment>
        ),
      forms:
        [
          {
            title: "Theft",
            link: "/files/forms/OnlineVehicleTheftAffidavitWithBusinessUse091212_final.pdf"
          },
          {
            title: "Accident",
            link: "/files/forms/OnlineVehicleTheftAffidavitWithBusinessUse091212_final.pdf"
          }
        ],
      fullText:
        [
          {
            title: (
              <div style={{
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'column',
                textAlign: 'center'
              }}>
                Procedure for Filing<br/>
                <small>Report your claim as soon as possible by calling our Claims Center at
                  888-684-9327.<br/>
                  Working hours are Monday – Friday 8:30 a.m. to 5:00 p.m. Eastern Time.
                </small>
              </div>
            ),
            steps: [
              {
                title: "What You Need",
                icon: "CollectInformationIcon",
                details: (
                  <React.Fragment>
                    <ul>
                      <li>
                        Please make sure that all documents provided to our office are legible and
                        include the claim number.
                      </li>
                      <li>
                        Please note that each claim is distinctive and the claim administrator may
                        request additional information not listed below.
                      </li>
                      <li>
                        Complete and return the applicable Affidavit:
                        <ul>
                          <li>
                            See above for theft claims.
                          </li>
                          <li>
                            See above for total loss claims other than theft.
                            <br/><b>NOTE: The affidavit must be notarized.</b>
                          </li>
                        </ul>
                      </li>
                      <li>
                        Complete copy of your Theft/Total Loss Protection contract.
                      </li>
                      <li>
                        Copy of the Covered Vehicle Finance Agreement.
                      </li>
                      <li>
                        Copy of the Covered Vehicle Buyer’s Order.
                      </li>
                      <li>
                        Copy of the police report with complete narrative of the incident.
                      </li>
                      <li>
                        Copy of the police recovery report if the vehicle was recovered.
                      </li>
                      <li>
                        Copy of your auto insurance declarations (this is the page of your policy
                        that describes the vehicle(s) covered, the coverage limits, the
                        deductible(s), etc.
                      </li>
                      <li>
                        Copy of your auto insurance company’s settlement check.
                      </li>
                      <li>
                        Copy of your auto insurance company’s settlement breakdown letter.
                      </li>
                      <li>
                        If your vehicle was not insured, please notify us in writing.
                      </li>
                      <li>
                        If you are claiming the total loss Dealer Replacement Benefit:
                        <ul>
                          <li>
                            You must return to the dealership where you purchased the covered
                            vehicle to purchase the replacement vehicle. If that dealership is
                            out of business, or if you are otherwise unable to return to the
                            original selling dealer, please call our Claims Center for
                            assistance.
                          </li>
                          <li>
                            Complete and return the Affidavit of Vehicle Replacement included with the Proof of Loss
                            above.
                          </li>
                          <li>
                            Provide us with a copy of the replacement vehicle buyer’s order
                            and/or bill of sale.
                          </li>
                        </ul>
                      </li>
                      <li>
                        For Partial Loss benefits, provide a copy of your auto insurance company’s
                        repair estimate and proof that you paid your deductible to the repair
                        facility.
                      </li>
                    </ul>
                  </React.Fragment>
                )
              },
              {
                title: "How to File Your Claim",
                icon: 'FileClaimIcon',
                details: (
                  <React.Fragment>
                    <ul>
                      <li>
                        Call our Claims Center at <a href={"tel:18886849327"}> 1-888-684-9327.</a> Monday through Friday
                        from 8:30 a.m. to 5:00 p.m. Eastern
                        Time.
                      </li>
                      <li>
                        You will be provided with a claim number, instructions, and credentials to access our online
                        claims portal.
                      </li>
                    </ul>
                  </React.Fragment>
                )
              },
              {
                title: "What to Expect",
                icon: 'ReviewIcon',
                details: (
                  <React.Fragment>
                    <ul>
                      <li>
                        You must complete and return the required forms along with your supporting
                        documentation as soon as possible. Be sure to check your contract for claim
                        submission deadlines.
                      </li>
                      <li>
                        Incomplete documentation could result in significant claim settlement delays
                        or declination.
                      </li>
                      <li>
                        You can check the status of your claim and upload documents to us by logging
                        in to our Member Claims Portal. Just click the “Access Claims” button at the
                        top of this page and select “Members Area”. If you reported your claim by
                        phone, you can log in with the credentials that we provided to you at the
                        outset of the claim. These credentials are also listed on any letter that we
                        sent to you.
                      </li>
                      <li>
                        If you do not wish to upload your documents through the Member Claims
                        Portal, you may email them to Gapclaims@nsdmc.com, or mail them to our
                        Claims Center 800 W. Yamato Road Suite 100 Boca Raton, FL 33431.
                      </li>
                      <li>
                        We will process your documents, usually within 5 business days of the date
                        we receive them. That means that we will review them and record them in our
                        claim file. If the documentation submitted is incomplete, we will send you a
                        written request for the missing items.
                      </li>
                      <li>
                        If the documentation is complete and the claim is payable:
                        <ul>
                          <li>
                            For dealer replacement claims, we will issue a check to the
                            dealership from whom you purchased the replacement vehicle.
                          </li>
                          <li>
                            For Vehicle Protection expenses, we will send a check to the address
                            we have for you on file.
                          </li>
                        </ul>
                      </li>
                      <li>
                        If the claim is declined, you will be notified in writing.
                      </li>
                    </ul>
                  </React.Fragment>
                )
              },
              {
                title: "Important Notes",
                icon: 'ImportantIcon',
                details: (
                  <React.Fragment>
                    <ul>
                      <li>
                        For replacement vehicle benefit only, please contact your original selling
                        DEALERSHIP and obtain a
                        legible copy of the REPLACEMENT VEHICLE BUYERS ORDER AND/OR BILL OF SALE
                      </li>
                      <li>
                        All documents must be completely legible, otherwise the Guarantee Benefit
                        will be suspended until
                        legible copies can be obtained.
                      </li>
                      <li>
                        Each claim is distinctive and the claim administrator may request additional
                        information not
                        listed above
                      </li>
                    </ul>
                  </React.Fragment>
                )
              }
            ],
          }
        ],
      bottomContent:
        [
          (
            <React.Fragment>
              <h3>
                Please be advised that no action taken in the investigation of any claim should be construed
                as an
                admission of coverage or liability. We reserve the right to deny any claim for any valid
                reasons as
                described in the contract. Please be further advised that this is a generic claim
                instruction site and
                contains instructions regarding several types of coverages. This does not imply or suggest
                that your
                contract contains these coverages. Should you have any questions regarding coverage, please
                read your
                contract carefully and/or consult your dealer.
              </h3>
            </React.Fragment>
          )
        ]
    },
    {
      "name": "Nitrogen Tire Proof of Loss",
      filelink: "/files/forms/NitrogenTireclaim8_20_08.pdf",
      "img": require("assets/img/tire-wheel-product.png"),
      "description": "The information that you provide in this document will be used to evaluate your claim."
    },
    {
      name: "Value Protect",
      img: require("assets/img/value-protection-512.png"),
      description: `DEFEAT DEPRECIATION! Even if fully repaired, vehicles involved in accidents suffer depreciation 
                    up to 30%!`,
      externalLink: {
        href: 'https://valueprotect.nationsafedrivers.com/home',
        target: '_blank',
      }
    }
  ]
}
