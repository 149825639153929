import React from 'react';
import {Divider, Grid} from "@material-ui/core";
import SideBySideLayout from '../../../views/ProductDetails/Layouts/SideBySideLayout';

export default {
  "title": "Protections",
  image: require('assets/img/dealer-background-image-min.jpg'),
  "products":
    [
      {
        id: 'value-protect',
        name: 'Value Protect',
        externalLink: {
            href: 'https://valueprotect.nationsafedrivers.com/home',
            target: '_blank',
        }
      },
      {
        id: "traceable-theft-protection",
        "name": "Traceable Theft Protection",
        image: require("assets/img/car-2025538_640_color.png"),
        brochures: [
          {
            title: "Theft Protection",
            link: "brochures/traceable-theft-protection.pdf"
          }
        ],
        mainContent: "In the United States alone a vehicle is stolen every 20 seconds. That's over 1 million vehicles every year. Most are never recovered. The ones that are recovered usually have thousands of dollars in repairs. And if you have a deductible, you'll have to pay to get it repaired. Nation Safe Drivers Traceable Theft Protection Program is probably one of the best ways to make your car safe from professional thieves. They are not after the vehicle, they are after the money the vehicles brings them after they sell it. Nation Safe Drivers Traceable Theft Protection Program places a special identification code (and Nations Safe Drivers' 800 number) on your vehicle. This makes the vehicle traceable by police, so the thieves cannot sell it, because no one will risk buying it. With the Nation Safe Drivers' 800 number on your vehicle, anyone can call and report the vehicle stolen.",
        centerItems: [
          {
            title: "The Program",
            content: (
              <ol>
                <li>
                  Your registration number is permanently placed on your vehicle.
                </li>
                <li>
                  Only the Nation Safe Drivers Theft Protection Program provides real financial help when
                  you
                  need it the most.
                </li>
                <li>
                  When you begin to shop around for another vehicle, you receive reimbursement for a
                  rental
                  car
                  up to $25.00 a day for 20 days. That's $500.00!
                </li>
              </ol>
            )
          },
          {
            title: "Did you know?",
            content: (
              <ul>
                <li>Most insurance companies will reduce the cost of your comp/collision insurance when you have a
                  vehicle theft deterrent system installed?
                </li>
                <li>Nation Safe Drivers Theft Protection Program meets insurance requirements for reduction of insurance
                  premiums for vehicle theft deterrent systems?
                </li>
              </ul>
            )
          },
          {
            title: "Theft Program Coverages",
            content: "(Please see contract for the specific terms and conditions of coverage.)"
          }
        ],
        sections: [
          {
            title: "Protect Your Investment.",
            description: [
              "Wherever you are, you'll enjoy the secure feeling of knowing that you are protected by the Nation Safe Drivers Theft Protection Program. Fast response and excellent service are just two reasons why millions of motorists rely on Nation Safe Drivers for on-the-road protection."
            ]
          },
          {
            title: "In the event your vehicle is stolen and not recovered",
            description: [
              "You receive a cash benefit that you can use towards the purchase or lease of a replacement vehicle, to pay your deductible or in any way you wish. (Limit based on program purchased)"
            ]
          },
          {
            title: "Down Payment Help",
            description: [
              "When you return to your selling dealership to purchase or lease your vehicle, you receive an additional benefit that you can apply to the purchase or lease of your replacement vehicle. (Limit based on program purchased)"
            ]
          },
          {
            title: "Deductible Coverage",
            description: [
              "If your vehicle is recovered from theft but is NOT declared a total loss, you receive a reimbursement of up to $1,000 to pay your primary deductible."
            ]
          }
        ]
      },
      {
        id: 'gap-protection',
        "name": "Gap Protection",
        image: require("assets/img/gap-insurance_color.png"),
        mainContent: "Gap is a unique coverage program that protects you from financial disaster in case your vehicle is stolen or totaled. Here’s why: as your vehicle gets older, the actual cash value (ACV) declines, while your lease or loan balance may remain higher than what the insurance company will pay you. You are then liable for the difference between what the insurance company pays you and what you owe. Many people do not realize that they are responsible for that balance owed on the vehicle. GAP covers the difference between your loan or lease balance and your insurance settlement. In other words, if your insurance does not cover the cost of the totaled or stolen vehicle YOU HAVE TO PAY THE DIFFERENCE.",
        brochures: [
          {
            title: "Gap Protection",
            link: "brochures/gap-protection.pdf"
          },
          {
            title: "Total Loss Protection",
            link: "brochures/total-loss-protection.pdf"
          }
        ],
        centerItems: [
          {
            title: "Difference Is Waived",
            content: "With Nation Safe Drivers Gap Coverage, you are covered 100% in case your vehicle is stolen or totaled. Even your deductible is covered! GAP is real peace of mind. (Please see contract for the specific terms and conditions of coverage.)"
          },
          {
            title: "Gap Protection Program",
            content:
              (
                <div style={{
                  display: 'flex',
                  width: '100%',
                  justifyContent: 'center',
                  alignItems: 'center',
                  alignContent: 'center'
                }}>
                  <img style={{width: '80%'}}
                       src={require('assets/img/gappie.gif')}/>
                </div>
              )
          },
          {
            title: "Protect Your Investment",
            content:
              (
                <React.Fragment>
                  Wherever you are, you’ll like the secure feeling of knowing that you have Nation Safe Drivers GAP
                  Protection.
                  <br/>
                  Fast response and excellent service are just two reasons why millions of motorists rely on Nation Safe
                  Drivers for on-the-road protection.
                </React.Fragment>
              )
          }
        ],
        sections: []
      },
      {
        id: "tire-and-wheel-protection",
        "name": "Tire and Wheel Protection",
        image: require("assets/img/tire-wheel-product.png"),
        mainContent: "The NSD Tire & Wheel Protection Plan picks up where the factory warranty and service contract leaves off! Includes 3, 5, 7 Years of Coverage with UNLIMITED MILEAGE. Full coverage of all mounting and balancing associated with the repair of any tire repaired or replaced, under contract. NSD will also absorb the cost of taxes associated with the replacement of a tire or wheel.",
        mainContentNote: "Not available in all States. Some restrictions apply. See your contract for specific details of coverage.",
        brochures: [
          {
            title: "Motorcycle",
            link: "brochures/tire-wheel-road-hazard-motorcycle.pdf"
          },
          {
            title: "RV",
            link: "brochures/tire-wheel-road-hazard-rv.pdf"
          }
        ],
        centerItems: [
          {
            title: "Benefits backed by \"A\" rated insurance carrier",
            content: (
              <React.Fragment>
                <ul>
                  <li>Increase your customer satisfaction and retention</li>
                  <li>3 & 5 year program with NO DEDUCTIBLE</li>
                  <li>Cars, Light Trucks, Motorcycles, Single and Dual Axle Trailers</li>
                  <li>5 & 7 year trailer and RV Coverage</li>
                  <li>NO REPLACEMENT COST LIMITS*</li>
                </ul>
                <h6 style={{
                  fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                  bottom: "10px",
                  color: "#c0c1c2",
                  display: "block",
                  fontWeight: "400",
                  fontSize: "13px",
                  lineHeight: "13px"
                }}>
                  * For most programs, see contract for details.
                </h6>
              </React.Fragment>
            )
          },
          {
            title: "Covered Road Hazard",
            content: "Potholes, rocks, wood debris, metal parts, plastic or composite scraps or any item in the roadway that is not supposed to be there."
          },
          {
            title: "Coverage Includes",
            content:
              (
                <ul>
                  <li>Repair or replacement coverage for tire and rims (including mounting, balancing, and taxes)</li>
                  <li>Emergency road assistance benefit</li>
                </ul>
              )
          }
        ],
        sections: [
          {
            title: "Protect your investment",
            description: [
              "Whenever you are driving, you'll like the secure feeling of knowing that you are protected by the Nation Safe Drivers Tire and Wheel Road Hazard Protection Plan. Fast response and excellent service are just two reasons why millions of motorists rely on Nation Safe Drivers for on-the-road protection."
            ]
          },
          {
            title: "Flat tire coverage",
            description: [
              "You will be reimbursed for the full amount of charges incurred for the repair of flat tires caused by road hazard."
            ]
          },
          {
            title: "Tire replacement",
            description: [
              "Reimbursement is provided should the tire become nonrepairable due to impact leads, snags, cuts, puncture, or other road hazards."
            ]
          },
          {
            title: "Wheel/rim protection",
            description: [
              "Reimbursement is provided for expenses arising out of the repair or replacement of wheels rendered unserviceable due to failure of the wheel covered under your contract."
            ]
          },
          {
            title: "Mounting/balance and taxes",
            description: [
              "Reimbursement is provided for any tire covered by your agreement. We will absorb the cost of all local and state taxes, where applicable, as required as part of a covered repair."
            ]
          },
          {
            title: "24 Hour emergency battery assistance",
            description: [
              "All of our trucks are capable of jumpstarting your dead battery and tightening cables as needed."
            ]
          },
          {
            title: "24 Hour lockout service",
            description: [
              "Whether you locked your keys in the car or your keys are lost or damaged, we will send out a locksmith to help you out."
            ]
          }
        ]
      },
      {
        id: "identity-theft-protection",
        image: require("assets/img/theft-protection_color.png"),
        "name": "Identity Theft Protection",
        mainContent: (
          <React.Fragment>
            Identity Theft is the fastest growing crime in America. In a matter of seconds, personal information such as
            a social security number, a credit card number and/or an address can be stolen; leaving individuals to
            unravel the financial mess created by an identity thief.
            <br/>
            <br/>
            Members will have 24/7 access to Identity Theft Restoration Advocates who will provide them with
            comprehensive, personalized recovery services. (Please see contract for the specific terms and conditions of
            coverage.)
            <br/>
            <br/>
            If a member becomes a victim of identity theft, NSD assigns a Recovery Advocate to manage the case and
            activate the members of our Recovery Team (fraud investigators, legal counsel, and other experts) needed to
            help with the recovery process from beginning to end.
          </React.Fragment>
        ),
        mainContentNote: (
          <span
            style={{color: 'red'}}>Please see your contract for the specific terms and conditions of coverage.</span>
        ),
        centerItems: [
          {
            title: "Coverage",
            content: (
              <React.Fragment>
                <ul>
                  <li>Consult with the covered member to ascertain the severity of the theft and start the notification
                    process.
                  </li>
                  <li>Prepare and send the customized pre-completed, state specific "ID Recovery Kit™" to member via
                    Federal Express overnight..
                  </li>
                  <li>Contact all three credit reporting agencies to: obtain a free credit report for the covered
                    member, place fraud alerts on the covered member's credit records, and obtain a list of creditors
                    involved with the fraud.
                  </li>
                  <li>Notify the appropriate bank or agency if other forms of identification (such as an ATM card,
                    driver's license, social security card, or passport) were stolen or are missing.
                  </li>
                  <li>Provide fraud resolution, legal and emotional assistance from beginning to end</li>
                </ul>
              </React.Fragment>
            )
          }, {
            title: "Additional Coverage",
            content: (
              <React.Fragment>
                Each member will also receive up to $25,000 worth of insurance coverage provided by a member company of
                American International Group (AIG) Inc. This coverage will help offset some of the cost of restoring
                your identity to its original status including:
                <br/>
                <ul>
                  <li>Lost wages up to $500 per week for 4 weeks, which includes remuneration for vacation days,
                    floating holidays, and paid personal days.
                  </li>
                  <li>Fees for re-filing of loans.</li>
                  <li>Defense cost for certain civil lawsuits brought against you by a creditor, for non-payment of
                    goods or services or default on a loan as a result of a stolen identity event.
                  </li>
                  <li>Cost of removing any civil judgment wrongfully entered against you as a result of the stolen
                    identity event.
                  </li>
                  <li>Reimbursement of fees.</li>
                </ul>
              </React.Fragment>
            )
          }
        ]
      },
      {
        id: "vr-theft-protection",
        image: require("assets/img/car-2025538_640_color.png"),
        "name": "VR Theft Protection",
        mainContentNote: "(Please see contract for specific terms & conditions of coverage.)",
        brochures: [
          {
            title: "VR Theft Protection",
            link: "brochures/vehicle-replacement-traceable-theft.pdf"
          }
        ],
        mainContent: "In the United States alone a vehicle is stolen every 20 seconds. That’s over 1 million vehicles every year. Most are never recovered. The ones that are recovered usually have thousands of dollars in repairs. And if you have a deductible, you’ll have to pay to get it repaired. Nation Safe Drivers Traceable Theft Program is probably one of the best ways to make your car safe from professional thieves. They are not after the vehicle, they are after the money the vehicles brings them after they sell it. Nation Safe Drivers Traceable Theft Program places a special identification code (and Nations Safe Drivers' 800 number) on your vehicle. This makes the vehicle traceable by police, so the thieves cannot sell it, because no one will risk buying it. With the Nation Safe Drivers' 800 number on your vehicle, anyone can call and report the vehicle stolen.",
        centerItems: [
          {
            title: "Did you know?",
            content: (
              <React.Fragment>
                <ul>
                  <li>Most insurance companies will reduce the cost of your comp/collision insurance when you have a
                    vehicle theft deterrent system installed?
                  </li>
                  <li>Nation Safe Drivers Theft Protection Program meets insurance requirements for reduction of
                    insurance premiums for vehicle theft deterrent systems?
                  </li>
                </ul>
              </React.Fragment>
            )
          },
          {
            title: "The Program",
            content: (
              <React.Fragment>
                <ol>
                  <li>Your registration number is placed on your vehicle.</li>
                  <li>Only the Nation Safe Drivers Theft Protection Program provides real financial help when you need
                    it the most.
                  </li>
                  <li>When you begin to shop around for another vehicle, you receive reimbursement for a rental car up
                    to $500.00.
                  </li>
                </ol>
              </React.Fragment>
            )
          },
          {
            title: "Program Coverages",
            content: (
              <React.Fragment>
                <b>In the event your vehicle is stolen and not recovered</b>
                <ul>
                  <li>You receive a replacement benefit (up to $25,000.00) that you can use towards the purchase or
                    lease of a replacement vehicle.
                  </li>
                  <li>Wherever you are, you'll enjoy the secure feeling of knowing that you are protected by the Nation
                    Safe Drivers Theft Protection Program.
                  </li>
                  <li>Fast response and excellent service are just two reasons why millions of motorists rely on Nation
                    Safe Drivers for on-the-road protection.
                  </li>
                </ul>
              </React.Fragment>
            )
          }
        ]
      },
      {
        id: 'appearance-protection',
        brochures: [
          {
            title: "Appearance Protection",
            link: "brochures/paint-interior-protection.pdf"
          }
        ],
        "name": "Appearance Protection",
        mainContent: (
          <React.Fragment>
            <Grid container>
              <Grid item xs={12} sm={12} md={12} lg={6} xl={6} style={{marginBottom: 15}}>
                <div style={{textAlign: 'left'}}>
                  <b style={{float: 'left'}}>Exterior</b>
                  <br/>
                  <img
                    style={{
                      marginRight: 10,
                      float: 'left',
                      backgroundClip: 'padding-box',
                      borderRadius: "6px",
                      width: 175,
                      boxShadow:
                        "0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)"
                    }}
                    alt={"car exterior"}
                    src={require('assets/img/car-3956530_640.jpg')}
                  />
                  A professionally applied system maintains your vehicle's "showroom new" paint finish...
                  <br/>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                <div style={{textAlign: 'left'}}>
                  <b>Interior</b>
                  <br/>
                  <img
                    style={{
                      marginLeft: 10,
                      float: 'right',
                      width: 175,
                      backgroundClip: 'padding-box',
                      borderRadius: "6px",
                      boxShadow:
                        "0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)"
                    }}
                    alt={"car interior"}
                    src={require('assets/img/car-4036152_640.jpg')}
                  />
                  Keeping your interior fresh, vibrant and rich looking is challenging considering the rough and rugged
                  use your vehicle gets day-in and day-out. Nation Safe Drivers offers that protection!
                </div>
              </Grid>
            </Grid>
            <br/>
            <Divider/>
            <br/>
            <br/>
            Nation Safe Drivers Appearance Protection System protects your vehicle against acid rain, inclement weather
            elements, tree sap and bird droppings. (Please see contract for the specific terms and conditions of
            coverage.) The EPA - United States Environmental Protection Agency states that paint may be damaged by some
            form of environmental fallout, i.e. acid rain, etc. Protect now. Keep that beautiful showroom shine and
            you'll enjoy added value at trade-in time.
          </React.Fragment>
        ),
        centerItemsTitle: "Environmental Paint Protection",
        centerItems: [
          {
            title: "Tree sap, bird droppings",
            content: (
              <img style={{width: '100%', maxHeight: 270}} src={require('assets/img/bird-droppings.gif')}/>
            )
          },
          {
            title: "U.V. sun rays, weather elements",
            content: (
              <img style={{width: '100%', maxHeight: 270}} src={require('assets/img/sun.gif')}/>
            )
          },
          {
            title: "Acid rain, industrial fallout",
            content: (
              <img style={{width: '100%', maxHeight: 270}} src={require('assets/img/industrial.gif')}/>
            )
          }
        ],
        sections: [
          {
            title: "Fabric Protection",
            description: [
              "Fabric requires care and protection especially when it's under constant use day-in and day-out. Nation Safe Drivers protects against most fast-foods stains, i.e. soda, coffee, mustard, ketchup, french fries!"
            ]
          },
          {
            title: "Leather & Vinyl",
            description: [
              "Leather and vinyl not protected can be damaged by fast food stains. Harmful U.V. rays from the sun can also affect the appearance of your leather and vinyl. Nation Safe Drivers knows protection means added value for your vehicle."
            ]
          },
          {
            title: "Protect Your Investment",
            description: [
              "One worth more... Why? Increased resale value. There's a big difference between a vehicle that's clean (just washed) and a \"clean vehicle\" (one that's been protected inside and out). A \"clean vehicle\" has greater value at trade-in time. A showroom new paint finish is important to a vehicle's trade in value!"
            ]
          }
        ]
      },
      {
        id: 'key-replacement',
        "name": "Key Replacement",
        image: require("assets/img/key-replacement_color.png"),
        mainContent: "Thanks to technological advancements, most vehicles nowadays are operated with keys which are computer programmed with special codes that match the codes located within the ignition. Because of this new technology, and attempts by the manufacturer to keep the vehicle's keys theft proof, replacing a lost or stolen key can be extremely expensive not to mention time consuming! In some cases, depending on the make of the car, it can cost as much as $100-$800 per key. We at NSD have earned a solid reputation for fast and courteous service. You can count on the company with \"safe\" as its middle name!",
        brochures: [
          {
            title: 'Key Replacement',
            link: "brochures/motor-club-key.pdf"
          }
        ],
        centerItems: [
          {
            title: "Benefits",
            content: (
              <React.Fragment>
                <b>Key replacement benefit</b>
                In the event your key/remote is lost, stolen or destroyed we will pay for a replacement key remote.
                <b>24 Hour emergency road service</b>
                You are covered for emergency road service throughout the U.S. and Canada.
                <b>24 Hour emergency delivery</b>
                A service truck will deliver emergency supplies of gasoline, water, oil, or other necessary supplies.
                You only pay for the cost of the supplies.
                <b>24 Hour emergency battery service</b>
                You are covered to boost batteries and perform minor adjustments to alternators, starters, etc. while on
                the road.
                <h6 style={{
                  fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                  bottom: "10px",
                  color: "#c0c1c2",
                  display: "block",
                  fontWeight: "400",
                  fontSize: "13px",
                  lineHeight: "13px"
                }}>
                  (Please see contract for the specific terms and conditions of coverage.)
                </h6>
              </React.Fragment>
            )
          },
          {
            title: "Additional Benefits",
            content: (
              <React.Fragment>
                <b>Lost key or lockout service</b>
                If you are accidentally locked out of your vehicle, you will be covered and entitled to the service
                specified in your contract.
                <b>Rent-a-car discounts</b>
                You are entitled to discount cards for vehicle rentals, which are honored at thousands of locations
                throughout the US and abroad.
                <b>Map routing service</b>
                We will, at no charge, provide you with a map and travel route to the destination of your choice.
                <b>Theft and hit & run protection</b>
                A $500 reward is offered for information leading to the arrest and conviction of anyone responsible for
                hit & run damages or stealing your vehicle.
                Whenever you travel, you'll like the secure feeling knowing that you are protected by the Nation Safe
                Drivers Auto Club.
                Fast response and excellent service are just two reasons why millions of motorists rely on Nation Safe
                Drivers for on-the-road protection.
              </React.Fragment>
            )
          }
        ],
        sections: []
      },
      {
        id: 'windshield-protection',
        "name": "Windshield Protection",
        image: require("assets/img/windshield-protection_color.png"),
        title: 'Windshield',
        mainContentTop: (
          <>
            Nation Safe Drivers offers a Windshield Protection Program to cover cracks and breaks up to six inches long. 
            In many cases, these breaks can be repaired by our network of professionals.
            {"\n\n"}
            This coverage includes protection for bullseye cracks, combination cracks, and even star cracks. Windshield 
            cracks up to the rough size of a dollar bill can often be saved and repaired without windshield replacement.
            {"\n\n"}
            The process is simple. Call our office. Our mobile team will arrive at your home or office to assess the situation.
          </>
        ),
        brochures: [
          {
            title: 'Windshield Protection',
            link: "brochures/windshield-protection.pdf"
          }
        ],
        unavailableStates: [
          "AZ",
          "FL",
          "GA",
          "KS",
          "ME",
          "NY",
          "PR",
          "TX",
          "WY",
        ],
        licensedRequiredStates: [],
        availableStates: [
          "AK",
          "AL",
          "AR",
          "CA",
          "CO",
          "CT",
          "DC",
          "DE",
          "HI",
          "IA",
          "ID",
          "IL",
          "IN",
          "KY",
          "LA",
          "MA",
          "MD",
          "ME",
          "MI",
          "MN",
          "MO",
          "MS",
          "MT",
          "NC",
          "ND",
          "NE",
          "NH",
          "NJ",
          "NM",
          "NV",
          "OH",
          "OK",
          "OR",
          "PA",
          "RI",
          "SC",
          "SD",
          "TN",
          "UT",
          "VA",
          "VT",
          "WA",
          "WI",
          "WV"
        ],
        centerItemsTitle: 'Our Coverage',
        centerItemsSpacing: 2,
        centerItems: [
          {
            title: "Bullseye Cracks",
            content:
              (
                <img style={{width: '100%'}} src={require('assets/img/bullseye.gif')}/>
              )

          },
          {
            title: "Combination Cracks",
            content:
              (
                <img style={{width: '100%'}} src={require('assets/img/combinationcracks.gif')}/>
              )

          },
          {
            title: "Cracks",
            content:
              (
                <img style={{width: '100%'}} src={require('assets/img/Cracks.gif')}/>
              )

          },
          {
            title: "Star Cracks",
            content:
              (
                <img style={{width: '100%'}} src={require('assets/img/StarCracks.gif')}/>
              )

          }
        ],
        sections: []
      },
      {
        id: 'vehicle-wrap',
        "name": "Vehicle Wrap",
        sideBySide: {
            text: (
                <>
                    Nation Safe Drivers offers a Vehicle Wrap Program to cover items often not included in most maintenance &
                    warranty programs. Our Vehicle Wrap Coverage includes protection for brake pads, batteries, belts & hoses,
                    wiper blades, and electrical items (such as headlamps, lights, bulbs, and fuses).
                    {"\n\n"}
                    This comprehensive program also covers diagnostic services for mechanical breakdowns, wheel
                    alignments, roadside assistance, 24-hour towing and rental car coverage.
                    {"\n\n"}
                    The Vehicle Wrap program prevents clients from having to pay out-of-pocket expenses for these items.
                    The 24-hour emergency roadside component includes towing services to the destination of your
                    choice, delivery of essential fluids and supplies (such as gasoline, water and oil), along with flat tire
                    assistance, battery assistance and lockout services. Contact your NSD representative today for more details
                    on our Vehicle Wrap Program.
                </>
            ),
            image: (
                <img style={{width: '100%'}}
                     src={require('assets/img/ENGINE W HOSES-1200-AdobeStock_45842348.png')}/>
            )
        },
        image: require('assets/img/car-wrap-color.png'),
        brochures: [
          {
            title: 'Vehicle Wrap',
            link: 'brochures/VW_BRO-0820-VEHICLE WRAP BROCHURE.pdf'
          }
        ],
        sections: []
      }
    ]
}
