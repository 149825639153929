import React, {useState} from "react";
// nodejs library that concatenates classes
import withStyles from "@material-ui/core/styles/withStyles";

import landingPageStyle from "assets/jss/material-kit-react/views/landingPage.jsx";
import SingleItemSection from "../../components/StandardPage/components/SingleItemSection";

import content from '../../content/AutomotiveDealers/main'
import assistanceContent from '../../content/AutomotiveDealers/sections/assistance';
import programsContent from '../../content/AutomotiveDealers/sections/programs';
import protectionsContent from '../../content/AutomotiveDealers/sections/protections';
import StandardPage from "../../components/StandardPage/StandardPage";
import bundlesContent from '../../content/AutomotiveDealers/sections/bundles';

import MultiItemSection from "../../components/StandardPage/components/MultiItemSection";
import {Helmet} from "react-helmet";

function AutomotiveDealers({...props}) {
  const {classes, ...rest} = props;
  const [contentItems] = useState([
    {
      ...protectionsContent
    },
    {
      ...programsContent
    }
  ]);
  const [multiContentItems] = useState([
    {
      ...assistanceContent
    },
    {
      ...bundlesContent
    }
  ]);

  return (
    <StandardPage
      title={content.title}
      description={content.description}
      image={require("assets/img/background-car-dealer.jpg")}
    >
      <Helmet>
        <meta charSet="utf-8"/>
        <title>{`NSD Automotive Dealers`}</title>
        <meta name="description"
              content={`Nation Safe Drivers Automotive division offers a full line of F&I products for the auto dealership business. We can also enhance warranty programs with additional coverage giving your service contract a competitive advantage. Our products can be sold as stand-alone or included as enhanced coverage increasing your customers perceived value of your products and services. All can be private labeled to meet your own marketing and service goals.`}/>
        <link rel="canonical" href={window.location.href}/>
      </Helmet>
      {
        contentItems && contentItems.map((content, index) => {
          return (
            <div>
              <SingleItemSection
                productArea={"automotive"}
                altRow={index % 2 === 1}
                imageLeft={index % 2 !== 1}
                content={content}
              />
            </div>
          )
        })
      }
      {
        multiContentItems && multiContentItems.map((content, index) => {
          return (
            <div>
              <MultiItemSection
                productArea={"automotive"}
                altRow={index % 2 === 1}
                title={content.title}
                items={content.items}
              />
            </div>
          )
        })
      }
    </StandardPage>
  );
}

export default withStyles(landingPageStyle)(AutomotiveDealers);
