import React from "react";
import productDetailsStyle from '../../assets/jss/material-kit-react/components/productDetails.jsx'
import withStyles from "@material-ui/core/styles/withStyles";
import classNames from 'classnames';
import USAMap from "react-usa-map";
import {dangerColor, infoColor, successColor} from "../../assets/jss/material-kit-react";
import {Grid} from "@material-ui/core";

const Map = ({ classes, product, selectedStates }) => {
    return (
        <>
          <div className={classes.mapHeader}>
            <h2>NSD Windshield Protection Service available in most of the United States.</h2>
          </div>
          <div className={classNames({
              [classes.mapContainer]: true,
              [classes.altSection]: true
            })}>
              <div style={{textAlign: 'center', width: '100%'}}>
                <h2
                  className={classNames({[classes.description]: !(product.sections && product.sections.length > 0)})}>
                  Product Availability
                </h2>
              </div>
              <div style={{display: 'flex', justifyContent: 'center', padding: 10}}>
                <Grid container justify={"center"}>
                  <Grid
                    item xs={12} sm={12} md={12} lg={3} xl={3}
                    style={{display: 'flex', flexDirection: 'row', justifyContent: 'center'}}
                    className={classNames({[classes.description]: !(product.sections && product.sections.length > 0)})}
                  >
                    <div style={{height: 25, width: 25, backgroundColor: successColor, marginRight: 10}}></div>
                    Available
                  </Grid>
                  <Grid
                    item xs={12} sm={12} md={12} lg={3} xl={3}
                    style={{display: 'flex', flexDirection: 'row', justifyContent: 'center'}}
                    className={classNames({[classes.description]: !(product.sections && product.sections.length > 0)})}
                  >
                    <div style={{height: 25, width: 25, backgroundColor: infoColor, marginRight: 10}}></div>
                    Licensing Required
                  </Grid>
                  <Grid
                    item xs={12} sm={12} md={12} lg={3} xl={3}
                    style={{display: 'flex', flexDirection: 'row', justifyContent: 'center'}}
                    className={classNames({[classes.description]: !(product.sections && product.sections.length > 0)})}
                  >
                    <div style={{height: 25, width: 25, backgroundColor: dangerColor, marginRight: 10}}></div>
                    Not Available
                  </Grid>
                </Grid>
              </div>
              <div className="path">
                <USAMap title={"Product availability"} customize={selectedStates} width={"100%"}/>
              </div>
          </div>
        </>
    )
};

export default withStyles(productDetailsStyle)(Map);