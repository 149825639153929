/*eslint-disable*/
import React from "react";
// react components for routing our app without refresh
import {Link} from "react-router-dom";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
// @material-ui/icons
// core components
import Button from "components/CustomButtons/Button.jsx";

import headerLinksStyle from "assets/jss/material-kit-react/components/headerLinksStyle.jsx";
import Tooltip from "@material-ui/core/Tooltip";
import scrollIntoView from "scroll-into-view-if-needed";

function RoadsideAssistanceFutureHeaderLinks({...props}) {
  const {classes} = props;

  const marketAppraisalDropDownLink = classNames({
    [classes.dropdownLink]: true,
    [classes.selectedDropdownLink]: location.pathname == "/market-appraisal",
  });

  const productAppraisalDropDownLink = classNames({
    [classes.dropdownLink]: true,
    [classes.selectedDropdownLink]: location.pathname == "/product-appraisal",
  });

  const infrastructureAppraisalDropDownLink = classNames({
    [classes.dropdownLink]: true,
    [classes.selectedDropdownLink]: location.pathname == "/infrastructure-appraisal",
  });

  const technicalAppraisalDropDownLink = classNames({
    [classes.dropdownLink]: true,
    [classes.selectedDropdownLink]: location.pathname == "/technical-appraisal",
  });

  const databaseReviewDropDownLink = classNames({
    [classes.dropdownLink]: true,
    [classes.selectedDropdownLink]: location.pathname == "/database-review",
  });

  const localizationDropDownLink = classNames({
    [classes.dropdownLink]: true,
    [classes.selectedDropdownLink]: location.pathname == "/localization",
  });

  const mobilePlatformReviewDropDownLink = classNames({
    [classes.dropdownLink]: true,
    [classes.selectedDropdownLink]: location.pathname == "/mobile-platform-review",
  });

  const setupAndConfigDropDownLink = classNames({
    [classes.dropdownLink]: true,
    [classes.selectedDropdownLink]: location.pathname == "/setup-and-config",
  });

  const deploymentDropDownLink = classNames({
    [classes.dropdownLink]: true,
    [classes.selectedDropdownLink]: location.pathname == "/deployment",
  });
  const onHomePage = (location.pathname == "/roadside-assistance-future" || location.pathname == "/roadside-assistance-future/");

  const closeSideBar = () => {
    var closeSideBarEvent = new Event('closeSideBar');
    window.dispatchEvent(closeSideBarEvent);
  }
  return (
    <List className={classes.list}>
      <ListItem className={classes.listItem}>
      <Tooltip
        id="instagram-linkedin"
        title="LinkedIn"
        placement={window.innerWidth > 959 ? "top" : "left"}
        classes={{tooltip: classes.tooltip}}
      >
        <Button
          href="https://www.linkedin.com/company/nation-safe-drivers?trk=cp_followed_name_nation-safe-drivers"
          target="_blank"
          color="transparent"
          className={classes.navLink}
        >
          <i className={classes.socialIcons + " fab fa-linkedin"}/>
        </Button>
      </Tooltip>
    </ListItem>
      <ListItem className={classes.listItem}>
        <Tooltip
          id="instagram-twitter"
          title="Twitter"
          placement={window.innerWidth > 959 ? "top" : "left"}
          classes={{tooltip: classes.tooltip}}
        >
          <Button
            href="https://twitter.com/NSD_MotorClub"
            target="_blank"
            color="transparent"
            className={classes.navLink}
          >
            <i className={classes.socialIcons + " fab fa-twitter"}/>
          </Button>
        </Tooltip>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Tooltip
          id="instagram-facebook"
          title="Facebook"
          placement={window.innerWidth > 959 ? "top" : "left"}
          classes={{tooltip: classes.tooltip}}
        >
          <Button
            href="https://www.facebook.com/NationSafeDrivers"
            target="_blank"
            color="transparent"
            className={classes.navLink}
          >
            <i className={classes.socialIcons + " fab fa-facebook"}/>
          </Button>
        </Tooltip>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Tooltip
          id="instagram-intragram"
          title="Facebook"
          placement={window.innerWidth > 959 ? "top" : "left"}
          classes={{tooltip: classes.tooltip}}
        >
          <Button
            href="https://www.instagram.com/nationsafedrivers/"
            target="_blank"
            color="transparent"
            className={classes.navLink}
          >
            <i className={classes.socialIcons + " fab fa-instagram"}/>
          </Button>
        </Tooltip>
      </ListItem>
    </List>
  );
}

export default withStyles(headerLinksStyle)(RoadsideAssistanceFutureHeaderLinks);
