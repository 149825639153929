import React from "react";
import {Grid} from '@material-ui/core'

export default {
  underContent: "",
  description: [
    (
      <React.Fragment>
        <Grid container>
          <Grid item xs={12} sm={12} md={12} lg={4} xl={4} style={{paddingRight: 50}}>
            <a href={"https://www.nrde.com/"} target={"_blank"}>
              <img
                src={require('../../../assets/img/nrdelogo.jpg')}
                style={{
                  borderRadius: "6px",
                  boxShadow:
                    "0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)",
                  width: '100%',
                  maxWidth: 400
                }}
              />
            </a>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={8} xl={8}>
            National Insurance Underwriters (NIU) is a full service MGA and is an affiliate of the Nation Safe Drivers
            (NSD)
            group of companies. NIU currently manages automobile insurance program for Occidental Fire and Casualty
            Company
            of North Carolina in Colorado and Maryland. Additionally, NIU markets homeowners, renters, commercial, agent
            error and omissions and a variety of niche products nationwide.
            <br/>
            <br/>
            Our auto programs combine traditional conservative pricing and underwriting values with the latest
            technology.
            The NIU auto program provides a Web based quoting and binding system where motor vehicle records and clue
            reports are ordered prior to binding an application. This reduces additional premiums and cancellations.
            Suspense items are reduced as drivers are automatically listed as drivers or excluded. Additionally, this is
            a
            point of sale policy issuance program. The declaration page, all forms and the ID cards are printed at the
            point
            of sale so the customer leaves with their entire policy in hand.
          </Grid>
        </Grid>
      </React.Fragment>
    ),
    (
      <React.Fragment>
        <Grid container>
          <Grid item xs={12} sm={12} md={12} lg={4} xl={4} style={{paddingRight: 50}}>
            <a href={"http://aapconline.com/mga/rating/"} target={"_blank"}>
              <img
                src={require('../../../assets/img/globe.png')}
                style={{
                  borderRadius: "6px",
                  boxShadow:
                    "0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)",
                  width: '100%',
                  maxWidth: 400
                }}
              />
            </a>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={8} xl={8}>
            National Insurance Underwriters (NIU) is a full service MGA and is an affiliate of the Nation Safe Drivers
            (NSD)
            group of companies. NIU currently manages automobile insurance program for Occidental Fire and Casualty
            Company
            of North Carolina in Colorado and Maryland. Additionally, NIU markets homeowners, renters, commercial, agent
            error and omissions and a variety of niche products nationwide.
            <br/>
            <br/>
            Our auto programs combine traditional conservative pricing and underwriting values with the latest
            technology.
            The NIU auto program provides a Web based quoting and binding system where motor vehicle records and clue
            reports are ordered prior to binding an application. This reduces additional premiums and cancellations.
            Suspense items are reduced as drivers are automatically listed as drivers or excluded. Additionally, this is
            a
            point of sale policy issuance program. The declaration page, all forms and the ID cards are printed at the
            point
            of sale so the customer leaves with their entire policy in hand.
          </Grid>
        </Grid>
      </React.Fragment>
    ),
    (
      <React.Fragment>
        <Grid container>
          <Grid item xs={12} sm={12} md={12} lg={4} xl={4} style={{paddingRight: 50}}>
            <a href={"http://niuw.com/flupload.asp"} target={"_blank"}>
              <img
                src={require('../../../assets/img/upload.png')}
                style={{
                  borderRadius: "6px",
                  boxShadow:
                    "0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)",
                  width: '100%',
                  maxWidth: 400
                }}
              />
            </a>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={8} xl={8}>
            National Insurance Underwriters (NIU) is a full service MGA and is an affiliate of the Nation Safe Drivers
            (NSD)
            group of companies. NIU currently manages automobile insurance program for Occidental Fire and Casualty
            Company
            of North Carolina in Colorado and Maryland. Additionally, NIU markets homeowners, renters, commercial, agent
            error and omissions and a variety of niche products nationwide.
            <br/>
            <br/>
            Our auto programs combine traditional conservative pricing and underwriting values with the latest
            technology.
            The NIU auto program provides a Web based quoting and binding system where motor vehicle records and clue
            reports are ordered prior to binding an application. This reduces additional premiums and cancellations.
            Suspense items are reduced as drivers are automatically listed as drivers or excluded. Additionally, this is
            a
            point of sale policy issuance program. The declaration page, all forms and the ID cards are printed at the
            point
            of sale so the customer leaves with their entire policy in hand.
          </Grid>
        </Grid>
      </React.Fragment>
    )
  ]
}
