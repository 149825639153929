import React from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel as ResponsiveCarousel } from 'react-responsive-carousel';

const Carousel = ({ children, ...rest }) => {
    return (
        <ResponsiveCarousel {...rest}>
            { children }
        </ResponsiveCarousel>
    );
}

export default Carousel;