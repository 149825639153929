import React, {useState} from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import productStyle from "../../assets/jss/material-kit-react/views/landingPageSections/productStyle.jsx";
import content from '../../content/Privacy/privacy';
import StandardPage from "../../components/StandardPage/StandardPage";
import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from '@material-ui/core';
import protectionContent from '../../content/Agents/sections/protection'
import servicesContent from '../../content/Agents/sections/services'
import travelContent from '../../content/Agents/sections/travel';
import {Helmet} from "react-helmet";

function Agents({...props}) {
  const {classes, ...rest} = props;
  const [contentItems] = useState([
    {
      ...protectionContent
    }, {
      ...servicesContent
    }, {
      ...travelContent
    }
  ]);

  return (
    <StandardPage
      title={content.title}
      description={content.description}
      image={require("assets/img/security_bg.png")}
    >
      <Helmet>
        <meta charSet="utf-8"/>
        <title>{`NSD Privacy Policy`}</title>
        <meta name="description"
              content={`NSD privacy policy`}/>
        <link rel="canonical" href={window.location.href}/>
      </Helmet>
      <div className={classes.section}>
        <h2 className={classes.title}>Privacy Policy</h2>
        <h5 className={classes.title}>Effective Date: January 1, 2020</h5>
        <h5 className={classes.title}>Posting Date: December 17, 2019</h5>
        <div className={classes.description}>
          <div className={classes.title} style={{fontWeight: 'normal'}}>
            Nation Motor Club, LLC d/b/a Nation Safe Drivers, NIU of Florida, Inc., National Adjustment Bureau, LLC,
            Nation Safe Drivers Services, Inc, and National Insurance Underwriters, LLC (collectively, "NSD") values
            the trust that our clients and business partners put in us to safeguard your Personal Information.
            <br/>
            <br/>
            We know that you care about your Personal Information, and we want you to be informed about our
            practices.
            This Privacy Policy explains how we collect, use, disclose, and protect your Personal Information.
            Please
            review this Privacy Policy, as it contains information, terms, and conditions that may affect your legal
            rights, as well as your ability to use our websites, including www.nsdmc.com, www.nationsafedrivers.com,
            www.nsdclaims.com, www.niufl.com, www.towingwithattitude.com (collectively, "the Site").
          </div>
          <ol className={classes.title}>
            <li>
              <h4 className={classes.title}>Information NSD Collects</h4>
              <div style={{fontWeight: 'normal'}}>
                NSD collects information that identifies, relates to, describes, references, is capable of being
                associated with, or could reasonably be linked, directly or indirectly, with a particular consumer
                or
                device ("Personal Information").
                <br/>
                <br/>
                The following table identifies the categories of Personal Information NSD collects or has collected
                within the last twelve (12) months. NSD will not collect additional categories of Personal
                Information
                without providing you notice.

                <Table size="small">
                  <TableHead>
                    <TableCell>
                      Category
                    </TableCell>
                    <TableCell>
                      Examples
                    </TableCell>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell>
                        Identifiers
                      </TableCell>
                      <TableCell>
                        A real name, alias, postal address, unique personal identifier, online identifier, Internet
                        Protocol
                        address, email address, account name, Social Security number, driver's license number, passport
                        number, or other similar identifiers.
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        Personal information categories listed in the California Customer Records statute (Cal. Civ.
                        Code §
                        1798.80(e))
                      </TableCell>
                      <TableCell>
                        A name, signature, Social Security number, physical characteristics or description, address,
                        telephone
                        number, passport number, driver's license or state identification card number, insurance policy
                        number, education, employment, employment history, bank account number, credit card number,
                        debit
                        card
                        number, or any other financial information, medical information, or health insurance
                        information.

                        *Please note that some Personal Information included in this category may overlap with other
                        categories.
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        Commercial information
                      </TableCell>
                      <TableCell>
                        Records of personal property, products or services purchased, obtained, or considered, or other
                        purchasing or consuming histories or tendencies.
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        Internet or other similar network activity
                      </TableCell>
                      <TableCell>
                        Browsing history, search history, information on a consumer's interaction with a website,
                        application,
                        or advertisement.
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        Geolocation data
                      </TableCell>
                      <TableCell>Physical location or movements.</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </div>
            </li>
            <li>
              <h4 className={classes.title}>How NSD Collects Personal Information</h4>
              <div style={{fontWeight: 'normal'}}>
                NSD may collect Personal Information falling into one of the above-listed categories actively or
                passively.
                <br/><br/>
                Active Collection
                <br/><br/>
                NSD may collect information directly from you. For example, we may collect Personal Information from
                you when you:
                <br/><br/>
                <ul>
                  <li>Enroll in a program or purchase a service directly from NSD</li>
                  <li>Make a claim that NSD services and processes</li>
                  <li>Create an account on the Site.</li>
                  <li>Participate in optional surveys.</li>
                  <li>Correspond with us through message boards, chat sessions, email, messages delivered via a portal
                    accessed on the Site, telephone conversations, and letters of correspondence.
                  </li>
                </ul>
                <br/>
                We may also collect information from you from the following categories of third parties:
                <ul>
                  <li>Business partners from whom you purchase a benefit or service provided by NSD.</li>
                  <li>Business partners who enroll you in program or service provided by NSD.</li>
                  <li>Business partners and insurers on whose behalf NSD processes claims.</li>
                </ul>
                <br/>
                Passive Collection
                <br/>
                <br/>
                When accessing, browsing, or using the Site, our software may automatically collect certain
                information from your device (e.g., computer, smartphone, and tablet) using "cookies" and other
                tracking techniques, or by collecting platform information from our servers.
                <br/>
                <br/>
                Information Collected by "Cookies" And Other Tracking Techniques
                <br/>
                <br/>
                NSD, as well as our vendors or business partners, may use various technologies, such as cookies and
                web beacons, to collect information about you when you access, browse, or use the Site. For
                additional
                information, including how to block cookies, please see "NSD's Use of Personal Information" below.
                You
                may still use the Site if you reject, block, and/or delete our vendors' or our cookies, but please
                note that you may not be able to access some areas or features of the Site.
                <br/>
                <br/>
                NSD may use third-party services, such as Google Analytics, to provide Site-usage analytics. Such
                third-party services may use cookies, web beacons, and/or other technologies to collect information
                about your use of the Site. Such information may include your device's Internet Protocol address,
                browser name and version, pages visited, time spent viewing each page, links clicked, and other
                information about your visit to and use of the Site. Such analytics may help us track usage of the
                Site, identify popular features, provide advertising and other content specific to your use of the
                Site and other websites, and better understand the online activity of our website visitors. If you
                wish to block, erase, or be warned of cookies used for analytic purposes, please refer to your
                browser's instructions or help screen to learn about these functions.
                <br/>
                <br/>
                Platform Information
                <br/>
                <br/>
                When you visit the Site, our servers automatically record certain information about the device
                (e.g.,
                a computer or mobile device) you used to access the Site. Such information, often referred to as
                "log
                file" information, may include your device's Internet Protocol address, operating system name and
                version, and browser name and version, as well as the referring URL, number of times your device has
                accessed the Site, pages of the Site you viewed, links you clicked, and other information about your
                visit to and use of the Site.
                <br/>
                <br/>
                Collecting Personal Information from Children
                <br/>
                <br/>
                Because NSD is committed to protecting the privacy of children, the Site is not intended for use by
                or
                directed at children under the age of We do not knowingly collect, via the Site or otherwise,
                Personal Information from anyone under 16 years of age. We encourage parents and guardians to take
                an
                active role in their children's online and mobile activities and interests.
              </div>
            </li>
            <li>
              <h4 className={classes.title}>NSD's Use of Personal Information</h4>
              <div style={{fontWeight: 'normal'}}>
                We may use or disclose the Personal Information we collect for one or more of the following business
                purposes:
                <ul>
                  <li>
                    To fulfill or meet the reason you provided the information. For example, if you share your name and
                    contact information to ask a question about our products or services, we will use that Personal
                    Information to respond to your inquiry. Similarly, if you provide your Personal Information to
                    purchase a product or service, we will use that information to process your payment.
                  </li>
                  <li>
                    To provide, support, personalize, and improve and enhance the user experience while navigating our
                    Site.
                  </li>
                  <li>To process a claim you make.</li>
                  <li>To provide you with a benefit or service to which you are entitled.</li>
                  <li>To provide, develop, evaluate, and improve our products and services.</li>
                  <li>To create, maintain, customize, and secure your account with us.</li>
                  <li>To process your requests, transactions, and payments, and to prevent transactional fraud.</li>
                  <li>
                    To provide you with support and to respond to your inquiries, including to investigate and address
                    your concerns and monitor and improve our responses.
                  </li>
                  <li>
                    To deliver content, information, and product or service offerings relevant to your interests,
                    including targeted offers and ads through our Site, third-party sites, and via email or text message
                    (with your consent, where required by law).
                  </li>
                  <li>
                    To help maintain the safety, security, and integrity of our Site, products and services, databases
                    and
                    other technology assets, and business.
                  </li>
                  <li>
                    For testing, research, analysis, and product development, including to develop and improve our Site,
                    products, and services.
                  </li>
                  <li>
                    To respond to law enforcement requests and as required by applicable law, court order, or
                    governmental regulations.
                  </li>
                </ul>
              </div>
            </li>
            <li>
              <h4 className={classes.title}>How and When NSD Shares Your Personal Information</h4>
              <div style={{fontWeight: 'normal'}}>
                NSD does not and will not sell your Personal Information. NSD may disclose your Personal Information
                to
                a third party for a business purpose. When we disclose Personal Information for a business purpose,
                we
                take reasonable steps to ensure that our business partners and service providers who receive your
                Personal Information protect it. As needed, and where and when required by law, NSD will enter into
                a
                contract that describes the purpose for which the business partner or service provides can use your
                Personal Information and requires the recipient to keep your Personal Information confidential and
                not
                use it for any purpose except performing the purpose described in the contract.
                <br/>
                <br/>
                We share your Personal Information for business purposes with the following categories of third
                parties:
                <ul>
                  <li>Service providers.</li>
                  <li>Insurers on behalf of whom we provide claim processing.
                  </li>
                  <li>Banks or financial service providers, if necessary to provide you with a benefit or process a
                    transactions.
                  </li>
                  <li>Entities which have agreed to pay NSD to administer a service or benefit to you on their behalf.

                  </li>
                </ul>
                <br/>
                How NSD Uses Cookies
                <br/>
                <br/>
                NSD, and some of our business partners or service providers, may use cookies to help us count
                how
                you
                access, browse, and use the Site (i.e., by counting the number of times you and other users
                visit
                a
                webpage or access a feature), thereby providing us with information we can use to improve the
                Site.
                Cookies are small data files locally stored on the device used to access the Site (e.g.,
                computer,
                smartphone, tablet). Web beacons are electronic images that we or our vendors may use in the
                Site
                or
                in
                emails to deliver cookies, count visits, understand Site usage, and/or evaluate the
                effectiveness
                of
                marketing campaigns.
                <br/>
                <br/>
                NSD and our business partners/service providers may use one or more of the above-described
                cookies
                to
                facilitate access to various features of the Site.
              </div>
            </li>
            <li>
              <h4 className={classes.title}>NSD's Past Disclosures of Personal Information for a Business Purpose</h4>
              <div style={{fontWeight: 'normal'}}>
                In the preceding twelve (12) months, NSD has disclosed to third parties (i.e., our business partners
                and
                service providers) the following categories of Personal Information for a business purpose:
                <ul>
                  <li>Identifiers.</li>
                  <li>Personal Information categories listed in the California Customer Records statute (Cal. Civ.
                    Code § 1798.80(e))
                  </li>
                  <li>Commercial information.</li>
                  <li>Internet or other similar network activity.</li>
                  <li>Geolocation data.</li>
                </ul>
              </div>
            </li>
            <li>
              <h4 className={classes.title}>How NSD Protects Your Personal Information</h4>
              <div style={{fontWeight: 'normal'}}>
                While no website or electronic data can ever be completely secure, NSD is committed to maintaining
                up-to-date and appropriate security measures and safeguards. We maintain a variety of physical,
                electronic, and procedural safeguards to help prevent unauthorized access to and improper use of
                your
                personal information. For example, our employees' computers are password protected, and we provide
                regular education to our employees regarding our security and privacy practices.
              </div>
            </li>
            <li>
              <h4 className={classes.title}>How NSD Communicates with You</h4>
              <div style={{fontWeight: 'normal'}}>
                Many of our products and services utilize SMS messaging to update you on the progress of your
                service
                or
                to gain additional information on your service throughout the process. You may opt to not receive
                text
                messages by declining the feature when asked by your customer service representative. If you agree
                to
                receive SMS messages, we will provide you with an option to respond to an SMS message in manner that
                will allow you to opt out of receiving future SMS messages. In some instances where we have an
                existing
                relationship with you, you may not have previously received an option to opt out. In such
                circumstances
                you can always opt out of receiving future texts by contacting us through any of the means described
                in
                Section12.
              </div>
            </li>
            <li>
              <h4 className={classes.title}>Special Information for California Residents</h4>
              <div style={{fontWeight: 'normal'}}>
                California residents have certain rights under the California Consumer Privacy Act (Cal. Civ. Code §
                1798.100-90) ("CCPA"), which are explained below. Please note that the rights described in this
                section
                do not apply to Personal Information excluded from the CCPA's scope, such as:
                <ul>
                  <li>Publicly available information from government records</li>
                  <li>De-identified or aggregated consumer information</li>
                  <li>
                    Health or medical information covered by the Health Insurance Portability and Accountability Act of
                    1996
                    (HIPAA) and the California Confidentiality of Medical Information Act (CMIA) or clinical trial data;
                  </li>
                  <li>
                    Personal information covered by certain sector-specific privacy laws, including the Fair Credit
                    Reporting Act (FRCA), the Gramm-Leach-Bliley Act (GLBA) or California Financial Information Privacy
                    Act
                    (FIPA), and the Driver's Privacy Protection Act of 1994.
                  </li>
                </ul>
                <br/>
                Right of Access to Specific Information
                <br/><br/>
                As a California resident, you have the right to request that NSD disclose certain information
                concerning
                our collection and use of your Personal Information over the past 12 months. Once we receive and
                confirm
                your verifiable consumer request (see <span style={{textDecoration: 'underline', fontWeight: 'bold'}}>Exercising Your Access, Data Portability, and Deletion Rights </span>
                below), we will disclose to you:
                <br/>
                <ul>
                  <li>The categories of Personal Information we collected about you.</li>
                  <li>The categories of sources for the Personal Information we collected about you.</li>
                  <li>Our business or commercial purpose for collecting your Personal Information.</li>
                  <li>The categories of third parties with whom we shared your Personal Information.</li>
                  <li>If we disclosed your Personal Information for a business purpose, a list identifying the Personal
                    Information categories that each category of recipient obtained.
                  </li>
                </ul>
                <br/>

                Right to Data Portability
                <br/>
                <br/>
                As a California resident, you have the right to request that NSD provide you with the specific
                pieces
                of
                Personal Information we have collected about you. Once we receive and confirm your verifiable
                consumer
                request (see <span style={{textDecoration: 'underline', fontWeight: 'bold'}}>Exercising Your Access, Data Portability, and Deletion Rights </span>below),
                we will provide
                with
                your choice of an electronic or physical copy of the specific pieces of Personal Information we have
                collected from you, provided that such Personal Information has not been anonymized or aggregated in
                a
                manner in which we can no longer associate with you.
                <br/>
                <br/>
                Right to Request Deletion of Personal Information
                <br/>
                <br/>
                As a California resident, you have the right to request that NSD delete any of your Personal
                Information
                that we collected from you and retained, subject to certain exceptions. Once we receive and confirm
                your
                verifiable consumer request (see Exercising Your Access, Data Portability, and Deletion Rights
                below),
                we will delete (and direct our service providers to delete) your Personal Information from our
                records,
                unless an exception applies.
                <br/>
                <br/>
                California residents may also request the deletion or removal of their Personal Information by
                contacting the company through which an NSD product or service was purchased and ask such company to
                send notification to NSD to delete or remove any Personal Information.
                <br/>
                <br/>
                We may deny your deletion request if retaining the information is necessary for us or our service
                provider(s) to:
                <ul>
                  <li>
                    Complete the transaction for which we collected the Personal Information, provide a product,
                    service,
                    or
                    benefit that you requested, take actions reasonably anticipated within the context of our ongoing
                    business relationship with you, or otherwise perform our contract with you.
                  </li>
                  <li>
                    Detect security incidents, protect against malicious, deceptive, fraudulent, or illegal activity, or
                    prosecute those responsible for such activities.
                  </li>
                  <li>
                    Debug the Site to identify and repair errors that impair existing intended functionality.
                  </li>
                  <li>
                    Exercise free speech, ensure the right of another consumer to exercise their free speech rights, or
                    exercise another right provided for by law.
                  </li>
                  <li>
                    Comply with the California Electronic Communications Privacy Act (Cal. Penal Code § 1546 et. seq.).
                  </li>
                  <li>
                    Engage in public or peer-reviewed scientific, historical, or statistical research in the public
                    interest that adheres to all other applicable ethics and privacy laws, when the information's
                    deletion may likely render impossible or seriously impair the research's achievement, if you
                    previously provided informed consent.
                  </li>
                  <li>
                    Enable solely internal uses that are reasonably aligned with consumer expectations based on your
                    relationship with us.
                  </li>
                  <li>Comply with a legal obligation.</li>
                  <li>
                    Make other internal and lawful uses of that information that are compatible with the context in
                    which
                    you provided it.
                  </li>
                </ul>

                <br/>
                In the event NSD cannot presently honor a deletion request, NSD will provide an anticipated date
                that
                such information will be removed once we have received a request to remove this data. In the event
                your
                purchasing outlet has gone out of business, you may elect to have NSD remove information about you
                directly, but please be advised that the deletion of data may prevent you from being identified and
                qualified for specific products and services in the future.
                <br/>
                <br/>
                Exercising Your Access, Data Portability, and Deletion Rights
                <br/>
                <br/>
                To exercise the access, data portability, and deletion rights described above, please submit a
                verifiable consumer request to us by any of the following:
                <ul>
                  <li>Calling us at 1-800-338-2680</li>
                  <li>Messaging us online, below: Contact Form</li>
                  <li>
                    Writing to us at:<br/>
                    <div style={{marginLeft: 15}}>
                      Nation Safe Drivers<br/>
                      800 West Yamato Road, Suite 100<br/>
                      Boca Raton, FL 33431<br/>
                      Attention: Compliance Department
                    </div>
                  </li>
                </ul>
                <br/>
                Only you, or a person registered with the California Secretary of State that you authorize to act on
                your behalf, may make a verifiable consumer request related to your Personal Information. You may
                also
                make a verifiable consumer request on behalf of your minor child.
                <br/>
                <br/>
                You may only make a verifiable consumer request for access or data portability twice within a
                12-month
                period. The verifiable consumer request must:
                <ul>
                  <li>
                    Provide sufficient information that allows us to reasonably verify you are the person about whom we
                    collected Personal Information or an authorized representative.
                  </li>
                  <li>
                    Describe your request with sufficient detail that allows us to properly understand, evaluate, and
                    respond to it.
                  </li>
                </ul>
                <br/>
                We cannot respond to your request or provide you with Personal Information if we cannot verify your
                identity or authority to make the request and confirm the Personal Information relates to you.
                Making
                a
                verifiable consumer request does not require you to create an account with us. However, we do
                consider
                requests made through your password protected account sufficiently verified when the request relates
                to
                Personal Information associated with that specific account.
                <br/>
                <br/>
                We will only use Personal Information provided in a verifiable consumer request to verify the
                requestor's identity or authority to make the request.
                <br/>
                <br/>
                Response Timing and Format
                <br/>
                <br/>
                We endeavor to respond to a verifiable consumer request within forty-five (45) days of its receipt.
                If
                we require more time (up to 90 days), we will inform you of the reason and extension period in
                writing.
                <br/>
                <br/>
                If you have an account with us, we may deliver our written response to you via that account or
                information you provided when you created that account. If you do not have an account with us, we
                will
                deliver our written response by mail or electronically, at your option.
                <br/>
                <br/>
                Any disclosures we provide will only cover the 12-month period preceding the verifiable consumer
                request's receipt. The response we provide will also explain the reasons we cannot comply with a
                request, if applicable. For data portability requests, we will select a format to provide your
                Personal
                Information that is readily useable and should allow you to transmit the information from one entity
                to
                another entity without hindrance.
                <br/>
                <br/>
                We do not charge a fee to process or respond to your verifiable consumer request unless it is
                excessive,
                repetitive, or manifestly unfounded. If we determine that the request warrants a fee, we will tell
                you
                why we made that decision and provide you with a cost estimate before completing your request.
                <br/>
                <br/>
                Non-Discrimination
                <br/>
                <br/>
                We will not discriminate against you for exercising any of your CCPA rights. Unless permitted by the
                CCPA, we will not:
                <ul>
                  <li>Deny you goods or services.</li>
                  <li>Charge you different prices or rates for goods or services, including through granting discounts
                    or
                    other benefits, or imposing penalties.
                  </li>
                  <li>Provide you a different level or quality of goods or services.</li>
                  <li>Suggest that you may receive a different price or rate for goods or services or a different level
                    or
                    quality of goods or services.
                  </li>
                </ul>
                <br/>

                Rights under California's Shine the Light Law
                <br/>
                <br/>
                California's "Shine the Light" law (Civil Code Section § 1798.83) permits California residents who
                use
                of our Site to request certain information regarding our disclosure of Personal Information to third
                parties for their direct marketing purposes. To make such a request, please send an email to us via
                our
                below contact form, or write us at:
                <div style={{marginLeft: 15}}>
                  NSD<br/>
                  800 West Yamato Road, STE 100<br/>
                  Boca Raton, FL 33431<br/>
                  Attn: Compliance Department<br/>
                </div>
              </div>
            </li>
            <li>
              <h4 className={classes.title}>Third-Party Websites</h4>
              <div style={{fontWeight: 'normal'}}>
                The Site may have links to other websites that NSD does not own, control, or maintain. We cannot be
                responsible for their privacy policies and practices, and we make no representations or warranties
                about
                the privacy practices of those third-party websites. Similarly, we cannot be responsible for the
                policies and practices of any site from which you linked to our Site. We recommend that you check
                the
                privacy policy of other sites and contact the operator if you have concerns or questions.
                <br/>
                <br/>
                Please keep in mind that whenever you voluntarily disclose personal information online for example
                on
                message boards, through email, or in chat areas, such information can be collected and used by
                others.
                In short, if you post personal information online that is accessible to the public, you may receive
                unsolicited messages from other parties in return.
              </div>
            </li>
            <li>
              <h4 className={classes.title}>Law Applicable to this Privacy Policy</h4>
              <div style={{fontWeight: 'normal'}}>
                NSD is located in the State of Florida, and the Site is intended for users in the United States.
                Except
                as otherwise provided, by viewing any content or otherwise accessing the Site, you consent to the
                transfer of information to the United States to the extent applicable, and the collection, storage,
                and
                processing of information under the laws and regulations of the State of Florida and the United
                States.
              </div>
            </li>
            <li>
              <h4 className={classes.title}>Changes to Our Privacy Policy</h4>
              <div style={{fontWeight: 'normal'}}>
                NSD reserves the right to amend this Privacy Policy at our discretion and at any time. When we make
                changes to this Privacy Policy, we will post the updated notice on the Site and update the notice's
                effective date. Your continued use of the Site following the posting of changes constitutes your
                acceptance of such changes.
              </div>
            </li>
            <li>
              <h4 className={classes.title}>Contacting NSD</h4>
              <div style={{fontWeight: 'normal'}}>
                If you have any questions or comments about this notice, the ways in which NSD collects and uses
                your
                information, your choices and rights regarding such use, or wish to exercise your rights under
                California law, please do not hesitate to contact us through any of the following:<br/>
                By phone: 1-800-338-2680<br/>
                By writing to us at:
                <div style={{marginLeft: 15}}>
                  Nation Safe Drivers<br/>
                  800 West Yamato Road, STE 100<br/>
                  Boca Raton, FL 33431<br/>
                  Attention: Compliance Department<br/>
                </div>
              </div>
            </li>
          </ol>
        </div>
      </div>
    </StandardPage>
  );

}

export default withStyles(productStyle)(Agents);
