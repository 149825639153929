import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import {Grid} from '@material-ui/core';
import workplaceImg from 'assets/img/workplace-640.jpg';
import careerStyle from "../../../assets/jss/material-kit-react/views/landingPageSections/careerStyle.jsx";
import Fade from 'react-reveal/Fade';
import content from '../../../content/LandingPage/Sections/careers'
import {Link} from "react-router-dom";

class Careers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      expanded: false
    }
  }

  render() {
    const {classes} = this.props;
    return (
      <div className={classes.section}>
        <div>
          <Grid container
                style={{padding: 50, flex: 1, display: 'flex', justifyContent: 'left', flexDirection: 'center'}}>
            <Grid item xs={12} sm={12} md={12}>
              <h2 className={classes.title}>{content.title}</h2>
              <Fade bottom>
                <Grid container>
                  <Grid item lg={7} style={{padding: 25, textAlign: 'left'}}>
                    {
                      content.sections.map((section, index) => (
                        <React.Fragment>
                          <h2 className={classes.description}>{section.title}</h2>
                          <h5 className={classes.description}>
                            {section.content}
                          </h5>
                        </React.Fragment>
                      ))
                    }
                  </Grid>
                  <Grid item lg={5} style={{
                    padding: 25,
                    display: 'flex',
                    alignContent: 'center',
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}>
                    <img src={workplaceImg} alt="terrifiq" className={classes.raised} style={{width: '100%'}}/>
                  </Grid>
                  <Grid item lg={12}>
                    <h3 className={classes.description}>
                      {content.tagLine}
                      <br/>
                      <a href={"/careers/product-details/careers"}>Apply Now</a>
                    </h3>
                  </Grid>
                </Grid>
              </Fade>
            </Grid>
          </Grid>

        </div>
      </div>
    );
  }
}

export default withStyles(careerStyle)(Careers);
