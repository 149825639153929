import React, {useEffect, useState} from "react";
import {Background, Parallax} from "react-parallax";
import {Grid, IconButton} from '@material-ui/core';
import content from "../../../content/FutureOfRoadsideAssistance/sections/advantages";
import {makeStyles} from "@material-ui/core/styles";
import classNames from "classnames";
import {title} from "../../../assets/jss/material-kit-react.jsx";
import {primaryColor, secondaryColor} from "../../../assets/jss/material-kit-react";
import each from 'lodash/each';
import {Close as CloseIcon} from "@material-ui/icons";
import Animation from "../../../components/CustomAnimation/CustomAnimation";

var tinycolor2 = require('tinycolor2')

const useStyles = makeStyles(theme => ({
  section: {
    textAlign: "center"
  },
  parallax: {
    height: "90vh",
    maxHeight: "1000px",
    overflow: "hidden",
    position: "relative",
    backgroundPosition: "center center",
    backgroundSize: "cover",
    margin: "0",
    padding: "0",
    border: "0",
    display: "flex",
    alignItems: "center"
  },
  filter: {
    "&:before": {
      background: "rgba(0, 0, 0, 0.75)"
    },
    "&:after,&:before": {
      position: "absolute",
      zIndex: "1",
      width: "100%",
      height: "100%",
      display: "block",
      left: "0",
      top: "0",
      content: "''"
    }
  },
  small: {
    height: "380px"
  },
  title: {
    ...title,
    display: "inline-block",
    position: "relative",
    marginTop: "30px",
    minHeight: "32px",
    color: "#FFFFFF",
    textDecoration: "none"
  },
  sectionItemTitle: {
    ...title,
    display: "inline-block",
    position: "relative",
    margin: 0,
    color: 'inherit',
    textDecoration: "none"
  },
  container: {
    paddingRight: '15%',
    paddingLeft: '15%',
    marginBottom: 150
  },
  tileGridContainer: {
    marginBottom: 10,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    minHeight: 375,
    color: '#fff',
    paddingLeft: 50,
    paddingRight: 50,
    '@media (max-width: 576px)': {
      paddingLeft: 0,
      paddingRight: 0
    },
    '@media (max-width: 768px)': {
      paddingLeft: 0,
      paddingRight: 0
    }
  },
  tileGridContainerHover: {
    cursor: 'pointer',
    "&:hover": {
      background: tinycolor2("#fff").darken(15).setAlpha(.5).toRgbString(),
      transition: 'background 1s, color .25s',
      borderRadius: 50,
      color: `${primaryColor} !important`
    },
  },
  tileMainGrid: {
    transition: 'all 2s',
  },
  lighterTransitionBackground: {
    backgroundColor: tinycolor2(secondaryColor).brighten(45).toHexString()
  },
  tileDetailsContainer: {
    background: tinycolor2(primaryColor).darken(1).setAlpha(.85).toRgbString(),
    borderRadius: 5,
    boxShadow:
      "0 5px 15px -8px rgba(0, 0, 0, 0.24), 0 8px 10px -5px rgba(0, 0, 0, 0.2)",
    color: `#fff !important`,
    padding: theme.spacing(2)
  },
  mainDiv: {
    minHeight: 500,
    paddingLeft: '15%',
    paddingRight: '15%',
    paddingBottom: 15
  },
  mainDivFullExpansion: {
    minHeight: 500,
    paddingLeft: 0,
    paddingRight: 0,
    paddingBottom: 15
  }
}))

const Advantages = (props) => {
  const classes = useStyles();
  const [tiles, setTiles] = useState([])
  const [showTiles, setShowTiles] = useState(true);
  const [showTileDetails, setShowTileDetails] = useState(false);
  const [selectedTile, setSelectedTile] = useState(null)
  const parallaxClasses = classNames({
    [classes.parallax]: true,
    [classes.filter]: true,
    [classes.small]: false
  });

  const showHideTiles = clickedTile => event => {
    if (clickedTile && !selectedTile || !clickedTile) {
      console.log("set selected tile", clickedTile)
      setSelectedTile(clickedTile)
    }
  }

  useEffect(() => {
    if (showTileDetails) {
      document.querySelector('#tileDetailsAdvantages').scrollIntoView({
        behavior: 'smooth'
      });
    }
  }, [showTileDetails])

  useEffect(() => {
    let newTiles = [];
    let delayHideTiles = 0;

    each(content.tiles, (tile, index) => {
      newTiles.push(tile);
      newTiles[newTiles.length - 1].showTile = selectedTile === null;
      delayHideTiles += 75 * index;
    })

    if (selectedTile !== null) {
      setTiles(newTiles);
      setTimeout(() => {
        setShowTiles(false);
        setShowTileDetails(true);
      }, delayHideTiles)
    } else {
      setShowTiles(true);
      setShowTileDetails(false);
      setTiles(newTiles);
    }
  }, [selectedTile])

  return (
    <div className={classes.section}>
      <div className={classNames({[classes.container]: true, [classes.lighterTransitionBackground]: true})}>
        <h2 className={classes.title}>
          WHY NSD
        </h2>
        <h3>
          Our Programs Can Be Private-labeled & Offered, Included, or Used
          as a Supplement to Any Existing Program.
          <br/>
          <br/>
          We Incorporate Cutting Edge Technology & Innovative
          Product Development.
          <br/>
          <br/>
          Employee-Owned, With Over 50 Years of Innovation
          & Experience.
        </h3>
      </div>
      <div style={{
        backgroundImage: `url(${require("../../../assets/img/puzzle.png")})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center'
      }}>
        <div
          id="tileDetailsAdvantages"
          className={classNames({
            [classes.mainDiv]: true,
            [classes.mainDivFullExpansion]: (selectedTile && selectedTile.detailFullExpand && !showTiles)
          })}>
          <Grid container style={{minHeight: 700}}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <h2 className={classes.title} style={{color: '#fff'}}>{content.title}</h2>
            </Grid>
            {
              selectedTile && !showTiles &&
              <Grid item xs={12}>
                <Animation animationIn="zoomIn" animationOut="zoomOut"
                           isVisible={showTileDetails}>
                  <div className={classes.tileDetailsContainer}>
                    <div style={{display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-end'}}>
                      <h3 className={classes.sectionItemTitle}
                          style={{marginLeft: 5, color: '#fff'}}>{selectedTile.title}</h3>
                    </div>
                    <div style={{display: 'flex', justifyContent: 'flex-end', flexDirection: 'row'}}>
                      <IconButton
                        color={"secondary"}
                        style={{color: 'inherit'}}
                        onClick={(event) => {
                          setShowTileDetails(false);
                          setTimeout(() => {
                            showHideTiles(null)(event)
                          }, 1000)
                        }}
                      >
                        <CloseIcon style={{color: 'inherit'}}/>
                      </IconButton>
                    </div>
                    {selectedTile.detail}
                  </div>
                </Animation>
              </Grid>
            }
            {
              showTiles && tiles.map((tile, index) => {
                return (
                  <Grid
                    key={`tile${index}`}
                    item xs={12}
                    sm={12}
                    md={6}
                    lg={4}
                    className={classNames({
                      [classes.tileGridContainer]: true,
                      [classes.tileGridContainerHover]: tile.showTile
                    })}
                    onClick={showHideTiles(tile)}
                  >
                    <Animation animationIn="zoomIn" delay={150 * index} animationOut="zoomOut"
                               isVisible={tile.showTile}>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'flex-end'
                        }}
                      >
                        <div>
                          {tile.image}
                        </div>
                      </div>
                      <h3 className={classes.sectionItemTitle}>{tile.title}</h3>
                      <h5 className={classes.sectionItemTitle}>
                        {tile.description}
                      </h5>
                    </Animation>
                  </Grid>
                )
              })
            }
          </Grid>
        </div>
      </div>
    </div>
  )
}

export default Advantages
