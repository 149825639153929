import React from "react";
import productDetailsStyle from '../../assets/jss/material-kit-react/components/productDetails.jsx'
import withStyles from "@material-ui/core/styles/withStyles";
import {Card, CardContent, CardHeader, Grid} from "@material-ui/core";

const CenterItems = ({ classes, product }) => {
    return (
        <div className={classes.centerCardContainer}>
        <div style={{textAlign: 'center', width: '100%'}}>
          <h2>{product.centerItemsTitle}</h2>
        </div>
        <Grid container justify={"center"}>
          {product.centerItems && product.centerItems.map((centerItem, index) => {
            return (
              <Grid item xl={product.centerItemsSpacing || 3} lg={product.centerItemsSpacing || 3}
                    md={product.centerItemsSpacing || 3} sm={12} xs={12}
                    style={{
                      display: 'flex',
                      alignContent: 'center',
                      alignItems: 'center',
                      flexDirection: 'column',
                      margin: 10
                    }}>
                <Card style={{width: '100%', flex: 1, height: '100%'}}>
                  <CardHeader
                    className={classes.brochureCardHeader}
                    title={centerItem.title}
                  />
                  <CardContent style={{
                    padding: 25,
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                  }}>
                    {centerItem.content}
                  </CardContent>
                </Card>
              </Grid>
            )
          })}
        </Grid>
      </div>
    )
};

export default withStyles(productDetailsStyle)(CenterItems);