/*eslint-disable*/
import React from "react";
import {Link} from "react-router-dom";
import withStyles from "@material-ui/core/styles/withStyles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Button from "components/CustomButtons/Button.jsx";

import headerLinksStyle from "assets/jss/material-kit-react/components/headerLinksStyle.jsx";

function HeaderLinks({...props}) {
  const {classes} = props;

  const onHomePage = (location.pathname == "/" || location.pathname.indexOf("/landing-page") > -1);

  const closeSideBar = () => {
    var closeSideBarEvent = new Event('closeSideBar');
    window.dispatchEvent(closeSideBarEvent);
  }
  return (
    <List className={classes.list}>
      <ListItem className={classes.listItem}>
        {
          onHomePage ?
            <a target={"_blank"} href="/claims-center" style={{color: "inherit"}} onClick={closeSideBar}>
              <Button
                onClick={closeSideBar}
                color={props.highlighted == "claims-center" ? "lightGray" : "transparent"}
                className={props.highlighted == "claims-center" ? classes.navLinkHighlight : classes.navLink}
              >
                Claims Center
              </Button>
            </a>
            :
            <a target={"_blank"} href="/claims-center" style={{color: "inherit"}} onClick={closeSideBar}>
              <Button
                color={props.highlighted == "claims-center" ? "lightGray" : "transparent"}
                className={props.highlighted == "claims-center" ? classes.navLinkHighlight : classes.navLink}
              >
                Claim Center
              </Button>
            </a>
        }
      </ListItem>
    </List>
  );
}

export default withStyles(headerLinksStyle)(HeaderLinks);
