export default [
  {
    "title": "THE ROAD MORE PROFITABLE STARTS HERE",
    "subTitle": "From roadside assistance to tire/wheel protection to hospital indemnity programs; NSD has the profitable solution for your particular customers’ needs. Put our 50 years of experience to work for you, today.",
    "image": require("assets/img/landing-bg.jpg"),
    "filter": true
  },
  {
    "title": "VALUE PROTECT",
    "subTitle": "DEFEAT DEPRECIATION! Even if fully repaired, vehicles involved in accidents suffer depreciation up to 30%!",
    "image": require("assets/img/value_protect_bg.jpg"),
    "button": "Click Here to Learn More",
    "href": "https://valueprotect.nationsafedrivers.com/",
    "filter": true,
    "subImage": require("assets/img/value-protect-logo-white.png")
  },
  {
    "title": "WELCOME TO THE FUTURE OF ROADSIDE ASSISTANCE",
    "subTitle": "The road more profitable starts here",
    "image": require("assets/img/main1.jpg"),
    "filter": true,
    "button": "Click Here to Learn More",
    "href": "https://nationsafedrivers.com/roadside-assistance-future/",
  },
]
