/*eslint-disable*/
import React from "react";
// react components for routing our app without refresh
import {Link} from "react-router-dom";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
// @material-ui/icons
import {Apps} from "@material-ui/icons";
// core components
import CustomDropdown from "components/CustomDropdown/CustomDropdown.jsx";
import Button from "components/CustomButtons/Button.jsx";

import headerLinksStyle from "assets/jss/material-kit-react/components/headerLinksStyle.jsx";

function HeaderLinks({...props}) {
  const {classes} = props;

  const marketAppraisalDropDownLink = classNames({
    [classes.dropdownLink]: true,
    [classes.selectedDropdownLink]: location.pathname == "/market-appraisal",
  });

  const productAppraisalDropDownLink = classNames({
    [classes.dropdownLink]: true,
    [classes.selectedDropdownLink]: location.pathname == "/product-appraisal",
  });

  const infrastructureAppraisalDropDownLink = classNames({
    [classes.dropdownLink]: true,
    [classes.selectedDropdownLink]: location.pathname == "/infrastructure-appraisal",
  });

  const technicalAppraisalDropDownLink = classNames({
    [classes.dropdownLink]: true,
    [classes.selectedDropdownLink]: location.pathname == "/technical-appraisal",
  });

  const databaseReviewDropDownLink = classNames({
    [classes.dropdownLink]: true,
    [classes.selectedDropdownLink]: location.pathname == "/database-review",
  });

  const localizationDropDownLink = classNames({
    [classes.dropdownLink]: true,
    [classes.selectedDropdownLink]: location.pathname == "/localization",
  });

  const mobilePlatformReviewDropDownLink = classNames({
    [classes.dropdownLink]: true,
    [classes.selectedDropdownLink]: location.pathname == "/mobile-platform-review",
  });

  const setupAndConfigDropDownLink = classNames({
    [classes.dropdownLink]: true,
    [classes.selectedDropdownLink]: location.pathname == "/setup-and-config",
  });

  const deploymentDropDownLink = classNames({
    [classes.dropdownLink]: true,
    [classes.selectedDropdownLink]: location.pathname == "/deployment",
  });
  const onHomePage = (location.pathname == "/claims-center" || location.pathname == "/claims-center/");

  const closeSideBar = () => {
    var closeSideBarEvent = new Event('closeSideBar');
    window.dispatchEvent(closeSideBarEvent);
  }
  return (
    <List className={classes.list}>
      {/*<ListItem className={classes.listItem}>*/}
      {/*  {*/}
      {/*    onHomePage ?*/}
      {/*      <a target={"_blank"} href="/niuw" style={{color: "inherit"}}>*/}
      {/*        <Button*/}
      {/*          color={props.highlighted == "claims-center-products" ? "lightGray" : "transparent"}*/}
      {/*          className={props.highlighted == "claims-center-products" ? classes.navLinkHighlight : classes.navLink}*/}
      {/*          onClick={closeSideBar}*/}
      {/*        >*/}
      {/*          NIUW*/}
      {/*        </Button>*/}
      {/*      </a>*/}
      {/*      :*/}
      {/*      <a target={"_blank"} href="/niuw" style={{color: "inherit"}}>*/}
      {/*        <Button*/}
      {/*          color={props.highlighted == "claims-center-products" ? "lightGray" : "transparent"}*/}
      {/*          className={props.highlighted == "claims-center-products" ? classes.navLinkHighlight : classes.navLink}*/}
      {/*          onClick={closeSideBar}*/}
      {/*        >*/}
      {/*          NIUW*/}
      {/*        </Button>*/}
      {/*      </a>*/}
      {/*  }*/}
      {/*</ListItem>*/}
      {/*<ListItem className={classes.listItem}>*/}
      {/*  {*/}
      {/*    onHomePage ?*/}
      {/*      <a target={"_blank"} href="/niu-fl" style={{color: "inherit"}}>*/}
      {/*        <Button*/}
      {/*          color={props.highlighted == "claims-center-products" ? "lightGray" : "transparent"}*/}
      {/*          className={props.highlighted == "claims-center-products" ? classes.navLinkHighlight : classes.navLink}*/}
      {/*          onClick={closeSideBar}*/}
      {/*        >*/}
      {/*          NIU Florida*/}
      {/*        </Button>*/}
      {/*      </a>*/}
      {/*      :*/}
      {/*      <a target={"_blank"} href="/niu-fl" style={{color: "inherit"}}>*/}
      {/*        <Button*/}
      {/*          color={props.highlighted == "claims-center-products" ? "lightGray" : "transparent"}*/}
      {/*          className={props.highlighted == "claims-center-products" ? classes.navLinkHighlight : classes.navLink}*/}
      {/*          onClick={closeSideBar}*/}
      {/*        >*/}
      {/*          NIU Florida*/}
      {/*        </Button>*/}
      {/*      </a>*/}
      {/*  }*/}
      {/*</ListItem>*/}
      <ListItem className={classes.listItem}>
        {
          onHomePage ?
            <Button
              href="/claims-center-products"
              color={props.highlighted == "claims-center-products" ? "lightGray" : "transparent"}
              className={props.highlighted == "claims-center-products" ? classes.navLinkHighlight : classes.navLink}
              onClick={()=>{
                closeSideBar()
                var resetProductPage = new Event('resetProductPage');
                window.dispatchEvent(resetProductPage);
              }}
            >
              Products
            </Button>
            :
            <a href="/claims-center-products" style={{color: "inherit"}}>
              <Button
                color={props.highlighted == "claims-center-products" ? "lightGray" : "transparent"}
                className={props.highlighted == "claims-center-products" ? classes.navLinkHighlight : classes.navLink}
                onClick={()=>{
                  closeSideBar()
                  var resetProductPage = new Event('resetProductPage');
                  window.dispatchEvent(resetProductPage);
                }}
              >
                Products
              </Button>
            </a>
        }
      </ListItem>
      <ListItem className={classes.listItem}>
        <CustomDropdown
          noLiPadding
          buttonText="Access Claims"
          buttonProps={{
            color: (props.highlighted == "products-services" ? "lightGray" : "transparent"),
            className: (props.highlighted == "products-services" ? classes.navLinkHighlight : classes.navLink)
          }}
          buttonIcon={Apps}
          dropdownList={[
            // coreServicesLink,
            <a href={"/login-page/Member"} onClick={closeSideBar} className={technicalAppraisalDropDownLink}>
              Members Area
            </a>,
            <a href={"/login-page/Producer"} onClick={closeSideBar} className={technicalAppraisalDropDownLink}>
              Producers
            </a>,
            <a href={"/login-page/Leinholder"} onClick={closeSideBar} className={technicalAppraisalDropDownLink}>
              Leinholder
            </a>
            // <a href="https://www.nsdmc.com/NSD_OGK/claimscenter/claimview/claims.asp?ptype=Member" target={"_blank"}
            //    className={technicalAppraisalDropDownLink}
            //    onClick={closeSideBar}>
            //   Members Area
            // </a>,
            // <a href="https://www.nsdmc.com/NSD_OGK/claimscenter/claimview/claims.asp?ptype=Producer" target={"_blank"}
            //    className={infrastructureAppraisalDropDownLink}
            //    onClick={closeSideBar}>
            //   Producers
            // </a>,
            // <a href="https://www.nsdmc.com/NSD_OGK/claimscenter/claimview/claims.asp?ptype=Leinholder" target={"_blank"}
            //    className={productAppraisalDropDownLink}
            //    onClick={closeSideBar}>
            //   Leinholder
            // </a>
          ]}
        />
      </ListItem>
    </List>
  );
}

export default withStyles(headerLinksStyle)(HeaderLinks);
