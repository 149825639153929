import React from "react";

export default {
  "title": "Services",
  "name": "PDR Travel Program",
  id: 'pdr-travel-program',
  image: require('assets/img/paintless-dent-repair.jpg'),
  detailImage: require('assets/img/travel-1303949_640_color.png'),
  "description": [
    "Paint-less Dent Repair (PDR) Travel Program",
    "It is almost impossible to prevent your new car from eventually having a minor dent or ding. With NSD's PDR Travel program you are ultimately protected from occasional dent and dings. Protect your new car buying experience today! The PDR Travel Program is designed to enhance your vehicle ownership experience. Whether you are leasing or buying you will enjoy the confidence that comes with knowing those irritating dents will be taken care of. (Please see contract for the specific terms and conditions of coverage.)"
  ],
  brochures: [
    {
      title: "PDR Travel Program",
      link: "brochures/paintless-dent-repair-travel.pdf"
    }
  ],
  mainContentNote: "Please ask your finance consultant or sales advisor for details of the program.",
  mainContent: (
    <React.Fragment>
      It is almost impossible to prevent your new car from eventually having a minor dent or ding. With NSD's PDR Travel
      program you are ultimately protected from occasional dent and dings. Protect your new car buying experience today!
      <br/>
      <br/>
      The PDR Travel Program is designed to enhance your vehicle ownership experience. Whether you are leasing or buying
      you will enjoy the confidence that comes with knowing those irritating dents will be taken care of. (Please see
      contract for the specific terms and conditions of coverage.)
      <br/>
      <br/>
      Using special tools and techniques developed by auto manufacturing teams, technicians are able to permanently
      remove door dings and minor dents. The process is seamless and will not harm a vehicles factory finish.
    </React.Fragment>
  ),
  centerItems: [
    {
      title: "The Process",
      content: (
        <ul>
          <li>No paint required</li>
          <li>Permanent repair of minor dents and dings</li>
          <li>Won't harm your vehicles factory finish</li>
          <li>Designed for the automotive community by auto manufacturing teams</li>
          <li>No hassle, easy-to-use</li>
          <li>Nationwide network</li>
          <li>Discounts & travel benefits</li>
        </ul>
      )
    },
    {
      title: "Travel Benefits",
      content: (
        <React.Fragment>
          Wherever you are parked, you'll enjoy the secure feeling of knowing you are protected by the Nation Safe
          Drivers PDR Travel Program.
          <br/>
          <br/>
          Fast response and excellent service are just two reasons why millions of motorists rely on Nation Safe Drivers
          for vehicle protection.
        </React.Fragment>
      )
    }
  ],
  sections: [
    {
      title: "Online Travel Discounts",
      description: [
        "Members will be able to obtain hotel discounts, low airfare, and rental car discounts online."
      ]
    },
    {
      title: "Hotel Discounts",
      description: [
        "Members may go online or call toll-free to obtain the industry's best hotel rates."
      ]
    },
    {
      title: "Family Fun Travel Packages",
      description: [
        "Personal travel consultants stand ready to assist you in planning great romantic or family getaways."
      ]
    },
    {
      title: "Contact Lens Discounts",
      description: [
        "Members enjoy up to 60% off name brand mail-order contact lenses."
      ]
    }
  ]

}
