import React from "react";

export default {
  title: "Assistance",
  items: [
    {
      "title": "Nation Safe Drivers Auto Club",
      id: "roadside-assistance",
      image: require("assets/img/Roadside-Assistance.jpg"),
      "description": [
        "The Nation Safe Drivers Auto Club provides real peace-of-mind at affordable rates. When on the road you'll know that you are covered and protected by an organization that cares and has been caring since 1962! Our customer representatives respond quickly to provide you the best service and protection available anywhere. We at NSD have earned a solid reputation for fast and courteous service. You can count on the company with \"Safe\" as its middle name!"
      ]
    },
    {
      "title": "Rental Car Reimburse",
      "name": "Rental Car Reimburse",
      id: "rental-car-reimburse",
      image: require("assets/img/rentalcar.jpg"),
      detailImage: require("assets/img/reimbursement_color.png"),
      "description": [
        "NSD Offers rental reimbursement in the event your covered automobile is involved in a collision or theft. We will cover the cost of renting a replacement vehicle during the time the covered vehicle is in the repair shop. Other benefits include ambulance assistance, personal effects coverage, and bail bond coverage."
      ],
      "note": "Not available in all States",
      "noteTwo": "(Please see contract for the specific terms and conditions of coverage.)",
      mainContent: "NSD Offers rental reimbursement in the event your covered automobile is involved in a collision or theft. We will cover the cost of renting a replacement vehicle during the time the covered vehicle is in the repair shop. Other benefits include ambulance assistance, personal effects coverage, and bail bond coverage. ",
      mainContentNote: (
        <React.Fragment>
          Not available in all States
          <br/>
          <br/>
          (Please see contract for the specific terms and conditions of coverage.)
        </React.Fragment>
      ),
      centerItems: [
        {
          title: "Also Includes these Benefits",
          content: (
            <React.Fragment>
              <ul>
                <li>Ambulance Assistance</li>
                <li>Personal Effects Coverage</li>
                <li>Bail Bond Coverage</li>
              </ul>
            </React.Fragment>
          )
        }
      ]
    }
  ]
}
