import 'react-app-polyfill/ie9';
import React from "react";
import ReactDOM from "react-dom";
import {createBrowserHistory} from "history";
import {Route, Router, Switch} from "react-router-dom";

import "assets/scss/material-kit-react.scss?v=1.4.0";
import LandingPage from "views/LandingPage/LandingPage.jsx";
import LoginPage from "views/LoginPage/LoginPage.jsx";
import ClaimsCenterLandingPage from "views/ClaimsCenter/LandingPage";
import ClaimsCenterProducts from "views/ClaimsCenter/ClaimsCenter";
import AutomotiveDealers from "views/AutomotiveDealers/AutomotiveDealers";
import Agents from "views/Agents/Agents";
import InsurancePartners from "views/InsurancePartners/InsurancePartners"
import ProductDetails from "./views/ProductDetails/ProductDetails";
import Availability from "./views/Availability/Availability";
import NIUFlorida from "./views/NIUFlorida/NIUFlorida";
import NIUW from "./views/NIUW/NIUW";
import NIUWProducts from "./views/NIUW/NIUWProducts";
import NIUWClientCenter from "./views/NIUW/NIUWClientCenter";
import NIUWOurPartners from "./views/NIUW/NIUWOurPartners";
import NIUWGlossary from "./views/NIUW/NIUWGlossary";
import Privacy from "./views/Privacy/Privacy"
import FutureOfRoadsideAssistanceLandingPage from "./views/FutureOfRoadsideAssistance/LandingPage";
import AtlassianServiceDeskChatWidget from "./components/AtlassianServiceDeskChatWidget/AtlassianServiceDeskChatWidget";
import Theme from "./views/Theme";

var hist = createBrowserHistory();

ReactDOM.render(
  <Router history={hist}>
    {/* <AtlassianServiceDeskChatWidget widgetKey="e525141c-ef26-4677-bf53-bf66d7e7a97b" /> */}
    <Switch>
      <Route path="/landing-page" component={LandingPage}/>
      <Route path="/login-page/:productId" component={LoginPage}/>
      <Route path="/claims-center" component={ClaimsCenterLandingPage}/>
      <Route path="/automotive-dealers" component={AutomotiveDealers}/>
      <Route path="/agents" component={Agents}/>
      <Route path="/privacy" component={Privacy}/>
      <Route path="/insurance-partners" component={InsurancePartners}/>
      <Route path="/:areaId/product-details/:productId" component={ProductDetails}/>
      <Route path="/claims-center-products" component={ClaimsCenterProducts}/>
      <Route path="/claims-center-privacy" component={Privacy}/>
      <Route path="/availability" component={Availability}/>
      <Route path="/niu-fl" component={NIUFlorida}/>
      <Route path="/niuw" component={NIUW}/>
      <Route path="/niuw-products" component={NIUWProducts}/>
      <Route path="/niuw-client-center" component={NIUWClientCenter}/>
      <Route path="/niuw-glossary" component={NIUWGlossary}/>
      <Route path="/niuw-our-partners" component={NIUWOurPartners}/>
      <Route path="/roadside-assistance-future" component={FutureOfRoadsideAssistanceLandingPage}/>
      <Route path="/theme/:theme" component={Theme}/>
      <Route path="/" component={LandingPage}/>
    </Switch>
  </Router>
  ,
  document.getElementById("root")
);
