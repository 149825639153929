import React from "react";

export default {
  title: "Protections",
  items:
    [
      {
        id: "identity-theft",
        "title": "Identity Theft Protection",
        image: require("assets/img/binary-1538721_640.jpg"),
        "description": [
          "Identity Theft is the fastest growing crime in America. In a matter of seconds, personal information such as a social security number, a credit card number and/or an address can be stolen; leaving individuals to unravel the financial mess created by an identity thief."
        ]
      },
      {
        id: "mortgage-protection",
        "title": "Mortgage Protection",
        image: require("assets/img/AdobeStock_310541670.jpg"),
        "name": "Mortgage Protection",
        detailImage: require("assets/img/black-1296170_640_color.png"),
        "description": [
          "This unique product is underwritten by an \"A\" Rated Admitted Insurance Company. It is offered through our agency partners throughout the continental US. First Protector helps pay your customer's mortgage payments and provides them with CASH, among other benefits not typically covered by their homeowners insurance, if they are displaced from their home due to a hurricane, flood, mudslides, tornado or other type of covered disaster..."
        ],
        mainContent: (
          <React.Fragment>
            This unique product is underwritten by an "A" Rated Admitted Insurance Company. It is offered through our
            agency partners throughout the continental US.
            <br/>
            <br/>
            First Protector helps pay your customer's mortgage payments and provides them with CASH, among other
            benefits not typically covered by their homeowners insurance, if they are displaced from their home due to a
            hurricane, flood, mudslides, tornado or other type of covered disaster...
            <br/>
            <br/>
            Your mortgage remains your responsibility even when you are displaced due to damage to your home.
          </React.Fragment>
        ),
        mainContentNote: "Available in 43 states, including Florida.",
        centerItemsTitle: "First Protector - Mortgage Disaster Protection/Homeowner's Deductible Coverage",
        centerItems: [
          {
            title: "First Protector Pays",
            content: (
              <React.Fragment>
                <ul>
                  <li>Your monthly mortgage payment for up to two years, giving you time to have your home repaired or
                    rebuilt to livable conditions
                  </li>
                  <li>The balance on your mortgage, after proceeds payable by your homeowner's policy, when your home is
                    judged to be permanently unlivable.
                  </li>
                  <li>Your homeowners policy deductible - up to $1,000</li>
                  <li>In addition to all other insurance, including homeowners</li>
                </ul>
              </React.Fragment>
            )
          }
        ],
        sections: [
          {
            title: "Please contact National Insurance Underwriters Marketing ",
            description: [
              "1-800-338-2680 x507"
            ]
          }
        ]
      }
    ]
}
