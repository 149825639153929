import React, {useState} from "react";
import StandardPage from "../../components/StandardPage/StandardPage";
import content from "../../content/Availability/main";
import withStyles from "@material-ui/core/styles/withStyles";
import availabilityPageStyle from '../../assets/jss/material-kit-react/views/availability/availability';
import './style/map.css';
import USAMap from "react-usa-map";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Grid,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Tabs,
  Typography
} from '@material-ui/core';
import {ExpandMore as ExpandMoreIcon} from "@material-ui/icons";
import {primaryColor, secondaryColor} from "../../assets/jss/material-kit-react";
import each from 'lodash/each';
import find from 'lodash/find';
import {Helmet} from "react-helmet";

var tinycolor2 = require('tinycolor2');

function TabPanel(props) {
  const {children, value, index, ...other} = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      <Box p={3}>{children}</Box>
    </Typography>
  );
}

const StyledTabs = withStyles({
  root: {
    borderBottom: `1px solid ${tinycolor2(secondaryColor).lighten(46).toHexString()}`,
  },
  indicator: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: primaryColor,
    '& > div': {
      width: '100%',
      backgroundColor: primaryColor,
    },
  },
})(props => <Tabs {...props} TabIndicatorProps={{children: <div/>}}/>);

const StyledTab = withStyles(theme => ({
  root: {
    textTransform: 'none',
    color: secondaryColor,
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(15),
    marginRight: theme.spacing(1),
    '&:focus': {
      opacity: 1,
    },
  }
}))(props => <Tab disableRipple {...props} />);

function Availability({...props}) {
  const {classes, ...rest} = props;
  const [selectedState, setSelectedState] = useState(null)
  const [availableProducts, setAvailableProducts] = useState({
    automotive: [],
    insurancePartners: [],
    powerSportsMarine: [],
    recreationVehicles: [],
    forms: []
  })
  const [tabIndex, setTabIndex] = useState(0)
  const [moreFormsExpanded, setMoreFormsExpanded] = useState(false);

  const mapHandler = (event) => {
    let stateAbbr = event.target.dataset.name;
    setSelectedState({
      [stateAbbr]: {
        fill: secondaryColor,
      }
    });

    let newAvailableProducts = {
      automotive: [],
      insurancePartners: [],
      powerSportsMarine: [],
      recreationVehicles: [],
      forms: []
    }

    each(newAvailableProducts, (areaValue, area) => {
      each(content.products[area], product => {
        if (product.available.indexOf(stateAbbr) >= 0) {
          newAvailableProducts[area].push(product.name);
        } else if (product.licenseRequired.indexOf(stateAbbr) >= 0) {
          newAvailableProducts[area].push(`${product.name} - License Required`);
        }
      })
    })

    let stateForm = find(content.licensingForms, {state: stateAbbr});
    if (stateForm)
      newAvailableProducts.forms.push(stateForm)

    console.log('newAvailableProducts', newAvailableProducts)
    setAvailableProducts(newAvailableProducts);
  };

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue)
  };

  return (
    <StandardPage
      title={content.title}
      description={content.description}
      image={require("assets/img/background-3258587_1920.jpg")}
    >
      <Helmet>
        <meta charSet="utf-8"/>
        <title>{`NSD Product Availability`}</title>
        <meta name="description"
              content={`NSD products are available in most states. Please refer to availability options to find out what products are in your state.`}/>
        <link rel="canonical" href={window.location.href}/>
      </Helmet>
      <div className={classes.title} style={{width: '100%', display: "flex", justifyContent: "center", padding: 10}}>
        <h2 className={classes.description}>Product Availability and Licensing Forms</h2>
      </div>
      <div className={classes.mainInformationContainer}>
        <h3 className={classes.note}>
          NSD products are available in most states. Please refer to availability options to find out what products are
          in your state.
        </h3>
      </div>
      <div className={classes.centerCardContainer}>
        <div style={{textAlign: 'center', width: '100%'}}>
          <h2>NSD Licensing Forms</h2>
        </div>
        <Grid container justify={"center"}>
          {
            content.forms && content.forms.map((form, index) => {
              return (
                <Grid item key={`form${index}`} xs={12} sm={12} md={12} lg={3} xl={3}>
                  <div className={classes.formContainer}>
                    <Card className={classes.formCard}>
                      <CardHeader
                        className={classes.formCardHeader}
                        subheader={form.name}
                      />
                      <CardContent style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        alignContent: 'center'
                      }}>
                        <img style={{width: 100}} src={require('../../assets/img/form_640_color.png')}/>
                      </CardContent>
                      <CardActions className={classes.formAction}>
                        <a href={form.link} target={"_blank"}>
                          <Button variant={"contained"} color={"primary"}>DOWNLOAD FORM</Button>
                        </a>
                      </CardActions>
                    </Card>
                  </div>
                </Grid>
              )
            })
          }
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <div style={{padding: "10px 15% 0px 15%"}}>
              <ExpansionPanel
                expanded={moreFormsExpanded} onChange={() => {
                setMoreFormsExpanded(!moreFormsExpanded)
              }}
              >
                <ExpansionPanelSummary
                  expandIcon={<ExpandMoreIcon/>}
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                >
                  <Typography className={classes.secondaryExpansionPanelHeading}>Additional forms by state</Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  <Grid container justify={"center"}>
                    {
                      content.licensingForms && content.licensingForms.map((form, index) => {
                        return (
                          <Grid item key={`form${index}`} xs={12} sm={12} md={12} lg={3} xl={3}>
                            <div className={classes.formContainer}>
                              <Card className={classes.formCard}>
                                <CardHeader
                                  className={classes.formCardHeader}
                                  subheader={`${form.state} - ${form.name}`}
                                />
                                <CardContent style={{
                                  display: 'flex',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                  alignContent: 'center'
                                }}>
                                </CardContent>
                                <CardActions className={classes.formAction}>
                                  <a href={form.link} target={"_blank"}>
                                    <Button variant={"contained"} color={"primary"}>DOWNLOAD FORM</Button>
                                  </a>
                                </CardActions>
                              </Card>
                            </div>
                          </Grid>
                        )
                      })
                    }
                  </Grid>
                </ExpansionPanelDetails>
              </ExpansionPanel>
            </div>
          </Grid>
        </Grid>
      </div>
      <Grid container>
        <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
          <div style={{display: 'flex', width: '100%', justifyContent: 'center', padding: 10}}>
            <h3 className={classes.description}>Choose a state to see which products are available in that area</h3>
          </div>
          <div className="path">
            <USAMap title={"Choose your state"} customize={selectedState} width={"100%"} onClick={mapHandler}/>
          </div>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
          <div style={{padding: 15}}>
            <StyledTabs value={tabIndex} onChange={handleTabChange} aria-label="product availability">
              <StyledTab label="Automotive"/>
              <StyledTab label="Power Sports & Marine"/>
              <StyledTab label="Insurance Partners"/>
              <StyledTab label="Recreational Vehicles"/>
              <StyledTab label="Forms"/>
            </StyledTabs>
            <TabPanel value={tabIndex} index={0}>
              {
                availableProducts.automotive.length === 0 && selectedState &&
                <div className={classes.description}>No products available</div>
              }
              {
                !selectedState &&
                <div className={classes.description}>Select a state to see product availability</div>
              }
              <Table>
                <TableBody>
                  {
                    availableProducts.automotive.map(product => {
                      return (
                        <TableRow>
                          <TableCell>
                            {product}
                          </TableCell>
                        </TableRow>
                      )
                    })
                  }
                </TableBody>
              </Table>
            </TabPanel>
            <TabPanel value={tabIndex} index={1}>
              {
                availableProducts.powerSportsMarine.length === 0 && selectedState &&
                <div className={classes.description}>No products available</div>
              }
              {
                !selectedState &&
                <div className={classes.description}>Select a state to see product availability</div>
              }
              <Table>
                <TableBody>
                  {
                    availableProducts.powerSportsMarine.map(product => {
                      return (
                        <TableRow>
                          <TableCell>
                            {product}
                          </TableCell>
                        </TableRow>
                      )
                    })
                  }
                </TableBody>
              </Table>
            </TabPanel>
            <TabPanel value={tabIndex} index={2}>
              {
                availableProducts.insurancePartners.length === 0 && selectedState &&
                <div className={classes.description}>No products available</div>
              }
              {
                !selectedState &&
                <div className={classes.description}>Select a state to see product availability</div>
              }
              <Table>
                <TableBody>
                  {
                    availableProducts.insurancePartners.map(product => {
                      return (
                        <TableRow>
                          <TableCell>
                            {product}
                          </TableCell>
                        </TableRow>
                      )
                    })
                  }
                </TableBody>
              </Table>
            </TabPanel>
            <TabPanel value={tabIndex} index={3}>
              {
                availableProducts.recreationVehicles.length === 0 && selectedState &&
                <div className={classes.description}>No products available</div>
              }
              {
                !selectedState &&
                <div className={classes.description}>Select a state to see product availability</div>
              }
              <Table>
                <TableBody>
                  {
                    availableProducts.recreationVehicles.map(product => {
                      return (
                        <TableRow>
                          <TableCell>
                            {product}
                          </TableCell>
                        </TableRow>
                      )
                    })
                  }
                </TableBody>
              </Table>
            </TabPanel>
            <TabPanel value={tabIndex} index={4}>
              {
                availableProducts.forms.length === 0 && selectedState &&
                <div className={classes.description}>No forms available</div>
              }
              {
                !selectedState &&
                <div className={classes.description}>Select a state to see form availability</div>
              }
              <Table>
                <TableBody>
                  {
                    availableProducts.forms.map(product => {
                      return (
                        <TableRow>
                          <TableCell>
                            <a href={product.link} target={"_blank"}>{product.name}</a>
                          </TableCell>
                        </TableRow>
                      )
                    })
                  }
                </TableBody>
              </Table>
            </TabPanel>
          </div>
        </Grid>
      </Grid>
    </StandardPage>
  )
}

export default withStyles(availabilityPageStyle)(Availability);
