import React from 'react'
import classNames from "classnames";
import {useTheme, withStyles} from '@material-ui/core/styles';
import Fade from "react-reveal/Fade";
import {Link} from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import multiItemSectionStyle from '../../../assets/jss/material-kit-react/components/multiItemSection'

function SingleItemSelection({...props}) {
  const theme = useTheme();
  const {classes, content, altRow, imageLeft, productArea, noLearnMore} = props;

  return (
    <div className={classNames({[classes.section]: true, [classes.altSection]: altRow})}>
      <div>
        <Grid container className={classes.mainGridContainer}>
          <Grid item xs={12} sm={12} md={12}>
            <h2 className={classes.title}>{content.title || content.name || ''}</h2>
            <Fade bottom>
              <Grid container>
                {
                  imageLeft && content.image ?
                    <Grid item lg={5} style={{padding: 10}}>
                      <img src={content.image} alt="terrifiq" className={classes.raised}
                           style={{width: '100%', maxWidth: 600}}/>
                    </Grid>
                    :
                    null
                }
                <Grid item lg={content.image ? 7 : 12} style={{padding: 50, textAlign: 'left'}}>
                  {
                    content.products &&
                    <React.Fragment>
                      <h3 className={classes.description}>
                        NSD offers many protection plans to meet your needs. Today we provide:
                        <ul>
                          {
                            !noLearnMore && content.products && content.products.map(product => {
                              let link = {};
                              if (product.externalLink) {
                                link = { ...product.externalLink }
                              } else {
                                link = { href: `/${productArea}/product-details/${product.id}` }
                              }
                              return (
                                <li className={classNames({[classes.description]: true})}>{product.name} <a
                                    {...link}><small>...Learn more</small></a>
                                </li>
                              );
                            })
                          }
                        </ul>
                      </h3>
                    </React.Fragment>
                  }
                  {
                    <React.Fragment>
                      <h3 className={classes.description}>
                        {
                          content.description && content.description.map((item, index) => (
                            <React.Fragment>{item}<br/><br/></React.Fragment>
                          ))
                        }
                        {!noLearnMore && !content.products ?
                          <a href={`/${productArea}/product-details/${content.id}`}><small>...Learn
                            more</small></a> : null}
                      </h3>
                    </React.Fragment>
                  }
                </Grid>
                {
                  !imageLeft && content.image ?
                    <Grid item lg={5} style={{padding: 10}}>
                      <img src={content.image || null} alt={content.altImage || ''} className={classes.raised}
                           style={{width: '100%', maxWidth: 600}}/>
                    </Grid>
                    :
                    null
                }
              </Grid>
            </Fade>
          </Grid>
        </Grid>
      </div>
    </div>
  )
}

export default withStyles(multiItemSectionStyle)(SingleItemSelection)
