import {container, secondaryColor, title} from "../../material-kit-react.jsx";
import {primaryColor} from "../../material-kit-react";

var tinycolor2 = require('tinycolor2');

function arrowGenerator(color) {
  return {
    '&[x-placement*="bottom"] $arrow': {
      top: 0,
      left: 0,
      marginTop: '-0.95em',
      width: '3em',
      height: '1em',
      '&::before': {
        borderWidth: '0 1em 1em 1em',
        borderColor: `transparent transparent ${color} transparent`,
      },
    },
    '&[x-placement*="top"] $arrow': {
      bottom: 0,
      left: 0,
      marginBottom: '-0.95em',
      width: '3em',
      height: '1em',
      '&::before': {
        borderWidth: '1em 1em 0 1em',
        borderColor: `${color} transparent transparent transparent`,
      },
    },
    '&[x-placement*="right"] $arrow': {
      left: 0,
      marginLeft: '-0.95em',
      height: '3em',
      width: '1em',
      '&::before': {
        borderWidth: '1em 1em 1em 0',
        borderColor: `transparent ${color} transparent transparent`,
      },
    },
    '&[x-placement*="left"] $arrow': {
      right: 0,
      marginRight: '-0.95em',
      height: '3em',
      width: '1em',
      '&::before': {
        borderWidth: '1em 0 1em 1em',
        borderColor: `transparent transparent transparent ${color}`,
      },
    },
  };
}

const landingPageStyle = theme => ({
  eqWrap: {
    display: 'flex'
  },
  equalHMR: {
    width: '100%',
    marginBottom: '2%'
  },
  eq: {
    padding: 10
  },
  equalHW: {
    flex: 1
  },
  equalHMRWrap: {
    // justifyContent: 'space-between',
    width: '100%',
    flexWrap: 'wrap'
  },
  zeroHeight: {
    width: '90%'
  },
  sections: {
    padding: "70px 0px -250px 0px"
  },
  altSection: {
    backgroundColor: tinycolor2(secondaryColor).brighten(15).toHexString(),
  },
  description: {
    margin: "1.071rem auto 0",
    color: "#999",
    textAlign: "center",
  },
  mainContentTop: {
    margin: "0px 150px",
    color: "#999",
    whiteSpace: 'pre-wrap',
    wordSpacing: 0
  },
  feedbackIconContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
    marginRight: 12,
    marginBottom: 12,
    backgroundColor: tinycolor2("#3f51b5").lighten(35).toHexString(),
    height: 60,
    width: 60,
    padding: 10,
    borderRadius: 30
  },
  feedbackIcon: {
    color: tinycolor2(secondaryColor).lighten(15).toHexString(),
    width: 28
  },
  container: {
    zIndex: "12",
    color: "#FFFFFF",
    ...container
  },
  centerCard: {
    margin: 10,
    // flex: 1,
    height: '100%',
    maxWidth: 450
  },
  centerCardContainer: {
    margin: '50px 0px 0px 0px',
    padding: '50px 0px 50px 0px',
    width: '100%',
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    backgroundColor: tinycolor2(secondaryColor).brighten(15).toHexString(),
    justifyContent: 'center'
  },
  mapContainer: {
    margin: '20px 0px 0px 0px',
    padding: '50px 0px 50px 0px',
    width: '100%',
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'center'
  },
  brochureCard: {
    margin: 10,
    maxWidth: 275
  },
  brochureCardHeader: {
    textAlign: 'center'
  },
  mainInformationContainer: {
    padding: "10px 15% 10px 15%",
    display: "block",
    width: '100%',
    textAlign: 'center'
  },
  brochureContainer: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    alignContent: 'center',
    justifyContent: 'center'
  },
  brochureAction: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    alignContent: 'center',
    justifyContent: 'center'
  },
  titleImageContainer: {
    textAlign: "center !important"
  },
  titleImage: {
    maxWidth: 150,
    marginTop: 25,
    marginBottom: -25
  },
  titleReverse: {
    ...title,
    marginBottom: "1rem",
    marginTop: "30px",
    minHeight: "32px",
    textDecoration: "none",
    color: "#FFF !important"
  },
  note: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    bottom: "10px",
    color: "#c0c1c2",
    display: "block",
    fontWeight: "400",
    fontSize: "13px",
    lineHeight: "13px",
  },
  sectionTitle: {
    color: secondaryColor,
    fontFamily: `"Roboto Slab", "Times New Roman", serif`,
    fontWeight: 700,
    textDecoration: 'none'
  },
  title: {
    ...title,
    marginBottom: "1rem",
    marginTop: "30px",
    minHeight: "32px",
    textDecoration: "none"
  },
  fab: {
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
    zIndex: "12",
  },
  arrowPopper: arrowGenerator(theme.palette.grey[700]),
  arrow: {
    position: 'absolute',
    fontSize: 6,
    width: '3em',
    height: '3em',
    '&::before': {
      content: '""',
      margin: 'auto',
      display: 'block',
      width: 0,
      height: 0,
      borderStyle: 'solid',
    },
  },
  subtitle: {
    fontSize: "1.313rem",
    maxWidth: "500px",
    margin: "10px auto 0"
  },
  main: {
    background: "#FFFFFF",
    position: "relative",
    zIndex: "3"
  },
  mainRaised: {
    margin: "-160px 0px 0px",
    borderRadius: "6px",
    boxShadow:
      "0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)"
  },
  sideBySide: {
    '& > h1': {
      color: '#999',
      textAlign: 'center',
      fontSize: 40,
      fontWeight: 300,
      marginTop: 10,
      paddingTop: 25
    },
    '& > div': {
      [theme.breakpoints.up("lg")]: {
        display:'flex',
      },
      paddingTop: theme.spacing(2),
      '& > div': {
        flex: 1,
        fontSize: 20,
        color: "#999",
        padding: theme.spacing(2),
        whiteSpace: 'pre-wrap',
        wordSpacing: 0
      },
    }
  },
  mapHeader: {
    textAlign: 'center',
    width: '100%',
    padding: '0px 20px',
    '& > h2': {
      [theme.breakpoints.down("sm")]: {
        fontSize: 30,
      },
      fontSize: 36,
      color: tinycolor2(secondaryColor).brighten(15).toHexString(),
    }
  },
  ourCoverage: {
    '& > h3': {
      margin: 0,
      fontWeight: 400,
      color: '#4b569b'
    },
    '& img': {
      width: '100%'
    },
    '& > div': {
      marginBottom: 30,
      '& > span': {
        marginBottom: 30,
        fontSize: 18,
        fontWeight: 400,
        color: '#000000'
      },
    },
  }
});

export default landingPageStyle;
