import React, {useEffect, useState} from "react";
import StandardPage from "../../components/StandardPage/StandardPage";
import dealerProtectionContent from '../../content/AutomotiveDealers/sections/protections'
import dealerTravelContent from '../../content/AutomotiveDealers/sections/programs'
import dealerAssistanceContent from '../../content/AutomotiveDealers/sections/assistance'
import dealerMainContent from '../../content/AutomotiveDealers/main'
import agentsMainContent from '../../content/Agents/main'
import agentsTravelContent from '../../content/Agents/sections/travel'
import agentsServicesContent from '../../content/Agents/sections/services'
import agentsProtectionContent from '../../content/Agents/sections/protection'
import insurancePartnersMainContent from '../../content/InsurancePartners/main'
import insurancePartnersServicesContent from '../../content/InsurancePartners/sections/services'
import insurancePartnersTravelContent from '../../content/InsurancePartners/sections/travel'
import insurancePartnersProtectionContent from '../../content/InsurancePartners/sections/protection'
import insurancePartnersInsuranceContent from '../../content/InsurancePartners/sections/insurance'
import careersContent from '../../content/LandingPage/Sections/careers'
import cellularContent from '../../content/Footer/cellular'
import ourNetworkContent from '../../content/Footer/our-network'
import {useParams} from "react-router-dom";
import withStyles from "@material-ui/core/styles/withStyles";
import find from 'lodash/find';
import productDetailsStyle from '../../assets/jss/material-kit-react/components/productDetails.jsx'
import {dangerColor, infoColor, successColor} from "../../assets/jss/material-kit-react";
import each from 'lodash/each';
import {Helmet} from "react-helmet";
import capitalize from "@material-ui/core/utils/capitalize";
import Map from "./Map";
import ProductSections from "./ProductSections";
import DefaultLayout from "./Layouts/DefaultLayout";

function ProductDetails({...props}) {
  let {areaId, productId} = useParams();
  const {classes, ...rest} = props;
  const [selectedStates, setSelectedStates] = useState({})
  const [productName, setProductName] = useState('')
  const [data] = useState({
    automotive: {
      image: require("assets/img/background-car-dealer.jpg"),
      mainContent: dealerMainContent
    },
    insuranceAgents: {
      image: require("assets/img/background-car-dealer.jpg"),
      mainContent: agentsMainContent
    },
    insurancePartners: {
      image: require("assets/img/background-car-dealer.jpg"),
      mainContent: insurancePartnersMainContent
    },
    products: [
      ...dealerProtectionContent.products,
      ...dealerAssistanceContent.items,
      dealerTravelContent,
      ...agentsTravelContent.items,
      ...agentsServicesContent.items,
      ...agentsProtectionContent.items,
      ...insurancePartnersServicesContent.items,
      ...insurancePartnersTravelContent.items,
      ...insurancePartnersProtectionContent.items,
      ...insurancePartnersInsuranceContent.items,
      ...careersContent.items,
      ...cellularContent.items,
      ...ourNetworkContent.items,
    ],
  })
  const [mainImage, setMainImage] = useState(null)
  const [product, setProduct] = useState({})
  const [productArea, setProductArea] = useState({})

  useEffect(()=>{

    if(typeof product.name !== "string" && product && product.id){
      let newProductNameBits = product && product.id ? product.id.split("-") : [];


      newProductNameBits.forEach((val,idx)=>{
        newProductNameBits[idx] = capitalize(val)
      });

      let newProductName = newProductNameBits.join(' ');
      setProductName(newProductName)
    }
    else{
      setProductName(product.name)
    }
  },[product])

  useEffect(() => {
    let areaData = data[areaId];
    let newProduct = find(data.products, {id: productId});
    if (areaData) {
      setProductArea(areaData.mainContent)
      setMainImage(areaData.image)
    }

    if (newProduct) {
      setProduct(newProduct);

      let availableStates = {};
      each(newProduct.availableStates, state => {
        availableStates[state] = {
          fill: successColor,
        }
      })
      each(newProduct.unavailableStates, state => {
        availableStates[state] = {
          fill: dangerColor,
        }
      })
      each(newProduct.licensedRequiredStates, state => {
        availableStates[state] = {
          fill: infoColor,
        }
      })
      if (Object.keys(availableStates).length > 0) {
        setSelectedStates(availableStates);
      }
    }

  }, [areaId, productId]);

  const MainSectionLayout = product.Layout ?? DefaultLayout;

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8"/>
        <title>{`${productName} Product Details`}</title>

        <meta name="description" content={`Product details for ${product.name} - ${product.mainContent}`}/>
        <link rel="canonical" href={window.location.href}/>
      </Helmet>
      <StandardPage
        title={product.name}
      >
        <div className={classes.sections}>
          <MainSectionLayout product={product} />
        </div>
        {
          product.sections?.length > 0 && <ProductSections sections={product.sections} />
        }
        {
          (product.availableStates || product.unavailableStates || product.licensedRequiredStates) &&
          <Map product={product} selectedStates={selectedStates} />
        }
      </StandardPage>
    </div>
  );
}

export default withStyles(productDetailsStyle)(ProductDetails);
