import {secondaryColor, title} from "assets/jss/material-kit-react.jsx";

var tinycolor2 = require('tinycolor2');

const teamStyle = {
  secondItem: {
    paddingTop: 75
  },
  section: {
    padding: "30px 0",
    textAlign: "center",
    marginTop: 75
  },
  altSection: {
    backgroundColor: tinycolor2(secondaryColor).lighten(57).toHexString()
  },
  leftImg: {
    float: 'left',
    marginRight: 15,
    backgroundClip: 'padding-box'
  },
  rightImg: {
    float: 'right',
    marginLeft: 15,
    backgroundClip: 'padding-box'
  },
  raised: {
    borderRadius: "6px",
    margin: "0 10px 0 0",
    boxShadow:
      "0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)"
  },
  description: {
    color: "#999"
  },
  title: {
    ...title,
    marginBottom: "1rem",
    marginTop: "30px",
    minHeight: "32px",
    textDecoration: "none"
  }
};

export default teamStyle;
