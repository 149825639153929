import React from "react";

export default {
  title: "Services",
  items: [
    {
      id: "pre-paid-legal",
      detailImage: require('assets/img/weight-scale-2402966_640_color.png'),
      image: require("assets/img/AdobeStock_247963248.jpg"),
      "name": "Pre-Paid Legal Services, Inc.",
      "description": [
        "Nation Safe Drivers and Pre-Paid Legal Services, Inc. are working together to provide a new tool for all Nation Safe Drivers' Independent Agents, MGAs and Agencies.",
        "For 32 years, Pre-Paid Legal Services, Inc. has provided equal access to justice for middle income families across the United States for less than a cup of coffee a day."
      ],
      mainContent: (
        <React.Fragment>
          Nation Safe Drivers and Pre-Paid Legal Services, Inc. are working together to provide a new tool for all
          Nation Safe Drivers' Independent Agents, MGAs and Agencies.
          <br/>
          <br/>
          For 32 years, Pre-Paid Legal Services, Inc. has provided equal access to justice for middle income families
          across the United States for less than a cup of coffee a day.
          <br/>
          <br/>
          A New York Stock Exchange Company with 1.8 million members, Pre-Paid Legal is quickly becoming a household
          name as a revolutionary, rock-solid solution to an often, inaccessible system.
        </React.Fragment>
      ),
      centerItems: [
        {
          title: "You have access to several plan options for your clients",
          content: (
            <React.Fragment>
              <ul>
                <li>Individual / Family / Corporate Memberships</li>
                <li>
                  Employee Benefits
                </li>
                <li>
                  Plus a wide range of specialty plans for small and home-based businesses, commercial drivers, law
                  officers, teachers and many, many more.
                </li>
              </ul>
            </React.Fragment>
          )
        },
        {
          title: "The Product",
          content: "Have you or one of your clients ever been treated unfairly, received a speeding ticket, signed a contract without knowing what all the fine print said, or been overcharged for a repair...but didn't seek the help of an attorney because of the cost? What if there was a service that gave you access to quality attorneys for these situations and more? Pre-Paid Legal Services, Inc. is here, and that's what we do."
        }
      ]
    },
    {
      "id": "rx-prescription-program",
      "title": "Rx Prescription Program",
      "name": "Rx Prescription Program",
      image: require("assets/img/drugs-1728381_640.jpg"),
      detailImage: require("assets/img/medicine-296966_640_color.png"),
      "description": [
        "NSD offers discounts on prescription drugs at over 51,000 pharmacies nationwide. This program gives significant savings through mail orders, walk-in situations, and through our \"preferred pricing\" program.",
        "This program is one of the most comprehensive prescription cards available today. Nation Safe Drivers can provide this card to you for a very affordable price for your organization and your members."
      ],
      "note": "Not available in all States.",
      mainContent: (
        <React.Fragment>
          Offers discounts on prescription drugs at over 51,000 pharmacies nationwide. This program gives significant
          savings through mail orders, walk-in situations, and through our "preferred pricing" program.
          This program is one of the most comprehensive prescription cards available today. Nation Safe Drivers can
          provide this card to you for a very affordable price for your organization and your members.
          <br/>
          <br/>
          <a href={"https://www.nsdmc.com/NSD_OGK/products/affinity/discount-rx.asp#"} target={"_blank"}>Find your local
            Provider</a>
        </React.Fragment>
      ),
      mainContentNote: "Not available in all States.",
      centerItems: [
        {
          title: "NSD Rx Ways to Save",
          content: (
            <React.Fragment>
              <b>Without Leaving your Home</b>
              NSD Rx members can save on 90-day supplies on all covered prescriptions through our mail service provider.
              Average Savings of 20% with unlimited use.
              <b>Your Local Pharmacy</b>
              Just present your NSD Rx Prescription Drug Card at any of the more than 51,000 participating pharmacies
              nationwide.
              <b>Maintenance Medications</b>
              Your NSD Rx Prescription Drug Card enables you to receive PREFERRED PRICING on certain BRAND NAME
              prescription drug products prescribed for common medical conditions. See your NSD Rx brochure for covered
              drugs and referred pricing.
            </React.Fragment>
          )
        }
      ]
    }
  ]

}
