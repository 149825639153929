import React from "react";

export default {
  "name": "RENTERS INSURANCE",
  image: require('../../../assets/img/new-home-4083230_640.jpg'),
  "description": [
    (
      <React.Fragment>
        This HO4 product is offered through our agency partners in 22 states. Web-based 24 hour access allows our agents
        to quote, store information, and bind coverage online, in minutes. We offer 1-Pay and 4-Pay Payment Plans, the
        program is set-up for ACH and/or Credit Card payments.
      </React.Fragment>
    ),
    (
      <React.Fragment>
        There is no underwriting restrictions and no credit scoring requirements. The "Packaged" program includes
        Personal Liability, Medical Payment to Others, and Personal Property Coverage with Replacement Cost included.
        There is a $500 deductible. The program will protect your client's personal property and protect their assets
        from liability exposure. It is a great way to strengthen your relationship and retain your business.
      </React.Fragment>
    ),
    (
      <React.Fragment>
        Competitive rates, competitive commissions, limited underwriting, and complete automation, make this program a
        great choice for your agency. You may demonstrate our online system today! Please call 1-800-338-2680 x507.
      </React.Fragment>
    )

  ]
}
