import React, {useState} from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import landingPageStyle from "assets/jss/material-kit-react/views/landingPage.jsx";
import content from '../../content/Agents/main';
import MultiItemSection from "../../components/StandardPage/components/MultiItemSection";
import StandardPage from "../../components/StandardPage/StandardPage";

import protectionContent from '../../content/Agents/sections/protection'
import servicesContent from '../../content/Agents/sections/services'
import travelContent from '../../content/Agents/sections/travel';
import {Helmet} from "react-helmet";

function Agents({...props}) {
  const {classes, ...rest} = props;
  const [contentItems] = useState([
    {
      ...protectionContent
    }, {
      ...servicesContent
    }, {
      ...travelContent
    }
  ]);

  return (
    <StandardPage
      title={content.title}
      description={content.description}
      image={require("assets/img/agents-bg.jpg")}
    >
      <Helmet>
        <meta charSet="utf-8"/>
        <title>{`NSD Affinity Groups & Organizations`}</title>
        <meta name="description"
              content={`Associations, credit card issuers and organizations can enhance their member benefits through a full line of products designed to drive value to your consumers. These products can be customized to meet your unique marketing goals and are proven to increase membership participation and retention. The travel industry can benefit from adding our services to compliment vacation offerings and separate themselves with a unique value proposition.`}/>
        <link rel="canonical" href={window.location.href}/>
      </Helmet>
      {
        contentItems && contentItems.map((content, index) => {
          return (
            <div>
              <MultiItemSection
                productArea={"insuranceAgents"}
                altRow={index % 2 === 1}
                title={content.title}
                items={content.items}
              />
            </div>
          )
        })
      }
    </StandardPage>
  );

}

export default withStyles(landingPageStyle)(Agents);
