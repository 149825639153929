import React from "react";

export default {
  title: 'Protections',
  items: [
    {
      id: 'identity-theft',
      "title": "Identity Theft Protection",
      "name": "Identity Theft Protection",
      image: require("assets/img/binary-1538721_640.jpg"),
      detailImage: require('assets/img/theft-protection_color.png'),
      "description": [
        "Identity Theft is the fastest growing crime in America. In a matter of seconds, personal information such as a social security number, a credit card number and/or an address can be stolen; leaving individuals to unravel the financial mess created by an identity thief."
      ],
      mainContent: (
        <React.Fragment>
          Identity Theft is the fastest growing crime in America. In a matter of seconds, personal information such as a
          social security number, a credit card number and/or an address can be stolen; leaving individuals to unravel
          the financial mess created by an identity thief.
          <br/>
          <br/>
          Members will have 24/7 access to Identity Theft Restoration Advocates who will provide them with
          comprehensive, personalized recovery services. (Please see contract for the specific terms and conditions of
          coverage.)
          <br/>
          <br/>
          If a member becomes a victim of identity theft, NSD assigns a Recovery Advocate to manage the case and
          activate the members of our Recovery Team (fraud investigators, legal counsel, and other experts) needed to
          help with the recovery process from beginning to end.
        </React.Fragment>
      ),
      mainContentNote: (
        <span style={{color: 'red'}}>Please see your contract for the specific terms and conditions of coverage.</span>
      ),
      centerItems: [
        {
          title: "Coverage",
          content: (
            <React.Fragment>
              <ul>
                <li>Consult with the covered member to ascertain the severity of the theft and start the notification
                  process.
                </li>
                <li>
                  Prepare and send the customized pre-completed, state specific "ID Recovery Kit™" to member via Federal
                  Express overnight.
                </li>
                <li>
                  Contact all three credit reporting agencies to: obtain a free credit report for the covered member,
                  place fraud alerts on the covered member's credit records, and obtain a list of creditors involved
                  with the fraud.
                </li>
                <li>
                  Notify the appropriate bank or agency if other forms of identification (such as an ATM card, driver's
                  license, social security card, or passport) were stolen or are missing.
                </li>
                <li>
                  Provide fraud resolution, legal and emotional assistance from beginning to end.
                </li>
              </ul>
            </React.Fragment>
          )
        },

        {
          title: "Additional Coverage",
          content: (
            <React.Fragment>
              Each member will also receive up to $25,000 worth of insurance coverage provided by a member company of
              American International Group (AIG) Inc. This coverage will help offset some of the cost of restoring your
              identity to its original status including:
              <ul>
                <li>Lost wages up to $500 per week for 4 weeks, which includes remuneration for vacation days, floating
                  holidays, and paid personal days.
                </li>
                <li>
                  Fees for re-filing of loans.
                </li>
                <li>
                  Defense cost for certain civil lawsuits brought against you by a creditor, for non-payment of goods or
                  services or default on a loan as a result of a stolen identity event.
                </li>
                <li>
                  Cost of removing any civil judgment wrongfully entered against you as a result of the stolen identity
                  event.
                </li>
                <li>
                  Reimbursement of fees.
                </li>
              </ul>
            </React.Fragment>
          )
        }
      ]
    }
  ]
}
