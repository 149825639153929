import React from "react";

export default {
  title: "Assistance",
  items: [
    {
      "name": "Nation Safe Drivers Auto Club",
      id: "roadside-assistance",
      image: require("assets/img/Roadside-Assistance.jpg"),
      "description": [
        " The Nation Safe Drivers Auto Club provides real peace-of-mind at affordable rates. When on the road you'll know that you are covered and protected by an organization that cares and has been caring since 1962! Our customer representatives respond quickly to provide you the best service and protection available anywhere. We at NSD have earned a solid reputation for fast and courteous service. You can count on the company with \"Safe\" as its middle name!"
      ]
    },
    {
      id: "add-travel-program",
      "name": "AD&D Travel Program",
      image: require("assets/img/roadimg.jpg"),
      detailImage: require('assets/img/travel-1303949_640_color.png'),
      "description": [
        " Offers Accidental Death & Dismemberment coverage to the named member's beneficiary in the event of a covered accident. This Program is available in all 50 States. Limits start at as little as $1,000 and go up to $50,000. With a variety of options and guarantied acceptance, we can find one to suit your specific needs. "
      ],
      "note": "Not available in all States.",
      mainContent: "Offers Accidental Death & Dismemberment coverage to the named member's beneficiary in the event of a covered accident. This Program is available in all 50 States. Limits start at as little as $1,000 and go up to $50,000. With a variety of options and guarantied acceptance, we can find one to suit your specific needs.",
      mainContentNote: "Not available in all States",
      centerItems: [
        {
          title: "Benefits",
          content: (
            <React.Fragment>
              <ul>
                <li>Travel Assistance & Discounts</li>
                <li>Credit Card Protection</li>
                <li>Rent-A-Car Discounts</li>
                <li>Notary Public Service</li>
                <li>Message Center</li>
                <li>Post Office Box</li>
                <li>Lost Luggage Protection</li>
              </ul>
              (Please see contract for the specific terms and conditions of coverage.)
            </React.Fragment>
          )
        }
      ]
    }
  ]
}
