import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import {Grid, Typography} from '@material-ui/core';
import workplaceImg from 'assets/img/bigstock-Call-Center-79077622.jpg';
import callForAssistanceStyle from "assets/jss/material-kit-react/views/landingPageSections/callForAssistance.jsx";
import Fade from 'react-reveal/Fade';
import content from '../../../content/NIUFlorida/Sections/callForAssistance'

class CallForAssistance extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      expanded: false
    }
  }

  render() {
    const {classes} = this.props;

    return (
      <div className={classes.section}>
        <div>
          <Grid container spacing={4} className={classes.mainGridContainer}>
            <Grid item xs={12} sm={12} md={12}>
              <h2 className={classes.title}>{content.title}</h2>
              <Fade bottom>
                <Grid container spacing={4}>
                  <Grid item lg={5}>
                    <img src={workplaceImg} alt="terrifiq" className={classes.raised} style={{width: '100%'}}/>
                  </Grid>
                  <Grid item lg={7} style={{padding: 50, textAlign: 'left'}}>
                    <h3 className={classes.description}>
                      {content.mainContent}
                      <br/><br/>
                      <Typography variant="h4">
                        {content.needHelp} <br/>
                        <a href={"tel:Call 18886849327"}>Call: {content.callAndNumber}</a>
                      </Typography>
                    </h3>
                  </Grid>
                </Grid>
              </Fade>
            </Grid>
          </Grid>

        </div>
      </div>
    );
  }
}

export default withStyles(callForAssistanceStyle)(CallForAssistance);
