import { title } from "assets/jss/material-kit-react.jsx";

const productStyle = theme => ({
    section: {
        padding: "70px 100px",
        [theme.breakpoints.down("sm")]: {
            padding: "70px 60px",
        },
        textAlign: "center"
    },
    main: {
        maxWidth: '1300px',
        margin: '0px auto', 
    },
    title: {
        ...title,
        marginBottom: "1rem",
        marginTop: "30px",
        minHeight: "32px",
        textDecoration: "none",
        [theme.breakpoints.down("sm")]: {
            fontSize: 40
        }
    },
    media: {
        padding: '50%'
    },
    personName: {
        margin: 0,
        fontWeight: 600
    },
    jobTitle: {
        margin: 0
    },
    teamMemberCard: {
        position: 'relative'
    },
    teamMemberCardHover: {
        cursor: 'pointer'
    },
    cardOverlay: {
        position: 'absolute',
        top: 0,
        left: 0,
        height: '100%',
        width: '100%',
        background: 'rgba(0,0,0,0.4)',
        transition: 'opacity 0.2s ease-out'
    },
    hidden: {
        opacity: 0
    },
    iconContainer: {
        width: 50,
        height: 50,
        background: '#0976b4',
        borderRadius: '0px 0px 8% 0px',
        display: 'flex',
        justifyContent: 'center'
    },
    icon: {
        width: '50%'
    }
});

export default productStyle;
