import React from "react";
import classNames from "classnames";
import withStyles from "@material-ui/core/styles/withStyles";
import {Grid} from '@material-ui/core'
import Header from "components/Header/Header.jsx";
import Footer from "components/Footer/Footer.jsx";
import ClaimsCenterHeaderLinks from "components/Header/ClaimsCenterHeaderLinks.jsx";
import Parallax from "components/Parallax/Parallax.jsx";
import landingPageStyle from "assets/jss/material-kit-react/views/landingPage.jsx";
import content from '../../content/ClaimsCenter/main'
import About from "./Sections/About";
import Privacy from "./Sections/Privacy";
import {Helmet} from "react-helmet";

const dashboardRoutes = [];

class LandingPage extends React.Component {
  constructor(props) {
    super(props);
    this.pageTitle = React.createRef();
    this.refBusinessValue = React.createRef();
    this.refExpertise = React.createRef();
    this.refCoreServices = React.createRef();
    this.refOurProcess = React.createRef();
    this.state = {
      topText: 200,
      arrowRef: null,
      open: false,
      highlightHeader: ""
    }
  }

  componentDidMount() {
    window.addEventListener('scroll', this.listenScrollEvent);
    let path = window.location.href;
    if (path.indexOf("/claims-center#") > -1) {
      let location = path.split("#")[path.split("#").length - 1];
      let top = this.refBusinessValue.current.getBoundingClientRect().top;
    }
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.listenScrollEvent);
  }

  render() {
    const {classes, ...rest} = this.props;
    return (
      <div>
        <Helmet>
          <meta charSet="utf-8"/>
          <title>{`NSD Claims Center`}</title>
          <meta name="description"
                content={`Please select your product from the choices below. `}/>
          <link rel="canonical" href={window.location.href}/>
        </Helmet>
        <Header
          color="transparent"
          routes={dashboardRoutes}
          smallLogo={true}
          brand="NSD"
          rightLinks={<ClaimsCenterHeaderLinks highlighted={this.state.highlightHeader}/>}
          fixed
          changeColorOnScroll={{
            height: this.state.topText,
            color: "dark"
          }}
          {...rest}
        />
        <Parallax filter image={require("assets/img/claims-center-bg.jpg")} style={{minHeight: 750}}>
          <div className={classes.container} style={{paddingLeft: '10%'}}>
            <Grid style={{marginTop: 0}}>
              <Grid xs={12} sm={12} md={6}>
                <div style={{marginTop: -65}}>
                  <h1 ref={this.pageTitle} className={classes.title}>{content.title}</h1>
                  <h4>
                    {content.subTitle}
                  </h4>
                  <br/>
                </div>
              </Grid>
            </Grid>
          </div>
        </Parallax>
        <div className={classNames(classes.main, classes.mainRaised)}>
          <div className={classes.container}>
            <div id="business-value" ref={this.refBusinessValue}>
              <About/>
            </div>
            <div id="core-services" ref={this.refCoreServices}>
              <Privacy/>
            </div>
          </div>
        </div>
        <Footer claimsCenter/>
      </div>
    );
  }
}

export default withStyles(landingPageStyle)(LandingPage);
