import {title} from "../../../../../assets/jss/material-kit-react.jsx";
import {
  primaryColor,
  secondaryColor,
  warningColor,
  successColor,
  infoColor,
  darkColor,
  dangerColor,
  roseColor,
  grayColor
} from "assets/jss/material-kit-react.jsx";

const productStyle = theme => ({
  widgetWrapper: {
    display: "flex",
    minHeight: "100%"
  },
  raised: {
    borderRadius: "6px",
    boxShadow:
      "0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)"
  },
  infoArea: {
    maxWidth: "360px",
    margin: "0 auto",
    padding: "10px",
  },
  iconWrapper: {
    float: "left",
    marginTop: "24px",
    marginRight: "10px"
  },
  icon: {
    width: "36px",
    height: "36px"
  },
  iconVertical: {
    width: "61px",
    height: "61px"
  },
  iconWrapperVertical: {
    float: "none"
  },
  link: {
    textDecoration: 'none !important'
  },
  card: {
    width: '100%'
  },
  section: {
    padding: "0 0 70px 0",
    textAlign: "center"
  },
  ceoIcon: {
    color: primaryColor,
    fontSize: '3em'
  },
  ctoIcon: {
    color: secondaryColor,
    fontSize: '3em'
  },
  cioIcon: {
    color: darkColor,
    fontSize: '3em'
  },
  cfoIcon: {
    color: successColor,
    fontSize: '3em'
  },
  cooIcon: {
    color: grayColor,
    fontSize: '3em'
  },
  cpoIcon: {
    color: infoColor,
    fontSize: '3em'
  },
  alignLeft: {
    textAlign: 'left !important'
  },
  bold: {
    fontSize: '1.1em',
    fontFamily: 'bold'
  },
  primaryColor: {
    color: primaryColor
  },
  secondaryColor: {
    color: secondaryColor
  },
  warningColor: {
    color: warningColor
  },
  successColor: {
    color: successColor
  },
  infoColor: {
    color: infoColor
  },
  darkColor: {
    color: darkColor
  },
  dangerColor: {
    color: dangerColor
  },
  roseColor: {
    color: roseColor
  },
  grayColor: {
    color: grayColor
  },
  title: {
    ...title,
    marginBottom: "1rem",
    marginTop: "30px",
    minHeight: "32px",
    textDecoration: "none"
  },
  descriptionSubSection: {
    color: "#999",
    textAlign: "left"
  },
  description: {
    padding: "0 15% 0 15%",
    color: "#999",
    textAlign: 'left'
  },
  topTrianglePiece: {
    width: 0,
    height: 0,
    borderLeft: '80px solid transparent',
    borderRight: '80px solid transparent',
    borderBottom: '100px solid ' + primaryColor,
    textAlign: 'center',
    marginLeft: '35%',
  },
  trianglePieceText: {
    marginTop: -41
  },
  secondTrianglePiece: {
    width: 240,
    height: 0,
    borderLeft: '40px solid transparent',
    borderRight: '40px solid transparent',
    borderBottom: '50px solid ' + darkColor,
    textAlign: 'center',
    marginTop: -9,
    marginLeft: '27.6%',
  },
  thirdTrianglePiece: {
    width: 320,
    height: 0,
    borderLeft: '40px solid transparent',
    borderRight: '40px solid transparent',
    borderBottom: '50px solid ' + infoColor,
    textAlign: 'center',
    marginTop: -9,
    marginLeft: '20.1%',
  },
  fourthTrianglePiece: {
    width: 400,
    height: 0,
    borderLeft: '40px solid transparent',
    borderRight: '40px solid transparent',
    borderBottom: '50px solid ' + warningColor,
    textAlign: 'center',
    marginTop: -9,
    marginLeft: '12.6%',
  },
  fithTrianglePiece: {
    width: 480,
    height: 0,
    borderLeft: '40px solid transparent',
    borderRight: '40px solid transparent',
    borderBottom: '50px solid ' + successColor,
    textAlign: 'center',
    marginTop: -9,
    marginLeft: '5.1%',
  },

  topTrianglePieceMobile: {
    marginRight: '5%',
    marginLeft: '5%',
    borderRadius: 5,
    backgroundColor: primaryColor
  },
  secondTrianglePieceMobile: {
    marginRight: '5%',
    marginLeft: '5%',
    borderRadius: 5,
    backgroundColor: darkColor
  },
  thirdTrianglePieceMobile: {
    marginRight: '5%',
    marginLeft: '5%',
    borderRadius: 5,
    backgroundColor: infoColor
  },
  fourthTrianglePieceMobile: {
    marginRight: '5%',
    marginLeft: '5%',
    borderRadius: 5,
    backgroundColor: warningColor
  },
  fithTrianglePieceMobile: {
    marginRight: '5%',
    marginLeft: '5%',
    borderRadius: 5,
    backgroundColor: successColor
  },

  topTrianglePieceMobileBorder: {
    borderRadius: 5,
    border: '1px solid ' + primaryColor,
    marginTop: 5
  },
  secondTrianglePieceMobileBorder: {
    borderRadius: 5,
    border: '1px solid ' + darkColor,
    marginTop: 5
  },
  thirdTrianglePieceMobileBorder: {
    borderRadius: 5,
    border: '1px solid ' + infoColor,
    marginTop: 5
  },
  fourthTrianglePieceMobileBorder: {
    borderRadius: 5,
    border: '1px solid ' + warningColor,
    marginTop: 5
  },
  fithTrianglePieceMobileBorder: {
    borderRadius: 5,
    border: '1px solid ' + successColor,
    marginTop: 5
  },
});

export default productStyle;
