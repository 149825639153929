import React, {useState} from "react";
// nodejs library that concatenates classes
import withStyles from "@material-ui/core/styles/withStyles";

import landingPageStyle from "assets/jss/material-kit-react/views/landingPage.jsx";
import SingleItemSection from "../../components/StandardPage/components/SingleItemSection";
import content from '../../content/NIUW/Sections/glossary_main'
import StandardPage from "../../components/StandardPage/StandardPage";
import NIUWHeaderLinks from "../../components/Header/NIUWHeaderLinks";
import reference_documents from "../../content/NIUW/Sections/reference_documents";
import glossary from "../../content/NIUW/Sections/glossary";
import {Helmet} from "react-helmet";

function NIUWProducts({...props}) {
  const {classes, ...rest} = props;
  const [contentItems] = useState([
    {
      ...reference_documents
    },
    {
      ...glossary
    }
  ]);

  return (
    <StandardPage
      title={content.title}
      niuWLogo={true}
      headerLinks={NIUWHeaderLinks}
      description={content.description}
      image={require("assets/img/insurance-partners-bg.jpg")}
    >
      <Helmet>
        <meta charSet="utf-8"/>
        <title>{`NIU Glossary`}</title>
        <link rel="canonical" href={window.location.href}/>
      </Helmet>
      {
        contentItems && contentItems.map((content, index) => {
          console.log()
          return (
            <div>
              <SingleItemSection
                noLearnMore={true}
                productArea={"automotive"}
                altRow={index % 2 === 1}
                imageLeft={index % 2 !== 1}
                content={content}
              />
            </div>
          )
        })
      }
    </StandardPage>
  );
}

export default withStyles(landingPageStyle)(NIUWProducts);
