import React from "react";
// @material-ui/core components
import {
  IconButton,
  InputAdornment
} from '@material-ui/core'
import {
  withStyles
} from "@material-ui/styles";
// @material-ui/icons
import {
  VisibilityOff,
  Visibility,
  Email,
  People,
  InsertDriveFile,
  PermIdentity,
  DriveEta
} from "@material-ui/icons";
// core components
import Header from "components/Header/Header.jsx";
import HeaderLinks from "components/Header/HeaderLinks.jsx";
import Footer from "components/Footer/Footer.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import CustomInput from "../../components/CustomInput/CustomInput.jsx";
import {dangerColor, defaultFont, primaryColor, successColor} from "assets/jss/material-kit-react.jsx";
import loginPageStyle from "assets/jss/material-kit-react/views/loginPage.jsx";

import image from "assets/img/cloud-computing-2001090_1920.jpg";
import {useParams} from "react-router-dom";

class LoginPage extends React.Component {

  constructor(props) {
    super(props);
    // we use this to make the card to appear after the page has been rendered
    this.state = {
      cardAnimaton: "cardHidden",
      showPassword: false,
      logInFor: props.match.params.productId
    };
  }

  componentDidMount() {
    // we add a hidden class to the card and after 700 ms we delete it and the transition appears
    setTimeout(
      function () {
        this.setState({cardAnimaton: ""});
      }.bind(this),
      700
    );
  }

  formValueChange = (name) => (value) => {
    let formValue = value.currentTarget.value;
    if (formValue) {
      this.setState({[`${name}Error`]: false})
    }
    this.setState({[name]: formValue});
  }

  login = () => {
    let error = false;
    switch (this.state.logInFor) {
      case 'Document ID':
        console.log(this.state.documentId)
        if (!this.state.documentId) {
          this.setState({documentIdError: true})
          return;
        }
        window.location.href = `https://nsdmc.com/NSD_OGK/client-center.asp?DocumentID=${this.state.documentId}`
        break;
      case 'NRDE System':
        console.log(this.state.email)
        console.log(this.state.password)
        if (!this.state.email) {
          this.setState({emailError: true})
          error = true;
        }
        if (!this.state.password) {
          this.setState({passwordError: true})
          error = true;
        }
        if (error) {
          return;
        }
        window.location.href = `https://nrde.com/?UserID=${this.state.email}&Password=${this.state.password}`
        break;
      case 'Supply System':
        console.log(this.state.agNumber)
        console.log(this.state.zipcode)
        if (!this.state.agNumber) {
          this.setState({agNumberError: true})
          error = true;
        }
        if (!this.state.zipcode) {
          this.setState({zipcodeError: true})
          error = true;
        }
        if (error) {
          return;
        }
        window.location.href = `https://nsdmc.com/supplies/?AG_NO=${this.state.agNumber}&AG_ZIP=${this.state.zipcode}`
        break;
      case 'NSD Broker Services':
        console.log(this.state.email)
        console.log(this.state.password)
        if (!this.state.email) {
          this.setState({emailError: true})
          error = true;
        }
        if (!this.state.password) {
          this.setState({passwordError: true})
          error = true;
        }
        if (error) {
          return;
        }
        window.location.href = `https://www.nsdmc.com/broker/?email=${this.state.email}&password=${this.state.password}`
        break;
      case 'Leinholder':
        console.log(this.state.vinNumber)
        console.log(this.state.claimNumber)
        console.log(this.state.lastName)
        if (!this.state.vinNumber) {
          this.setState({vinNumberError: true})
          error = true;
        }
        if (!this.state.claimNumber && !this.state.lastName) {
          this.setState({claimNumberError: true})
          this.setState({lastNameError: true})
          error = true;
        }
        if (error) {
          return;
        }
        window.location.href = `https://nsdmc.com/NSD_OGK/claimscenter/claimview/claims.asp?ptype=ClaimList&VIN=${this.state.vinNumber}&ClaimNum=${this.state.claimNumber ? this.state.claimNumber : ''}&LastName=${this.state.lastName ? this.state.lastName : ''}&eLogin=Leinholder`
        break;
      case 'Producer':
        console.log(this.state.username)
        console.log(this.state.password)
        if (!this.state.username) {
          this.setState({usernameError: true})
          error = true;
        }
        if (!this.state.password) {
          this.setState({passwordError: true})
          error = true;
        }
        if (error) {
          return;
        }
        window.location.href = `https://nsdmc.com/NSD_OGK/claimscenter/claimview/claims.asp?ptype=ClaimList&UserID=${this.state.username}&Password=${this.state.password}&eLogin=Producer`
        break;
      case 'Member':
        console.log(this.state.claimNumber)
        console.log(this.state.accessCode)
        if (!this.state.claimNumber) {
          this.setState({claimNumberError: true})
          error = true;
        }
        if (!this.state.accessCode) {
          this.setState({accessCodeError: true})
          error = true;
        }
        if (error) {
          return;
        }
        window.location.href = `https://nsdmc.com/NSD_OGK/claimscenter/claimview/claims.asp?ptype=ClaimList&ClaimNum=${this.state.claimNumber}&AccessCode=${this.state.accessCode}&eLogin=Member`
        break;
    }
  }

  handleClickShowPassword = () => {
    this.setState({showPassword: !this.state.showPassword});
  };

  render() {
    const {classes, ...rest} = this.props;
    return (
      <div>
        <Header
          absolute
          color="transparent"
          brand="NSD"
          rightLinks={<HeaderLinks/>}
          {...rest}
        />
        <div
          className={classes.pageHeader}
          style={{
            backgroundImage: "url(" + image + ")",
            backgroundSize: "cover",
            backgroundPosition: "top center"
          }}
        >
          <div className={classes.container}>
            <GridContainer justify="center">
              <GridItem xs={12} sm={12} md={4}>
                <Card className={classes[this.state.cardAnimaton]}>
                  <form className={classes.form}>
                    <CardHeader color="primary" className={classes.cardHeader}>
                      {this.state.logInFor === "Document ID" ? <h4>Enter Document ID to continue</h4> :
                        <h4>Log in to continue to: <br/> {this.state.logInFor}</h4>}

                    </CardHeader>
                    {/*<p className={classes.divider}>Or Be Classical</p>*/}
                    <CardBody>
                      {
                        this.state.logInFor === "Supply System" && <React.Fragment>
                          <CustomInput
                            labelText="AG Number..."
                            id="email"
                            error={this.state.agNumberError}
                            formControlProps={{
                              fullWidth: true
                            }}
                            inputProps={{
                              onChange: this.formValueChange('agNumber')
                            }}
                          />
                          {this.state.agNumberError &&
                          <small style={{color: dangerColor}}>AG Number is required</small>}
                          <CustomInput
                            labelText="Zipcode..."
                            id="email"
                            error={this.state.zipcodeError}
                            formControlProps={{
                              fullWidth: true
                            }}
                            inputProps={{
                              onChange: this.formValueChange('zipcode')
                            }}
                          />
                          {this.state.zipcodeError &&
                          <small style={{color: dangerColor}}>Zipcode is required</small>}
                        </React.Fragment>
                      }
                      {
                        this.state.logInFor === "NRDE System" && <React.Fragment>
                          <CustomInput
                            labelText="User ID..."
                            id="email"
                            error={this.state.emailError}
                            formControlProps={{
                              fullWidth: true
                            }}
                            inputProps={{
                              type: "email",
                              onChange: this.formValueChange('email'),
                              endAdornment: (
                                <InputAdornment position="end">
                                  <PermIdentity className={classes.inputIconsColor}/>
                                </InputAdornment>
                              )
                            }}
                          />
                          {this.state.emailError &&
                          <small style={{color: dangerColor}}>User ID is required</small>}
                          <CustomInput
                            labelText="Password"
                            id="pass"
                            error={this.state.passwordError}
                            formControlProps={{
                              fullWidth: true
                            }}
                            inputProps={{
                              type: this.state.showPassword ? 'text' : 'password',
                              onChange: this.formValueChange('password'),
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton
                                    edge="end"
                                    style={{marginLeft: -12}}
                                    aria-label="toggle password visibility"
                                    onClick={this.handleClickShowPassword}
                                    onMouseDown={this.handleMouseDownPassword}
                                  >
                                    {this.state.showPassword ? <VisibilityOff/> : <Visibility/>}
                                  </IconButton>
                                </InputAdornment>
                              )
                            }}
                          />
                          {this.state.passwordError &&
                          <small style={{color: dangerColor}}>Password is required</small>}
                        </React.Fragment>
                      }
                      {
                        this.state.logInFor === "Document ID" && <React.Fragment>
                          <CustomInput
                            error={this.state.documentIdError}
                            labelText="Document ID..."
                            id="email"
                            formControlProps={{
                              fullWidth: true
                            }}
                            inputProps={{
                              required: true,
                              onChange: this.formValueChange('documentId'),
                              type: "email",
                              endAdornment: (
                                <InputAdornment position="end">
                                  <InsertDriveFile className={classes.inputIconsColor}/>
                                </InputAdornment>
                              )
                            }}
                          />
                          {this.state.documentIdError &&
                          <small style={{color: dangerColor}}>Document ID is required</small>}
                        </React.Fragment>
                      }
                      {
                        this.state.logInFor === "Member" && <React.Fragment>
                          <CustomInput
                            labelText="Claim Number..."
                            id="email"
                            error={this.state.claimNumberError}
                            inputProps={{
                              onChange: this.formValueChange('claimNumber')
                            }}
                            formControlProps={{
                              fullWidth: true
                            }}
                          />
                          {this.state.claimNumberError &&
                          <small style={{color: dangerColor}}>Claim Number is required</small>}
                          <CustomInput
                            labelText="Access code.."
                            id="email"
                            error={this.state.accessCodeError}
                            inputProps={{
                              onChange: this.formValueChange('accessCode')
                            }}
                            formControlProps={{
                              fullWidth: true
                            }}
                          />
                          {this.state.accessCodeError &&
                          <small style={{color: dangerColor}}>Access code is required</small>}
                        </React.Fragment>
                      }
                      {
                        this.state.logInFor === "Producer" && <React.Fragment>
                          <CustomInput
                            labelText="User Name..."
                            id="email"
                            error={this.state.usernameError}
                            formControlProps={{
                              fullWidth: true
                            }}
                            inputProps={{
                              onChange: this.formValueChange('username'),
                              type: "email",
                              endAdornment: (
                                <InputAdornment position="end">
                                  <PermIdentity className={classes.inputIconsColor}/>
                                </InputAdornment>
                              )
                            }}
                          />
                          {this.state.usernameError &&
                          <small style={{color: dangerColor}}>User Name is required</small>}
                          <CustomInput
                            labelText="Password"
                            id="pass"
                            formControlProps={{
                              fullWidth: true
                            }}
                            error={this.state.passwordError}
                            inputProps={{
                              type: this.state.showPassword ? 'text' : 'password',
                              onChange: this.formValueChange('password'),
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton
                                    edge="end"
                                    style={{marginLeft: -12}}
                                    aria-label="toggle password visibility"
                                    onClick={this.handleClickShowPassword}
                                    onMouseDown={this.handleMouseDownPassword}
                                  >
                                    {this.state.showPassword ? <VisibilityOff/> : <Visibility/>}
                                  </IconButton>
                                </InputAdornment>
                              )
                            }}
                          />
                          {this.state.passwordError &&
                          <small style={{color: dangerColor}}>Password is required</small>}
                        </React.Fragment>
                      }
                      {
                        this.state.logInFor === "Leinholder" && <React.Fragment>
                          <CustomInput
                            labelText="VIN Number..."
                            id="email"
                            formControlProps={{
                              fullWidth: true
                            }}
                            error={this.state.vinNumberError}
                            inputProps={{
                              onChange: this.formValueChange('vinNumber'),
                              type: "email",
                              endAdornment: (
                                <InputAdornment position="end">
                                  <DriveEta className={classes.inputIconsColor}/>
                                </InputAdornment>
                              )
                            }}
                          />
                          {this.state.vinNumberError &&
                          <small style={{color: dangerColor}}>VIN Number is required</small>}
                          <CustomInput
                            labelText="Claim Number..."
                            id="email"
                            error={this.state.claimNumberError}
                            inputProps={{
                              onChange: this.formValueChange('claimNumber')
                            }}
                            formControlProps={{
                              fullWidth: true
                            }}
                          />
                          {this.state.claimNumberError &&
                          <small style={{color: dangerColor}}>Claim Number is required</small>}
                          <div style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignContent: 'center',
                            alignItems: 'center',
                            marginBottom: -20
                          }}>
                            <h4
                              style={{
                                border: '1px solid',
                                padding: 10,
                                borderRadius: 50
                              }}
                            >
                              OR
                            </h4>
                          </div>
                          <CustomInput
                            labelText="Last Name..."
                            id="email"
                            error={this.state.lastNameError}
                            inputProps={{
                              onChange: this.formValueChange('lastName')
                            }}
                            formControlProps={{
                              fullWidth: true
                            }}
                          />
                          {this.state.lastNameError &&
                          <small style={{color: dangerColor}}>Last Name is required</small>}
                        </React.Fragment>
                      }
                      {
                        this.state.logInFor === "NSD Broker Services" && <React.Fragment>
                          <CustomInput
                            labelText="Email..."
                            id="email"
                            error={this.state.emailError}
                            formControlProps={{
                              fullWidth: true
                            }}
                            inputProps={{
                              onChange: this.formValueChange('email'),
                              type: "email",
                              endAdornment: (
                                <InputAdornment position="end">
                                  <Email className={classes.inputIconsColor}/>
                                </InputAdornment>
                              )
                            }}
                          />
                          {this.state.emailError &&
                          <small style={{color: dangerColor}}>Email is required</small>}
                          <CustomInput
                            labelText="Password"
                            id="pass"
                            error={this.state.passwordError}
                            formControlProps={{
                              fullWidth: true
                            }}
                            inputProps={{
                              type: this.state.showPassword ? 'text' : 'password',
                              onChange: this.formValueChange('password'),
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton
                                    edge="end"
                                    style={{marginLeft: -12}}
                                    aria-label="toggle password visibility"
                                    onClick={this.handleClickShowPassword}
                                    onMouseDown={this.handleMouseDownPassword}
                                  >
                                    {this.state.showPassword ? <VisibilityOff/> : <Visibility/>}
                                  </IconButton>
                                </InputAdornment>
                              )
                            }}
                          />
                          {this.state.passwordError &&
                          <small style={{color: dangerColor}}>Password is required</small>}
                        </React.Fragment>
                      }
                    </CardBody>
                    <CardFooter className={classes.cardFooter}>
                      <Button color="primary" outlined size="lg" onClick={this.login}>
                        Log In
                      </Button>
                    </CardFooter>
                  </form>
                </Card>
              </GridItem>
            </GridContainer>
          </div>
          {/* <Footer whiteFont /> */}
        </div>
      </div>
    );
  }
}

export default withStyles(loginPageStyle)(LoginPage);
