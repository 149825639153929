import React from "react";

export default {
  items:
    [
      {
        id: 'cellular',
        "name": "Telematics / Cellular / GPS Benefits",
        imageAlt: '',
        detailImage: require("assets/img/telematics_640_color.png"),
        "description": [
          "Offers Towing and Roadside Assistance for members' vehicles. Certain plans also include rental reimbursement for a covered peril. Nation Safe Drivers will customize these programs to fit your specific needs. With access to over 45,000 service vehicles in our network, we are quick to respond to your client's needs. Partner with us for award winning service for on-time arrival and customer service."
        ],
        mainContent: (
          <React.Fragment>
            NSD has leveraged cutting edge technology and innovative product development to create meaningful consumer
            programs. The programs are designed to integrate with your company's technology and drive significant
            perceived
            value in your marketplace. NSD's strong insurance background and relationships allow us the flexibility to
            accommodate any request. Services range from 24-hour assistance services to product warranties.
            <br/>
            <br/>
            NSD is able to create the right solution for you! Benefit from over 125 years of combined insurance
            experience
            from a knowledgeable industry leader.
          </React.Fragment>
        ),
        centerItemsTitle: "Insurance Company and MGA Benefits",
        centerItems: [
          {
            title: "Client Base",
            content: (
              <ul>
                <li>Cutting Edge Telematic Leading Industry</li>
                <li>Cellular Phone Manufacturers and Providers</li>
                <li>GPS and Location Based Product Manufacturers</li>
                <li>Technology Driven Industry</li>
              </ul>
            )
          },
          {
            title: "Industry leading programs designed to...",
            content: (
              <ul>
                <li>Increase product usage and retention</li>
                <li>
                  Build meaningful brand recognition
                </li>
                <li>
                  Increase revenue potential
                </li>
                <li>
                  Capitalize on the leverage usage data to make smart, profitable decisions across an insurance
                  company's portfolio of businesses
                </li>
              </ul>
            )
          },
          {
            title: "Services Include",
            content: (
              <ul>
                <li>Private labeling of services</li>
                <li>
                  Information services i.e. addresses, phone numbers
                </li>
                <li>
                  ATM, restaurant, hotel, doctor, dentist, and hospital locators
                </li>
                <li>
                  24-Hour emergency message relay
                </li>
                <li>
                  24-Hour evacuation route and traffic assistance
                </li>
                <li>
                  Turn by turn directions
                </li>
                <li>
                  Flower and gift ordering
                </li>
                <li>
                  Executive travel assistance
                </li>
                <li>
                  Emergency travel services
                </li>
                <li>
                  Flight schedule and flight status
                </li>
                <li>
                  24-Hour "Sign and Drive" roadside assistance services including: towing, jumpstarts, fuel delivery,
                  lock-out services, tire changes, mechanical assistance and more.
                </li>
                <li>
                  Dollar limited roadside programs - Any dollar amount needed to meet your marketing goals
                </li>
                <li>
                  Guaranteed tow mileage programs - Guaranteed tow mileage programs ensure consistent expectations
                  nationwide.
                </li>
              </ul>
            )
          },
          {
            title: "Enhanced Services*",
            content: (
              <React.Fragment>
                <ul>
                  <li>
                    4-color, wire-bound trip routings
                  </li>
                  <li>
                    Premium travel services
                  </li>
                  <li>
                    Turn by turn directions
                  </li>
                  <li>
                    Traffic alerts
                  </li>
                  <li>
                    Emergency travel expense reimbursements
                  </li>
                  <li>
                    Theft rewards
                  </li>
                  <li>
                    Hit and Run rewards
                  </li>
                  <li>
                    Hotel and rental car savings
                  </li>
                  <li>
                    Legal benefits
                  </li>
                </ul>
                <h6 style={{
                  fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                  bottom: "10px",
                  color: "#c0c1c2",
                  display: "block",
                  fontWeight: "400",
                  fontSize: "13px",
                  lineHeight: "13px"
                }}>
                  *Listed enhanced services is just a sampling of available services to ensure a unique and value packed
                  presentation to your customers.
                </h6>
              </React.Fragment>
            )
          }
        ]
      }
    ]
}
