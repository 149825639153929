/*eslint-disable*/
import React, {useState, useEffect} from "react";
// react components for routing our app without refresh
import {Link} from "react-router-dom";
import classNames from "classnames";
import withStyles from "@material-ui/core/styles/withStyles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Tooltip from "@material-ui/core/Tooltip";
import {Apps} from "@material-ui/icons";
import CustomDropdown from "components/CustomDropdown/CustomDropdown.jsx";
import Button from "components/CustomButtons/Button.jsx";
import scrollIntoView from 'scroll-into-view-if-needed'
import headerLinksStyle from "assets/jss/material-kit-react/components/headerLinksStyle.jsx";
import {ResponsiveFontSizesOptions as theme} from "@material-ui/core/styles/responsiveFontSizes";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import useTheme from "@material-ui/core/styles/useTheme";

function HeaderLinks({...props}) {
  const {classes} = props;
  const [mobileLinks, setMobileLinks] = useState({
    nationClaimsUrl: 'https://play.google.com/store/apps/details?id=com.NationClaims',
    roadHazardTireClaims: 'https://play.google.com/store/apps/details?id=com.RoadHazardTireClaims',
    trwsClaims: 'https://play.google.com/store/apps/details?id=com.nsdmc.nsdtrwsclaims'
  })

  useEffect(() => {
    if (navigator.userAgent.toLowerCase().indexOf("iphone") > -1 || navigator.userAgent.toLowerCase().indexOf("ipad") > -1) {
      setMobileLinks({
        nationClaimsUrl: 'https://apps.apple.com/us/developer/nation-safe-drivers/id688918723',
        roadHazardTireClaims: 'https://apps.apple.com/us/developer/nation-safe-drivers/id688918723',
        trwsClaims: 'https://apps.apple.com/us/developer/nation-safe-drivers/id688918723',
      })
    }
  }, [])

  const productAppraisalDropDownLink = classNames({
    [classes.dropdownLink]: true,
    [classes.selectedDropdownLink]: location.pathname == "/product-appraisal",
  });

  const infrastructureAppraisalDropDownLink = classNames({
    [classes.dropdownLink]: true,
    [classes.selectedDropdownLink]: location.pathname == "/infrastructure-appraisal",
  });

  const technicalAppraisalDropDownLink = classNames({
    [classes.dropdownLink]: true,
    [classes.selectedDropdownLink]: location.pathname == "/technical-appraisal",
  });

  const onHomePage = (location.pathname == "/" || location.pathname.indexOf("/landing-page") > -1);
  const loginUrl = window.location.protocol + "//app" + window.location.host.replace("www", "");

  const closeSideBar = () => {
    var closeSideBarEvent = new Event('closeSideBar');
    window.dispatchEvent(closeSideBarEvent);
  }

  const isMobileDevice = () => {
    return useMediaQuery(useTheme().breakpoints.down(920), {
      defaultMatches: true
    });
  }

  return (
    <List className={classes.list}>
      <ListItem className={classes.listItem}>
        {
          onHomePage ?
            <a target={"_blank"} href="/claims-center" style={{color: "inherit"}} onClick={closeSideBar}>
              <Button
                onClick={closeSideBar}
                color={props.highlighted == "claims-center" ? "lightGray" : "transparent"}
                className={props.highlighted == "claims-center" ? classes.navLinkHighlight : classes.navLink}
              >
                Claims Center
              </Button>
            </a>
            :
            <a target={"_blank"} href="/claims-center" style={{color: "inherit"}} onClick={closeSideBar}>
              <Button
                onClick={closeSideBar}
                color={props.highlighted == "claims-center" ? "lightGray" : "transparent"}
                className={props.highlighted == "claims-center" ? classes.navLinkHighlight : classes.navLink}
              >
                Claim Center
              </Button>
            </a>
        }
      </ListItem>
      <ListItem className={classes.listItem}>
        <CustomDropdown
          noLiPadding
          buttonText="Products & Services"
          buttonProps={{
            color: (props.highlighted == "products-services" ? "lightGray" : "transparent"),
            className: (props.highlighted == "products-services" ? classes.navLinkHighlight : classes.navLink)
          }}
          buttonIcon={Apps}
          dropdownList={[
            // coreServicesLink,
            <a href="/automotive-dealers" className={technicalAppraisalDropDownLink} onClick={closeSideBar}>
              Automotive
            </a>,
            <a href="/agents" className={infrastructureAppraisalDropDownLink} onClick={closeSideBar}>
              Agents
            </a>,
            <a href="/insurance-partners" className={productAppraisalDropDownLink} onClick={closeSideBar}>
              Insurance Partners
            </a>
          ]}
        />
      </ListItem>
      <ListItem className={classes.listItem}>
        <CustomDropdown
          noLiPadding
          buttonText="Partners"
          buttonProps={{
            color: (props.highlighted == "products-services" ? "lightGray" : "transparent"),
            className: (props.highlighted == "products-services" ? classes.navLinkHighlight : classes.navLink)
          }}
          buttonIcon={Apps}
          dropdownList={[
            // coreServicesLink,
            <a href="/our-network/product-details/service-providers" className={technicalAppraisalDropDownLink}
               onClick={closeSideBar}>
              Service Providers
            </a>//,
            // <a href="/our-network/product-details/brokers" className={infrastructureAppraisalDropDownLink}
            //    onClick={closeSideBar}>
            //   Brokers
            // </a>,
            // <a href="/our-network/product-details/underwriters" className={productAppraisalDropDownLink}
            //    onClick={closeSideBar}>
            //   Underwriters
            // </a>
          ]}
        />
      </ListItem>
      {
        isMobileDevice() &&
        <React.Fragment>
          <ListItem className={classes.listItem}>
            <Tooltip
              id="mobile-nationClaimApp"
              title="Nation Claim App"
              placement={window.innerWidth > 959 ? "top" : "left"}
              classes={{tooltip: classes.tooltip}}
            >
              <Button
                onClick={() => {
                  window.open(mobileLinks.nationClaimsUrl, "_blank")
                }}
                color="transparent"
                className={classes.navLink}
              >
                Nation Claims
              </Button>
            </Tooltip>
          </ListItem>
          <ListItem className={classes.listItem}>
            <Tooltip
              id="mobile-trwsAppName"
              title="TRWS Claims App"
              placement={window.innerWidth > 959 ? "top" : "left"}
              classes={{tooltip: classes.tooltip}}
            >
              <Button
                onClick={() => {
                  window.open(mobileLinks.trwsClaims, "_blank")
                }}
                color="transparent"
                className={classes.navLink}
              >
                TRWS Claims
              </Button>
            </Tooltip>
          </ListItem>
          <ListItem className={classes.listItem}>
            <Tooltip
              id="mobile-nationClaimApp"
              title="Nation Claim App"
              placement={window.innerWidth > 959 ? "top" : "left"}
              classes={{tooltip: classes.tooltip}}
            >
              <Button
                onClick={() => {
                  window.open(mobileLinks.roadHazardTireClaims, "_blank")
                }}
                color="transparent"
                className={classes.navLink}
              >
                Road Hazard Tire Claims
              </Button>
            </Tooltip>
          </ListItem>
        </React.Fragment>
      }
      <ListItem className={classes.listItem}>
        <Tooltip
          id="instagram-linkedin"
          title="LinkedIn"
          placement={window.innerWidth > 959 ? "top" : "left"}
          classes={{tooltip: classes.tooltip}}
        >
          <Button
            href="https://www.linkedin.com/company/nation-safe-drivers?trk=cp_followed_name_nation-safe-drivers"
            target="_blank"
            color="transparent"
            className={classes.navLink}
          >
            <i className={classes.socialIcons + " fab fa-linkedin"}/>
          </Button>
        </Tooltip>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Tooltip
          id="instagram-twitter"
          title="Twitter"
          placement={window.innerWidth > 959 ? "top" : "left"}
          classes={{tooltip: classes.tooltip}}
        >
          <Button
            href="https://twitter.com/NSD_MotorClub"
            target="_blank"
            color="transparent"
            className={classes.navLink}
          >
            <i className={classes.socialIcons + " fab fa-twitter"}/>
          </Button>
        </Tooltip>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Tooltip
          id="instagram-facebook"
          title="Facebook"
          placement={window.innerWidth > 959 ? "top" : "left"}
          classes={{tooltip: classes.tooltip}}
        >
          <Button
            href="https://www.facebook.com/NationSafeDrivers"
            target="_blank"
            color="transparent"
            className={classes.navLink}
          >
            <i className={classes.socialIcons + " fab fa-facebook"}/>
          </Button>
        </Tooltip>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Tooltip
          id="instagram-intragram"
          title="Facebook"
          placement={window.innerWidth > 959 ? "top" : "left"}
          classes={{tooltip: classes.tooltip}}
        >
          <Button
            href="https://www.instagram.com/nationsafedrivers/"
            target="_blank"
            color="transparent"
            className={classes.navLink}
          >
            <i className={classes.socialIcons + " fab fa-instagram"}/>
          </Button>
        </Tooltip>
      </ListItem>
      {/*<ListItem className={classes.listItem}>
        {
          onHomePage ?
            <Button
              href="#expertise"
              color={props.highlighted == "expertise" ? "lightGray" : "transparent"}
              className={props.highlighted == "expertise" ? classes.navLinkHighlight : classes.navLink}
            >
              Expertise
            </Button>
            :
            <a href="/landing-page/#expertise" style={{ color: "inherit" }}>
              <Button
                color={props.highlighted == "expertise" ? "lightGray" : "transparent"}
                className={props.highlighted == "expertise" ? classes.navLinkHighlight : classes.navLink}
              >
                Expertise
              </Button>
            </Link>
        }
      </ListItem>
      <ListItem className={classes.listItem}>
        {
          onHomePage ?
            <Button
              href="#our-process"
              color={props.highlighted == "our-process" ? "lightGray" : "transparent"}
              className={props.highlighted == "our-process" ? classes.navLinkHighlight : classes.navLink}
            >
              Our Process
            </Button>
            :
            <a href="/landing-page/#our-process" style={{ color: "inherit" }}>
              <Button
                color={props.highlighted == "expertise" ? "lightGray" : "transparent"}
                className={props.highlighted == "expertise" ? classes.navLinkHighlight : classes.navLink}
              >
                Our Process
              </Button>
            </Link>
        }

      </ListItem> */}
      {/* <ListItem className={classes.listItem}>
        <Button
          href={loginUrl}
          color={props.highlighted == "login" ? "lightGray" : "transparent"}
          className={props.highlighted == "login" ? classes.navLinkHighlight : classes.navLink}
        >
          Login
        </Button>
      </ListItem> */}
    </List>
  );
}

export default withStyles(headerLinksStyle)(HeaderLinks);
