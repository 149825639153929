import React from "react";
import {Link} from 'react-router-dom';

export default {
  image: require('../../assets/img/security-2168233_640.jpg'),
  title: "Your Privacy Matters",
  description: [
    (
      <React.Fragment>
        We understand that your privacy is important to you. Our customers have trusted us with their roadside
        assistance, travel and security needs for over 40 years, and it is important to us to maintain a high degree of
        reliability and integrity. We want you to understand how we protect your privacy when we collect and use
        information about you.
      </React.Fragment>
    )
  ]
}
