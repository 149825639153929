import React from "react";
import productDetailsStyle from '../../assets/jss/material-kit-react/components/productDetails.jsx'
import withStyles from "@material-ui/core/styles/withStyles";
import {Button, Card, CardContent, CardActions, CardHeader, Grid} from "@material-ui/core";

const Brochures = ({ classes, brochures }) => {
    let size = brochures.length > 1 ? {
        xs: 12,
        lg: 3
    } : {};
    return (
        <>
        {
            brochures.map((brochure, index) => (
                <Grid item key={`brochure${index}`} {...size}>
                <div className={classes.brochureContainer}>
                    <Card className={classes.brochureCard}>
                    <CardHeader
                        className={classes.brochureCardHeader}
                        subheader={brochure.title}
                    />
                    <CardContent
                        style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        alignContent: 'center'
                        }}
                    >
                        <img style={{width: 100}} src={require('../../assets/img/brochure.png')}/>
                    </CardContent>
                    <CardActions className={classes.brochureAction}>'
                        <a href={`/files/${brochure.link}`} target={"_blank"}>
                        <Button variant={"contained"} color={"primary"}>DOWNLOAD BROCHURE</Button>
                        </a>
                    </CardActions>
                    </Card>
                </div>
                </Grid>  
            ))            
        }
        </>
      );
};

export default withStyles(productDetailsStyle)(Brochures);