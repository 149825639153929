import React from "react";
import productDetailsStyle from '../../assets/jss/material-kit-react/components/productDetails.jsx'
import withStyles from "@material-ui/core/styles/withStyles";
import {Grid} from "@material-ui/core";
import {Feedback as FeedbackIcon} from "@material-ui/icons";

const ProductSections = ({ classes, sections }) => {
    return (
        <>
            <Grid container style={{marginTop: 50, padding: "0px 15% 0px 15%"}}>
            {
                sections && sections.map((section, index) => {
                return (
                    <Grid item xs={12} sm={12} md={12} lg={6}
                        xl={6} style={{padding: 15}}>
                    <div style={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                        alignContent: 'center',
                        flexDirection: 'row'
                    }}>
                        <div className={classes.feedbackIconContainer}>
                        <FeedbackIcon className={classes.feedbackIcon}/>
                        </div>
                        <h4 className={classes.sectionTitle}>{section.title}</h4>
                    </div>
                    {
                        section.description.map((desc, index) => {
                        return (
                            <div>
                            <h5 className={classes.description} style={{textAlign: 'left'}}>{desc}</h5>
                            </div>
                        )
                        })
                    }
                    </Grid>
                )
                })
            }
            </Grid>
        </>
    )
};

export default withStyles(productDetailsStyle)(ProductSections);