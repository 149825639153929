import React from "react";
// nodejs library that concatenates classes
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import landingPageStyle from "assets/jss/material-kit-react/views/landingPage.jsx";
// Sections for this page
// Content
import content from '../../../content/ClaimsCenter/Sections/privacy';
import SingleItemSection from "../../../components/StandardPage/components/SingleItemSection";

class LandingPage extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {classes, ...rest} = this.props;
    return (
      <SingleItemSection
        noLearnMore={true}
        altRow={true}
        imageLeft={true}
        content={content}
      />
    );
  }
}

export default withStyles(landingPageStyle)(LandingPage);
