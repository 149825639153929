import React, {useEffect, useState} from "react";
import {makeStyles} from "@material-ui/styles";
import Carousel from "react-spring-3d-carousel";
import {config} from "react-spring";
import {Fab} from "@material-ui/core";
import classNames from "classnames";
import {Background, Parallax} from "react-parallax";
import {secondaryColor, title} from "../../../assets/jss/material-kit-react";
import {ChevronLeft as BackIcon, ChevronRight as ForwardIcon} from "@material-ui/icons";

let currentIndex = 0;
const useStyles = makeStyles(theme => ({
  root: {
    paddingRight: '15%',
    paddingLeft: '15%',
    // marginBottom: 15,
    display: 'flex',
    flexDirection: 'row',
    width: "100%",
    margin: "0 auto",
    '@media (max-width: 576px)': {
      height: '585px'
    },
    '@media (min-width: 576px)': {
      height: '525px'
    },
    '@media (min-width: 768px)': {
      height: '575px'
    },
    '@media (min-width: 992px)': {
      height: '700px'
    },
    '@media (min-width: 1200px)': {
      height: '850px'
    }
  },
  section: {
    textAlign: "center"
  },
  parallax: {
    height: "90vh",
    maxHeight: "1000px",
    overflow: "hidden",
    position: "relative",
    backgroundPosition: "center center",
    backgroundSize: "cover",
    margin: "0",
    padding: "0",
    border: "0",
    display: "flex",
    alignItems: "center"
  },
  filter: {
    "&:before": {
      background: "rgba(0, 0, 0, 0.75)"
    },
    "&:after,&:before": {
      position: "absolute",
      zIndex: "1",
      width: "100%",
      height: "100%",
      display: "block",
      left: "0",
      top: "0",
      content: "''"
    }
  },
  small: {
    height: "380px"
  },
  title: {
    ...title,
    display: "inline-block",
    position: "relative",
    marginTop: "30px",
    minHeight: "32px",
    color: "#FFFFFF",
    textDecoration: "none"
  },
  phoneTitle: {
    ...title,
    margin: 0,
    display: "inline-block",
    position: "relative",
    color: '#000',
    textDecoration: "none"
  },
  container: {
    paddingRight: '15%',
    paddingLeft: '15%',
    marginBottom: 150
  },
  fullOpacity: {
    opacity: `1 !important`
  },
  noOpacity: {
    transition: 'opacity 2s',
    opacity: 0
  },
  phoneImage: {
    '@media (max-width: 576px)': {
      width: '185px'
    },
    '@media (min-width: 576px)': {
      width: '150px'
    },
    '@media (min-width: 768px)': {
      width: '175px'
    },
    '@media (min-width: 992px)': {
      width: '225px'
    },
    '@media (min-width: 1200px)': {
      width: '300px'
    }
  }
}));

const MobileApp = () => {
  const classes = useStyles();
  const [slideIndex, setSlideIndex] = useState(0)
  const [offsetRadius] = useState(2)
  const [opacity, setOpacity] = useState(true)
  const [showNavigation] = useState(false)
  const [carouselConfig] = useState(config.slow);

  const checkMyIndex = index => {
    let compareIndex = slideIndex;
    if (slideIndex < 0) {
      while (compareIndex < 0)
        compareIndex += 5
    }
    if (compareIndex > 4)
      compareIndex = slideIndex % 5;

    return index === compareIndex;
  }

  const scrollToSlide = newIndex => event => {
    setSlideIndex(newIndex)
  }
  const [slides] = useState()
  const parallaxClasses = classNames({
    [classes.parallax]: true,
    [classes.filter]: true,
    [classes.small]: false
  });

  useEffect(() => {
    setOpacity(false)
    // setTimeout(() => {
    //   setSlideIndex(slideIndex + 1)
    // }, 1000)
  }, [slideIndex])

  const getSlides = () => {
    let slideList = [
      {
        image: require('../../../assets/img/phone_black1.png'),
        icon: require('../../../assets/img/phone1_icon.png'),
        title: "REGISTER",
        description: "Input membership information."
      },
      {
        image: require('../../../assets/img/phone_black2.png'),
        icon: require('../../../assets/img/phone2_icon.png'),
        title: "CONFIRM YOUR LOCATION",
        description: "We will pinpoint your exact location, simply confirm that we have located you properly."
      },
      {
        image: require('../../../assets/img/phone_black3.png'),
        icon: require('../../../assets/img/phone3_icon.png'),
        title: "CHOOSE YOUR SERVICE",
        description: "Simply choose the service you need or select other if unsure of the problem."
      },
      {
        image: require('../../../assets/img/phone_black4.png'),
        icon: require('../../../assets/img/phone4_icon.png'),
        title: "GET ARRIVAL TIMES",
        description: "Get arrival time and selected vendor information."
      },
      {
        image: require('../../../assets/img/phone_black4.png'),
        icon: require('../../../assets/img/phone4_icon.png'),
        title: "GET REAL TIME UPDATES",
        description: "Receive realtime vehicle tracking and service information. Share the link with Friends & Family!"
      }
    ]
    let returnSlides = [];
    for (let i = 0; i < slideList.length; i++) {
      let showMyText = checkMyIndex(i)
      returnSlides.push({
        key: (i + 1),
        content: <div
          onClick={scrollToSlide(i)}
          style={{
            color: '#000',
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            alignItems: 'center'
          }}
        >
          <img className={classes.phoneImage} src={slideList[i].image} alt={slideList[i].title}/>
          <img style={{width: 50, marginTop: 5}} src={slideList[i].icon}/>
          {showMyText &&
          <div className={classNames({[classes.noOpacity]: true, [classes.fullOpacity]: opacity && showMyText})}
          >
            <h4 className={classes.phoneTitle}>
              <span style={{fontSize: 32}}>{i + 1}</span> {slideList[i].title}
            </h4>
            <div style={{height: 150, maxWidth: 225}}>
              <h5 className={classes.phoneTitle}>
                {slideList[i].description}
              </h5>
            </div>
          </div>
          }
        </div>
      })
    }
    setTimeout(() => {
      setOpacity(true)
    }, 250)
    return returnSlides;
  }

  return (
    <div className={classes.section}>
      <div className={classNames({[classes.container]: true, [classes.lighterTransitionBackground]: true})}>
        <h2 className={classes.title}>
          How It Works
        </h2>
        <h3 style={{textAlign: 'left'}}>
          Download our mobile app and use it any time you are in need of assistance. Our application is simple and easy
          to use. It provides you with everything you need in order to stay in contact with your driver and anyone else
          that needs to stay up to date on your current situation when things get tough out on the road.
        </h3>
      </div>
      <Parallax
        bgImage={require("../../../assets/img/connections.png")}
        bgStyle={{
          "&:before": {
            background: "rgba(0, 0, 0, 0.75)"
          },
          "&:after,&:before": {
            position: "absolute",
            zIndex: "1",
            width: "100%",
            height: "100%",
            display: "block",
            left: "0",
            top: "0",
            content: "''"
          }
        }}
        strength={500}
      >
        <Background className={parallaxClasses}></Background>
        {/*<h2 className={classes.title} style={{color: '#000'}}>How It Works</h2>*/}
        <br/>
        <br/>
        <div className={classes.root}>
          <div style={{display: 'flex', alignItems: 'center'}}>
            <Fab
              style={{backgroundColor: secondaryColor, marginLeft: -60, marginRight: 60, color: '#fff'}}
              onClick={scrollToSlide(slideIndex - 1)}
            >
              <BackIcon/>
            </Fab>
          </div>
          <Carousel
            slides={getSlides()}
            goToSlide={slideIndex}
            offsetRadius={offsetRadius}
            showNavigation={showNavigation}
            animationConfig={carouselConfig}
          />
          <div style={{display: 'flex', alignItems: 'center'}}>
            <Fab
              style={{backgroundColor: secondaryColor, marginLeft: 60, marginRight: -60, color: '#fff'}}
              onClick={scrollToSlide(slideIndex + 1)}
            >
              <ForwardIcon/>
            </Fab>
          </div>
        </div>
      </Parallax>
    </div>
  )
}

export default MobileApp
