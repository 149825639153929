import React from "react";
import classNames from "classnames";
import withStyles from "@material-ui/core/styles/withStyles";
import Header from "components/Header/Header.jsx";
import Footer from "components/Footer/Footer.jsx";
import HeaderLinks from "../../components/Header/HeaderLinks.jsx";
import landingPageStyle from "../../assets/jss/material-kit-react/views/landingPage.jsx";
import ProductSection from "./Sections/ProductSection.jsx";
import CallForAssistance from "./Sections/CallForAssistance.jsx";
import Careers from "./Sections/Careers.jsx";
import Team from "./Sections/Team.jsx";
import AboutNSD from "./Sections/AboutNSD.jsx";
import {Snackbar} from '@material-ui/core';
import LandingPageCarousel from "./Sections/LandingPageCarousel";

const dashboardRoutes = [];

class LandingPage extends React.Component {
  constructor(props) {
    super(props);
    if (window.location.href.indexOf('niufl.com') >= 0) {
      window.location.href = 'https://niufl.com/niu-fl/';
    }
    this.refBusinessValue = React.createRef();
    this.refExpertise = React.createRef();
    this.refTeam = React.createRef();
    this.refCoreServices = React.createRef();
    this.refOurProcess = React.createRef();
    this.listenScrollEvent = this.listenScrollEvent.bind(this);
    this.moveToNextNewExperienceIndex = this.moveToNextNewExperienceIndex.bind(this);
    this.state = {
      openMessage: false,
      newExperienceIndex: 0,
      newExperienceIndexMove: 0,
      topText: 200,
      arrowRef: null,
      open: false,
      highlightHeader: ""
    }
  }

  listenScrollEvent = e => {
    let newHighlightValue = '';

    this.setState({highlightHeader: newHighlightValue})
  }

  IE = () => {
    return navigator.userAgent.indexOf('MSIE 10.0') >= 0 || (navigator.userAgent.indexOf('Windows NT') >= 0 && navigator.userAgent.indexOf('rv:11.0') >= 0)
  }

  componentDidMount() {

    if (this.IE()) {
      this.setState({openMessage: true}, function () {
        setTimeout(function () {
          this.setState({openMessage: false});
        }.bind(this), 6000)
      }.bind(this))
    }

    setTimeout(function () {
      this.moveToNextNewExperienceIndex();
    }.bind(this), 3000)

    window.addEventListener('scroll', this.listenScrollEvent);
    let path = window.location.href;
    if (path.indexOf("/landing-page/#") > -1) {
      let location = path.split("#")[path.split("#").length - 1];
      let top = this.refBusinessValue.current.getBoundingClientRect().top;

      window.scrollTo({
        top: top,
        left: 0,
        behavior: 'smooth'
      });
    }
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.listenScrollEvent);
  }

  moveToNextNewExperienceIndex = () => {
    if (this.state.newExperienceIndex < 5) {
      this.setState({
        newExperienceIndex: this.state.newExperienceIndex + 1
      })
    } else {
      this.setState({
        newExperienceIndex: 0
      })
    }

    setTimeout(function () {
      this.moveToNextNewExperienceMoveIndex();
    }.bind(this), 1500)
  }

  moveToNextNewExperienceMoveIndex = () => {
    if (this.state.newExperienceIndex < 5) {
      this.setState({
        newExperienceIndexMove: this.state.newExperienceIndexMove + 1
      })
    } else {
      this.setState({
        newExperienceIndexMove: 0
      })
    }

    setTimeout(function () {
      this.moveToNextNewExperienceIndex();
    }.bind(this), 3000)
  }

  render() {
    const {classes, ...rest} = this.props;

    return (
      <div>
        <Snackbar
          anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
          open={this.state.openMessage}
          onClose={() => {
            this.setState({openMessage: false})
          }}
          ContentProps={{
            'aria-describedby': 'message-id',
          }}
          message={<span id="message-id">For a better experience we recommend using Chrome, Safari, or IE Edge</span>}
        />
        <Header
          color="transparent"
          routes={dashboardRoutes}
          brand="NSD"
          rightLinks={<HeaderLinks highlighted={this.state.highlightHeader}/>}
          fixed
          changeColorOnScroll={{
            height: this.state.topText,
            color: "dark"
          }}
          {...rest}
        />
        <LandingPageCarousel />
        <div className={classNames(classes.main, classes.mainRaised)}>
          <div className={classes.container}>
            <div id="business-value" ref={this.refBusinessValue}>
              <ProductSection/>
            </div>
            <div id="core-services" ref={this.refCoreServices}>
              <CallForAssistance/>
            </div>
            <div id="team" ref={this.refTeam}>
              <Team/>
            </div>
            <div id="expertise" ref={this.refExpertise}>
              <Careers/>
            </div>
            <div id="expertise" ref={this.refExpertise}>
              <AboutNSD/>
            </div>
          </div>
        </div>
        <Footer/>
      </div>
    );
  }
}

export default withStyles(landingPageStyle)(LandingPage);
