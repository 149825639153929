import React, {useState} from "react";
// nodejs library that concatenates classes
import withStyles from "@material-ui/core/styles/withStyles";

import landingPageStyle from "assets/jss/material-kit-react/views/landingPage.jsx";
import SingleItemSection from "../../components/StandardPage/components/SingleItemSection";
import content from '../../content/NIUW/Sections/products_main'
import StandardPage from "../../components/StandardPage/StandardPage";
import personal_automobile from "../../content/NIUW/Sections/personal_automobile";
import homeowners_program from "../../content/NIUW/Sections/homeowners_program";
import renters_insurance from "../../content/NIUW/Sections/renters_insurance";
import flood_insurance from "../../content/NIUW/Sections/flood_insurance";
import commercial_insurance from "../../content/NIUW/Sections/commercial_insurance";
import errors_and_omissions from "../../content/NIUW/Sections/errors_and_omissions";
import NIUWHeaderLinks from "../../components/Header/NIUWHeaderLinks";
import {Helmet} from "react-helmet";

function NIUWProducts({...props}) {
  const {classes, ...rest} = props;
  const [contentItems] = useState([
    {
      ...personal_automobile
    },
    {
      ...renters_insurance
    },
    {
      ...homeowners_program
    },
    {
      ...flood_insurance
    },
    {
      ...commercial_insurance
    },
    {
      ...errors_and_omissions
    }
  ]);

  return (
    <StandardPage
      title={content.title}
      niuWLogo={true}
      headerLinks={NIUWHeaderLinks}
      description={content.description}
      image={require("assets/img/background-car-dealer.jpg")}
    >
      <Helmet>
        <meta charSet="utf-8"/>
        <title>{`NIU Products`}</title>
        <meta name="keywords" content="HOMEOWNERS PROGRAM,RENTERS INSURANCE,PERSONAL AUTOMOBILE"/>
        <meta name="description"
              content={`
National Insurance Underwriters (NIU) is a full service MGA and is an affiliate of the Nation Safe Drivers (NSD) group of companies. NIU currently manages automobile insurance program for Occidental Fire and Casualty Company of North Carolina in Colorado and Maryland. Additionally, NIU markets homeowners, renters, commercial, agent error and omissions and a variety of niche products nationwide.Competitive rates, competitive commissions, limited underwriting, and complete automation, make this program a great choice for your agency.`}/>
        <link rel="canonical" href={window.location.href}/>
      </Helmet>
      {
        contentItems && contentItems.map((content, index) => {
          console.log()
          return (
            <div>
              <SingleItemSection
                noLearnMore={true}
                productArea={"automotive"}
                altRow={index % 2 === 1}
                imageLeft={index % 2 !== 1}
                content={content}
              />
            </div>
          )
        })
      }
    </StandardPage>
  );
}

export default withStyles(landingPageStyle)(NIUWProducts);
