import React from "react";
import classNames from "classnames";
import withStyles from "@material-ui/core/styles/withStyles";
import {Button, Grid, Menu, MenuItem} from '@material-ui/core'
import Header from "../../components/Header/Header.jsx";
import Footer from "../../components/Footer/Footer.jsx";
import NIUFloridaHeaderLinks from "../../components/Header/NIUFloridaHeaderLinks.jsx";
import Parallax from "components/Parallax/Parallax.jsx";
import content from '../../content/NIUFlorida/main.js'
import landingPageStyle from '../../assets/jss/material-kit-react/views/niuFlorida.jsx';
import CallForAssistance from "./Sections/CallForAssistance";
import {Helmet} from "react-helmet";

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
  },
})(props => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles(theme => ({
  root: {
    '&:focus': {
      backgroundColor: theme.palette.primary.main,
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem);

const dashboardRoutes = [];

class NIUFlorida extends React.Component {
  constructor(props) {
    super(props);

    this.pageTitle = React.createRef();
    this.listenScrollEvent = this.listenScrollEvent.bind(this);
    this.state = {
      hideProducts: false,
      topText: 400,
      anchorEl: false,
      arrowRef: null,
      open: false,
      highlightHeader: ""
    }
    this.handleClick = this.handleClick.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  handleArrowRef = node => {
    this.setState({
      arrowRef: node,
    });
  };

  listenScrollEvent = e => {
    this.setState({
      topText: this.pageTitle.current.getBoundingClientRect().y
    })
  }

  componentDidMount() {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
    window.addEventListener('scroll', this.listenScrollEvent);
    this.setState({
      topText: this.pageTitle.current.getBoundingClientRect().top
    })
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.listenScrollEvent);
  }

  handleClick(event) {
    this.setState({anchorEl: event.currentTarget});
  }

  handleClose() {
    this.setState({anchorEl: null});
  }

  IE = () => {
    return navigator.userAgent.indexOf('MSIE 10.0') >= 0 || (navigator.userAgent.indexOf('Windows NT') >= 0 && navigator.userAgent.indexOf('rv:11.0') >= 0)
  }

  render() {
    const {classes, ...rest} = this.props;
    return (
      <div>
        <Helmet>
          <meta charSet="utf-8"/>
          <title>{`NIU Florida`}</title>
          <meta name="description"
                content={`Let our experts help you customize the perfect product offering to fit the specific needs of your clientele. NIU of Florida, Inc.'s commitment to the highest overall quality of our service will ensure that your client's expectations are exceeded with every opportunity.`}/>
          <link rel="canonical" href={window.location.href}/>
        </Helmet>
        <Header
          color="transparent"
          routes={dashboardRoutes}
          niuFlLogo={true}
          brand="NSD"
          rightLinks={<NIUFloridaHeaderLinks highlighted={this.state.highlightHeader}/>}
          fixed
          changeColorOnScroll={{
            height: this.state.topText,
            color: "dark"
          }}
          {...rest}
        />
        <Parallax filter image={require("assets/img/nifl-background.jpg")} style={{minHeight: 750}}>
          <div className={classes.container} style={{paddingLeft: '10%'}}>
            <Grid container
                  style={this.IE() ? {marginTop: 0, display: 'table-footer-group !important'} : {marginTop: 0}}>
              <Grid item xs={10}>
                <div style={{marginTop: -65}}>
                  <h1 ref={this.pageTitle} className={classes.title}>{content.title}</h1>
                </div>
                <div>
                  <a href={`${content.form.link}`} target={"_blank"}>
                    <Button variant={"contained"} color={"primary"}>DOWNLOAD LICENSING FORM</Button>
                  </a>
                </div>
                <h3>
                  {content.subTitle}
                </h3>
                <h4>
                  {content.subTitleTwo}
                </h4>
              </Grid>
            </Grid>
          </div>
        </Parallax>
        <div className={classNames(classes.main, classes.mainRaised)}>
          <div className={classes.container}>
            <CallForAssistance/>
          </div>
        </div>
        <Footer niuFl/>
      </div>
    );
  }
}

export default withStyles(landingPageStyle)(NIUFlorida);
