import {
  boxShadow,
  container,
  dangerColor,
  darkColor,
  defaultFont,
  drawerWidth,
  infoColor,
  primaryColor,
  roseColor,
  secondaryColor,
  successColor,
  transition,
  warningColor
} from "../../material-kit-react.jsx";

var tinycolor2 = require("tinycolor2");

const headerStyle = {
  link: {
    textDecoration: 'none'
  },
  appBar: {
    opacity: .9,
    display: "flex",
    border: "0",
    borderRadius: "3px",
    padding: "0.625rem 0",
    marginBottom: "20px",
    color: "#555",
    width: "100%",
    backgroundColor: "#fff",
    boxShadow:
      "0 4px 18px 0px rgba(0, 0, 0, 0.12), 0 7px 10px -5px rgba(0, 0, 0, 0.15)",
    transition: "all 150ms ease 0s",
    alignItems: "center",
    // flexFlow: "row nowrap",
    justifyContent: "flex-start",
    position: "relative",
    zIndex: "unset"
  },
  absolute: {
    position: "absolute",
    zIndex: "1100"
  },
  fixed: {
    position: "fixed",
    zIndex: "1100"
  },
  container: {
    ...container,
    "@media (min-width: 576px)": {
      maxWidth: "540px"
    },
    "@media (min-width: 768px)": {
      maxWidth: "458px"
    },
    "@media (min-width: 992px)": {
      maxWidth: "960px"
    },
    "@media (min-width: 1200px)": {
      maxWidth: "1140px"
    },
    minHeight: "50px",
    flex: "1",
    alignItems: "center",
    justifyContent: "space-between",
    display: "flex",
    flexWrap: "nowrap"
  },
  flex: {
    flex: 1
  },
  title: {
    ...defaultFont,
    lineHeight: "30px",
    fontSize: "18px",
    borderRadius: "3px",
    textTransform: "none",
    color: "inherit",
    padding: "8px 16px",
    "&:hover,&:focus": {
      color: "inherit",
      background: "transparent"
    }
  },
  appResponsive: {
    margin: "20px 10px"
  },
  primary: {
    backgroundColor: primaryColor,
    color: "#FFFFFF",
    boxShadow:
      `0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 12px -5px ${tinycolor2(primaryColor).setAlpha(.46).toRgbString()}`
  },
  secondary: {
    backgroundColor: secondaryColor,
    color: "#FFFFFF",
    boxShadow:
      `0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 12px -5px ${tinycolor2(secondaryColor).setAlpha(.46).toRgbString()}`
  },
  info: {
    backgroundColor: infoColor,
    color: "#FFFFFF",
    boxShadow:
      `0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 12px -5px ${tinycolor2(infoColor).setAlpha(.46).toRgbString()}`
  },
  success: {
    backgroundColor: successColor,
    color: "#FFFFFF",
    boxShadow:
      `0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 12px -5px ${tinycolor2(successColor).setAlpha(.46).toRgbString()}`
  },
  warning: {
    backgroundColor: warningColor,
    color: "#FFFFFF",
    boxShadow:
      `0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 12px -5px ${tinycolor2(warningColor).setAlpha(.46).toRgbString()}`
  },
  danger: {
    backgroundColor: dangerColor,
    color: "#FFFFFF",
    boxShadow:
      `0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 12px -5px ${tinycolor2(dangerColor).setAlpha(.46).toRgbString()}`
  },
  rose: {
    backgroundColor: roseColor,
    color: "#FFFFFF",
    boxShadow:
      `0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 12px -5px ${tinycolor2(roseColor).setAlpha(.46).toRgbString()}`
  },
  transparent: {
    backgroundColor: "transparent !important",
    boxShadow: "none",
    paddingTop: "25px",
    color: "#FFFFFF"
  },
  dark: {
    color: "#FFFFFF",
    backgroundColor: `${darkColor} !important`,
    boxShadow:
      `0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 12px -5px ${tinycolor2(darkColor).setAlpha(.46).toRgbString()}`
  },
  white: {
    border: "0",
    padding: "0.625rem 0",
    marginBottom: "20px",
    color: "#555",
    backgroundColor: "#fff !important",
    boxShadow:
      "0 4px 18px 0px rgba(0, 0, 0, 0.12), 0 7px 10px -5px rgba(0, 0, 0, 0.15)"
  },
  drawerPaper: {
    border: "none",
    bottom: "0",
    transitionProperty: "top, bottom, width",
    transitionDuration: ".2s, .2s, .35s",
    transitionTimingFunction: "linear, linear, ease",
    width: drawerWidth,
    ...boxShadow,
    position: "fixed",
    display: "block",
    top: "0",
    height: "100vh",
    right: "0",
    left: "auto",
    visibility: "visible",
    overflowY: "visible",
    borderTop: "none",
    textAlign: "left",
    paddingRight: "0px",
    paddingLeft: "0",
    ...transition
  }
};

export default headerStyle;
