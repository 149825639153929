import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import productStyle from "assets/jss/material-kit-react/views/landingPageSections/productStyle.jsx";
//images
import SingleItemSelection from "../../../components/StandardPage/components/SingleItemSection";
// @material-ui/icons
// react components for routing our app without refresh
// core components

class ProductSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      nationClaimsUrl: 'https://play.google.com/store/apps/details?id=com.NationClaims',
      roadHazardTireClaims: 'https://play.google.com/store/apps/details?id=com.RoadHazardTireClaims',
      trwsClaims: 'https://play.google.com/store/apps/details?id=com.nsdmc.nsdtrwsclaims'
    }
  }

  componentDidMount() {
    if (navigator.userAgent.toLowerCase().indexOf("iphone") > -1 || navigator.userAgent.toLowerCase().indexOf("ipad") > -1) {
      this.setState({
        nationClaimsUrl: 'https://apps.apple.com/us/developer/nation-safe-drivers/id688918723',
        roadHazardTireClaims: 'https://apps.apple.com/us/developer/nation-safe-drivers/id688918723',
        trwsClaims: 'https://apps.apple.com/us/developer/nation-safe-drivers/id688918723',
      })
    }
  }

  isMobileDevice = () => {
    return (typeof window.orientation !== "undefined") || (navigator.userAgent.indexOf('IEMobile') !== -1);
  }

  render() {
    const {classes} = this.props;
    return (
      <SingleItemSelection
        productArea={"niuw"}
        altRow={false}
        imageLeft={false}
        noLearnMore={true}
        content={{
          title: "",
          image: require('../../../assets/img/banner1.jpg'),
          description: [
            "We specialize in both Personal Lines and Commercial Lines products including auto, homeowners and renters. Additionally our target Commercial Lines classes include hospitality, retail and many more classes.",
            "We provide web-based technology for rating and applications with minimal underwriting for many of our products.",
            "Our agent-partners come in all sizes, large and small. There are no minimum production requirements.",
            "Ease of use and excellent customer service separate National Insurance Underwriters from the rest."
          ]
        }}
      />
    );
  }
}

export default withStyles(productStyle)(ProductSection);
