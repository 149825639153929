import React from "react";
import PropTypes from 'prop-types';
import {makeStyles, withStyles} from '@material-ui/core/styles';
import {Grid, Hidden, Step, StepConnector, StepLabel} from '@material-ui/core';
import Fade from 'react-reveal/Fade';
import classNames from 'classnames'
import productStyle from "assets/jss/material-kit-react/views/landingPageSections/productStyle.jsx";
import {
  Assignment as FileClaimIcon,
  AssignmentTurnedIn as ClaimReviewedIcon,
  AttachMoney as ClaimPayoutIcon,
  FlipCameraAndroid as ClaimReviewIcon
} from '@material-ui/icons'
import {infoColor, primaryColor, secondaryColor} from '../../../assets/jss/material-kit-react'

var tinycolor2 = require('tinycolor2')

const ColorlibConnector = withStyles({
  alternativeLabel: {
    top: 22,
  },
  active: {
    '& $line': {
      backgroundImage:
        `linear-gradient( 95deg,${primaryColor} 0%,${tinycolor2(infoColor).darken(25).toHexString()} 50%,${secondaryColor} 100%)`,
    },
  },
  completed: {
    '& $line': {
      backgroundImage:
        `linear-gradient( 95deg,${primaryColor} 0%,${tinycolor2(infoColor).darken(25).toHexString()} 50%,${secondaryColor} 100%)`,
    },
  },
  line: {
    height: 3,
    border: 0,
    backgroundColor: '#eaeaf0',
    borderRadius: 1,
  },
})(StepConnector);

const useColorlibStepIconStyles = makeStyles({
  root: {
    backgroundColor: '#ccc',
    zIndex: 1,
    color: '#fff',
    width: 50,
    height: 50,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  active: {
    backgroundImage:
      `linear-gradient( 136deg, ${primaryColor} 0%, ${tinycolor2(infoColor).darken(25).toHexString()} 50%, ${secondaryColor} 100%)`,
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
  },
  completed: {
    backgroundImage:
      `linear-gradient( 136deg, ${primaryColor} 0%, ${tinycolor2(infoColor).darken(25).toHexString()} 50%, ${secondaryColor} 100%)`,
  },
});

function ColorlibStepIcon(props) {
  const classes = useColorlibStepIconStyles();
  const {active, completed, icon} = props;
  console.log(props)

  const icons = {
    1: <FileClaimIcon/>,
    2: <ClaimReviewIcon/>,
    3: <ClaimReviewedIcon/>,
    4: <ClaimPayoutIcon/>,
  };

  return (
    <div
      className={classNames(classes.root, {
        [classes.active]: true,
        [classes.completed]: true,
      })}
    >
      {icons[icon]}
    </div>
  );
}

ColorlibStepIcon.propTypes = {
  active: PropTypes.bool,
  completed: PropTypes.bool,
  icon: PropTypes.node,
};

class About extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      nationClaimsUrl: 'https://play.google.com/store/apps/details?id=com.NationClaims',
      roadHazardTireClaims: 'https://play.google.com/store/apps/details?id=com.RoadHazardTireClaims',
      trwsClaims: 'https://play.google.com/store/apps/details?id=com.nsdmc.nsdtrwsclaims'
    }
  }

  componentDidMount() {
    if (navigator.userAgent.toLowerCase().indexOf("iphone") > -1 || navigator.userAgent.toLowerCase().indexOf("ipad") > -1) {
      this.setState({
        nationClaimsUrl: 'https://apps.apple.com/us/developer/nation-safe-drivers/id688918723',
        roadHazardTireClaims: 'https://apps.apple.com/us/developer/nation-safe-drivers/id688918723',
        trwsClaims: 'https://apps.apple.com/us/developer/nation-safe-drivers/id688918723',
      })
    }
  }

  isMobileDevice = () => {
    return (typeof window.orientation !== "undefined") || (navigator.userAgent.indexOf('IEMobile') !== -1);
  }

  render() {
    const {classes} = this.props;
    const steps = [
      {
        title: 'File Your Claim',
        details: (
          <React.Fragment>
            Start your claim online by going to <a href={"/claims-center-products"}>products</a> and choosing the
            type of claim you wish to file. We recommend
            using the NSD Mobile App which is available for both Apple and Android OS. You can also file your claim by
            calling <a href={"8886849327"}> 888-684-9327</a> M-F 8:30 a.m. ET to 8 p.m. ET (Tire/Wheel/Key) or 5 p.m. ET
            for all other claims.
          </React.Fragment>
        )
      },
      {
        title: 'We Will Review It',
        details: (
          <React.Fragment>
            We will ask you some simple questions and review your answers to determine if the incident is covered. We
            will assess the damage, and we will ask you for some basic documents such as receipts, etc. Sometimes we
            might ask for photos, and we will guide you through the process. We will get in touch with you if we need
            more information, to explain any coverage questions, or to discuss next steps.
          </React.Fragment>
        )
      },
      // {
      //   title: 'Get Your Estimates',
      //   details: (
      //     <React.Fragment>
      //       We’ll provide you with estimate and repair options so you can make an informed decision.
      //     </React.Fragment>
      //   )
      // },
      // {
      //   title: 'Select a Repair Facility',
      //   details: (
      //     <React.Fragment>
      //       Unless we are dispatching repair services, you may in most cases choose the repair facility. We recommend
      //       that you return to the dealership where you purchased your vehicle, but in most cases, you are free to take
      //       it anywhere you like. Please see your contract for limitations. (will this cause confusion with Key claims?)
      //     </React.Fragment>
      //   )
      // },
      // {
      //   title: 'Receive Your Benefits',
      //   details: (
      //     <React.Fragment>
      //       Once we verify coverage, claim resolution is based on the terms of your contract. We may dispatch service,
      //       pay the repairer or lienholder directly, send payment to your bank account, or send payment to you by mail.
      //       (MPS?)
      //     </React.Fragment>
      //   )
      // }
    ]
    return (
      <div className={classes.section}>
        <Grid container justify="center">
          <Grid item style={{minHeight: 500}} xs={12} sm={12} md={12}>
            <div className={classes.container} style={{padding: 50}}>
              <div style={{textAlign: 'center'}}>
                <h2 className={classes.title}>Understand Our Claim Process</h2>
              </div>
              <div ref={this.refOurProcess} id="our-process-back" style={{margin: -100}}></div>
              <div id="our-process" style={{margin: 100}}></div>
              <Fade bottom>
                <Grid container spacing={4} style={{paddingRight: '10%', paddingLeft: '10%'}}>
                  <Hidden smDown>
                    <Grid item xs={12} style={{marginBottom: -44}}>
                      <hr
                        style={{
                          width: '53%',
                          height: 2,
                          border: 0,
                          // backgroundColor: tinycolor2(infoColor).darken(25).toHexString(),
                          backgroundImage:
                            `linear-gradient( 95deg,${primaryColor} 0%,${tinycolor2(infoColor).darken(25).toHexString()} 50%,${secondaryColor} 100%)`,
                          margin: ' 32px auto 0 auto'
                        }}
                      />
                    </Grid>
                  </Hidden>
                  {
                    steps.map((step, index) => (
                      <Grid item xs={12} sm={12} md={6} style={{marginTop: -17}}>
                        <Hidden smDown>
                          <div style={{display: 'flex', justifyContent: 'center'}}>
                            <Step>
                              <StepLabel StepIconComponent={ColorlibStepIcon}
                                         icon={`${(index + 1).toString()}`}></StepLabel>
                            </Step>
                          </div>
                          <div style={{display: 'flex', justifyContent: 'center'}}>
                            <h5 className={classes.title}>{step.title}</h5>
                          </div>
                          <div style={{color: '#999', textAlign: 'left'}}>
                            {step.details}
                          </div>
                        </Hidden>
                        <Hidden mdUp>
                          <div style={{display: 'flex', justifyContent: 'flex-start', flexDirection: 'row'}}>
                            <div style={{flex: 1, display: 'flex', justifyContent: 'flex-end', marginRight: 25}}>
                              <Step>
                                <StepLabel StepIconComponent={ColorlibStepIcon}
                                           icon={`${(index + 1).toString()}`}></StepLabel>
                              </Step>
                            </div>
                            <div style={{width: '75%', marginTop: -15}}>
                              <div style={{textAlign: 'left'}}>
                                <h5 className={classes.title}>{step.title}</h5>
                              </div>
                              <div style={{color: '#999', textAlign: 'left'}}>
                                {step.details}
                              </div>
                            </div>
                          </div>
                        </Hidden>
                      </Grid>
                    ))
                  }
                </Grid>
              </Fade>
              <br/>
              <h3 className={classes.title}>
                Any questions? Please give our Claims Center a call today.
                <a href={"tel:18886849327"}> 1-888-684-9327.</a>
                <br/>
                M-F 8:30 a.m. ET to 8 p.m. ET (Tire/Wheel/Key) or 5 p.m. ET for all other claims.
              </h3>
            </div>
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default withStyles(productStyle)(About);
