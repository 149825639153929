import React, {useEffect} from "react";
import {makeStyles} from "@material-ui/core/styles";
import StandardPage from "../../components/StandardPage/StandardPage";
import Industries from "./sections/Industries";
import {secondaryColor} from "../../assets/jss/material-kit-react";
import Advantages from "./sections/Advantages";
import RoadsideAssistanceFutureHeaderLinks from "../../components/Header/RoadsideAssistanceFutureHeaderLinks.jsx";
import MobileApp from "./sections/MobileApp";
import {Helmet} from "react-helmet";
import 'core-js/es/object'
import 'core-js/es/string'

var tinycolor2 = require('tinycolor2');

const useStyles = makeStyles(theme => ({
  root: {}
}));

const FutureOfRoadsideAssistanceLandingPage = (props) => {

  useEffect(() => {
    let path = window.location.href;
    if (path.indexOf("/landing-page/#") > -1) {
      let location = path.split("#")[path.split("#").length - 1];
      let top = this.refBusinessValue.current.getBoundingClientRect().top;
      
      window.scrollTo({
        top: top,
        left: 0,
        behavior: 'smooth'
      });
    }
  }, [])

  return (
    <StandardPage
      futureRoadside={true}
      title={"WELCOME TO THE FUTURE OF ROADSIDE ASSISTANCE"}
      description={"The road more profitable starts here"}
      image={require("assets/img/main1.jpg")}
      headerLinks={RoadsideAssistanceFutureHeaderLinks}
    >
      <Helmet>
        <meta charSet="utf-8"/>
        <title>{`NSD FUTURE OF ROADSIDE ASSISTANCE`}</title>
        <meta name="description"
              content={`Download our mobile app and use it any time you are in need of assistance. Our application is simple and easy to use. It provides you with everything you need in order to stay in contact with your driver and anyone else that needs to stay up to date on your current situation when things get tough out on the road.`}/>
        <link rel="canonical" href={window.location.href}/>
      </Helmet>
      <div style={{backgroundColor: tinycolor2(secondaryColor).brighten(15).toHexString()}}>
        <MobileApp/>
        <Industries/>
        <div style={{backgroundColor: tinycolor2(secondaryColor).brighten(45).toHexString()}}>
          <Advantages/>
        </div>
      </div>
    </StandardPage>
  )
}

export default FutureOfRoadsideAssistanceLandingPage;
