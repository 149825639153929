import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import {Card, CardContent, Grid} from '@material-ui/core';
import Button from "components/CustomButtons/Button.jsx";
import productStyle from "../../../assets/jss/material-kit-react/views/landingPageSections/productStyle.jsx";
import Fade from 'react-reveal/Fade';
import mobileImg from 'assets/img/mobile-640.png';
import content from '../../../content/LandingPage/Sections/productSection';

class ProductSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      nationClaimsUrl: 'https://play.google.com/store/apps/details?id=com.NationClaims',
      roadHazardTireClaims: 'https://play.google.com/store/apps/details?id=com.RoadHazardTireClaims',
      trwsClaims: 'https://play.google.com/store/apps/details?id=com.nsdmc.nsdtrwsclaims'
    }
  }

  componentDidMount() {
    if (navigator.userAgent.toLowerCase().indexOf("iphone") > -1 || navigator.userAgent.toLowerCase().indexOf("ipad") > -1) {
      this.setState({
        nationClaimsUrl: 'https://apps.apple.com/us/developer/nation-safe-drivers/id688918723',
        roadHazardTireClaims: 'https://apps.apple.com/us/developer/nation-safe-drivers/id688918723',
        trwsClaims: 'https://apps.apple.com/us/developer/nation-safe-drivers/id688918723',
      })
    }
  }

  isMobileDevice = () => {
    return (typeof window.orientation !== "undefined") || (navigator.userAgent.indexOf('IEMobile') !== -1);
  }

  IE = () => {
    return navigator.userAgent.indexOf('MSIE 10.0') >= 0 || (navigator.userAgent.indexOf('Windows NT') >= 0 && navigator.userAgent.indexOf('rv:11.0') >= 0)
  }

  IETen = () => {
    return navigator.userAgent.indexOf('MSIE 10.0') >= 0
  }

  render() {
    const {classes} = this.props;
    return (
      <div className={classes.section}>
        {this.IE() ? 'IE' : 'not IE'}
        <Grid container justify="center">
          <Grid item style={{minHeight: 500}} xs={12} sm={12} md={12}>
            <div className={classes.container} style={{padding: 50}}>
              <div style={{textAlign: 'center'}}>
                <h2 className={classes.title}>Access our Applications</h2>
                <h3 className={classes.title}>
                  Any questions? Please give your representative a call today.
                  <a href={"tel:18003382680"}> 1-800-338-2680</a></h3>
                <br/>
              </div>
              <div ref={this.refOurProcess} id="our-process-back" style={{margin: -100}}></div>
              <div id="our-process" style={{margin: 100}}></div>
              {/*<Fade bottom>*/}
              <Grid container justify="center" style={{width: '100%', height: this.IE() ? 371 : '100%'}}>
                <Grid item lg={2} md={2} sm={12} xs={12}
                      style={{
                        padding: 15,
                        display: 'flex',
                        alignContent: 'center',
                        alignItems: 'center',
                        flexDirection: 'column'
                      }}>
                  <Card style={{width: '100%', flex: 1, height: '100%'}}>
                    <CardContent style={this.IETen() ? {
                      padding: 25,
                      height: '100%',
                      justifyContent: 'center',
                      alignContent: 'center',
                      alignItems: 'center',
                      flexDirection: 'column'
                    } : {
                      padding: 25,
                      display: 'flex',
                      height: '100%',
                      justifyContent: 'center',
                      alignContent: 'center',
                      alignItems: 'center',
                      flexDirection: 'column'
                    }}>
                      <h4 className={classes.title}>{content.documentSearchName}</h4><br/>
                      <div style={{
                        width: '100%',
                        display: 'flex',
                        height: '100%',
                        justifyContent: 'center',
                        alignContent: 'center',
                        alignItems: 'center',
                        flex: 1
                      }}>
                        <a href={"/login-page/Document ID"} style={{width: '100%'}}>
                          <Button color="primary" outlined fullWidth>Log in</Button>
                        </a>
                      </div>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item lg={2} md={2} sm={12} xs={12}
                      style={{
                        padding: 15,
                        display: 'flex',
                        alignContent: 'center',
                        alignItems: 'center',
                        flexDirection: 'column'
                      }}>
                  <Card style={{width: '100%', flex: 1, height: '100%'}}>
                    <CardContent style={this.IETen() ? {
                      padding: 25,
                      height: '100%',
                      justifyContent: 'center',
                      alignContent: 'center',
                      alignItems: 'center',
                      flexDirection: 'column'
                    } : {
                      padding: 25,
                      display: 'flex',
                      height: '100%',
                      justifyContent: 'center',
                      alignContent: 'center',
                      alignItems: 'center',
                      flexDirection: 'column'
                    }}>
                      <h4 className={classes.title}>{content.nationsRemoteDataEntryName}</h4><br/>
                      <div style={{
                        width: '100%',
                        display: 'flex',
                        height: '100%',
                        justifyContent: 'center',
                        alignContent: 'center',
                        alignItems: 'center',
                        flex: 1
                      }}>
                        <a href={"https://nrde.com/"} style={{width: '100%'}}>
                          <Button color="primary" outlined fullWidth>Log In</Button>
                        </a>
                      </div>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item lg={2} md={2} sm={12} xs={12}
                      style={{
                        padding: 15,
                        display: 'flex',
                        alignContent: 'center',
                        alignItems: 'center',
                        flexDirection: 'column'
                      }}>
                  <Card style={{width: '100%', flex: 1, height: '100%'}}>
                    <CardContent style={this.IETen() ? {
                      padding: 25,
                      height: '100%',
                      justifyContent: 'center',
                      alignContent: 'center',
                      alignItems: 'center',
                      flexDirection: 'column'
                    } : {
                      padding: 25,
                      display: 'flex',
                      height: '100%',
                      justifyContent: 'center',
                      alignContent: 'center',
                      alignItems: 'center',
                      flexDirection: 'column'
                    }}>
                      <h4 className={classes.title}>{content.supplySystemName}</h4><br/>
                      <div style={{
                        width: '100%',
                        display: 'flex',
                        height: '100%',
                        justifyContent: 'center',
                        alignContent: 'center',
                        alignItems: 'center',
                        flex: 1
                      }}>
                        <a href={"/login-page/Supply System"} style={{width: '100%'}}>
                          <Button color="primary" outlined fullWidth>Log In</Button>
                        </a>
                      </div>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item lg={2} md={2} sm={12} xs={12}
                      style={{
                        padding: 15,
                        display: 'flex',
                        alignContent: 'center',
                        alignItems: 'center',
                        flexDirection: 'column'
                      }}>
                  <Card style={{width: '100%', flex: 1, height: '100%'}}>
                    <CardContent style={this.IETen() ? {
                      padding: 25,
                      height: '100%',
                      justifyContent: 'center',
                      alignContent: 'center',
                      alignItems: 'center',
                      flexDirection: 'column'
                    } : {
                      padding: 25,
                      display: 'flex',
                      height: '100%',
                      justifyContent: 'center',
                      alignContent: 'center',
                      alignItems: 'center',
                      flexDirection: 'column'
                    }}>
                      <h4 className={classes.title} style={{alignSelf: 'stretch'}}>{content.brokerServicesName}</h4>
                      <br/>
                      <div style={{
                        width: '100%',
                        display: 'flex',
                        height: '100%',
                        justifyContent: 'center',
                        alignContent: 'center',
                        alignItems: 'center',
                        flex: 1
                      }}>
                        <a href={"/login-page/NSD Broker Services"} style={{width: '100%'}}>
                          <Button color="primary" outlined fullWidth>Log In</Button>
                        </a>
                      </div>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item lg={2} md={2} sm={12} xs={12}
                      style={{
                        padding: 15,
                        display: 'flex',
                        alignContent: 'center',
                        alignItems: 'center',
                        flexDirection: 'column'
                      }}>
                  <Card style={{width: '100%', flex: 1, height: '100%'}}>
                    <CardContent style={this.IETen() ? {
                      padding: 25,
                      height: '100%',
                      justifyContent: 'center',
                      alignContent: 'center',
                      alignItems: 'center',
                      flexDirection: 'column'
                    } : {
                      padding: 25,
                      display: 'flex',
                      height: '100%',
                      justifyContent: 'center',
                      alignContent: 'center',
                      alignItems: 'center',
                      flexDirection: 'column'
                    }}>
                      <h4 className={classes.title}>Claims</h4><br/>
                      <div style={this.IETen() ? {
                        width: '100%',
                        height: '100%',
                        justifyContent: 'center',
                        alignContent: 'center',
                        alignItems: 'center',
                        flexDirection: 'column',
                        flex: 1
                      } : {
                        width: '100%',
                        display: 'flex',
                        height: '100%',
                        justifyContent: 'center',
                        alignContent: 'center',
                        alignItems: 'center',
                        flexDirection: 'column',
                        flex: 1
                      }}>
                        <a href={"/login-page/Leinholder"} style={this.IE() ? {width: 130} : {width: '100%'}}>
                          <Button color="primary" outlined fullWidth>Leinholder</Button>
                        </a>
                        <a href={"/login-page/Producer"} style={this.IE() ? {width: 130} : {width: '100%'}}>
                          <Button color="primary" outlined fullWidth>Producer</Button>
                        </a>
                        <a href={"/login-page/Member"} style={this.IE() ? {width: 130} : {width: '100%'}}>
                          <Button color="primary" outlined fullWidth>Member</Button>
                        </a>
                      </div>
                    </CardContent>
                  </Card>
                </Grid>
                {
                  this.isMobileDevice() ?
                    <Grid item lg={4} md={4} sm={12} xs={12} style={{
                      display: 'flex',
                      padding: 15,
                      alignContent: 'center',
                      alignItems: 'center',
                      flexDirection: 'column'
                    }}>
                      <Card style={{width: '100%', flex: 1, height: '100%'}}>
                        <CardContent style={this.IETen() ? {
                          padding: 25,
                          height: '100%',
                          justifyContent: 'center',
                          alignContent: 'center',
                          alignItems: 'center',
                          flexDirection: 'column'
                        } : {
                          padding: 25,
                          display: 'flex',
                          height: '100%',
                          justifyContent: 'center',
                          alignContent: 'center',
                          alignItems: 'center',
                          flexDirection: 'column'
                        }}>
                          <h4 className={classes.title}>{content.mobileApplicationNames}</h4><br/>
                          <div>
                            <img src={mobileImg} style={{minHeight: 150, maxWidth: '100%', height: 210}}/>
                          </div>
                          <div style={{
                            padding: 15,
                            width: '100%',
                            display: 'flex',
                            height: '100%',
                            justifyContent: 'center',
                            alignContent: 'center',
                            alignItems: 'center',
                            flex: 1,
                            flexDirection: 'column'
                          }}>
                            <Button onClick={() => {
                              window.open(this.state.nationClaimsUrl, "_blank")
                            }} style={{margin: 5}} color="primary" outlined
                                    fullWidth>{content.nationClaimAppName}</Button>
                            <Button onClick={() => {
                              window.open(this.state.roadHazardTireClaims, "_blank")
                            }} style={{margin: 5}} color="secondary" outlined
                                    fullWidth>{content.roadHazardClaims}</Button>
                            <Button color="info" outlined onClick={() => {
                              window.open(this.state.trwsClaims, "_blank")
                            }} style={{margin: 5}} fullWidth>{content.trwsAppName}</Button>
                          </div>
                        </CardContent>
                      </Card>
                    </Grid>
                    :
                    null
                }
              </Grid>
              {/*</Fade>*/}
            </div>
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default withStyles(productStyle)(ProductSection);
