import React from "react";
import {Grid} from "@material-ui/core";
import Container from "@material-ui/core/Container";
import CenterItems from "../CenterItems";
import Brochures from "../Brochures";
import withStyles from "@material-ui/core/styles/withStyles";
import productDetailsStyle from '../../../assets/jss/material-kit-react/components/productDetails.jsx'

const SideBySideLayout = ({ classes, product }) => {
    return (
        <>
          <Container className={classes.sideBySide}>
            <h1>{product.mainSection?.title}</h1>
            <div>
              <div style={{flex:4}}>
                {product.mainSection?.left}
                  <div className={classes.container}>
                      <div className={classes.titleImageContainer}>
                      <img className={classes.titleImage} src={product.detailImage || product.image}/>>
                      </div>
                      <div className={classes.title} style={{width: '100%'}}>
                      <h2 className={classes.description}>{product.name}</h2>
                      </div>
                      <Grid container justify={"center"}>
                      {
                          product.brochures && <Brochures brochures={product.brochures} />
                      }
                      </Grid>
                  </div>              
              </div>
              <div>
                {product.mainSection?.right(classes)}
              </div>              
            </div>
          </Container>   
        </>
    );
};

export default withStyles(productDetailsStyle)(SideBySideLayout);