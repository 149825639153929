import React, {useState} from "react";
// nodejs library that concatenates classes
import withStyles from "@material-ui/core/styles/withStyles";

import landingPageStyle from "assets/jss/material-kit-react/views/landingPage.jsx";
import SingleItemSection from "../../components/StandardPage/components/SingleItemSection";
import content from '../../content/NIUW/Sections/our_partners_main'
import StandardPage from "../../components/StandardPage/StandardPage";
import NIUWHeaderLinks from "../../components/Header/NIUWHeaderLinks";
import niu_partner from "../../content/NIUW/Sections/niu_partner";
import aamga_partner from "../../content/NIUW/Sections/aamga_partner";
import ambest_partner from "../../content/NIUW/Sections/ambest_partner";
import supporting_partners from "../../content/NIUW/Sections/supporting_partners";
import {Helmet} from "react-helmet";

function NIUWProducts({...props}) {
  const {classes, ...rest} = props;
  const [contentItems] = useState([
    {
      ...niu_partner
    },
    {
      ...aamga_partner
    },
    {
      ...ambest_partner
    },
    {
      ...supporting_partners
    }
  ]);

  return (
    <StandardPage
      title={content.title}
      niuWLogo={true}
      headerLinks={NIUWHeaderLinks}
      description={content.description}
      image={require("assets/img/insurance-partners-bg.jpg")}
    >
      <Helmet>
        <meta charSet="utf-8"/>
        <title>{`NIU Our Partners`}</title>
        <meta name="description"
              content={`Nation Safe Drivers (NSD) has been offering towing and roadside assistance programs, along with dozens of other ancillary products for over 50 years. It's one of the nation's largest suppliers of towing and roadside assistance, as well as additional supplemental insurance-related products.`}/>
        <link rel="canonical" href={window.location.href}/>
      </Helmet>
      {
        contentItems && contentItems.map((content, index) => {
          console.log()
          return (
            <div>
              <SingleItemSection
                noLearnMore={true}
                productArea={"automotive"}
                altRow={index % 2 === 1}
                imageLeft={index % 2 !== 1}
                content={content}
              />
            </div>
          )
        })
      }
    </StandardPage>
  );
}

export default withStyles(landingPageStyle)(NIUWProducts);
