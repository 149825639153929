import React, {useEffect, useRef, useState} from "react";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import Header from "../../components/Header/Header.jsx";
import HeaderLinks from "components/Header/HeaderLinks.jsx";
import pageStyle from "../../assets/jss/material-kit-react/components/standardPage.jsx";
import Footer from "../Footer/Footer";
import Parallax from "../Parallax/Parallax";
import {secondaryColor} from '../../assets/jss/material-kit-react'
import {Grid} from '@material-ui/core';
import classNames from 'classnames';

var tinycolor = require("tinycolor2");

function StandardPage({...props}) {
  const {
    classes,
    children,
    className,
    title,
    description,
    image,
    headerLinks,
    niuWLogo,
    headerImageList,
    futureRoadside,
    ...rest
  } = props;
  const [highlightHeader, setHighlightHeader] = useState("");
  const [topText, setTopText] = useState(400);
  const pageTitle = useRef();
  const imageRotation = 4000;
  const [rotationImage, setRotationImage] = useState(null);
  const [currentImageIndex, setCurrentImageIndex] = useState(1);

  const listenScrollEvent = e => {
    setTopText(pageTitle.current.getBoundingClientRect().y)
  }

  useEffect(() => {

    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
    window.addEventListener('scroll', listenScrollEvent);
    setTopText(pageTitle.current.getBoundingClientRect().top);

    if (headerImageList) {
      setTimeout(() => {
        let newIndex = currentImageIndex;
        if (currentImageIndex + 1 >= headerImageList.length) {
          newIndex = 0
        } else {
          newIndex++;
        }
        setCurrentImageIndex(newIndex);
      }, imageRotation)
    }

    return () => {
      window.removeEventListener('scroll', listenScrollEvent);
    }
  }, []);

  useEffect(() => {
    if (headerImageList) {
      setRotationImage(headerImageList[currentImageIndex]);
      setTimeout(() => {
        let newIndex = currentImageIndex;
        if (currentImageIndex + 1 >= headerImageList.length) {
          newIndex = 0
        } else {
          newIndex++;
        }
        setCurrentImageIndex(newIndex);
      }, imageRotation)

    }
  }, [currentImageIndex])

  let HeaderComponent = headerLinks || HeaderLinks;

  return (
    <div className={classes.main}>
      <Header
        color="transparent"
        brand="NSD"
        futureRoadside={futureRoadside}
        rightLinks={<HeaderComponent highlighted={highlightHeader}/>}
        fixed
        niuWLogo={niuWLogo}
        changeColorOnScroll={{
          height: topText,
          color: "dark"
        }}
        {...rest}
      />
      {
        image ?
          <Parallax filter image={rotationImage || image} style={{minHeight: 750}}>
            <div className={classes.container} style={{paddingLeft: '10%'}}>
              <Grid style={{marginTop: 0}}>
                <Grid xs={12} sm={12} md={6}>
                  <div style={{marginTop: -65}}>
                    <h1 ref={pageTitle} className={classes.title}>{title}</h1>
                    <h4>
                      {description}
                    </h4>
                    <br/>
                  </div>
                </Grid>
              </Grid>
            </div>
          </Parallax>
          :
          <div className={classes.container} style={{
            height: 350,
            paddingLeft: 50,
            backgroundColor: tinycolor(secondaryColor).darken(15).toHexString()
          }}>
            <Grid container style={{marginTop: 0}}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}
                    style={{
                      height: 350,
                      position: 'relative',
                      display: 'flex',
                      alignContent: 'center',
                      alignItems: 'center',
                      justifyContent: 'center'
                    }}>
                <h1 ref={pageTitle} className={classes.title} style={{position: 'absolute', bottom: 85}}></h1>
                <br/>
              </Grid>
            </Grid>
          </div>
      }
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container}>
          {children}
        </div>
      </div>
      <Footer niuw={niuWLogo} futureRoadside={futureRoadside}/>
    </div>
  );
}

StandardPage.defaultProps = {
  className: ""
};

StandardPage.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.node,
  className: PropTypes.string
};

export default withStyles(pageStyle)(StandardPage);
