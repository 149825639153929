import React, {useEffect} from "react";
import PropTypes from "prop-types";

function AtlassianServiceDeskChatWidget({ ...props }) {
    const {widgetKey, ...rest} = props;

    useEffect(() => {
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.async = true;
        script.onload = function(){
            var DOMContentLoaded_event = document.createEvent("Event");
            DOMContentLoaded_event.initEvent("DOMContentLoaded", true, true);
            window.document.dispatchEvent(DOMContentLoaded_event);
        };
        script.src = "https://jsd-widget.atlassian.com/assets/embed.js";
        script.setAttribute("data-base-url", "https://jsd-widget.atlassian.com");
        script.setAttribute("data-key", widgetKey);
        script.setAttribute("data-jsd-embedded", null);
        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        }
    });

    return null;
}

AtlassianServiceDeskChatWidget.propTypes = {
    widgetKey: PropTypes.string.isRequired
};

export default AtlassianServiceDeskChatWidget;
