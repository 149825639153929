import React, { useState } from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import {Grid, Card, CardMedia, CardContent, CardActions} from '@material-ui/core';
import teamStyle from "../../../assets/jss/material-kit-react/views/landingPageSections/nsdTeamStyle.jsx";
import content from '../../../content/LandingPage/Sections/team';
import clsx from 'clsx';

const LinkedInIcon = withStyles(teamStyle)(({ classes }) => {
  return (
    <div className={classes.iconContainer}>
      <svg className={classes.icon} viewBox="0 0 448 512">
        <path 
          className={classes.linkedInPath}
          fill="#fff"
          d="M100.28 448H7.4V148.9h92.88zM53.79 108.1C24.09 108.1 0 83.5 0 53.8a53.79 53.79 0 0 1 107.58 0c0 29.7-24.1 54.3-53.79 54.3zM447.9 448h-92.68V302.4c0-34.7-.7-79.2-48.29-79.2-48.29 0-55.69 37.7-55.69 76.7V448h-92.78V148.9h89.08v40.8h1.3c12.4-23.5 42.69-48.3 87.88-48.3 94 0 111.28 61.9 111.28 142.3V448z" class="">
        </path>
      </svg>
    </div>
  )
});

const GridItem = withStyles(teamStyle)(({classes, person}) => {
    const [isHovered, setIsHovered] = useState(false);
    return (
        <Grid item xs={12} sm={6} md={3}>
            <Card
              className={
                clsx(
                  classes.teamMemberCard, 
                  { [classes.teamMemberCardHover]: person.linkedInProfile }
                )
              }
              onMouseOver={() => setIsHovered(true)} 
              onMouseOut={() => setIsHovered(false)}
            >
                {
                  person.linkedInProfile && (
                    <a
                      onClick={() => setIsHovered(false)}
                      target="_blank"
                      href={`https://www.linkedin.com/in/${person.linkedInProfile}`}
                      className={clsx(classes.cardOverlay, { [classes.hidden]: !isHovered })}
                    >
                      <LinkedInIcon />
                    </a>
                  )
                }
                <CardMedia
                    className={classes.media}
                    image={person.image}
                    title={person.name}
                />
                <CardContent>
                    <h4 className={classes.personName}>{person.name}</h4>
                    <h6 className={classes.jobTitle}>{person.jobTitle}</h6>
                </CardContent>
            </Card>
        </Grid>
    );
});

class Careers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      expanded: false
    }
  }

  render() {
    const {classes} = this.props;
    return (
      <div className={classes.section}>
        <div>
          <Grid container className={classes.main}>
            <Grid item xs={12} sm={12} md={12}>
              <h2 className={classes.title}>{content.title}</h2>
              <Grid container spacing={6}>
                  {
                        content.people.map((person) => <GridItem person={person} />)
                  }
              </Grid>
            </Grid>
          </Grid>

        </div>
      </div>
    );
  }
}

export default withStyles(teamStyle)(Careers);
