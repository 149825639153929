import React, {useEffect, useState} from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import landingPageStyle from "assets/jss/material-kit-react/views/landingPage.jsx";
import StandardPage from "../../components/StandardPage/StandardPage";

import content from '../../content/InsurancePartners/main'
import insuranceContent from '../../content/InsurancePartners/sections/insurance';
import protectionContent from '../../content/InsurancePartners/sections/protection';
import servicesContent from '../../content/InsurancePartners/sections/services';
import travelContent from '../../content/InsurancePartners/sections/travel';
import MultiItemSection from "../../components/StandardPage/components/MultiItemSection";
import {Helmet} from "react-helmet";

function InsurancePartners({...props}) {
  const {classes, ...rest} = props;
  const [contentItems, setContentItems] = useState([
    {
      ...protectionContent
    }, {
      ...servicesContent
    }, {
      ...travelContent
    }, {
      ...insuranceContent
    }
  ]);

  useEffect(() => {

  }, [])

  return (
    <StandardPage
      title={content.title}
      description={content.description}
      image={require("assets/img/insurance-partners-bg.jpg")}
    >
      <Helmet>
        <meta charSet="utf-8"/>
        <title>{`NSD Insurance Partners`}</title>
        <meta name='keywords' content='Protections,Services,Assistance,Insurance,Partners,Agent'/>
        <meta name="description"
              content={`Nation Safe Drivers (NSD) offers Insurance Company Solutions that increase policyholder retention, build loyalty and help you manage costs to keep you competitive. From private labeled Motor Club services, Accident Towing to Deductible Reimbursement and Hospital Indemnity, NSD has a solution just right for you!`}/>
        <link rel="canonical" href={window.location.href}/>
      </Helmet>
      {
        contentItems && contentItems.map((content, index) => {
          return (
            <div>
              <MultiItemSection
                productArea={"insurancePartners"}
                altRow={index % 2 === 1}
                title={content.title}
                items={content.items}
              />
            </div>
          )
        })
      }
    </StandardPage>
  );
}

export default withStyles(landingPageStyle)(InsurancePartners);
