import person from 'assets/img/team/michaelWiener.png';
import tomWarsop from 'assets/img/team/tomWarsop.png';
import rajivAmar from 'assets/img/team/rajivAmar.png';
import michaelSothen from 'assets/img/team/michaelSothen.png';
import jacAlvarez from 'assets/img/team/jacAlvarez.png';
import jeffWiener from 'assets/img/team/jeffWiener.png';
import trevorMoore from 'assets/img/team/trevorMoore.png';
import jonathanBorowka from 'assets/img/team/jonathanBorowka.png';
import craigCalver from 'assets/img/team/craigCalver.jpg';
import michaelMachado from 'assets/img/team/michaelMachado.png';
import ericSharfstein from 'assets/img/team/ericSharfstein.png';
import wandaLopez from 'assets/img/team/wandaLopez.png';
import laurenSmith from 'assets/img/team/laurenSmith.png';
import debraAmar from 'assets/img/team/debraAmar.jpg';
import lisaClark from 'assets/img/team/lisaClark.jpg';
import joDreiling from 'assets/img/team/joDreiling.jpg';
import stevenTrammell from 'assets/img/team/stevenTrammell.jpg';
import barryCunningham from 'assets/img/team/barryCunningham.jpg';

export default {
  title: 'Our Leadership Team',
  people: [
    // {
    //   name: 'Thomas Warsop',
    //   jobTitle: 'Chief Executive Officer',
    //   image: tomWarsop,
    //   linkedInProfile: 'thomas-warsop-792185',
    // },
    {
      name: 'Rajiv Amar',
      jobTitle: 'Chief Operating Officer',
      image: rajivAmar,
      linkedInProfile: 'rajivamar',
    },
    {
      name: 'Michael Sothen',
      jobTitle: 'Chief Financial Officer',
      image: michaelSothen,
      linkedInProfile: 'michael-sothen-56643951',
    },
    {
      name: 'Jac Alvarez',
      jobTitle: 'Senior Vice-President Marketing',
      image: jacAlvarez,
      linkedInProfile: 'jac-alvarez-0044788',
    },
    {
      name: 'Lisa Clark',
      jobTitle: 'Executive Vice President of Finance',
      image: lisaClark,
      linkedInProfile: 'lisa-clark-b339651b',
    },
    {
      name: 'Jeff Wiener',
      jobTitle: 'Senior Vice-President of Network/Call Center Operations',
      image: jeffWiener,
      linkedInProfile: 'jeffrey-wiener-37920a110',
    },
    // {
    //   name: 'Trevor Moore',
    //   jobTitle: 'Senior Vice-President Technology',
    //   image: trevorMoore,
    //   linkedInProfile: 'trevor-moore-45029770',
    // },
    // {
    //   name: 'Michael Machado',
    //   jobTitle: 'Automotive Director',
    //   image: michaelMachado,
    //   linkedInProfile: 'michael-machado-07a815bb',
    // },
    {
      name: 'Jo Dreiling',
      jobTitle: 'Insurance Director',
      image: joDreiling,
      linkedInProfile: '',
    },
    {
      name: 'Barry Cunningham',
      jobTitle: 'Director of Marketing',
      image: barryCunningham,
      linkedInProfile: '',
    },
    {
      name: 'Eric Sharfstein',
      jobTitle: 'Claims Director',
      image: ericSharfstein,
      linkedInProfile: 'eric-sharfstein-5a473a201',
    },
    {
      name: 'Wanda Lopez',
      jobTitle: 'Human Resources Director',
      image: wandaLopez,
      linkedInProfile: 'wanda-lopez-phr-shrm-cp-3256a771',
    },
    {
      name: 'Lauren Smith',
      jobTitle: 'Director of Community Outreach and Social Impact',
      image: laurenSmith,
      linkedInProfile: 'lauren-smith-51778475',
    },
    {
      name: 'Jonathan Borowka',
      jobTitle: 'Site Director Call Center Operations',
      image: jonathanBorowka,
      linkedInProfile: 'jon-borowka-31092713',
    },
    {
      name: 'Steven Trammell',
      jobTitle: 'Site Director Call Center Operations',
      image: stevenTrammell,
      linkedInProfile: 'steventrammell',
    },
  ],
};
