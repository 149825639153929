import React from "react";

export default {
  image: require('../../../assets/img/nationsafedrivers.jpg'),
  description: [
    (
      <React.Fragment>
        <a href={"/"} target={"_blank"}> Nation Safe Drivers (NSD)</a> has been offering towing and roadside assistance
        programs, along
        with dozens of other
        ancillary products for over 50 years. It's one of the nation's largest suppliers of towing and roadside
        assistance, as well as additional supplemental insurance-related products.
      </React.Fragment>
    )
  ]
}
