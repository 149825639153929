import React, {useState} from "react";
// nodejs library that concatenates classes
import withStyles from "@material-ui/core/styles/withStyles";

import landingPageStyle from "assets/jss/material-kit-react/views/landingPage.jsx";
import SingleItemSection from "../../components/StandardPage/components/SingleItemSection";
import content from '../../content/NIUW/Sections/client_center_main'
import StandardPage from "../../components/StandardPage/StandardPage";
import client_center from "../../content/NIUW/Sections/client_center";
import NIUWHeaderLinks from "../../components/Header/NIUWHeaderLinks";
import {Helmet} from "react-helmet";

function NIUWProducts({...props}) {
  const {classes, ...rest} = props;
  const [contentItems] = useState([
    {
      ...client_center
    }
  ]);

  return (
    <StandardPage
      title={content.title}
      niuWLogo={true}
      headerLinks={NIUWHeaderLinks}
      description={content.description}
      image={require("assets/img/background-car-dealer.jpg")}
    >
      <Helmet>
        <meta charSet="utf-8"/>
        <title>{`NIU Client Center`}</title>
        <meta name="description"
              content={`National Insurance Underwriters (NIU) is a full service MGA and is an affiliate of the Nation Safe Drivers (NSD) group of companies. NIU currently manages automobile insurance program for Occidental Fire and Casualty Company of North Carolina in Colorado and Maryland. Additionally, NIU markets homeowners, renters, commercial, agent error and omissions and a variety of niche products nationwide.`}/>
        <link rel="canonical" href={window.location.href}/>
      </Helmet>
      {
        contentItems && contentItems.map((content, index) => {
          console.log()
          return (
            <div>
              <SingleItemSection
                noLearnMore={true}
                productArea={"automotive"}
                altRow={index % 2 === 1}
                imageLeft={index % 2 !== 1}
                content={content}
              />
            </div>
          )
        })
      }
    </StandardPage>
  );
}

export default withStyles(landingPageStyle)(NIUWProducts);
