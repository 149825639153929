import React from "react";

export default {
  "title": "Careers",
  "items": [
    {
      "name": "Careers",
      id: "careers",
      centerItems: [
        {
          title: "The Company",
          content: (
            <React.Fragment>
              Nation Safe Drivers (NSD) celebrated its 50th Anniversary in 2012. Since 1962 NSD has been a leader in
              providing innovative products and services to the Automotive and Insurance industry. Its marquis product,
              24-hour Dispatched Roadside Assistance has been heralded to have one of the most technologically advanced
              and
              intuitive software platforms in the world. Once a sleeping giant NSD has been propelled onto the world
              stage
              and has experienced unprecedented growth. In addition to Roadside Assistance, NSD provides "niche"
              products
              for all types of industry. Our products are designed to fill gaps and enhance traditional product
              offerings.
              <br/>
              <br/>
              During the recent economic downturn, NSD's product and service diversification allowed us to remain
              profitable
              to our clients. The industries using our products and services are forecasting much growth and NSD is well
              positioned to support them.
            </React.Fragment>
          )
        },
        {
          title: "The Culture",
          content: (
            <React.Fragment>
              Our corporate headquarters is located in pristine Boca Raton, Florida and housed in a 50,000 ft2 facility.
              The
              NSD campus is immaculately manicured and offers a park like picturesque backdrop for employees to enjoy
              their
              break and lunch times, contains a basketball court, is conveniently located next to affordable
              restaurants,
              health club facilities and the TriRail.
            </React.Fragment>
          )
        },
        {
          title: "The Career",
          content: (
            <React.Fragment>
              Currently, NSD is on track to have over 450 employees. Whether you have just graduated, hold insurance
              licenses, have experience in insurance services, technology, finance, marketing, customer service, or
              willing
              to embark on a new career path, NSD has something for you.
              <br/>
              <br/>

              Once you have met your enrollment qualifications you will be eligible to join our Employee Stock Option
              Plan
              (ESOP). This allows you to earn shares in our company at NO COST to you every year that you qualify! ESOPs
              are
              known to be invaluable in helping to build wealth for their employees over time.
              <br/>
              <br/>
              To be successful at NSD you must have an ownership mentality and be willing to make a difference. If you
              are
              looking to build a future with a company that will reward your loyalty, performance and contribution then
              NSD
              is for you.
              <br/>
              <br/>
              NSD also offers a comprehensive benefits package including healthcare, dental, vision, short and long term
              disability and a 401k Retirement plan.
            </React.Fragment>
          )
        }
      ],
      mainContent: (
        <React.Fragment>
          <h3>
            Imagine going to work each day in a business where your contribution has an impact on the company's value.
            Where your performance and contribution has a measurable and defined benefit for you and your family! Join
            NSD
            and own that future!
            <br/>
            <br/>
          </h3>
          <h1>
            <a href={"https://secure4.saashr.com/ta/6141769.careers?CareersSearch"} target={"_blank"}>Apply Now</a>
          </h1>
        </React.Fragment>
      ),
      image: require("assets/img/business_640_color.png"),
      "description": [
        "The Nation Safe Drivers Auto Club provides real peace-of-mind at affordable rates. When on the road you'll know that you are covered and protected by an organization that cares and has been caring since 1962! Our customer representatives respond quickly to provide you the best service and protection available anywhere. We at NSD have earned a solid reputation for fast and courteous service. You can count on the company with \"Safe\" as its middle name!"
      ]
    }
  ],
  "tagLine": "Excellence drives us ...come take the ride!",
  "sections": [
    {
      "title": "The Company",
      "content": " Nation Safe Drivers (NSD) celebrated its 50th Anniversary in 2012. Since 1962 NSD has been a leader in providing innovative products and services to the Automotive and Insurance industry."
    },
    {
      "title": "The Culture",
      "content": "Imagine going to work each day in a business where your contribution has an impact on the company's value. Where your performance and contribution has a measurable and defined benefit for you and your family! Join NSD and own that future!"
    },
    {
      "title": "The Career",
      "content": "Currently, NSD is on track to have over 450 employees. Whether you have just graduated, hold insurance licenses, have experience in insurance services, technology, finance, marketing, customer service, or willing to embark on a new career path, NSD has something for you."
    }
  ]
}
