import React from "react";

export default {
  description: [
    (
      <React.Fragment>
        <div><h2>INSURANCE GLOSSARY</h2></div>
        <div><b>Abandonment:</b> As used in property insurance, prohibits the insured from abandoning damaged property
          to the insurance company for repair or disposal
        </div>
        <div><b>Accelerated Benefits Rider:</b> An adjustment (rider) to a life insurance policy that allows for the
          early payment of some portion of the policy's face amount should the insured suffer from a terminal illness or
          injury.
        </div>
        <div><b>Accidental Death Benefit Rider:</b> An adjustment (rider) to a life insurance policy that provides for
          payment of an additional cash benefit when death occurs by accidental means. This amount depends on the value
          of the policy.
        </div>
        <div><b>Accidental Death Insurance:</b> An Insurance policy that provides payment if the insured's death occurs
          as a results from an accident.
        </div>
        <div><b>Accounts Receivable Coverage:</b> Covers loss of sums owed to the insured by its customers that are
          uncollectible due to damage by an insured peril to accounts receivable records
        </div>
        <div><b>Actual Cash Value (ACV):</b> Cost to repair or replace damaged property with materials of like kind and
          quality, less depreciation
        </div>
        <div><b>Additional Insured:</b> A person or organization for whom insured status is arranged by endorsement
        </div>
        <div><b>Advertising Injury:</b> General liability coverage that insures against libel, slander, invasion of
          privacy, copyright infringement and misappropriation of advertising in connection with the insured's
          advertising of its goods or services
        </div>
        <div><b>Agent:</b> An authorized representative of an insurance company.</div>
        <div><b>Aggregate:</b> The maximum amount an insurance company will pay during the policy</div>
        <div><b>All Risk Coverage:</b> Property insurance covering loss arising from all causes of loss except those
          that are specifically excluded
        </div>
        <div><b>Annually Renewable Term:</b> Term insurance that provides coverage for one year and allows the policy
          owner to renew his or her coverage each year.
        </div>
        <div><b>Application:</b> A form with the information needed for an insurance company to underwrite and rate a
          specific policy
        </div>
        <div><b>Assignment:</b> The transfer of ownership of a Life Insurance policy from one person to another.</div>
        <div><b>Attained Age:</b> Your current age. Your attained age is a factors life insurance companies use to
          determine premiums.
        </div>
        <div><b>Audit:</b> A verification of the financial records, usually payroll or receipts, of an organization to
          determine exposures and premiums
        </div>
        <div><b>Automobile: </b>A land motor vehicle, trailer or semi-trailer designed for travel on public roads, not
          including 'mobile equipment'
        </div>
        <div><b>Backdating:</b> Making the effective date of a policy earlier than the date of application. Backdating
          is often used to make the age of the applicant lower than it actually was at the time of application so that
          he/she can get a lower premium. State laws often set limits to this.
        </div>
        <div><b>Bailee Coverage:</b> Coverage on property left in the care of the insured for storage, repair or
          servicing
        </div>
        <div><b>Basic Cause of Loss Form:</b> Property coverage for named perils: Fire, Lightening, Explosion, Smoke,
          Windstorm, Hail, Riot, Civil Commotion, Aircraft, Vehicles, Vandalism, Sprinkler Leakage, Sinkhole Collapse
          and Volcanic Action
        </div>
        <div><b>Basic Limits:</b> The minimum limits of liability that can be carried by an insured</div>
        <div><b>Beneficiary:</b> The designated person set to receive the death benefit if the insured should die.</div>
        <div><b>Best's Rating:</b> A rating system by A.M. Best Company giving the financial condition of insurance
          companies
        </div>
        <div><b>Binder:</b> A temporary insurance policy that expires at the end of a specific time period or when a
          permanent policy is written. A binder is given to an applicant for insurance during the time it takes the
          insurance company to complete the policy paperwork.
        </div>
        <div><b>Bodily Injury by Accident Limit:</b> The most an insurer will pay under Part Two of a Workers'
          Compensation Policy for claims arising out of any one accident, regardless of how many employee claims arise
          out of the accident
        </div>
        <div><b>Bodily Injury by Disease, Each Employee: </b> The most an insurer will pay under Part Two of a Workers'
          Compensation Policy for damages due to bodily injury by disease to any one employee
        </div>
        <div><b>Bodily Injury by Disease-Policy Limit: </b> The most an insurer will pay under Part Two of a Workers'
          Compensation Policy employee bodily injury by disease claims during the policy period regardless of the number
          of employees who make such claims
        </div>
        <div><b>Bodily Injury Liability Limit:</b> The insured is legally liable for damages due to bodily injury,
          sickness, or disease, including resulting death
        </div>
        <div><b>Boiler & Machinery Insurance:</b> Coverage for loss caused by mechanical or electrical equipment
          breakdown, including damage to the equipment
        </div>
        <div><b>Bond:</b> A written agreement in which one party, the surety, guarantees the performance or honesty of a
          second party, the principal (obligor), to the third party (oblige) to whom the performance or debt is owed
        </div>
        <div><b>Brands and Labels Endorsement:</b> Property insurance coverage that allows the insured to remove labels
          from damaged goods or mark the items as 'salvage,' provided the goods are not damaged in the process
        </div>
        <div><b>Broad Causes of Loss Form:</b> Property coverage for the named perils: Fire, Lightening, Explosion,
          Smoke, Windstorm, Hail, Riot, Civil Commotion, Aircraft, Vehicles, Vandalism, Sprinkler Leakage, Sinkhole
          Collapse, Volcanic Action, Breakage of Building Glass, Falling Objects, Weight of Snow, Ice or Sleet, Water
          Damage (in the form of leakage from appliances) and Collapse from Specified Causes
        </div>
        <div><b>Building Ordinance Coverage:</b> Covers against loss caused by enforcement or ordinances or laws
          regulating construction and repair of damaged buildings
        </div>
        <div><b>Burglary:</b> Theft of property by forcible entry, which is evidenced by visible signs, in a premises,
          by a person
        </div>
        <div><b>Business Auto Policy:</b> Auto Policy for businesses that includes auto liability and auto physical
          damage coverage’s
        </div>
        <div><b>Business Income Coverage:</b> Insurance covering loss of income by a business when operations are
          interrupted due to property loss that is a covered cause of loss
        </div>
        <div><b>Business Interruption Coverage:</b> See Business Income Coverage</div>
        <div><b>Business Owners Policy (BOP):</b>A policy that combines property and liability coverage’s for special
          types of small businesses
        </div>
        <div><b>Cancellation:</b> The termination of an insurance policy usually before its expiration</div>
        <div><b>Care, Custody or Control:</b> An exclusion of liability insurance which eliminates coverage for damage
          to property in the insured's care, custody or control
        </div>
        <div><b>Carrier:</b> The insurance company which provides coverage</div>
        <div><b>Cash Benefits:</b> The Money that is paid to the policy holder upon settlement of a covered claim.</div>
        <div><b>Cash Value:</b> The equity amount or "savings" accumulation in a whole life insurance policy.</div>
        <div><b>Casualty Insurance:</b> Insurance that covers loss caused by injuries to persons and the legal liability
          imposed on the insured for injury or for damage to property of others
        </div>
        <div><b>Catastrophe:</b> A severe loss causing sizable financial loss</div>
        <div><b>Causes of Loss Forms:</b> The commercial property forms that define the covered causes of loss for which
          coverage is provided. Commonly, there are 3 Cause of Loss Forms: Basic, Broad and Special
        </div>
        <div><b>Certificate of Insurance:</b> A document providing evidence that insurance has been purchased</div>
        <div><b>Claim:</b> A request by a policyholder or a claimant for payment under a policy of insurance</div>
        <div><b>Claim Expense:</b> Expenses of settling or investigating a claim</div>
        <div><b>Claimant:</b> The person presenting a claim</div>
        <div><b>Claims Reserve:</b> An amount of money set aside to meet claims reported but not paid</div>
        <div><b>Class:</b> A group of businesses who have common or similar exposures and are grouped together for
          rating purposes
        </div>
        <div><b>Classification:</b> The arranging or establishing of business groups or categories for rating purposes
        </div>
        <div><b>Coinsurance Provision:</b> An insurance provision for property coverage’s in which the policyholder must
          carry an amount of insurance that is at least equal to a set percentage of the value of the property in order
          to receive full payment of a loss
        </div>
        <div><b>Collapse:</b> Collapse of a building and collapse of personal property within a building due to
          specified causes (such as weight of snow, ice or rain). Does not include collapse due to design error or due
          to faulty workmanship or materials if the collapse occurs after construction is complete
        </div>
        <div><b>Collision Insurance:</b> Provides for payment to a covered automobile resulting from the striking of
          another object by a moving vehicle
        </div>
        <div><b>Commercial General Liability Policy (CGL):</b> A coverage which protects business organizations against
          liability claims for bodily injury and property damage. Those claims may be the result of events at your place
          of business, from your business operations, the products or services you make or do, communications or
          advertisements your business broadcasts
        </div>
        <div><b>Competitive State Funds:</b> State-owned and operated facilities that write Workers' Compensation
          Insurance solely for that state
        </div>
        <div><b>Completed Operations:</b> A General Liability coverage for the work of the insured that has been
          completed away from the business premises
        </div>
        <div><b>Comprehensive Auto Coverage:</b> Covers an automobile for loss or damage for all causes except for those
          specifically excluded
        </div>
        <div><b>Compulsory Insurance:</b> Insurance that is required by law</div>
        <div><b>Concealment:</b> Failure to disclose facts which may void an insurance policy</div>
        <div><b>Conditional Receipt:</b> Given to policy owners when they pay a premium at the time of the application.
          These receipts bind the insurance company, provided your policy is approved, but are subject to any other
          conditions stated on the receipt.
        </div>
        <div><b>Conditions:</b> Things agreed upon in an insurance policy that state the rights and the requirements of
          the insured and the insurer
        </div>
        <div><b>Consequential Loss:</b> An indirect loss such as the reduction in value of property that is the result
          of a direct damage loss
        </div>
        <div><b>Constructive Total Loss:</b> Term used when damage to property is more than the value of the property
        </div>
        <div><b>*Contestable Clause:</b> A provision in an insurance policy setting forth the conditions or time period
          under which the insurance company may contest or void the policy. After this time has lapsed, typically two
          years, the policy cannot be contested. Example: Suicide.
        </div>
        <div><b>Contingent Beneficiary:</b> Person or persons designated to receive the value of an insurance policy in
          case the original beneficiary is not alive.
        </div>
        <div><b>Contract:</b> An agreement between two or more parties with characteristics of mutual assent, competent
          parties, a valid consideration and legal subject
        </div>
        <div><b>*Coverage:</b> Coverage is just another term for Insurance. It can be used to mean either the dollar
          amounts of insurance purchased ($500,000 of liability coverage), or the type of loss covered (coverage for
          theft).
        </div>
        <div><b>Convertible Term:</b> A policy that may be changed to another form by contractual provision and without
          evidence of insurability. Most term policies are convertible into permanent insurance.
        </div>
        <div><b>Countersignature:</b> The signature of a licensed agent or representative on a policy that is required
          to validate the policy
        </div>
        <div><b>Cross-Purchase Plan:</b> An agreement that provides that upon a business owner's death, surviving owners
          will purchase the deceased's interest, often with funds from life insurance.
        </div>
        <div><b>Cumulative Injury:</b> A type of injury which occurs from the repetition of tasks over an extended
          length of time
        </div>
        <div><b>Data Processing or EDP Coverage:</b> All risk property insurance for electronic data processing
          equipment (computers), computer programs and data including mechanical breakdown, electrical injury and
          changes in temperature and humidity
        </div>
        <div><b>Death Benefit:</b> The amount of money paid to the beneficiary when the insured person dies.</div>
        <div><b>Decreasing Term Insurance:</b> Term life insurance on which the face value slowly decreases in scheduled
          steps from the date the policy comes into force to the date the policy expires, while the premium remains
          level. The intervals between decreases are usually monthly or annually.
        </div>
        <div><b>Debris Removal:</b> The cost of removal of debris from covered property damaged by an insured peril
        </div>
        <div><b>Deductible:</b> The amount of loss which is paid or absorbed by the insured prior to determining the
          insurance company's liability
        </div>
        <div><b>Deposit Premium:</b> The amount of premium required at the beginning of a policy prior to the actual
          premium being determined
        </div>
        <div><b>Depreciation:</b> The reduction in value of property over a period of time. Usually as a result of age,
          wear and tear, or economic obsolescence
        </div>
        <div><b>Direct Damage:</b> Causes of loss that produce direct and straightforward property damage (without
          interruption in time or deviation in space) from the cause of the event to the damaged property
        </div>
        <div><b>Double Indemnity:</b> Payment of twice the basic benefit in the event of loss resulting from specified
          causes or under specified circumstances.
        </div>
        <div><b>Driver Other Car Endorsement:</b> An endorsement that can be added to an automobile policy that gives
          protection while the insured designated in the endorsement is driving a car other than the one named in the
          policy
        </div>
        <div><b>Drop Down Provision:</b> A clause used in Umbrella policies providing that the Umbrella will 'drop-down'
          over underlying policy aggregate limits when they have been reduced or exhausted
        </div>
        <div><b>Earned Premium:</b> The amount of premium that has been used for certain periods of time</div>
        <div><b>Earth Movement or Earthquake Exclusion:</b> An exclusion found in most property insurance policies
          eliminating coverage for earth movement or earthquake, except ensuing fire
        </div>
        <div><b>Effective Date:</b> The date on which an insurance binder or policy goes into effect</div>
        <div><b>Electrical Damage or Injury Exclusion:</b> An exclusion usually contained in property insurance policies
          eliminating coverage for damage to electrical appliances caused by artificially generated currents, except for
          ensuing fire or explosion
        </div>
        <div><b>Employee Dishonesty Coverage:</b> Coverage for theft of money, securities or property by an employee
        </div>
        <div><b>Employee Leasing:</b> A staffing method which an employee leasing company provides all or most of its
          client's employees
        </div>
        <div><b>Employers Excess Indemnity Insurance:</b> Insurance coverage purchased by employers that do not
          subscribe to the Texas Workers' Compensation law
        </div>
        <div><b>Employers Liability Coverage:</b> Part 2 of the Workers' Compensation policy which pays on behalf of the
          employer all sums that the employer becomes legally obligated to pay because of bodily injury by accident or
          disease sustained by any employee of the insured arising out of and in the course of his employment by the
          insured
        </div>
        <div><b>Employment Practices Liability Insurance:</b> A form of liability insurance covering wrongful acts
          arising from employment practices such as wrongful termination, discrimination and sexual harassment
        </div>
        <div><b>Endorsement:</b> A document attached to an insurance policy that changes the original policy provisions
        </div>
        <div><b>Equipment Floater:</b> A property insurance coverage for equipment that is often moved from place to
          place
        </div>
        <div><b>Estimated Premium:</b> A preliminary premium amount that could be adjusted based on a variance in
          exposures
        </div>
        <div><b>Evidence of Insurability:</b> Any statement or proof of a person's physical condition, occupation, etc.,
          affecting acceptance of the applicant for insurance.
        </div>
        <div><b>Excess and Surplus Lines Insurance:</b> Coverage that is provided by insurers not licensed in the states
          where the risk is located
        </div>
        <div><b>Excess Liability Policy:</b> A policy that provides additional limits in excess of an underlying
          liability policy
        </div>
        <div><b>Exclusions:</b> Specified hazards listed in a policy for which benefits will not be paid.</div>
        <div><b>Expected or Intended:</b> An exclusion for injury or damage that is expected or intended</div>
        <div><b>Expediting Expense Coverage:</b> Coverage providing reimbursement of expenses for temporary repairs and
          costs incurred to speed up the permanent repair or replacement of covered property or equipment
        </div>
        <div><b>Expense Constant:</b> A small flat expense charged to Workers' Compensation policies</div>
        <div><b>Experience Modifier:</b> A debit or credit factor developed by measuring the difference between the
          insured's actual past experience and the expected or actual experience of the class of business
        </div>
        <div><b>Expiration:</b> The ending date of an insurance policy</div>
        <div><b>Exposure Base:</b> The basis of rates that are applied to determine premium. Some exposures may be
          measured by payroll, receipts, sales, square footage, area, man-hours or per unit
        </div>
        <div><b>Extra Expense Coverage:</b> Coverage for reimbursement of expenses in excess of normal operating
          expenses that are incurred to continue operations after a direct damage loss
        </div>
        <div><b>Extraterritorial Coverage: </b>The coverage for extending workers'
          compensation law to provide benefits for workers hired in one state but injured while working in another state
        </div>
        <div><b>Face Amount:</b> The amount covered by the terms of an insurance contract, usually found on the first
          page of the policy.
        </div>
        <div><b>Fiduciary Liability:</b> The liability placed on trustees, employers, fiduciaries and professional
          administrators with respect to errors and omissions in the administration of employee benefit programs
        </div>
        <div><b>Final Expenses:</b> Expenses incurred at the time of a person's death. These include but are not limited
          to: funeral costs, court expenses, current bills or debt, mortgages, loans and taxes.
        </div>
        <div><b>Fine Arts Coverage:</b> Property insurance for works of art</div>
        <div><b>Fire Department Service Charge Coverage:</b> Coverage in a property insurance policy for charges
          incurred by the insured from a fire department for their services in fighting a fire
        </div>
        <div><b>Fire Legal Liability Coverage:</b> Liability coverage for the insured's legal liability for fire damage
          to premises rented by the insured
        </div>
        <div><b>Fire Wall:</b> A wall designed to prevent the spread of fire from one part of a building to another
        </div>
        <div><b>Firewall:</b> A computer that protects a company's private network from outside internet users</div>
        <div><b>Fixed Benefit:</b> A death benefit, the dollar amount of which does not vary.</div>
        <div><b>Flat Cancellation:</b> The full cancellation of a policy as of the effective date of coverage which
          requires the return of paid premium in full
        </div>
        <div><b>Flood Coverage:</b> Coverage for damage to property caused by flood</div>
        <div><b>Flood Exclusion:</b> A provision in most all property insurance policies eliminating coverage for damage
          by flood and possibly other types of water damage, such as seepage and sewer backup
        </div>
        <div><b>Follow Form:</b> An umbrella policy provision that follows the underlying policy for coverage’s and
          policy provisions
        </div>
        <div><b>Forgery or Alteration Coverage:</b> Covers loss due to the dishonesty of writing, signing or altering of
          checks and bank drafts
        </div>
        <div><b>Fortuitous Event:</b> An event that is subject to chance without the implication of suddenness</div>
        <div><b>Free Look:</b> Trial period required in most states where policy owners have up to 20 days to examine
          their new policies with no obligation.
        </div>
        <div><b>Frequency:</b> The number of times that a loss will occur within any given period of time</div>
        <div><b>Full Coverage:</b> Any form of insurance that provides payment in full of all losses caused by the
          perils insured against without applying a deductible or depreciation
        </div>
        <div><b>Funeral Expenses:</b> Expenses including casket, vault, grave plot, headstone and funeral director.
        </div>
        <div><b>Garage Liability Insurance:</b> Insurance coverage for the legal liability of automobile dealers,
          garages, repair shops and service stations for bodily injury and property damage arising out of their business
          operations
        </div>
        <div><b>Garage keepers Coverage:</b> Provides coverage to owners of storage garages, parking lots and body and
          repair shops for their liability of damage to automobiles left in their custody for safekeeping or repair
        </div>
        <div><b>General Aggregate Limit:</b> The maximum amount of insurance payable during the policy period for losses
          (other than those arising from the products - completed operations hazards as covered under the standard
          commercial general liability policy)
        </div>
        <div><b>General Liability Insurance:</b> Insurance protecting businesses from most liability exposures other
          than automobile and professional liability
        </div>
        <div><b>Glass Insurance:</b> A property insurance policy covering breakage of building glass regardless of cause
        </div>
        <div><b>Governing Classification:</b> In Workers' Compensation Insurance, the classification that best describes
          the workers' compensation exposure of an employer's business
        </div>
        <div><b>Grace Period:</b> Period of time after the due date of a premium during which the policy remains in
          force without penalty.
        </div>
        <div><b>Graded Premium Policy:</b> A type of whole life policy designed for people who want more life coverage
          than they can currently afford. They pay a lower premium rate that increases gradually over the first three to
          five years and then remains constant over the life of the policy.
        </div>
        <div><b>Gross Negligence:</b> Willful and wanton misconduct</div>
        <div><b>Gross Vehicle Weight (GVW):</b> The weight specified by a manufacturer for the maximum total loaded
          weight of a single vehicle
        </div>
        <div><b>Guaranteed Term:</b> A form of renewable term insurance that remains in force as long as the premiums
          are paid on time. With guaranteed term insurance, the insurance company cannot terminate the policy during the
          term.
        </div>
        <div><b>Hired Automobile:</b> An automobile whose exclusive use has been temporarily given to another for a
          monetary sum or other consideration. The business auto definition of 'hired autos,' however, includes autos
          borrowed except those borrowed from employees or partners
        </div>
        <div><b>Hold Harmless Agreement:</b> A contractual agreement that requires one contracting party to assume
          certain legal liabilities of the other party
        </div>
        <div><b>Host Liquor Liability:</b> Liability coverage for hosts of business or social functions arising out of
          the serving or distribution of alcoholic beverages by a party not engaged in this activity as a business
          enterprise
        </div>
        <div><b>Improvements and Betterments:</b> Additions or changes made by a lessee at his own expense to property
          that may not legally be removed. Usually covered under the tenants property coverage
        </div>
        <div><b>Incontestable Clause:</b> A clause in a policy providing that a policy has been in effect for a given
          length of time (two or three years), the insurer shall not be able to contest the statements contained in the
          application. In life policies, if an insured lied as to the condition of his health at the time the policy was
          taken out, that lie could not be used to contest payment under the policy if death occurred after the time
          limit stated in the incontestable clause.
        </div>
        <div><b>Incurred Losses:</b> The amount of paid claims and loss reserves within a particular period of time,
          usually a policy year. Customarily computed as losses incurred during the period, plus outstanding losses at
          the end of the period, less outstanding losses at the beginning of the period
        </div>
        <div><b>Independent Adjuster:</b> A claims adjuster who provides adjustment services to insurance companies but
          is not employed by them
        </div>
        <div><b>Independent Contractor:</b> An individual or company who has agreed, in writing, with another party to
          perform a job or function on behalf of that party
        </div>
        <div><b>Inflation Guard Provision:</b> A provision that increases the limit of insurance by a specified
          percentage over a specified period of time to offset inflation costs
        </div>
        <div><b>Insurability:</b> The condition of the individual wishing to be insured, including their health,
          susceptibility to injury and life expectancy.
        </div>
        <div><b>Insurance:</b> A formal social device for reducing risk by transferring the risks of several individual
          entities to an insurer. The insurer agrees, for a consideration, to pay for the loss in the amount specified
          in the contract.
        </div>
        <div><b>Insurance Policy:</b> The printed form which serves as the contract between an insurer and an insured.
        </div>
        <div><b>Insurance to Value:</b> Insurance written in an amount equal to the value of the property or which meets
          coinsurance requirements
        </div>
        <div><b>Insured:</b> The party who is being insured. In life insurance, it is the person because of his or her
          death the insurance company would pay out a death benefit to a designated beneficiary.
        </div>
        <div><b>Insurer:</b> The insurance company; Party that provides insurance coverage, typically through a contract
          of insurance.
        </div>
        <div><b>Irrevocable Beneficiary:</b> A beneficiary that cannot be changed without that beneficiary's consent.
        </div>
        <div><b>Increasing Term Insurance: </b>Term life insurance in which the death
          benefit increases periodically over the policy's term. Usually purchased as a cost of living rider to a whole
          life policy.
        </div>
        <div><b>Joint Venture: </b>A business relationship when two or more persons
          join their labor or property for a business undertaking and share profits
        </div>
        <div><b>Lapse:</b> Termination of a policy due to the policy owner's failure to pay the premium within the grace
          period.
        </div>
        <div><b>Leasehold Interest:</b> Property insurance covering the loss suffered by a tenant due to termination of
          a lease because of damage to the leased premises by a covered loss
        </div>
        <div><b>Lessee:</b> The person to whom a lease is granted</div>
        <div><b>Lessor:</b> The person granting the lease</div>
        <div><b>Liability:</b> The legal obligation to pay a monetary award for injury or damage caused by one's
          negligent or statutorily prohibited action
        </div>
        <div><b>Liberalization Clause:</b> A provision within an insurance policy that broadens the coverage if the
          insurance company offers a broader coverage form within the first 45 days of coverage
        </div>
        <div><b>Lien:</b> An obligation that can be held by an individual who has an interest in a particular matter or
          property
        </div>
        <div><b>Life Expectancy:</b> The average number of years a person is expected to live based on a national
          average per age group, and other factors.
        </div>
        <div><b>Life Insurance:</b> Insurance coverage that pays out a set amount of money to specified beneficiaries
          upon the death of the individual who is insured.
        </div>
        <div><b>Limit of Liability:</b> The most an insurance company agrees to pay in the case of loss</div>
        <div><b>Limited Pay Policy:</b> A type of whole life insurance designed to let the policyholder pay higher
          premiums over a specific time period such as 10 or 20 years so that they won't have to pay any premiums for
          the rest of his or her life.
        </div>
        <div><b>Longshore and Harbor Workers' Compensation Act:</b> A federal law that provides workers' compensation
          benefits to employees of a vessel injured in maritime employment - usually in loading, unloading, repairing or
          building a vessel - but not applicable to crew members
        </div>
        <div><b>Loss:</b> The amount an insurance company pays for damages under the terms of a policy</div>
        <div><b>Loss Adjustment Expense:</b> The cost assessed to a particular claim for investigating and adjusting
          that claim
        </div>
        <div><b>Loss Constant:</b> A flat charge added to the premium of small workers' compensation policies to offset
          higher loss ratios
        </div>
        <div><b>Loss Control:</b> A technique that is put in place to reduce the possibility that a loss will occur or
          reduce the severity of those that do occur
        </div>
        <div><b>Loss Payable Clause:</b> An insurance clause that authorizes loss payments to a person or entity having
          an insurable interest in the covered property
        </div>
        <div><b>Loss Ratio:</b> Percentage of losses incurred against earned premiums</div>
        <div><b>Loss Report:</b> A form showing reported claims which provides information such as the date of
          occurrence, type of claim, amount paid and amount reserved for each loss
        </div>
        <div><b>Loss Reserve:</b> An estimated amount set aside for a particular claim that has not yet been paid</div>
        <div><b>Lost Policy Release:</b> A signed statement by the named when the insured wishes to cancel the policy,
          but has lost or mislaid the policy, which releases the insurance company from all liability or losses
        </div>
        <div><b>Medical:</b> A document completed by a physician or another approved examiner and submitted to an
          insurer (insurance company) in order to provide medical information. This is usually done to determine
          insurability (or lack of insurability) or is sometimes done in relation to a claim.
        </div>
        <div><b>Medical Expenses:</b> Reasonable charges for medical, surgical, x-ray, dental, ambulance, hospital,
          professional nursing, prosthetic devices, and funeral expenses. What is considered reasonable is outlined in a
          policy.
        </div>
        <div><b>Medical Payments, Auto:</b> Coverage, which is optional, under an auto policy to pay for medical
          expenses for bodily injury caused by an auto accident, regardless of fault. Coverage for persons other than
          the named insured and his or her family members is typically restricted to circumstances when they are
          occupants of the insured auto
        </div>
        <div><b>Medical Payments, General Liability:</b> A general liability coverage that reimburses others, regardless
          of fault, for medical or funeral expenses incurred as a result of bodily injury or death sustained by an
          accident
        </div>
        <div><b>Mexico Coverage:</b> Coverage which is sometimes provided under automobile policies for the operation of
          an insured motor vehicle within Mexico, usually limited to a stated number of miles from the U.S. border
        </div>
        <div><b>Minimum Premium:</b> The lowest amount of premium to be charged for providing a particular insurance
          coverage
        </div>
        <div><b>Misrepresentation:</b> The act of knowingly presenting false information.</div>
        <div><b>Mobile Equipment:</b> Equipment such as earthmovers, tractors, diggers, farm machinery, forklifts, etc.,
          that even when self-propelled, are not considered as automobiles for insurance purposes
        </div>
        <div><b>Monopolistic State Funds:</b> States or Jurisdictions where an employer must obtain workers'
          compensation insurance from a state fund or qualify as a self-insurer, as is allowed in five of the states:
          North Dakota, Ohio, Washington, West Virginia, Wyoming, Puerto Rico and the U.S. Virgin Islands
        </div>
        <div><b>Mortality Rate: </b>The number of deaths in a group of people, usually expressed as deaths per thousand.
        </div>
        <div><b>Mortality Table:</b> A table showing the incidence of death at specified age groups.</div>
        <div><b>Mortgage Clause:</b> Property insurance provisions granting protection for the mortgagee named in the
          policy. It establishes that loss to mortgaged property is payable to the insured and to the mortgagee named in
          the policy
        </div>
        <div><b>Named Perils Coverage:</b> A property insurance term referring to exact causes of loss specifically
          listed as covered
        </div>
        <div><b>National Flood Insurance Program:</b> A federally funded program established to make flood insurance
          available to properties located in participating communities National Flood Insurance Program: A federally
          funded program established to make flood insurance available to properties located in participating
          communities
        </div>
        <div><b>Nonadmitted Insurer:</b> An insurance company that is not licensed to do business in a specific state.
          The insurers may write coverage through an excess and surplus lines broker that is licensed in these
          jurisdictions
        </div>
        <div><b>Nonowned Automobile:</b> In commercial auto policies, coverage for autos that are used in connection
          with the named insured's business but are neither owned, leased, hired, rented or borrowed by the named
          insured. The term specifically applies to vehicles owned by employees and used for company business
        </div>
        <div><b>Nonsubscription:</b> A Workers' Compensation term used in Texas that refers to employers who choose to
          be out of the workers' compensation system. Firms that are proven negligent in causing a worker's injury, can
          be held liable in tort, since nonsubscribing employers waive the traditional common law defenses available to
          employers subject to workers' compensation laws
        </div>
        <div><b>Original Age:</b> The age you were when you bought an insurance policy.</div>
        <div><b>Other Insured Rider:</b> The temporary addition to an insurance policy, usually a member of the direct
          family.
        </div>
        <div><b>Ownership:</b> All rights, benefits and privileges under life insurance policies are controlled by their
          owners. Policy owners may or may not be the insured. Ownership may be assigned or transferred by written
          request of current owner.
        </div>
        <div><b>Occupational Hazard:</b> A condition in the workplace that increases the chances of the accident,
          sickness, or death. It usually will mean higher premiums.
        </div>
        <div><b>Occurrence: </b>A continual, gradual or repeated exposure to
          substantially the same general harmful conditions. General liability policies insure liability for bodily
          injury or property damage that is caused by an occurrence
        </div>
        <div><b>Package Policy:</b> A policy providing several different coverages combined into one policy. Refers to a
          policy providing both general liability insurance and property insurance
        </div>
        <div><b>Payroll Limitation:</b> A limit on the amount of payroll for certain classifications used for the
          development of premium
        </div>
        <div><b>Peril:</b> Cause of loss such as fire, windstorm, collision, etc.</div>
        <div><b>Personal Auto Policy (PAP</b>): A policy insuring private-passenger autos owned by individuals</div>
        <div><b>Personal Injury:</b> A General Liability coverage for insurable offenses that cause harm, other than
          bodily injury, such as false arrest, detention or imprisonment, malicious prosecution, wrongful eviction,
          slander, libel and invasion of privacy
        </div>
        <div><b>Personal Injury Protection (PIP</b>): An automobile insurance coverage mandated by law in some states.
          The statutes typically require insurers to provide or offer to provide first-party benefits for medical
          expenses, loss of income, funeral expenses and similar expenses without regard to fault
        </div>
        <div><b>Personal Property:</b> All tangible property not classified as real property such as contents</div>
        <div><b>Policy:</b> The printed document given to the insured, outlining the terms and conditions of the
          Insurance coverage.
        </div>
        <div><b>Policy Fee:</b> A one-time charge per policy that does not change with the size of the premium</div>
        <div><b>Policy Holder:</b> The person who owns a life insurance policy. This is usually the insured person, but
          it may also be a relative of the insured, a partnership or a corporation.
        </div>
        <div><b>Policy Period:</b> The term or duration of a policy including the effective and expiration dates</div>
        <div><b>Pollutant:</b> An irritant or contaminant, whether in solid, liquid, or gaseous form, including smoke,
          vapor, soot, fumes, acids, alkalis, chemicals and waste
        </div>
        <div><b>Preferred Risk:</b> A positive characteristic of someone seeking to be insured. Usually means a better
          likely hood for long life, and usually means a lower premium.
        </div>
        <div><b>Premises:</b> The location where coverage applies</div>
        <div><b>Premises-Operations:</b> A category of hazard ordinarily insured by a general liability policy which is
          composed of those exposures to loss that fall outside the defined 'products-completed operations hazard,'
          including liability for injury or damage arising out of the insured's premises or out of the insured's
          business operations while such operations are in progress
        </div>
        <div><b>Premium:</b> The agreed upon, payment made to keep an insurance policy in force, usually a monthly
          payment.
        </div>
        <div><b>Premium Flexibility:</b> The policy holder's right to vary the amount of premium paid each month.</div>
        <div><b>Primary Beneficiary:</b> In life insurance, the beneficiary designated by the insured as the first to
          receive policy benefits.
        </div>
        <div><b>Primary Policy:</b> The insurance policy that pays first when you have a loss that's covered by more
          than one policy.
        </div>
        <div><b>Pro Rata Cancellation:</b> The cancellation of an insurance policy with the return premium being the
          full proportion of premium for the unexpired term of the policy, without penalty for early cancellation
        </div>
        <div><b>Product:</b> Items manufactured, sold, handled, distributed or disposed of by the named insured or
          others involved with the named insured in the course of their business. Includes containers, parts and
          equipment, product warranties and provision of or failure to provide instructions and warnings
        </div>
        <div><b>Product Liability:</b> The liability for bodily injury or property damage a merchant or manufacturer may
          incur as a consequence of some defect in the product sold or manufactured
        </div>
        <div><b>Products-Completed Operations:</b> General Liability coverage for liability arising out of the insured's
          products or business operations conducted away from the insured's premises once those operations have been
          completed
        </div>
        <div><b>Professional Liability:</b> Coverage designed to protect professionals such as physicians and real
          estate brokers, against liability incurred as a result of errors and omissions in performing professional
          services
        </div>
        <div><b>Property Damage:</b> In the general liability policy, a physical injury to property, resulting in the
          loss of use
        </div>
        <div><b>Property Insurance:</b> First-party insurance for real and personal property against physical loss or
          damage
        </div>
        <div><b>Provisions:</b> Details of an insurance policy which explain the benefits, conditions and other features
          of the insurance contract.
        </div>
        <div><b>Real Property:</b> Real estate including buildings and vegetation</div>
        <div><b>Re-entry Option:</b> An option in a renewable term life policy under which the policy owner is
          guaranteed, at the end of the term, to be able to renew his or her coverage without evidence of insurability,
          at a premium rate specified in the policy.
        </div>
        <div><b>Reinstatement:</b> Putting a lapsed policy back in force by producing satisfactory evidence of
          insurability and paying any past-due premiums required.
        </div>
        <div><b>Renewal Policy:</b> A policy issued to replace an expiring policy</div>
        <div><b>Rents or Rental Value Insurance:</b> Insurance that reimburses a building owner for loss of rental
          income due to damage by an insured peril
        </div>
        <div><b>Replacement:</b> A new policy written to take the place of one currently in force.</div>
        <div><b>Representation:</b> Statements made by applicants on their applications for insurance that they
          represent as being substantially true to the best of their knowledge and belief but that are not warranted as
          exact in every detail.
        </div>
        <div><b>Return Premium:</b> The amount of premium due the insured should the actual cost of a policy be less
          than the insured previously paid
        </div>
        <div><b>Rider:</b> An attachment to a policy that modifies its conditions by expanding or restricting benefits
          or excluding certain conditions from coverage.
        </div>
        <div><b>Risk:</b> The chance of injury, damage, or loss.</div>
        <div><b>Robbery:</b> Theft of property while force is used or threatened</div>
        <div><b>Secondary Beneficiary:</b> An alternate beneficiary designated to receive payment, usually in the event
          the original beneficiary predeceases the insured.
        </div>
        <div><b>Short-Term Cancellation:</b> Cancellation of an insurance policy prior to the expiration date in which a
          penalty in the form of a less than full pro-rata premium refund is allowed
        </div>
        <div><b>Single Premium Policy:</b> A whole life policy for people who want to buy a policy for a one-time lump
          sum, and then be covered for the rest of their lives without paying any additional premiums.
        </div>
        <div><b>Special Causes of Loss Form:</b> A cause of loss form providing coverage from all causes of loss unless
          specifically excluded or limited
        </div>
        <div><b>Specified Causes of Loss Coverage:</b> Auto physical damage coverage only for losses caused by the
          perils listed in the policy
        </div>
        <div><b>Sprinkler Leakage Coverage:</b> Coverage for property damage caused by the accidental discharge or
          leakage of water from automatic sprinkler systems or other fire prevention devices
        </div>
        <div><b>Surplus Lines Insurance:</b> Insurance written by insurers not licensed in the states where the risks
          are located and placed with such insurers under the surplus line laws of the various states. Before such
          placements can be made through specially licensed surplus line agents and brokers, state laws generally
          require evidence reported before some predetermined future date ('sunset')
        </div>
        <div><b>Time Element Insurance:</b> A term referring to property coverage for loss of earnings or income
          resulting from the inability to put damaged property to its normal use
        </div>
        <div><b>Term Insurance:</b> Protection during limited number of years; expiring without value if the insured
          survives the stated period, which may be one or more years but usually is five to twenty years, because such
          periods usually cover the needs for temporary protection.
        </div>
        <div><b>Term:</b> Period for which the policy runs. In life insurance, this is to the end of the term period for
          term insurance.
        </div>
        <div><b>Third-Party Owner:</b> A policy owner who is not the prospective insured. The policy owner and the
          insured may be, and often are the same person. If for example, you apply for and are issued an insurance
          policy on your life, then you are both the policy owner and the insured and may be known as the policy
          owner-insured. If, however, your mother applies for and is issued a policy on your life, then she is the
          policy owner and you are the insured.
        </div>
        <div><b>Transit Coverage:</b> Coverage on the insured's property while in transit from one location to another,
          over land
        </div>
        <div><b>Umbrella Liability Policy:</b> A policy designed to provide additional protection against catastrophic
          losses covered under liability policies, such as the business auto policy, commercial general liability
          policy, watercraft and aircraft liability policies and employers liability coverage. It provides excess limits
          when the limits of the underlying liability policies are used up by the payment of claims and it drops down
          and picks up where the underlying policy leaves off when the aggregate limit of the underlying policy in
          question is exhausted by the payment of claims. It also provides protection against some claims not covered by
          the underlying policies, subject to a self-insured retention
        </div>
        <div><b>Underinsured Motorists Coverage:</b> Provides coverage for bodily injury, and in some states property
          damage, for losses incurred by an insured when an accident is caused by a motorist who does not have
          sufficient insurance limits
        </div>
        <div><b>Underlying Coverage:</b> The insurance or coverage in place on the same risk that will respond to loss
          before the excess policy is called on to pay any portion of the claim
        </div>
        <div><b>Underwriter:</b> Company receiving premiums and accepting responsibility for fulfilling the policy
          contract. Also, company employee who decides whether the company should assume a particular risk; or the agent
          who sells the policy
        </div>
        <div><b>Uninsurable Risk:</b> A person who is not acceptable for insurance due to excessive risk.</div>
        <div><b>Universal Life: </b>An interest-sensitive life insurance policy that builds cash values. The premium
          payer has control over how the policy is structured. He has the flexibility to eliminate the premiums
          (essentially pay up the policy and pay no more premiums) or have the premiums continue for life. It is a
          matter of juggling three variables: the assumed interest rate, the cash value and the premium payment plan.
          The policy is interest-sensitive, and if interest rates change from the assumed interest, it will affect the
          other two variables. In the past, many Universal Life Policies were structured assuming a higher interest rate
          then was actually received; therefore, most of them have under performed. If you have a Universal Life Policy,
          you should have it evaluated to see if it needs to have the premiums adjusted to get it back on track. A
          fourth variable that has not been a factor but could be in the future, and the owner should be aware of, is
          the Mortality variable. Universal Life policies are usually structured assuming current mortality rates. The
          insurance companies reserve the right to change those rates.
        </div>
        <div><b>Unearned Premium:</b> That portion of the policy premium that represents the unexpired policy term</div>
        <div><b>Uninsured Motorist Coverage:</b> Provides coverage for bodily injury, and in some states property
          damage, for losses incurred by an insured when an accident is caused by a motorist who is not insured
        </div>
        <div><b>Utility Service Interruption Coverage:</b> Coverage for the loss to an insured due to lack of incoming
          electricity which was caused by damage from a covered cause of loss, such as a fire or windstorm, to property
          away from the insured's premises - usually the utility generating station. Also referred to as 'off-premises
          power coverage'
        </div>
        <div><b>Vacancy Provision:</b> Property insurance provision found in commercial property policies that restrict
          coverage in connection with buildings that have been vacant for a specified number of days, usually 60 days
        </div>
        <div><b>Valuable Papers and Records Coverage: </b> Coverage that pays the cost to reconstruct damaged or
          destroyed valuable papers and records and usually includes almost all forms of printed documents or records
          except money or securities; data processing programs, data and media are usually excluded
        </div>
        <div><b>Waiver of Premium:</b> Rider or provision included in most life insurance policies exempting the insured
          from paying premiums after he or she has been disabled for a specified period of time, usually six months.
        </div>
        <div><b>Waiver of Subrogation:</b> Also known as 'transfer of rights of recovery,' the relinquishment by an
          insurer of the right to collect from another party for damages paid on behalf of the insured
        </div>
        <div><b>Whole Life Insurance:</b> Life insurance that is kept in force for a person's whole life as long as the
          scheduled premiums are maintained. All Whole Life policies build up cash values. Most Whole Life policies are
          guaranteed as long as the scheduled premiums are maintained. The variable in a Whole life Policy is the
          dividend which could vary depending on how well the insurance is doing. If the company is doing well and the
          policies are not experiencing a higher mortality than projected, premiums are paid back to the policy holder
          in the form of dividends. Policyholders can use the cash from dividends in many ways. The three main uses are:
          it can be used to lower or vanish premiums, it can be used to purchase more insurance or it can be used to pay
          for term insurance.
        </div>
        <div><b>Workers' Compensation:</b> Protection which provides benefits to employees for injury or contracted
          disease arising out of and in the course of employment. Most states have laws which require such protection
          for workers and prescribe the length and amount of such benefits provided
        </div>


      </React.Fragment>
    )
  ]
}
