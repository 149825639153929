import React from "react";
import {Grid} from '@material-ui/core'

export default {
  description: [
    (
      <React.Fragment>
        <Grid container>
          <Grid item xs={12} sm={12} md={12} lg={4} xl={4} style={{padding: 50}}>
            <div style={{display: 'flex', justifyContent: 'center'}}>
              <img
                src={require('../../../assets/img/National-Hurricane-Center.jpg')}
                style={{
                  borderRadius: "6px",
                  boxShadow:
                    "0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)",
                }}
              />
            </div>
            <br/>
            Go to <a href={"http://www.nhc.noaa.gov/"} target={"_blank"}>National Hurricane Center</a> to learn about
            hurricanes, being prepared, forecasting models and the Saffir-Simpson scale
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={4} xl={4} style={{padding: 50}}>
            <div style={{display: 'flex', justifyContent: 'center'}}>
              <img
                src={require('../../../assets/img/Red-Cross.jpg')}
                style={{
                  borderRadius: "6px",
                  boxShadow:
                    "0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)",
                }}
              />
            </div>
            <br/>
            The <a href={"http://www.redcross.org/"} target={"_blank"}>American Red Cross</a> exists to provide
            compassionate care to those in need.
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={4} xl={4} style={{padding: 50}}>
            <div style={{display: 'flex', justifyContent: 'center'}}>
              <img
                src={require('../../../assets/img/USDHS-FEMA.jpg')}
                style={{
                  borderRadius: "6px",
                  boxShadow:
                    "0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)",
                }}
              />
            </div>
            <br/>
            Got to <a href={"http://www.fema.gov/"} target={"_blank"}>FEMA</a> to learn about hurricanes, being
            prepared, forecasting models and the Saffir-Simpson scale
          </Grid>
        </Grid>
      </React.Fragment>
    )
  ]
}
