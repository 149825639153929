import React from "react";
import {Grid} from "@material-ui/core";
import Container from "@material-ui/core/Container";
import CenterItems from "../CenterItems";
import Brochures from "../Brochures";
import withStyles from "@material-ui/core/styles/withStyles";
import productDetailsStyle from '../../../assets/jss/material-kit-react/components/productDetails.jsx'

const DefaultLayout = ({ classes, product }) => {
    return (
        <>
          <Container className={classes.sideBySide}>
            {product.title && <h1>{product.title}</h1>}
            <div>
              <div>
                {product.sideBySide?.image}
              </div>
              <div>
                {product.sideBySide?.text}
              </div>
            </div>
          </Container>
          {
            <h3 
              className={classes.mainContentTop}
            >
              {product.mainContentTop}
            </h3>
          }
          <div className={classes.container}>
            <div className={classes.titleImageContainer}>
              <img className={classes.titleImage} src={product.detailImage || product.image}/>>
            </div>
            <div className={classes.title} style={{width: '100%'}}>
              <h2 className={classes.description}>{product.name}</h2>
            </div>
            <Grid container justify={"center"}>
              {
                product.brochures && <Brochures brochures={product.brochures} />
              }
            </Grid>
          </div>
          <div className={classes.mainInformationContainer}>
            <h3 className={classes.description}>
              {product.mainContent}
            </h3>
            <h6 className={classes.note}>
              <br/>
              {product.mainContentNote}
            </h6>
          </div>
          {
            product.centerItems?.length > 0 && <CenterItems product={product} />
          }        
        </>
    );
};

export default withStyles(productDetailsStyle)(DefaultLayout);