import React from 'react';
import {Button, Card, CardActions, CardContent, CardHeader, Grid, Hidden, Step, StepLabel} from '@material-ui/core';
import {infoColor, primaryColor, secondaryColor} from "../../../assets/jss/material-kit-react";
import {
  Announcement as ImportantIcon,
  Assignment as FileClaimIcon,
  AssignmentTurnedIn as ClaimReviewedIcon,
  AttachMoney as ClaimPayoutIcon,
  FlipCameraAndroid as ClaimReviewIcon,
  Folder as CollectInformationIcon,
  Loop as ReviewIcon
} from "@material-ui/icons";
import classNames from "classnames";
import {makeStyles, withStyles} from '@material-ui/core/styles';
import PropTypes from "prop-types";
import productStyle from "assets/jss/material-kit-react/views/landingPageSections/productStyle.jsx";

var tinycolor2 = require('tinycolor2');


const useColorlibStepIconStyles = makeStyles({
  root: {
    backgroundColor: '#ccc',
    zIndex: 1,
    color: '#fff',
    width: 50,
    height: 50,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  active: {
    backgroundImage:
      `linear-gradient( 136deg, ${primaryColor} 0%, ${tinycolor2(infoColor).darken(25).toHexString()} 50%, ${secondaryColor} 100%)`,
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
  },
  completed: {
    backgroundImage:
      `linear-gradient( 136deg, ${primaryColor} 0%, ${tinycolor2(infoColor).darken(25).toHexString()} 50%, ${secondaryColor} 100%)`,
  },
});

function ColorlibStepIcon(props) {
  const classes = useColorlibStepIconStyles();
  const {active, completed, icon} = props;
  console.log(props)

  const icons = {
    "CollectInformationIcon": <CollectInformationIcon/>,
    "ClaimReviewIcon": <ClaimReviewIcon/>,
    "ClaimReviewedIcon": <ClaimReviewedIcon/>,
    "ClaimPayoutIcon": <ClaimPayoutIcon/>,
    "FileClaimIcon": <FileClaimIcon/>,
    "ImportantIcon": <ImportantIcon/>,
    "ReviewIcon": <ReviewIcon/>,
  };

  return (
    <div
      className={classNames(classes.root, {
        [classes.active]: true,
        [classes.completed]: true,
      })}
    >
      {icons[icon]}
    </div>
  );
}

ColorlibStepIcon.propTypes = {
  active: PropTypes.bool,
  completed: PropTypes.bool,
  icon: PropTypes.node,
};

function ProductDetails({...props}) {
  const {details, classes} = props;
  return (
    <div>
      <div style={{padding: "0px 15% 20px 15%"}}>
        {details.mainContent}
      </div>
      <div style={{
        textAlign: 'center',
        backgroundColor: tinycolor2(secondaryColor).brighten(15).toHexString()
      }}>
        {
          details.forms && details.forms.length > 0 &&
          <div>
            <div style={{paddingTop: 10}}>
              <h2 style={{color: '#fff'}}>{details.formTitle || "Proof of Loss"}</h2>
            </div>
            <div
              style={{
                width: '100%',
                paddingTop: 25,
                paddingBottom: 25,
                display: 'flex',
                alignItems: 'center',
                alignContent: 'center',
                justifyContent: 'center',
              }}
            >
              <Grid container justify={"center"}>
                {
                  details.forms && details.forms.map((form, index) => {
                    return (
                      <Grid item key={`form${index}`} xs={12} sm={12} md={3} lg={3} xl={3}>
                        <div
                          style={{
                            width: '100%',
                            paddingTop: 25,
                            paddingBottom: 25,
                            display: 'flex',
                            alignItems: 'center',
                            alignContent: 'center',
                            justifyContent: 'center',
                            minHeight: 265
                          }}
                        >
                          <Card
                            style={{
                              maxWidth: 250,
                              margin: 10,
                              flex: 1,
                              minHeight: 265,
                              position: 'relative'
                            }}
                          >
                            <CardHeader
                              style={{
                                textAlign: 'center'
                              }}
                              subheader={form.title}
                            />
                            <CardContent style={{
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              alignContent: 'center'
                            }}>
                              <img style={{width: 100}} src={require('assets/img/form_640_color.png')}/>
                            </CardContent>
                            <CardActions
                              style={{
                                width: '100%',
                                display: 'flex',
                                placeContent: 'center',
                                bottom: 0,
                                position: 'absolute'
                              }}
                            >
                              <a href={form.link} target={"_blank"}>
                                <Button variant={"contained"} color={"primary"}>DOWNLOAD FORM</Button>
                              </a>
                            </CardActions>
                          </Card>
                        </div>
                      </Grid>
                    )
                  })
                }
              </Grid>
            </div>
          </div>
        }
      </div>
      {
        details && details.fullText &&
        details.fullText.map(section => {
            return (
              <Grid container style={{paddingLeft: '5%', paddingRight: '5%'}}>
                <Grid item xs={12} style={{display: 'flex', justifyContent: 'center'}}>
                  <h2 className={classes.title}>{section.title || 'Procedure for Filing'}</h2>
                </Grid>
                <Hidden smDown>
                  <Grid item xs={12} style={{marginBottom: -10}}>
                    <hr
                      style={{
                        width: section.steps.length === 3 ? '66%' : section.steps.length === 2 ? '50%' : '75%',
                        height: 2,
                        border: 0,
                        backgroundImage:
                          `linear-gradient( 95deg,${primaryColor} 0%,${tinycolor2(infoColor).darken(25).toHexString()} 50%,${secondaryColor} 100%)`,
                        margin: ' 32px auto 0 auto'
                      }}
                    />
                  </Grid>
                </Hidden>
                {
                  section.steps.map((line, index) => {
                      return (
                        <Grid item xs={12} sm={12} md={parseInt(12 / section.steps.length)} style={{marginTop: -17}}>
                          <Hidden smDown>
                            <div style={{display: 'flex', justifyContent: 'center'}}>
                              <Step>
                                <StepLabel
                                  StepIconComponent={ColorlibStepIcon}
                                  icon={line.icon}
                                />
                              </Step>
                            </div>
                            <div style={{display: 'flex', justifyContent: 'center'}}>
                              <h5 className={classes.title}>{line.title}</h5>
                            </div>
                            <div style={{color: '#999', textAlign: 'left'}}>
                              <h4>
                                {line.details}
                              </h4>
                            </div>
                          </Hidden>
                          <Hidden mdUp>
                            <div style={{display: 'flex', justifyContent: 'flex-start', flexDirection: 'row'}}>
                              <div style={{flex: 1, display: 'flex', justifyContent: 'flex-end', marginRight: 25}}>
                                <Step>
                                  <StepLabel
                                    StepIconComponent={ColorlibStepIcon}
                                    icon={line.icon}
                                  />
                                </Step>
                              </div>
                              <div style={{width: '75%', marginTop: -15}}>
                                <div style={{textAlign: 'left'}}>
                                  <h5 className={classes.title}>{line.title}</h5>
                                </div>
                                <div style={{color: '#999', textAlign: 'left'}}>
                                  <h4>
                                    {line.details}
                                  </h4>
                                </div>
                              </div>
                            </div>
                          </Hidden>
                        </Grid>
                      )
                    }
                  )
                }
              </Grid>
            )
          }
        )
      }
      {/*{*/}
      {/*  details && details.fullText && details && details.fullText.length > 0 &&*/}
      {/*  <div style={{padding: '0px 15% 20px 15%'}}>*/}
      {/*    <Grid container>*/}
      {/*      {details && details.fullText && details.fullText.map((line, index) => {*/}
      {/*        let colSize = 3*/}
      {/*        if (details.fullText.length < 5) {*/}
      {/*          colSize = [12, 6, 3, 4][details.fullText.length - 1];*/}
      {/*        }*/}
      {/*        return (*/}
      {/*          <Grid item xs={12} sm={12} md={12} lg={colSize} xl={colSize}>*/}
      {/*            {line}<br/>*/}
      {/*          </Grid>*/}
      {/*        )*/}
      {/*      })}*/}
      {/*    </Grid>*/}
      {/*  </div>*/}
      {/*}*/}
      {
        details && details.bottomContent && details.bottomContent.length > 0 &&
        <div style={{
          backgroundColor: tinycolor2(secondaryColor).brighten(15).toHexString(),
          color: 'white',
          padding: '25px 15% 0px 15%'
        }}>
          {details.bottomContent.map((line, index) => {
            return (
              <div>
                {line}<br/>
              </div>
            )
          })}
        </div>
      }
    </div>
  )
}

export default withStyles(productStyle)(ProductDetails);
