import React from "react";
import {Grid} from '@material-ui/core'

export default {
  description: [
    (
      <React.Fragment>
        <h5>
          <Grid container style={{marginTop: -100}}>
            <Grid item xs={12} sm={12} md={12} lg={4} xl={4} style={{padding: 50}}>
              <div style={{display: 'flex', justifyContent: 'center', flexDirection: 'column'}}>
                <div style={{display: 'flex', justifyContent: 'center'}}>
                  <img
                    src={require('../../../assets/img/index-pdf.jpg')}
                    style={{
                      borderRadius: "6px",
                      boxShadow:
                        "0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)",
                    }}
                  />
                </div>
                <br/>
                <div style={{display: 'flex', justifyContent: 'center'}}>
                  National Insurance Underwriters, Inc. Agency Questionnaire
                </div>
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={4} xl={4} style={{padding: 50}}>
              <div style={{display: 'flex', justifyContent: 'center', flexDirection: 'column'}}>
                <div style={{display: 'flex', justifyContent: 'center'}}>
                  <img
                    src={require('../../../assets/img/index-pdf.jpg')}
                    style={{
                      borderRadius: "6px",
                      boxShadow:
                        "0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)",
                    }}
                  />
                </div>
                <br/>
                <div style={{display: 'flex', justifyContent: 'center'}}>
                  Insurance Glossary of Terms
                </div>
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={4} xl={4} style={{padding: 50}}>
              <div style={{display: 'flex', justifyContent: 'center', flexDirection: 'column'}}>
                <div style={{display: 'flex', justifyContent: 'center'}}>
                  <img
                    src={require('../../../assets/img/index-pdf.jpg')}
                    style={{
                      borderRadius: "6px",
                      boxShadow:
                        "0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)",
                    }}
                  />
                </div>
                <br/>
                <div style={{display: 'flex', justifyContent: 'center'}}>
                  Texas Auto Agency Appointment Forms
                </div>
              </div>
            </Grid>
          </Grid>
        </h5>
      </React.Fragment>
    )
  ]
}
