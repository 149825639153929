import React from "react";
import {Grid, Hidden} from '@material-ui/core';
import SwipeableTextMobileStepper from '../../../components/SwipableTextMobileStepper/SwipableTextMobileStepper'

export default {
  title: "Advantages",
  tiles: [
    {
      image: (
        <img style={{width: 200}} src={require('../../../assets/img/piece1.png')}/>
      ),
      showTile: true,
      title: (
        <React.Fragment>
          COMPLETE TOWING SERVICES
        </React.Fragment>
      ),
      description: (
        <React.Fragment>
          Our roadside assistance assists and supports you regardless of vehicle type.
        </React.Fragment>
      ),
      detailFullExpand: true,
      detail: (
        <React.Fragment>
          <div style={{textAlign: 'left'}}>
            <Hidden smDown>
              <SwipeableTextMobileStepper
                hideTitle
                images={[
                  {
                    imgPath: require('../../../assets/img/towing_services_hd.png')
                  },
                  {
                    imgPath: require('../../../assets/img/towing_services_ld.png')
                  },
                  {
                    imgPath: require('../../../assets/img/towing_services_md.png')
                  },
                  {
                    imgPath: require('../../../assets/img/towing_services_s.png')
                  },
                  {
                    imgPath: require('../../../assets/img/towing_services_sd.png')
                  }
                ]}
              />
            </Hidden>
            <Hidden mdUp>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={6}>
                  <img style={{
                    width: '100%',
                    borderRadius: "6px",
                    margin: "0 10px 0 0",
                    boxShadow:
                      "0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)"
                  }} src={require('../../../assets/img/towing_services_hd.png')}/>
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <img style={{
                    width: '100%',
                    borderRadius: "6px",
                    margin: "0 10px 0 0",
                    boxShadow:
                      "0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)"
                  }} src={require('../../../assets/img/towing_services_ld.png')}/>
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <img style={{
                    width: '100%',
                    borderRadius: "6px",
                    margin: "0 10px 0 0",
                    boxShadow:
                      "0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)"
                  }} src={require('../../../assets/img/towing_services_md.png')}/>
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <img style={{
                    width: '100%',
                    borderRadius: "6px",
                    margin: "0 10px 0 0",
                    boxShadow:
                      "0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)"
                  }} src={require('../../../assets/img/towing_services_s.png')}/>
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <img style={{
                    width: '100%',
                    borderRadius: "6px",
                    margin: "0 10px 0 0",
                    boxShadow:
                      "0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)"
                  }} src={require('../../../assets/img/towing_services_sd.png')}/>
                </Grid>
              </Grid>
            </Hidden>
          </div>
        </React.Fragment>
      )
    },
    {
      image: (
        <img style={{width: 200}} src={require('../../../assets/img/piece2.png')}/>
      ),
      showTile:
        true,
      title:
        (
          <React.Fragment>
            CLIENT MANAGEMENT PORTAL
          </React.Fragment>
        ),
      description:
        (
          <React.Fragment>
            See calls coming in LIVE! Watch your services progress and see all interactions REAL TIME and with complete
            transparency
          </React.Fragment>
        ),
      detail:
        (
          <React.Fragment>
            <div style={{textAlign: 'left'}}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={6}>
                  <img style={{
                    width: '100%',
                    borderRadius: "6px",
                    margin: "0 10px 0 0",
                    boxShadow:
                      "0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)"
                  }} src={require('../../../assets/img/dispatch_center.jpg')}/>
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <h4>
                    See calls coming in LIVE! Watch your services
                    progress and see all interactions REAL TIME!
                    <br/>
                    <br/>
                    NSD has Service Algorithms that track and follow information looking at the last 30 services of the
                    same type performed by our providers. For instance, if the call is for a jumpstart the
                    intelli-Dispatch System is looking at the last 30 jumpstarts performed by the network providers and
                    selects the highest rated performer. NSD's Algorithm System is completely customizable featuring
                    cost,
                    distance, ATA vs ETA, status, etc.
                  </h4>
                </Grid>
              </Grid>
            </div>
          </React.Fragment>
        )
    }
    ,
    {
      image: (
        <img style={{width: 200}} src={require('../../../assets/img/piece3.png')}/>
      ),
      showTile:
        true,
      title:
        (
          <React.Fragment>
            MULTINATIONAL COVERAGE
          </React.Fragment>
        ),
      description:
        (
          <React.Fragment>
            NSD's dispatch was rated #1 by service providers nationwide, including the United States, Canada, and
            Mexico.
          </React.Fragment>
        ),
      detail:
        (
          <React.Fragment>
            <div style={{textAlign: 'left'}}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={6}>
                  <img style={{
                    width: '100%',
                    borderRadius: "6px",
                    margin: "0 10px 0 0",
                    boxShadow:
                      "0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)"
                  }} src={require('../../../assets/img/city_night.jpg')}/>
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <h3>
                    NSD'S DISPATCH FEATURES
                  </h3>
                  <ul>
                    <li>
                      Real Time Algorithms to Determine the Most Apropriate Vendor by Distance, Performance, and Time of
                      Day
                    </li>
                    <li>
                      Member Communication by Phone, Text, App, Web Portal, & Email
                    </li>
                    <li>
                      GPS Location Technology(Reverse Geo-Coding and Lat/Long Pinpoint)
                    </li>
                  </ul>
                </Grid>
              </Grid>
            </div>
          </React.Fragment>
        )
    }
    ,
    {
      image: (
        <img style={{width: 200}} src={require('../../../assets/img/piece4.png')}/>
      ),
      showTile:
        true,
      title:
        (
          <React.Fragment>
            PROPRIETARY NETWORK
          </React.Fragment>
        ),
      description:
        (
          <React.Fragment>
            Ranked #1 Motor Club for two years running by tow411.net
          </React.Fragment>
        ),
      detail:
        (
          <React.Fragment>
            <div style={{textAlign: 'left'}}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={6}>
                  <img style={{
                    width: '100%',
                    borderRadius: "6px",
                    margin: "0 10px 0 0",
                    boxShadow:
                      "0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)"
                  }} src={require('../../../assets/img/silver_keyboard.jpg')}/>
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <h4>
                    NSD is the only company of its kind that operates and dispatches from their own servicing networks.
                    This allows us the flexibility to add or increase benefit at a competitive cost while providing an
                    unparalleled customer service.
                  </h4>
                  <h3>
                    THIS NETWORK INCLUDES
                  </h3>
                  <h4>
                    <ul>
                      <li>
                        Proprietary service provider ranking system
                      </li>
                      <li>
                        Network participants are 100% licensed and insured
                      </li>
                      <li>
                        Customer service performances survey on every call
                      </li>
                      <li>
                        Uniformed and professional service technicians
                      </li>
                      <li>
                        Quickest industry response time
                      </li>
                      <li>
                        Most appropriate service provider dispatched every time
                      </li>
                    </ul>
                  </h4>
                </Grid>
              </Grid>
            </div>
          </React.Fragment>
        )
    }
    ,
    {
      image: (
        <img style={{width: 200}} src={require('../../../assets/img/piece5.png')}/>
      ),
      showTile:
        true,
      title:
        (
          <React.Fragment>
            ROADSIDE INTEGRATION
          </React.Fragment>
        ),
      description:
        (
          <React.Fragment>
            Roadside solutions delivered via mobile applications, web-based platforms, API integrations, and call center
            services.
          </React.Fragment>
        ),
      detail:
        (
          <React.Fragment>
            <div style={{textAlign: 'left'}}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={6}>
                  <img style={{
                    width: '100%',
                    borderRadius: "6px",
                    margin: "0 10px 0 0",
                    boxShadow:
                      "0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)"
                  }} src={require('../../../assets/img/integration.png')}/>
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <h4>
                    NSD has leveraged cutting edge technology and innovative product development to create meaningful
                    consumer programs. The programs are designed to integrate with your company's technology and drive
                    significant value in your marketplace.
                  </h4>
                  <h4>
                    <ul>
                      <li>
                        We have an in-house IT department staffed with APP and software developers, as well as
                        programmers. NSD does not outsource our IT and provides all integration, reporting and
                        development
                        at no cost to our clients!
                      </li>
                      <li>
                        All NSD programs and algorithms are proprietary. We do not use any off the shelf or canned
                        software solutions.
                      </li>
                    </ul>
                  </h4>
                </Grid>
              </Grid>
            </div>
          </React.Fragment>
        )
    }
    ,
    {
      image: (
        <img style={{width: 200}} src={require('../../../assets/img/piece6.png')}/>
      ),
      showTile:
        true,
      title:
        (
          <React.Fragment>
            ABOUT US
          </React.Fragment>
        ),
      description:
        (
          <React.Fragment>
            When on the road you'll know that you are covered and protected by an organization that cares and has been
            caring since 1962!
          </React.Fragment>
        ),
      detail:
        (
          <React.Fragment>
            <div style={{textAlign: 'left'}}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={6}>
                  <img style={{
                    width: '100%',
                    borderRadius: "6px",
                    margin: "0 10px 0 0",
                    boxShadow:
                      "0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)"
                  }} src={require('../../../assets/img/group_photo.jpg')}/>
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <h4>
                    With over 50 years of innovation and experience, NSD has built a name for reliability and
                    dependability within the automotive industry. Our business philosophy is a simple one: we create
                    products and services that are of high value to our clients and deliver exceptional benefits to our
                    customers.
                  </h4>
                  <h4>
                    <ul>
                      <li>
                        25 million customers
                      </li>
                      <li>
                        Over 1.5 million applications per month
                      </li>
                      <li>
                        Proven track record of success
                      </li>
                      <li>
                        Revolutionary in product development
                      </li>
                      <li>
                        Revenues in excess of $860 million in 2016
                      </li>
                      <li>
                        Strong automotive relationships
                      </li>
                      <li>
                        Employee owned company
                      </li>
                    </ul>
                  </h4>
                </Grid>
              </Grid>
            </div>
          </React.Fragment>
        )
    }
  ]
}
