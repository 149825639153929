import React from "react";
import {Grid} from "@material-ui/core";

export default {
  "name": "AGENTS ERRORS & OMISSIONS INSURANCE",
  "description": [
    (
      <React.Fragment>
        <Grid container>
          <Grid item xs={12} sm={12} md={12} lg={8} xl={8}>
            NIU offers Errors and Omissions Insurance to insurance agents, brokers, MGAs and MGUs. For over 50 years NIU
            and
            its affiliates have been providing excellent customer service and administrative support for your insurance
            needs. NIU can provide your agency with a competitive quote for your E & O coverage through several of the
            largest and most reliable insurance providers. Coverage is available for all sizes and types of insurance
            agencies, including Retail Agents, Specialty Agent's, Chains, (start ups welcomed) and Brokers, as well as
            Wholesale Brokers and MGA's.
            <br/>
            <br/>
            You may be asking yourselves what Errors and Omissions Insurance is and why should you purchase it. Errors
            and
            Omissions Insurance is business liability insurance for professionals such as insurance agents, managing
            general
            agencies and wholesale brokers. This type of insurance helps to protect a professional, an individual or a
            company from bearing the full cost for lawsuits relating to an error or omission in providing covered
            Professional Services. "Even Insurance Professionals can make a mistake." Some highlights are outlined
            below:
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={4} xl={4} style={{paddingLeft: 50}}>
            <img
              src={require('../../../assets/img/insurance-eo-640.jpg')}
              style={{
                borderRadius: "6px",
                boxShadow:
                  "0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)",
                width: '100%',
                maxWidth: 600
              }}
            />
          </Grid>
        </Grid>
      </React.Fragment>
    ),
    (
      <React.Fragment>
        <ul>
          <li>Discounts for loss-free experience and loss-control efforts</li>
          <li>
            Prior Acts Protection for qualifying applicants
          </li>
          <li>
            Limits starting from $300,000 to $2,000,000 (Higher options available)
          </li>
          <li>
            Coverage For Tax Preparation & Bookkeeping Service (optional)
          </li>
          <li>
            Premiums Starting as low as $1,000 & Deductibles from $2,500
          </li>
          <li>
            Defense cost outside the limit (optional)
          </li>
          <li>
            Fast turn around time for quotes Our Insurance Agents E & O Program is quoted in house.
          </li>
          <li>Premium Financing options</li>
        </ul>
      </React.Fragment>
    ),
    (
      <React.Fragment>

        To obtain a quote please contact our office at 800-338-2680 Ext 299 for more information, or you can download
        and fax our application. Simply fill it out and fax it back to us at 561-226-3611. E & O applications:
        California --- All Other States
      </React.Fragment>
    )
  ]
}
