import React from "react";

export default {
  "name": "FLOOD INSURANCE",
  image: require('../../../assets/img/hurricane-harvey-3624911_640.jpg'),
  "description": [
    (
      <React.Fragment>
        This National Flood Insurance Program (NFIP) product is administered by an industry leader in specialty
        insurance coverage. Your agency will have a direct relationship with highly experienced flood underwriters and
        people who take pride in providing customer service.
      </React.Fragment>
    ),
    (
      <React.Fragment>
        This program utilizes the latest emerging technology to generate quotes quickly and shortens the application
        process. A step-by-step intelligent system helps you create and submit an application for flood coverage.
      </React.Fragment>
    ),
    (
      <React.Fragment>
        Free flood zone determinations and the ability to view policy information and billing online make this web-based
        program state-of-the-art, efficient, and user friendly. It is available nationwide.
      </React.Fragment>
    ),
    (
      <React.Fragment>
        Provide your clients with the protection they need while earning additional agency commissions by calling
        1-800-338-2680 x507.
      </React.Fragment>
    )
  ]
}
