import React from "react";

export default {
  title: "Services",
  items:
    [
      {
        id: "pre-paid-legal",
        "title": "Pre-Paid Legal Services, Inc.",
        "imageAlt": 'Legal Services Representative',
        image: require("assets/img/AdobeStock_247963248.jpg"),
        description: [
          "Nation Safe Drivers and Pre-Paid Legal Services, Inc. are working together to provide a new tool for all Nation Safe Drivers' Independent Agents, MGAs and Agencies.",
          "For 32 years, Pre-Paid Legal Services, Inc. has provided equal access to justice for middle income families across the United States for less than a cup of coffee a day."
        ]
      },
      {
        id: 'collision-towing',
        "title": "Collision Towing",
        "name": "Collision Towing",
        imageAlt: '',
        image: require("assets/img/AdobeStock_299103030.jpg"),
        detailImage: require("assets/img/tow-truck-2901948_640_color.png"),
        "description": [
          "Offers Towing and Roadside Assistance for members' vehicles. Certain plans also include rental reimbursement for a covered peril. Nation Safe Drivers will customize these programs to fit your specific needs. With access to over 45,000 service vehicles in our network, we are quick to respond to your client's needs. Partner with us for award winning service for on-time arrival and customer service."
        ],
        mainContent: (
          <React.Fragment>
            Offers Towing and Roadside Assistance for members' vehicles. Certain plans also include rental reimbursement
            for a covered peril. Nation Safe Drivers will customize these programs to fit your specific needs. With
            access to over 45,000 service vehicles in our network, we are quick to respond to your client's needs.
            Partner with us for award winning service for on-time arrival and customer service.
            <br/>
            <br/>
            Case Study: Major property and casualty insurer requiring over 500,000 accident related tow services per
            year has been able to save an average of $175 per accident due to quicker accident response time, less
            customer inconvenience with a rental car and fewer days an accident vehicle is in storage/impound. This has
            resulted in over $87 Million in savings being driven to their bottom line.
            <br/>
            <br/>
            <b><a href={`/insurancePartners/product-details/roadside-assistance`}>Click here</a> for 24-hour
              Roadside Assistance and Motor Club Services</b>
          </React.Fragment>
        ),
        centerItemsTitle: "Insurance Company and MGA Benefits",
        centerItems: [
          {
            title: "Client Base",
            content: (
              <ul>
                <li>Property and Casualty Insurance Companies</li>
                <li>Managing General Agencies</li>
                <li>Service Contract and related companies</li>
              </ul>
            )
          },
          {
            title: "Industry leading programs designed to...",
            content: (
              <ul>
                <li>Enhance profitability</li>
                <li>Reduce Collision Towing and related expenses e.g. replacement vehicle costs, storage fees, secondary
                  tows, at scene towing etc.
                </li>
                <li>Capitalize on and leverage usage data to make smart, profitable decisions across an insurance
                  company's portfolio of businesses
                </li>
              </ul>
            )
          },
          {
            title: "Services Include",
            content: (
              <ul>
                <li>Private labeling of services</li>
                <li>Data relay of vehicle location and status for appraisal or damage estimate</li>
                <li>Rapid response towing services at the scene of an accident</li>
                <li>Secondary towing services to move a vehicle from storage to repair facilities</li>
                <li>Salvage towing services to deliver a total loss vehicle to a salvage facility</li>
              </ul>
            )
          }
        ]
      },
      {
        "id": "rx-prescription-program",
        "title": "Rx Prescription Program",
        imageAlt: '',
        image: require("assets/img/drugs-1728381_640.jpg"),
        "description": [
          "Offers discounts on prescription drugs at over 51,000 pharmacies nationwide. This program gives significant savings through mail orders, walk-in situations, and through our \"preferred pricing\" program. This program is one of the most comprehensive prescription cards available today. Nation Safe Drivers can provide this card to you for a very affordable price for your organization and your members."
        ],
        "note": "Not available in all States."
      }
    ]
}
