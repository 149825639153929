import React from 'react';
import Parallax from "../../../components/Parallax/Parallax";
import {Grid} from "@material-ui/core";
import landingPageSlides from '../../../content/LandingPage/main';
import withStyles from "@material-ui/core/styles/withStyles";
import landingPageStyle from "../../../assets/jss/material-kit-react/views/landingPage";
import Carousel from "../../../components/Carousel";
import Button from "../../../components/CustomButtons/Button";

const LandingPageCarousel = ({ classes }) => {
    return (
        <Carousel
            interval={7000}
            showIndicators={false}
            autoPlay={true}
            stopOnHover={false}
            infiniteLoop={true}
            showStatus={false}
        >
            {
                landingPageSlides.map(slide => {
                    return (
                        <a href={slide.href}>
                            <Parallax filter={slide.filter} image={slide.image} style={{height: "85vh", minHeight: 750}}>
                                <div className={classes.container} style={{paddingLeft: '10%'}}>
                                    <Grid container style={{marginTop: 0}}>
                                        <Grid xs={12} sm={12} md={6}>
                                            <div style={{marginTop: -65}} className={classes.left}>
                                                <h1 className={classes.title}>{slide.title}</h1>
                                                <h4 className={classes.left}>
                                                    {slide.subTitle}
                                                </h4>
                                                {slide.button && <Button href={slide.href}>{slide.button}</Button>}
                                                <br/>
                                            </div>
                                        </Grid>
                                        <Grid xs={12} sm={12} md={6}>
                                            <img style={{width:400}} src={slide.subImage} />
                                        </Grid>
                                    </Grid>
                                </div>
                            </Parallax>
                        </a>
                    )
                })
            }
        </Carousel>
    );
}

export default withStyles(landingPageStyle)(LandingPageCarousel);
