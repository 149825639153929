import React from "react";

export default {
  title: "Insurance",
  items: [
    {
      id: "commercial-insurance",
      "name": "Commercial Insurance",
      detailImage: require("assets/img/commercial-insurance_640_color.png"),
      image: require("assets/img/insurance-commercial-640.jpg"),
      "description": [
        "National Insurance Underwriters (NIU) has become one of the largest underwriters of Hospitality Insurance in the market place. NIU Our \"A XII\" rated market, competitive commissions and specially designed products provide all that is necessary for you to become the Quick Service and Franchise Restaurant insurance agent of choice. NIU offer coverage for Nightclubs, Taverns, Discotheques, Wine Bars, Sports Bars and Adult Entertainment Clubs, along with Live Music Venues and Concerts. Coverage Available: General Liability, Liquor Liability, HNOA, and A&B on most risk."
      ],
      mainContent: (
        <React.Fragment>
          As the name suggests, commercial insurance protects businesses, including business owners and their employees.
          Because every business is different, there is no one-size-fits-all insurance policy for small business owners.
          <br/>
          <br/>
          For instance, an ice cream shop has very different insurance needs than an auto repair shop or a golf course.
          That’s why many small businesses opt for a business owners policy (BOP), which combines commercial property
          insurance, general liability insurance, crime insurance and other coverages into one convenient package.
          <br/>
          <br/>
          Business owners also have the option to choose coverage based on their industry.
        </React.Fragment>
      ),
      centerItems: [
        {
          title: "Restaurants",
          content: "National Insurance Underwriters (NIU) has become one of the largest underwriters of Hospitality Insurance in the market place. NIU recognizes that restaurants, café, bakeries and local tavern proprietors have unique business requirements. We specialize in casual dining, fine dining, take out, family style, cafes, pizza / sub shops and delis. We offer a Comprehensive Package Program or Monoline Liability in 42 states."

        },
        {
          title: "Quick Service Restaurants",
          content: (
            <React.Fragment>
              Our \"A XII\" rated market, competitive commissions and specially designed products provide all that is
              necessary for you to become the Quick Service and Franchise Restaurant insurance agent of choice.
              <br/>
              <br/>
              We bring Independent Agents a unique and comprehensive package of products, services and support to assist
              in expanding your book of business. Some of our clients include are Wendy's, Popeye's, Long John Silvers,
              Five Guys Burgers and Fries and Hardee's.
            </React.Fragment>
          )
        },
        {
          title: "Nightclubs and Bars",
          content: "NIU offer coverage for Nightclubs, Taverns, Discotheques, Wine Bars, Sports Bars and Adult Entertainment Clubs, along with Live Music Venues and Concerts. Coverage Available: General Liability, Liquor Liability, HNOA, and A&B on most risk."
        }
      ],
      sections: [
        {
          title: "Additional offerings",
          description: [
            "We also offer select programs for retail store, office exposure, lessor risk, and artisan contractors in certain states."
          ]
        }
      ]
    },
    {
      id: "auto-insurance",
      "name": "Auto Insurance",
      image: require("assets/img/insurance-auto-640.jpg"),
      detailImage: require("assets/img/car-insurance_640_color.png"),
      "description": [
        "National Insurance Underwriters (NIU) is a full service MGA and is an affiliate of the Nation Safe Drivers (NSD) group of companies. NIU currently manages automobile insurance program for Occidental Fire and Casualty Company of North Carolina in Colorado and Maryland. Additionally, NIU markets homeowners, renters, commercial, agent error and omissions and a variety of niche products nationwide."
      ],
      mainContent: (
        <React.Fragment>
          <b>Personal Automobile Insurance Program</b>
          <br/>
          <br/>
          National Insurance Underwriters (NIU) is a full service MGA and is an affiliate of the Nation Safe Drivers
          (NSD) group of companies. NIU currently manages automobile insurance program for Occidental Fire and Casualty
          Company of North Carolina in Colorado and Maryland. Additionally, NIU markets homeowners, renters, commercial,
          agent error and omissions and a variety of niche products nationwide.
          <br/>
          <br/>
          Our auto programs combine traditional conservative pricing and underwriting values with the latest technology.
          The NIU auto program provides a Web based quoting and binding system where motor vehicle records and clue
          reports are ordered prior to binding an application. This reduces additional premiums and cancellations.
          Suspense items are reduced as drivers are automatically listed as drivers or excluded. Additionally, this is a
          point of sale policy issuance program. The declaration page, all forms and the ID cards are printed at the
          point of sale so the customer leaves with their entire policy in hand.
        </React.Fragment>
      ),
      centerItems: [
        {
          title: "Features",
          content: (
            <React.Fragment>
              <ul>
                <li>Top Commissions "Some of the highest in the industry"</li>
                <li>New and Renewal business processing from quote to binding</li>
                <li>Post sale servicing- online policy inquiry, endorsement rating, forms, and status</li>
                <li>Advanced billing system allows 24/7 payment access and billing inquiry</li>
              </ul>
            </React.Fragment>
          )
        },
        {
          title: "Advantages",
          content: (
            <React.Fragment>
              <ul>
                <li>Faster binding/issuance and improved cash flow</li>
                <li>Accurate, real time online quoting 24/7</li>
                <li>Faster underwriter response and improved underwriting result</li>
                <li>Lowered operating cost through increased automation</li>
              </ul>
            </React.Fragment>
          )
        }
      ],
      sections: [
        {
          title: "Addtional perks",
          description: [
            "NIU also offers \"NSD\" Towing and Rental along with Emergency Road Service Assistance program. This is available on a stand alone basis or as an endorsement in some states."
          ]
        },
        {
          title: "We support you",
          description: [
            "National Insurance Underwriters takes pride in providing unprecedented customer service and assistance to their agents and clients."
          ]
        },
        {
          title: "Availability",
          description: [
            "The program is available in Colorado and Maryland - Additional states will be coming soon."
          ]
        }
      ]
    },
    {
      id: "home-insurance",
      "title": "Homeowners Insurance",
      "name": "Homeowners Insurance",
      image: require("assets/img/insurance-home-640.jpg"),
      detailImage: require("assets/img/home-insurance_640_color.png"),
      "description": [
        "NSD offers renters HO4, flood insurance, and homeowners HO3 coverage to meet all your homeowners insurance needs.Web-based 24 hour access allows our agents to quote, store information, and bind coverage online, in minutes. We offer 1-Pay and 4-Pay Payment Plans, the program is set-up for ACH and/or Credit Card payments. Your agency will have a direct relationship with highly experienced flood underwriters and people who take pride in providing customer service. t is underwritten by an \"A\" (Excellent) rated admitted carrier. It is completely web-based for quoting, underwriting, completing applications and binding coverage."
      ],
      mainContent: (
        <React.Fragment>
          his HO4 product is underwritten by an "A" rated insurance company. It is offered through our agency partners
          in 22 states. Web-based 24 hour access allows our agents to quote, store information, and bind coverage
          online, in minutes. We offer 1-Pay and 4-Pay Payment Plans, the program is set-up for ACH and/or Credit Card
          payments.
          <br/>
          <br/>
          There is no underwriting restrictions and no credit scoring requirements. The "Packaged" program includes
          Personal Liability, Medical Payment to Others, and Personal Property Coverage with Replacement Cost included.
          There is a $500 deductible. The program will protect your client's personal property and protect their assets
          from liability exposure. It is a great way to strengthen your relationship and retain your business.
          <br/>
          <br/>
          Competitive rates, competitive commissions, limited underwriting, and complete automation, make this program a
          great choice for your agency. You may demonstrate our online system today!
          <br/>
          <b>Please call 1-800-338-2680 x507.</b>
        </React.Fragment>
      ),
      centerItems: [
        {
          title: "Flood Insurance",
          content: (
            <React.Fragment>
              This National Flood Insurance Program (NFIP) product is administered by an industry leader in specialty
              insurance coverage. Your agency will have a direct relationship with highly experienced flood underwriters
              and people who take pride in providing customer service.
              <br/>
              <br/>
              This program utilizes the latest emerging technology to generate quotes quickly and shortens the
              application process. A step-by-step intelligent system helps you create and submit an application for
              flood coverage.
              <br/>
              <br/>
              Free flood zone determinations and the ability to view policy information and billing online make this
              web-based program state-of-the-art, efficient, and user friendly. It is available nationwide.
              <br/>
              Provide your clients with the protection they need while earning additional agency commissions by calling
              <b>1-800-338-2680 x507</b>.
            </React.Fragment>
          )
        },
        {
          title: "Homeowners",
          content: (
            <React.Fragment>
              This program offers modified HO3 coverage with very limited underwriting. It is underwritten by an "A"
              (Excellent) rated admitted carrier. It is completely web-based for quoting, underwriting, completing
              applications and binding coverage.
              <br/>
              <br/>
              1-4 Family residential dwellings, Townhouses and Row houses with firewalls are eligible for coverage. The
              dwelling may be owner or tenant occupied. Coverage is also available for seasonal-use homes, vacant
              dwellings and foreclosures (no mobile homes, multi-unit condos or apartments).
              <br/>
              <br/>
              Homegard-HO3 requires no credit scoring, no photos or inspection by the writing agent. Dwelling values
              from $100,000 to $750,000. The policy is "All Risk" with Personal Property, Other Structures and Liability
              included for owner-occupied homes.
              <br/>
              <br/>
              Our program provides Direct Bill to the insured or Mortgage Company with "Annual Pay", "4-Pay" and "6-Pay"
              available. Not available in some coastal counties and states.
            </React.Fragment>
          )
        },
        {
          title: "Exclusive HO 3 Homeowners Program",
          content: (
            <React.Fragment>
              <ul>
                <li>Program is completely web-based for EZ quoting, underwriting, with online application submission for
                  Binding.
                </li>
                <li>Residences should be insured for to 100% Replacement Cost. 80% Coinsurance applies.</li>
                <li>Eligible Single Family Homes: Owner Occupied One or Two Family Dwellings</li>
                <li>Personal Property can be written ACV or Replacement Cost on Contents</li>
                <li>Limited Coastal Restrictions in Most States (written with or without wind)</li>
                <li>Non Credit Scored</li>
                <li>Dwelling Values from $120,000 to $500,000</li>
                <li>Personal Liability may be written to a maximum of $300,000.</li>
                <li>Older homes accepted with proof of recent updates</li>
                <li>Premiums starting as low as $800</li>
              </ul>


            </React.Fragment>
          )
        }
      ],
      sections: [
        {
          title: "Included at no additional cost",
          description: [
            "Coverage For: Rental Car Discounts, Travel Discounts, Prescription Card Discount and Emergency Cash"
          ]
        },
        {
          title: "More states coming soon",
          description: [
            "Program is available in 8 States. EZ installment options to the insured. We also offer mortgagee bill with \"Annual Pay\" and other installment options."
          ]
        }
      ]
    }, {
      id: "eando-insurance",
      "title": "E & O Insurance",
      "name": "E & O Insurance",
      image: require("assets/img/insurance-eo-640.jpg"),
      detailImage: require("assets/img/handshake_640_color.png"),
      "description": [
        "NIU offers Errors and Omissions Insurance to insurance agents, brokers, MGAs and MGUs. For over 50 years NIU and its affiliates have been providing excellent customer service and administrative support for your insurance needs. NIU can provide your agency with a competitive quote for your E & O coverage through several of the largest and most reliable insurance providers. Coverage is available for all sizes and types of insurance agencies, including Retail Agents, Specialty Agent's, Chains, (start ups welcomed) and Brokers, as well as Wholesale Brokers and MGA's."
      ],
      mainContent: (
        <React.Fragment>
          <b>Insurance Agents Errors and Omissions Insurance</b>
          <br/>
          <br/>
          NIU offers Errors and Omissions Insurance to insurance agents, brokers, MGAs and MGUs. For over 50 years NIU
          and its affiliates have been providing excellent customer service and administrative support for your
          insurance needs. NIU can provide your agency with a competitive quote for your E & O coverage through several
          of the largest and most reliable insurance providers. Coverage is available for all sizes and types of
          insurance agencies, including Retail Agents, Specialty Agent's, Chains, (start ups welcomed) and Brokers, as
          well as Wholesale Brokers and MGA's.
          <br/>
          <br/>
          You may be asking yourselves what Errors and Omissions Insurance is and why should you purchase it. Errors and
          Omissions Insurance is business liability insurance for professionals such as insurance agents, managing
          general agencies and wholesale brokers. This type of insurance helps to protect a professional, an individual
          or a company from bearing the full cost for lawsuits relating to an error or omission in providing covered
          Professional Services. "Even Insurance Professionals can make a mistake."
        </React.Fragment>
      ),
      centerItems: [
        {
          title: "Program highlights",
          content: (
            <React.Fragment>
              <ul>
                <li>Discounts for loss-free experience and loss-control efforts</li>
                <li>Prior Acts Protection for qualifying applicants</li>
                <li>Limits starting from $300,000 to $2,000,000 (Higher options available)</li>
                <li>Coverage For Tax Preparation & Bookkeeping Service (optional)</li>
                <li>Premiums Starting as low as $1,000 & Deductibles from $2,500</li>
                <li>Defense cost outside the limit (optional)</li>
                <li>Fast turn around time for quotes Our Insurance Agents E & O Program is quoted in house.</li>
                <li>Premium Financing options</li>
              </ul>
            </React.Fragment>
          )
        }
      ],
      sections: [
        {
          title: "More information for you",
          description: [
            (
              <React.Fragment>
                To obtain a quote please contact our office at <b>800-338-2680 Ext 299</b> for more information, or you
                can download and fax our application. Simply fill it out and fax it back to us at <b>561-226-3611</b>.
                E & O applications:&nbsp;
                <a
                  href={"/files/forms/NMI-APP-8.13.12-Admitted-CA.pdf"}
                  target={"_blank"}
                >
                  California
                </a> ---
                <a
                  href={"/files/forms/Eo_Admitted_App_652013new.pdf"}
                  target={"_black"}
                >
                  All Other States
                </a>
              </React.Fragment>
            )

          ]
        }
      ]
    }
  ]

}
