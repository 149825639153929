import React from "react";
import {Grid} from '@material-ui/core'

export default {
  name: "HOMEOWNERS PROGRAM",
  description: [
    (
      <React.Fragment>
        <Grid container style={{padding: 10}}>
          <Grid item xs={12} sm={12} md={12} lg={6} xl={6} style={{padding: 10}}>
            Program is completely web-based for EZ quoting, underwriting, with online application submission for
            Binding.
            <br/>
            <br/>
            Residences should be insured for to 100% Replacement Cost. 80% Coinsurance applies.
            <br/>
            <br/>
            Eligible Single Family Homes: Owner Occupied One or Two Family Dwellings.
            <br/>
            <br/>
            Personal Property can be written ACV or Replacement Cost on Contents.
            <br/>
            <br/>
            Limited Coastal Restrictions in Most States (written with or without wind)
            <br/>
            <br/>
            Non Credit Scored
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={6} xl={6} style={{padding: 10}}>
            Dwelling Values from $120,000 to $500,000
            <br/>
            <br/>
            Personal Liability may be written to a maximum of $300,000.
            <br/>
            <br/>
            Older homes accepted with proof of recent updates
            <br/>
            <br/>
            Premiums starting as low as $1000
            <br/>
            <br/>
            Included at No Additional Cost Coverage For: Rental Car Discounts, Travel Discounts, Prescription Card
            Discount
            and Emergency Cash
            <br/>
            <br/>
            Program is available in 8 States, more states coming soon... EZ installment options to the insured. We also
            offer mortgagee bill with "Annual Pay" and other installment options.
          </Grid>
        </Grid>
      </React.Fragment>
    )
  ]
}
