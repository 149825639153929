import React from "react";
import classNames from "classnames";
import withStyles from "@material-ui/core/styles/withStyles";
import {Grid, Menu, MenuItem} from '@material-ui/core'
import Header from "components/Header/Header.jsx";
import Footer from "../../components/Footer/Footer.jsx";
import ClaimsCenterHeaderLinks from "components/Header/ClaimsCenterHeaderLinks.jsx";
import Parallax from "components/Parallax/Parallax.jsx";
import content from '../../content/ClaimsCenter/products_main'
import landingPageStyle from '../../assets/jss/material-kit-react/views/landingPage.jsx';
import Products from "./Sections/Products";
import {Helmet} from "react-helmet";

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
  },
})(props => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles(theme => ({
  root: {
    '&:focus': {
      backgroundColor: theme.palette.primary.main,
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem);

const dashboardRoutes = [];

class ClaimsCenter extends React.Component {
  constructor(props) {
    super(props);

    this.pageTitle = React.createRef();
    this.listenScrollEvent = this.listenScrollEvent.bind(this);
    this.state = {
      hideProducts: false,
      topText: 400,
      anchorEl: false,
      arrowRef: null,
      open: false,
      highlightHeader: ""
    }
    this.handleClick = this.handleClick.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  handleArrowRef = node => {
    this.setState({
      arrowRef: node,
    });
  };

  listenScrollEvent = e => {
    this.setState({
      topText: this.pageTitle.current.getBoundingClientRect().y
    })
  }

  componentDidMount() {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
    window.addEventListener('scroll', this.listenScrollEvent);
    this.setState({
      topText: this.pageTitle.current.getBoundingClientRect().top
    })
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.listenScrollEvent);
  }

  handleClick(event) {
    this.setState({anchorEl: event.currentTarget});
  }

  handleClose() {
    this.setState({anchorEl: null});
  }

  render() {
    const {classes, ...rest} = this.props;
    return (
      <div>
        <Helmet>
          <meta charSet="utf-8"/>
          <title>{`NSD CLAIM CENTER PRODUCTS`}</title>
          <meta name="description"
                content={`Please select your product from the choices below.`}/>
          <link rel="canonical" href={window.location.href}/>
        </Helmet>
        <Header
          color="transparent"
          routes={dashboardRoutes}
          smallLogo={true}
          brand="NSD"
          rightLinks={<ClaimsCenterHeaderLinks highlighted={this.state.highlightHeader}/>}
          fixed
          changeColorOnScroll={{
            height: this.state.topText,
            color: "dark"
          }}
          {...rest}
        />
        <Parallax filter image={require("assets/img/claims-center-bg.jpg")} style={{minHeight: 750}}>
          <div className={classes.container} style={{paddingLeft: '10%'}}>
            <Grid style={{marginTop: 0}}>
              <Grid xs={12} sm={12} md={6}>
                <div style={{marginTop: -65}}>
                  <h1 ref={this.pageTitle} className={classes.title}>{content.title}</h1>
                  <h4>
                    {content.subTitle}
                  </h4>
                  <br/>
                </div>
              </Grid>
            </Grid>
          </div>
        </Parallax>
        <div className={classNames(classes.main, classes.mainRaised)}>
          <div className={classes.container}>
            <Products/>
          </div>
        </div>
        <Footer claimsCenter/>
      </div>
    );
  }
}

export default withStyles(landingPageStyle)(ClaimsCenter);
