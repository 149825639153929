import React from "react";

export default {
  items:
    [
      {
        id: 'service-providers',
        name: (
          <React.Fragment>
            Join The NSD Network
            <br/>
            <h3>
              To provide the #1 service, we need amazing partners to work with us. We are always looking to expand our
              network and continue to grow with our partners.
            </h3>
          </React.Fragment>
        ),
        imageAlt: '',
        detailImage: require("assets/img/tow-truck-2901948_640_color.png"),
        brochures: [
          {
            title: "Download Brochure",
            link: "/brochures/NSD_Corporate_Brochure.pdf"
          }
        ],
        mainContent: (
          <React.Fragment>
            <div style={{}}>
              <h2>
                <a href={"https://www.nsdmc.com/ProviderDispatchPortal/ProviderApplication/"} target={"_blank"}>
                  Apply Now
                </a>
              </h2>
            </div>
            <div style={{height: 300}}>
              For any questions regarding becoming an NSD service provider please contact network support team at
              1-888-362-7805.
            </div>
          </React.Fragment>
        )
      }, {
      id: 'brokers',
      name: (
        <React.Fragment>
          Join The NSD Network
          <br/>
          <h3>
            To provide the #1 service, we need amazing partners to work with us. We are always looking to expand our
            network and continue to grow with our partners.
          </h3>
        </React.Fragment>
      ),
      imageAlt: '',
      detailImage: require("assets/img/property-agent-002-512_color.png"),
      brochures: [
        {
          title: "Download Brochure",
          link: "/brochures/NSD_Corporate_Brochure.pdf"
        }
      ],
      mainContent: (
        <React.Fragment>
          <div style={{}}>
            <h2>
              <a href={"https://www.nsdmc.com/ProviderDispatchPortal/ProviderApplication/"} target={"_blank"}>
                Apply Now
              </a>
            </h2>
          </div>
          <div style={{height: 300}}>
            For any questions regarding becoming an NSD service provider please contact network support team at
            1-888-362-7805.
          </div>
        </React.Fragment>
      )
    }, {
      id: 'underwriters',
      name: (
        <React.Fragment>
          Join The NSD Network
          <br/>
          <h3>
            To provide the #1 service, we need amazing partners to work with us. We are always looking to expand our
            network and continue to grow with our partners.
          </h3>
        </React.Fragment>
      ),
      imageAlt: '',
      detailImage: require("assets/img/contract-4545562_640_color.png"),
      brochures: [
        {
          title: "Download Brochure",
          link: "/brochures/NSD_Corporate_Brochure.pdf"
        }
      ],
      mainContent: (
        <React.Fragment>
          <div style={{}}>
            <h2>
              <a href={"https://www.nsdmc.com/ProviderDispatchPortal/ProviderApplication/"} target={"_blank"}>
                Apply Now
              </a>
            </h2>
          </div>
          <div style={{height: 300}}>
            For any questions regarding becoming an NSD service provider please contact network support team at
            1-888-362-7805.
          </div>
        </React.Fragment>
      )
    }
    ]
}
