export default {
  title: "Availability",
  description: "NSD products are available in most states. Please refer to availability options to find out what products are in your state.",
  forms: [
    {
      name: "Non Resident Background",
      link: "/files/forms/MCNAICNonResidentBackground.pdf"
    },
    {
      name: "Non Resident Applications",
      link: "/files/forms/MCNAICNonResidentApplications.pdf"
    },
    {
      name: "MC Licensing Matrix (All States)",
      link: "/files/forms/MC Licensing Matrix.pdf"
    }
  ],
  licensingForms: [
      {
          state: "AL",
          name: "NSD Licensing Form",
          link: "/files/forms/DL-AL.pdf"
      },
    {
      state: "AR",
      name: "NSD Licensing Form",
      link: "/files/forms/DL-AR.pdf"
    },
    {
      state: "CA",
      name: "NSD Licensing Form",
      link: "/files/forms/DL-CA.pdf"
    },
    {
      state: "DE",
      name: "NSD Licensing Form",
      link: "/files/forms/DL-DE.pdf"
    },
    {
      state: "FL",
      name: "NSD Licensing Form",
      link: "/files/forms/NIUFL_2020.pdf"
    },
    {
      state: "KS",
      name: "NSD Licensing Form",
      link: "/files/forms/DL-KS.pdf"
    },
    {
      state: "MA",
      name: "NSD Licensing Form",
      link: "/files/forms/DL-MA.pdf"
    },
    {
      state: "MD",
      name: "NSD Licensing Form",
      link: "/files/forms/DL-MD.pdf"
    },
    {
      state: "MS",
      name: "NSD Licensing Form",
      link: "/files/forms/DL-MS.pdf"
    },
    {
      state: "MT",
      name: "NSD Licensing Form",
      link: "/files/forms/DL-MT.pdf"
    },
    {
      state: "NC",
      name: "NSD Licensing Form",
      link: "/files/forms/DL-NC.pdf"
    },
    {
      state: "NE",
      name: "NSD Licensing Form",
      link: "/files/forms/DL-NE.pdf"
    },
    {
      state: "NH",
      name: "NSD Licensing Form",
      link: "/files/forms/DL-NH.pdf"
    },
    {
      state: "NM",
      name: "NSD Licensing Form",
      link: "/files/forms/DL-NM.pdf"
    },
    {
      state: "NV",
      name: "NSD Licensing Form",
      link: "/files/forms/DL-NV.pdf"
    },
    {
      state: "NY",
      name: "NSD Licensing Form",
      link: "/files/forms/DL-NY.pdf"
    },
    {
      state: "OK",
      name: "NSD Licensing Form",
      link: "/files/forms/DL-OK.pdf"
    },
    {
      state: "SC",
      name: "NSD Licensing Form",
      link: "/files/forms/DL-SC.pdf"
    },
    {
      state: "TN",
      name: "NSD Licensing Form",
      link: "/files/forms/DL-TN.pdf"
    },
    {
      state: "UT",
      name: "NSD Licensing Form",
      link: "/files/forms/DL-UT.pdf"
    }
  ],
  products: {
    "automotive": [
      {
        "name": "24 Hour Roadside",
        "id": "24-hour-roadside",
        "available": [
          "AK",
          "AZ",
          "CO",
          "CT",
          "DC",
          "FL",
          "GA",
          "HI",
          "IA",
          "ID",
          "IL",
          "IN",
          "KY",
          "LA",
          "ME",
          "MI",
          "MN",
          "MO",
          "ND",
          "NJ",
          "NY",
          "OH",
          "OR",
          "PA",
          "PR",
          "RI",
          "SD",
          "TX",
          "VA",
          "VT",
          "WA",
          "WI",
          "WV",
          "WY"
        ],
        "licenseRequired": [
          "AL",
          "AR",
          "CA",
          "DE",
          "KS",
          "MA",
          "MD",
          "MS",
          "MT",
          "NC",
          "NE",
          "NH",
          "NM",
          "NV",
          "OK",
          "SC",
          "TN",
          "UT"
        ],
        "unavailable": []
      },
      {
        "name": "Bundled with Lease End",
        "id": "bundled-with-lease-end",
        "available": [
          "AK",
          "AL",
          "AR",
          "AZ",
          "CA",
          "CO",
          "CT",
          "DC",
          "DE",
          "GA",
          "HI",
          "IA",
          "ID",
          "IN",
          "KS",
          "KY",
          "LA",
          "MA",
          "MD",
          "ME",
          "MI",
          "MN",
          "MO",
          "MS",
          "MT",
          "NC",
          "ND",
          "NE",
          "NH",
          "NJ",
          "NM",
          "NV",
          "OH",
          "OK",
          "OR",
          "PA",
          "RI",
          "SC",
          "SD",
          "TN",
          "TX",
          "UT",
          "VA",
          "VT",
          "WA",
          "WI",
          "WV",
          "WY"
        ],
        "licenseRequired": [],
        "unavailable": [
          "FL",
          "IL",
          "NY",
          "PR"
        ]
      },
      {
        "name": "Complete Titanium Plus",
        "id": "complete-titanium-plus",
        "available": [
          "AK",
          "AL",
          "AR",
          "AZ",
          "CA",
          "CO",
          "CT",
          "DC",
          "DE",
          "GA",
          "HI",
          "IA",
          "ID",
          "IL",
          "IN",
          "KS",
          "KY",
          "LA",
          "MA",
          "MD",
          "ME",
          "MI",
          "MN",
          "MO",
          "MS",
          "MT",
          "NC",
          "ND",
          "NE",
          "NH",
          "NJ",
          "NM",
          "NV",
          "NY",
          "OH",
          "OK",
          "OR",
          "PA",
          "RI",
          "SC",
          "SD",
          "TN",
          "TX",
          "UT",
          "VA",
          "VT",
          "WA",
          "WI",
          "WV",
          "WY"
        ],
        "licenseRequired": [
          "FL"
        ],
        "unavailable": [
          "PR"
        ]
      },
      {
        "name": "Excess Wear & Tear",
        "id": "excess-wear-&-tear",
        "available": [
          "AK",
          "AL",
          "AR",
          "AZ",
          "CA",
          "CO",
          "CT",
          "DC",
          "DE",
          "FL",
          "GA",
          "HI",
          "IA",
          "ID",
          "IL",
          "IN",
          "KS",
          "KY",
          "LA",
          "MA",
          "MD",
          "ME",
          "MI",
          "MN",
          "MO",
          "MS",
          "MT",
          "NC",
          "ND",
          "NE",
          "NH",
          "NJ",
          "NM",
          "NV",
          "NY",
          "OH",
          "OK",
          "OR",
          "PA",
          "RI",
          "SC",
          "SD",
          "TN",
          "TX",
          "UT",
          "VA",
          "VT",
          "WA",
          "WI",
          "WV",
          "WY"
        ],
        "licenseRequired": [],
        "unavailable": [
          "PR"
        ]
      },
      {
        "name": "GAP",
        "id": "gap",
        "available": [
          "AK",
          "AL",
          "AR",
          "AZ",
          "CA",
          "CO",
          "CT",
          "DC",
          "DE",
          "FL",
          "GA",
          "HI",
          "IA",
          "ID",
          "IL",
          "IN",
          "KS",
          "KY",
          "LA",
          "MA",
          "MD",
          "ME",
          "MI",
          "MN",
          "MO",
          "MS",
          "MT",
          "NC",
          "ND",
          "NE",
          "NH",
          "NJ",
          "NM",
          "NV",
          "NY",
          "OH",
          "OK",
          "OR",
          "PA",
          "RI",
          "SC",
          "SD",
          "TN",
          "TX",
          "UT",
          "VA",
          "VT",
          "WA",
          "WI",
          "WV",
          "WY"
        ],
        "licenseRequired": [],
        "unavailable": [
          "PR"
        ]
      },
      {
        "name": "GAP Plus",
        "id": "gap-plus",
        "available": [
          "AL",
          "AR",
          "AZ",
          "CA",
          "CO",
          "CT",
          "DC",
          "DE",
          "HI",
          "IA",
          "ID",
          "IN",
          "KY",
          "LA",
          "MA",
          "MD",
          "ME",
          "MI",
          "MN",
          "MO",
          "MS",
          "MT",
          "NC",
          "ND",
          "NH",
          "NJ",
          "NM",
          "NV",
          "OH",
          "OK",
          "OR",
          "PA",
          "RI",
          "SC",
          "SD",
          "TN",
          "UT",
          "VA",
          "VT",
          "WA",
          "WI",
          "WV",
          "WY"
        ],
        "licenseRequired": [],
        "unavailable": [
          "AK",
          "FL",
          "GA",
          "IL",
          "KS",
          "NE",
          "NY",
          "PR",
          "TX"
        ]
      },
      {
        "name": "Key & Ultimate Key",
        "id": "key-&-ultimate-key",
        "available": [
          "AK",
          "AL",
          "AR",
          "AZ",
          "CA",
          "CO",
          "CT",
          "DC",
          "GA",
          "HI",
          "IA",
          "ID",
          "IL",
          "IN",
          "KS",
          "KY",
          "LA",
          "MD",
          "ME",
          "MI",
          "MN",
          "MO",
          "MT",
          "NC",
          "ND",
          "NE",
          "NH",
          "NJ",
          "NM",
          "NY",
          "OH",
          "OK",
          "OR",
          "PA",
          "PR",
          "RI",
          "SD",
          "TN",
          "TX",
          "VA",
          "VT",
          "WA",
          "WI",
          "WV",
          "WY"
        ],
        "licenseRequired": [
          "DE",
          "FL",
          "MA",
          "MS",
          "NV",
          "SC",
          "UT"
        ],
        "unavailable": []
      },
      {
        "name": "Paint & Interior",
        "id": "paint-&-interior",
        "available": [
          "AK",
          "AL",
          "AR",
          "AZ",
          "CA",
          "CO",
          "CT",
          "DC",
          "DE",
          "FL",
          "GA",
          "HI",
          "IA",
          "ID",
          "IL",
          "IN",
          "KS",
          "KY",
          "LA",
          "MA",
          "MD",
          "ME",
          "MI",
          "MN",
          "MO",
          "MS",
          "MT",
          "NC",
          "ND",
          "NE",
          "NH",
          "NJ",
          "NM",
          "NV",
          "NY",
          "OH",
          "OK",
          "OR",
          "PA",
          "RI",
          "SC",
          "SD",
          "TN",
          "TX",
          "UT",
          "VA",
          "VT",
          "WA",
          "WI",
          "WV",
          "WY"
        ],
        "licenseRequired": [],
        "unavailable": [
          "PR"
        ]
      },
      {
        "name": "PDR",
        "id": "pdr",
        "available": [
          "AK",
          "AL",
          "AR",
          "AZ",
          "CA",
          "CO",
          "CT",
          "DC",
          "DE",
          "GA",
          "HI",
          "IA",
          "ID",
          "IL",
          "IN",
          "KS",
          "KY",
          "LA",
          "MA",
          "MD",
          "ME",
          "MI",
          "MN",
          "MO",
          "MS",
          "MT",
          "NC",
          "ND",
          "NE",
          "NH",
          "NJ",
          "NM",
          "NV",
          "NY",
          "OH",
          "OK",
          "OR",
          "PA",
          "RI",
          "SC",
          "SD",
          "TN",
          "TX",
          "UT",
          "VA",
          "VT",
          "WA",
          "WI",
          "WV",
          "WY"
        ],
        "licenseRequired": [
          "FL"
        ],
        "unavailable": [
          "PR"
        ]
      },
      {
        "name": "Platinum Protection",
        "id": "platinum-protection",
        "available": [
          "AK",
          "AL",
          "AR",
          "AZ",
          "CA",
          "CO",
          "CT",
          "DC",
          "DE",
          "GA",
          "HI",
          "IA",
          "ID",
          "IL",
          "IN",
          "KS",
          "KY",
          "LA",
          "MA",
          "MD",
          "ME",
          "MI",
          "MN",
          "MO",
          "MS",
          "MT",
          "NC",
          "ND",
          "NE",
          "NH",
          "NJ",
          "NM",
          "NV",
          "NY",
          "OH",
          "OK",
          "OR",
          "PA",
          "RI",
          "SC",
          "SD",
          "TN",
          "TX",
          "UT",
          "VA",
          "VT",
          "WA",
          "WI",
          "WV",
          "WY"
        ],
        "licenseRequired": [
          "FL"
        ],
        "unavailable": [
          "PR"
        ]
      },
      {
        "name": "Theft & Vehicle Replace",
        "id": "theft-&-vehicle-replace",
        "available": [
          "AK",
          "AL",
          "AR",
          "AZ",
          "CA",
          "CO",
          "CT",
          "DC",
          "DE",
          "GA",
          "HI",
          "IA",
          "ID",
          "IL",
          "IN",
          "KS",
          "KY",
          "LA",
          "MA",
          "MD",
          "ME",
          "MI",
          "MN",
          "MO",
          "MS",
          "MT",
          "NC",
          "ND",
          "NE",
          "NH",
          "NJ",
          "NM",
          "NV",
          "OH",
          "OK",
          "OR",
          "PA",
          "RI",
          "SC",
          "SD",
          "TN",
          "TX",
          "UT",
          "VA",
          "VT",
          "WA",
          "WI",
          "WV",
          "WY"
        ],
        "licenseRequired": [
          "FL",
          "NY"
        ],
        "unavailable": [
          "PR"
        ]
      },
      {
        "name": "Tire & Wheel",
        "id": "tire-&-wheel",
        "available": [
          "AK",
          "AL",
          "AR",
          "AZ",
          "CA",
          "CO",
          "CT",
          "DC",
          "DE",
          "GA",
          "HI",
          "IA",
          "ID",
          "IL",
          "IN",
          "KS",
          "KY",
          "LA",
          "MA",
          "MD",
          "ME",
          "MI",
          "MN",
          "MO",
          "MS",
          "MT",
          "NC",
          "ND",
          "NE",
          "NH",
          "NJ",
          "NM",
          "NV",
          "NY",
          "OH",
          "OK",
          "OR",
          "PA",
          "PR",
          "RI",
          "SC",
          "SD",
          "TN",
          "TX",
          "UT",
          "VA",
          "VT",
          "WA",
          "WI",
          "WV",
          "WY"
        ],
        "licenseRequired": [
          "FL"
        ],
        "unavailable": []
      },
      {
        "name": "Titanium",
        "id": "titanium",
        "available": [
          "AK",
          "AL",
          "AR",
          "AZ",
          "CA",
          "CO",
          "CT",
          "DC",
          "DE",
          "GA",
          "HI",
          "IA",
          "ID",
          "IL",
          "IN",
          "KS",
          "KY",
          "LA",
          "MA",
          "MD",
          "ME",
          "MI",
          "MN",
          "MO",
          "MS",
          "MT",
          "NC",
          "ND",
          "NE",
          "NH",
          "NJ",
          "NM",
          "NV",
          "NY",
          "OH",
          "OK",
          "OR",
          "PA",
          "PR",
          "RI",
          "SC",
          "SD",
          "TN",
          "TX",
          "UT",
          "VA",
          "VT",
          "WA",
          "WI",
          "WV",
          "WY"
        ],
        "licenseRequired": [
          "FL"
        ],
        "unavailable": []
      },
      {
        "name": "Titanium Lite",
        "id": "titanium-lite",
        "available": [
          "AK",
          "AL",
          "AR",
          "AZ",
          "CA",
          "CO",
          "CT",
          "DC",
          "DE",
          "GA",
          "HI",
          "IA",
          "ID",
          "IL",
          "IN",
          "KS",
          "KY",
          "LA",
          "MA",
          "MD",
          "ME",
          "MI",
          "MN",
          "MO",
          "MS",
          "MT",
          "NC",
          "ND",
          "NE",
          "NH",
          "NJ",
          "NM",
          "NV",
          "NY",
          "OH",
          "OK",
          "OR",
          "PA",
          "RI",
          "SC",
          "SD",
          "TN",
          "TX",
          "UT",
          "VA",
          "VT",
          "WA",
          "WI",
          "WV",
          "WY"
        ],
        "licenseRequired": [
          "FL"
        ],
        "unavailable": [
          "PR"
        ]
      },
      {
        "name": "Total Loss",
        "id": "total-loss",
        "available": [
          "AK",
          "AL",
          "AR",
          "AZ",
          "CO",
          "CT",
          "DC",
          "DE",
          "GA",
          "HI",
          "IA",
          "ID",
          "IL",
          "IN",
          "KS",
          "KY",
          "LA",
          "MA",
          "MD",
          "ME",
          "MI",
          "MN",
          "MO",
          "MS",
          "MT",
          "NC",
          "ND",
          "NE",
          "NH",
          "NJ",
          "NM",
          "NV",
          "OH",
          "OK",
          "PA",
          "RI",
          "SC",
          "SD",
          "VA",
          "VT",
          "WI",
          "WV",
          "WY"
        ],
        "licenseRequired": [],
        "unavailable": [
          "CA",
          "FL",
          "NY",
          "OR",
          "PR",
          "TN",
          "TX",
          "UT",
          "WA"
        ]
      },
      {
        "name": "Ultimate Titanium Plus",
        "id": "ultimate-titanium-plus",
        "available": [
          "AK",
          "AL",
          "AR",
          "AZ",
          "CA",
          "CO",
          "CT",
          "DC",
          "DE",
          "GA",
          "HI",
          "IA",
          "ID",
          "IL",
          "IN",
          "KS",
          "KY",
          "LA",
          "MA",
          "MD",
          "ME",
          "MI",
          "MN",
          "MO",
          "MS",
          "MT",
          "NC",
          "ND",
          "NE",
          "NH",
          "NJ",
          "NM",
          "NV",
          "NY",
          "OH",
          "OK",
          "OR",
          "PA",
          "RI",
          "SC",
          "SD",
          "TN",
          "TX",
          "UT",
          "VA",
          "VT",
          "WA",
          "WI",
          "WV",
          "WY"
        ],
        "licenseRequired": [
          "FL"
        ],
        "unavailable": [
          "PR"
        ]
      },
      {
        "name": "Vehicle Wrap",
        "id": "vehicle-wrap",
        "available": [
          "AK",
          "AL",
          "AR",
          "AZ",
          "CA",
          "CO",
          "CT",
          "DC",
          "DE",
          "GA",
          "HI",
          "IA",
          "ID",
          "IL",
          "IN",
          "KS",
          "KY",
          "LA",
          "MA",
          "MD",
          "ME",
          "MI",
          "MN",
          "MO",
          "MS",
          "MT",
          "NC",
          "ND",
          "NE",
          "NH",
          "NJ",
          "NM",
          "NV",
          "NY",
          "OH",
          "OK",
          "OR",
          "PA",
          "RI",
          "SC",
          "SD",
          "TN",
          "TX",
          "UT",
          "VA",
          "VT",
          "WA",
          "WI",
          "WV",
          "WY"
        ],
        "licenseRequired": [
          "FL"
        ],
        "unavailable": [
          "PR"
        ]
      },
      {
        "name": "Windshield Repair / Replace",
        "id": "windshield-repair-/-replace",
        "available": [
          "AK",
          "AL",
          "AR",
          "CA",
          "CO",
          "CT",
          "DC",
          "DE",
          "HI",
          "IA",
          "ID",
          "IL",
          "IN",
          "KY",
          "LA",
          "MA",
          "MD",
          "MI",
          "MN",
          "MO",
          "MS",
          "MT",
          "NC",
          "ND",
          "NE",
          "NH",
          "NJ",
          "NM",
          "NV",
          "OH",
          "OK",
          "OR",
          "PA",
          "RI",
          "SC",
          "SD",
          "TN",
          "UT",
          "VA",
          "VT",
          "WA",
          "WI",
          "WV"
        ],
        "licenseRequired": [],
        "unavailable": [
          "AZ",
          "FL",
          "GA",
          "KS",
          "ME",
          "NY",
          "PR",
          "TX",
          "WY"
        ]
      }
    ],
    "insurancePartners": [
      {
        "name": "Home & Wellness",
        "id": "home-&-wellness",
        "available": [
          "AK",
          "AL",
          "AR",
          "AZ",
          "CA",
          "CO",
          "CT",
          "DC",
          "DE",
          "FL",
          "GA",
          "HI",
          "IA",
          "ID",
          "IL",
          "IN",
          "KS",
          "KY",
          "LA",
          "MA",
          "MD",
          "ME",
          "MI",
          "MN",
          "MO",
          "MS",
          "MT",
          "NC",
          "ND",
          "NE",
          "NH",
          "NJ",
          "NM",
          "NV",
          "NY",
          "OH",
          "OK",
          "OR",
          "PA",
          "RI",
          "SC",
          "SD",
          "TN",
          "TX",
          "UT",
          "VA",
          "VT",
          "WA",
          "WI",
          "WV",
          "WY"
        ],
        "licenseRequired": [],
        "unavailable": [
          "PR"
        ]
      },
      {
        "name": "ID Theft",
        "id": "id-theft",
        "available": [
          "AK",
          "AL",
          "AR",
          "AZ",
          "CA",
          "CO",
          "CT",
          "DC",
          "DE",
          "FL",
          "GA",
          "HI",
          "IA",
          "ID",
          "IL",
          "IN",
          "KS",
          "KY",
          "LA",
          "MA",
          "MD",
          "ME",
          "MI",
          "MN",
          "MO",
          "MS",
          "MT",
          "NC",
          "ND",
          "NE",
          "NH",
          "NJ",
          "NM",
          "NV",
          "NY",
          "OH",
          "OK",
          "OR",
          "PA",
          "RI",
          "SC",
          "SD",
          "TN",
          "TX",
          "UT",
          "VA",
          "VT",
          "WA",
          "WI",
          "WV",
          "WY"
        ],
        "licenseRequired": [],
        "unavailable": [
          "PR"
        ]
      },
      {
        "name": "Reimbursement Motor Club",
        "id": "reimbursement-motor-club",
        "available": [
          "AK",
          "AZ",
          "CO",
          "CT",
          "DC",
          "FL",
          "GA",
          "HI",
          "IA",
          "ID",
          "IL",
          "IN",
          "KY",
          "LA",
          "ME",
          "MN",
          "MO",
          "ND",
          "NJ",
          "OH",
          "OR",
          "PA",
          "RI",
          "SD",
          "TX",
          "VA",
          "VT",
          "WA",
          "WI",
          "WV",
          "WY"
        ],
        "licenseRequired": [
          "AL",
          "AR",
          "DE",
          "KS",
          "MA",
          "MD",
          "MS",
          "MT",
          "NC",
          "NE",
          "NH",
          "NM",
          "NV",
          "OK",
          "SC",
          "TN",
          "UT"
        ],
        "unavailable": [
          "CA",
          "MI",
          "NY",
          "PR"
        ]
      },
      {
        "name": "Rx Savings",
        "id": "rx-savings",
        "available": [
          "AK",
          "AL",
          "AR",
          "AZ",
          "CA",
          "CO",
          "CT",
          "DC",
          "DE",
          "FL",
          "GA",
          "HI",
          "IA",
          "ID",
          "IL",
          "IN",
          "KS",
          "KY",
          "LA",
          "MA",
          "MD",
          "ME",
          "MI",
          "MN",
          "MO",
          "MS",
          "MT",
          "NC",
          "ND",
          "NE",
          "NH",
          "NJ",
          "NM",
          "NV",
          "NY",
          "OH",
          "OK",
          "OR",
          "PA",
          "RI",
          "SC",
          "SD",
          "TN",
          "TX",
          "UT",
          "VA",
          "VT",
          "WA",
          "WI",
          "WV",
          "WY"
        ],
        "licenseRequired": [],
        "unavailable": [
          "PR"
        ]
      },
      {
        "name": "Theft",
        "id": "theft",
        "available": [
          "AK",
          "AL",
          "AR",
          "AZ",
          "CA",
          "CO",
          "CT",
          "DC",
          "DE",
          "GA",
          "HI",
          "IA",
          "ID",
          "IL",
          "IN",
          "KS",
          "KY",
          "LA",
          "MA",
          "MD",
          "ME",
          "MI",
          "MN",
          "MO",
          "MS",
          "MT",
          "NC",
          "ND",
          "NE",
          "NH",
          "NJ",
          "NM",
          "NV",
          "OH",
          "OK",
          "OR",
          "PA",
          "RI",
          "SC",
          "SD",
          "TN",
          "TX",
          "UT",
          "VA",
          "VT",
          "WA",
          "WI",
          "WV",
          "WY"
        ],
        "licenseRequired": [
          "FL"
        ],
        "unavailable": [
          "NY",
          "PR"
        ]
      },
      {
        "name": "Towbuster 24 Hr Roadside",
        "id": "towbuster-24-hr-roadside",
        "available": [
          "AK",
          "AZ",
          "CO",
          "CT",
          "DC",
          "FL",
          "GA",
          "HI",
          "IA",
          "ID",
          "IL",
          "IN",
          "KY",
          "LA",
          "ME",
          "MI",
          "MN",
          "MO",
          "ND",
          "NJ",
          "NY",
          "OH",
          "OR",
          "PA",
          "PR",
          "RI",
          "SD",
          "TX",
          "VA",
          "VT",
          "WA",
          "WI",
          "WV",
          "WY"
        ],
        "licenseRequired": [
          "AL",
          "AR",
          "CA",
          "DE",
          "KS",
          "MA",
          "MD",
          "MS",
          "MT",
          "NC",
          "NE",
          "NH",
          "NM",
          "NV",
          "OK",
          "SC",
          "TN",
          "UT"
        ],
        "unavailable": []
      },
      {
        "name": "Travel Club",
        "id": "travel-club",
        "available": [
          "AL",
          "AR",
          "AZ",
          "CA",
          "CO",
          "CT",
          "DC",
          "DE",
          "FL",
          "GA",
          "HI",
          "IA",
          "ID",
          "IL",
          "IN",
          "KS",
          "KY",
          "LA",
          "MA",
          "MD",
          "ME",
          "MI",
          "MN",
          "MO",
          "MS",
          "MT",
          "NC",
          "ND",
          "NE",
          "NH",
          "NJ",
          "NM",
          "NV",
          "OH",
          "OK",
          "OR",
          "PA",
          "RI",
          "SC",
          "SD",
          "TN",
          "TX",
          "UT",
          "VA",
          "WA",
          "WI",
          "WV",
          "WY"
        ],
        "licenseRequired": [],
        "unavailable": [
          "AK",
          "NY",
          "PR",
          "VT"
        ]
      },
      {
        "name": "Windshield Repair",
        "id": "windshield-repair",
        "available": [
          "AK",
          "AL",
          "AZ",
          "CA",
          "CO",
          "CT",
          "DC",
          "DE",
          "GA",
          "HI",
          "IA",
          "ID",
          "IL",
          "IN",
          "KS",
          "KY",
          "LA",
          "MA",
          "MD",
          "MI",
          "MN",
          "MS",
          "MT",
          "NC",
          "ND",
          "NE",
          "NH",
          "NJ",
          "NM",
          "NV",
          "NY",
          "OH",
          "OK",
          "OR",
          "PA",
          "RI",
          "SC",
          "SD",
          "TN",
          "TX",
          "VA",
          "VT",
          "WV",
          "WY"
        ],
        "licenseRequired": [],
        "unavailable": [
          "AR",
          "FL",
          "ME",
          "MO",
          "PR",
          "UT",
          "WA",
          "WI"
        ]
      }
    ],
    "powerSportsMarine": [
      {
        "name": "24 Hour Roadside",
        "id": "24-hour-roadside",
        "available": [
          "AK",
          "AZ",
          "CO",
          "CT",
          "DC",
          "FL",
          "GA",
          "HI",
          "IA",
          "ID",
          "IL",
          "IN",
          "KY",
          "LA",
          "ME",
          "MI",
          "MN",
          "MO",
          "ND",
          "NJ",
          "NY",
          "OH",
          "OR",
          "PA",
          "PR",
          "RI",
          "SD",
          "TX",
          "VA",
          "VT",
          "WA",
          "WI",
          "WV",
          "WY"
        ],
        "licenseRequired": [
          "AL",
          "AR",
          "CA",
          "DE",
          "KS",
          "MA",
          "MD",
          "MS",
          "MT",
          "NC",
          "NE",
          "NH",
          "NM",
          "NV",
          "OK",
          "SC",
          "TN",
          "UT"
        ],
        "unavailable": []
      },
      {
        "name": "Key",
        "id": "key",
        "available": [
          "AK",
          "AL",
          "AR",
          "AZ",
          "CA",
          "CO",
          "CT",
          "DC",
          "GA",
          "HI",
          "IA",
          "ID",
          "IL",
          "IN",
          "KS",
          "KY",
          "LA",
          "MD",
          "ME",
          "MI",
          "MN",
          "MO",
          "MT",
          "NC",
          "ND",
          "NE",
          "NH",
          "NJ",
          "NM",
          "NY",
          "OH",
          "OK",
          "OR",
          "PA",
          "PR",
          "RI",
          "SD",
          "TN",
          "TX",
          "VA",
          "VT",
          "WA",
          "WI",
          "WV",
          "WY"
        ],
        "licenseRequired": [
          "DE",
          "FL",
          "MA",
          "MS",
          "NV",
          "SC",
          "UT"
        ],
        "unavailable": []
      },
      {
        "name": "Marine GAP",
        "id": "marine-gap",
        "available": [
          "AK",
          "AL",
          "AR",
          "AZ",
          "CO",
          "CT",
          "DC",
          "DE",
          "FL",
          "GA",
          "HI",
          "IA",
          "ID",
          "IL",
          "IN",
          "KS",
          "KY",
          "LA",
          "MA",
          "MD",
          "ME",
          "MI",
          "MN",
          "MO",
          "MS",
          "MT",
          "NC",
          "ND",
          "NE",
          "NH",
          "NJ",
          "NM",
          "NV",
          "OH",
          "OK",
          "OR",
          "PA",
          "PR",
          "RI",
          "SC",
          "SD",
          "TN",
          "UT",
          "VA",
          "VT",
          "WA",
          "WI",
          "WV",
          "WY"
        ],
        "licenseRequired": [],
        "unavailable": [
          "CA",
          "NY",
          "TX"
        ]
      },
      {
        "name": "Powersport GAP",
        "id": "powersport-gap",
        "available": [
          "AL",
          "AR",
          "AZ",
          "CA",
          "CT",
          "DC",
          "DE",
          "FL",
          "GA",
          "IA",
          "ID",
          "IL",
          "IN",
          "KS",
          "KY",
          "LA",
          "MA",
          "ME",
          "MI",
          "MN",
          "MO",
          "MS",
          "MT",
          "NC",
          "ND",
          "NE",
          "NJ",
          "NM",
          "NV",
          "OH",
          "OK",
          "OR",
          "PA",
          "PR",
          "RI",
          "SC",
          "SD",
          "TN",
          "UT",
          "VA",
          "VT",
          "WA",
          "WI",
          "WV",
          "WY"
        ],
        "licenseRequired": [],
        "unavailable": [
          "AK",
          "CO",
          "HI",
          "MD",
          "NH",
          "NY",
          "TX"
        ]
      },
      {
        "name": "Theft",
        "id": "theft",
        "available": [
          "AK",
          "AL",
          "AR",
          "AZ",
          "CA",
          "CO",
          "CT",
          "DC",
          "DE",
          "GA",
          "HI",
          "IA",
          "ID",
          "IL",
          "IN",
          "KS",
          "KY",
          "LA",
          "MA",
          "MD",
          "ME",
          "MI",
          "MN",
          "MO",
          "MS",
          "MT",
          "NC",
          "ND",
          "NE",
          "NH",
          "NJ",
          "NM",
          "NV",
          "OH",
          "OK",
          "OR",
          "PA",
          "PR",
          "RI",
          "SC",
          "SD",
          "TN",
          "TX",
          "UT",
          "VA",
          "VT",
          "WA",
          "WI",
          "WV",
          "WY"
        ],
        "licenseRequired": [
          "FL",
          "NY"
        ],
        "unavailable": []
      },
      {
        "name": "Tire & Wheel",
        "id": "tire-&-wheel",
        "available": [
          "AK",
          "AL",
          "AR",
          "AZ",
          "CA",
          "CO",
          "CT",
          "DC",
          "DE",
          "GA",
          "HI",
          "IA",
          "ID",
          "IL",
          "IN",
          "KS",
          "KY",
          "LA",
          "MA",
          "MD",
          "ME",
          "MI",
          "MN",
          "MO",
          "MS",
          "MT",
          "NC",
          "ND",
          "NE",
          "NH",
          "NJ",
          "NM",
          "NV",
          "NY",
          "OH",
          "OK",
          "OR",
          "PA",
          "PR",
          "RI",
          "SC",
          "SD",
          "TN",
          "TX",
          "UT",
          "VA",
          "VT",
          "WA",
          "WI",
          "WV",
          "WY"
        ],
        "licenseRequired": [
          "FL"
        ],
        "unavailable": []
      }
    ],
    "recreationVehicles": [
      {
        "name": "24 Hour Roadside",
        "id": "24-hour-roadside",
        "available": [
          "AK",
          "AZ",
          "CO",
          "CT",
          "DC",
          "FL",
          "GA",
          "HI",
          "IA",
          "ID",
          "IL",
          "IN",
          "KY",
          "LA",
          "ME",
          "MI",
          "MN",
          "MO",
          "ND",
          "NJ",
          "NY",
          "OH",
          "OR",
          "PA",
          "PR",
          "RI",
          "SD",
          "TX",
          "VA",
          "VT",
          "WA",
          "WI",
          "WV",
          "WY"
        ],
        "licenseRequired": [
          "AL",
          "AR",
          "CA",
          "DE",
          "KS",
          "MA",
          "MD",
          "MS",
          "MT",
          "NC",
          "NE",
          "NH",
          "NM",
          "NV",
          "OK",
          "SC",
          "TN",
          "UT"
        ],
        "unavailable": []
      },
      {
        "name": "Key",
        "id": "key",
        "available": [
          "AK",
          "AL",
          "AR",
          "AZ",
          "CA",
          "CO",
          "CT",
          "DC",
          "GA",
          "HI",
          "IA",
          "ID",
          "IL",
          "IN",
          "KS",
          "KY",
          "LA",
          "MD",
          "ME",
          "MI",
          "MN",
          "MO",
          "MT",
          "NC",
          "ND",
          "NE",
          "NH",
          "NJ",
          "NM",
          "NY",
          "OH",
          "OK",
          "OR",
          "PA",
          "PR",
          "RI",
          "SD",
          "TN",
          "TX",
          "VA",
          "VT",
          "WA",
          "WI",
          "WV",
          "WY"
        ],
        "licenseRequired": [
          "DE",
          "FL",
          "MA",
          "MS",
          "NV",
          "SC",
          "UT"
        ],
        "unavailable": []
      },
      {
        "name": "Paint & Interior",
        "id": "paint-&-interior",
        "available": [
          "AK",
          "AL",
          "AR",
          "AZ",
          "CA",
          "CO",
          "CT",
          "DC",
          "DE",
          "FL",
          "GA",
          "HI",
          "IA",
          "ID",
          "IL",
          "IN",
          "KS",
          "KY",
          "LA",
          "MA",
          "MD",
          "ME",
          "MI",
          "MN",
          "MO",
          "MS",
          "MT",
          "NC",
          "ND",
          "NE",
          "NH",
          "NJ",
          "NM",
          "NV",
          "NY",
          "OH",
          "OK",
          "OR",
          "PA",
          "PR",
          "RI",
          "SC",
          "SD",
          "TN",
          "TX",
          "UT",
          "VA",
          "VT",
          "WA",
          "WI",
          "WV",
          "WY"
        ],
        "licenseRequired": [],
        "unavailable": []
      },
      {
        "name": "RV GAP",
        "id": "rv-gap",
        "available": [
          "AK",
          "AL",
          "AR",
          "AZ",
          "CA",
          "CT",
          "DC",
          "DE",
          "FL",
          "GA",
          "HI",
          "IA",
          "ID",
          "IL",
          "IN",
          "KS",
          "KY",
          "LA",
          "MA",
          "ME",
          "MI",
          "MN",
          "MO",
          "MS",
          "MT",
          "NC",
          "ND",
          "NE",
          "NH",
          "NJ",
          "NM",
          "NV",
          "OH",
          "OK",
          "OR",
          "PA",
          "PR",
          "RI",
          "SC",
          "SD",
          "TN",
          "TX",
          "UT",
          "VA",
          "VT",
          "WA",
          "WI",
          "WV",
          "WY"
        ],
        "licenseRequired": [],
        "unavailable": [
          "CO",
          "MD",
          "NY"
        ]
      },
      {
        "name": "RV Tech w/ Optional Roadside",
        "id": "rv-tech-w/-optional-roadside",
        "available": [
          "AK",
          "AZ",
          "CO",
          "CT",
          "DC",
          "FL",
          "GA",
          "HI",
          "IA",
          "ID",
          "IL",
          "IN",
          "KY",
          "LA",
          "ME",
          "MI",
          "MN",
          "MO",
          "ND",
          "NJ",
          "NY",
          "OH",
          "OR",
          "PA",
          "PR",
          "RI",
          "SD",
          "TX",
          "VT",
          "WA",
          "WI",
          "WV",
          "WY"
        ],
        "licenseRequired": [
          "AL",
          "AR",
          "CA",
          "DE",
          "KS",
          "MA",
          "MD",
          "MS",
          "MT",
          "NC",
          "NE",
          "NH",
          "NM",
          "NV",
          "OK",
          "SC",
          "TN",
          "UT",
          "VA"
        ],
        "unavailable": []
      },
      {
        "name": "Theft",
        "id": "theft",
        "available": [
          "AK",
          "AL",
          "AR",
          "AZ",
          "CA",
          "CO",
          "CT",
          "DC",
          "DE",
          "GA",
          "HI",
          "IA",
          "ID",
          "IL",
          "IN",
          "KS",
          "KY",
          "LA",
          "MA",
          "MD",
          "ME",
          "MI",
          "MN",
          "MO",
          "MS",
          "MT",
          "NC",
          "ND",
          "NE",
          "NH",
          "NJ",
          "NM",
          "NV",
          "OH",
          "OK",
          "OR",
          "PA",
          "PR",
          "RI",
          "SC",
          "SD",
          "TN",
          "TX",
          "UT",
          "VA",
          "VT",
          "WA",
          "WI",
          "WV",
          "WY"
        ],
        "licenseRequired": [
          "FL",
          "NY"
        ],
        "unavailable": []
      },
      {
        "name": "Tire & Wheel",
        "id": "tire-&-wheel",
        "available": [
          "AK",
          "AL",
          "AR",
          "AZ",
          "CA",
          "CO",
          "CT",
          "DC",
          "DE",
          "GA",
          "HI",
          "IA",
          "ID",
          "IL",
          "IN",
          "KS",
          "KY",
          "LA",
          "MA",
          "MD",
          "ME",
          "MI",
          "MN",
          "MO",
          "MS",
          "MT",
          "NC",
          "ND",
          "NE",
          "NH",
          "NJ",
          "NM",
          "NV",
          "NY",
          "OH",
          "OK",
          "OR",
          "PA",
          "PR",
          "RI",
          "SC",
          "SD",
          "TN",
          "TX",
          "UT",
          "VA",
          "VT",
          "WA",
          "WI",
          "WV",
          "WY"
        ],
        "licenseRequired": [
          "FL"
        ],
        "unavailable": []
      },
      {
        "name": "Windshield Repair/Replace",
        "id": "windshield-repair/replace",
        "available": [
          "AK",
          "AL",
          "AR",
          "CA",
          "CO",
          "CT",
          "DC",
          "DE",
          "HI",
          "IA",
          "ID",
          "IL",
          "IN",
          "KY",
          "LA",
          "MA",
          "MD",
          "MI",
          "MN",
          "MO",
          "MS",
          "MT",
          "NC",
          "ND",
          "NE",
          "NH",
          "NJ",
          "NM",
          "NV",
          "OH",
          "OK",
          "OR",
          "PA",
          "PR",
          "RI",
          "SC",
          "SD",
          "TN",
          "UT",
          "VA",
          "VT",
          "WA",
          "WI",
          "WV"
        ],
        "licenseRequired": [],
        "unavailable": [
          "AZ",
          "FL",
          "GA",
          "KS",
          "ME",
          "NY",
          "TX",
          "WY"
        ]
      }
    ]
  }
}

