import React from "react";
import {Grid} from '@material-ui/core';
import {title} from "../../../assets/jss/material-kit-react";

export default {
  title: "Industries",
  tiles: [
    {
      image: (
        <img style={{width: 80}} src={require('../../../assets/img/car_yellow.png')}/>
      ),
      showTile: true,
      title: (
        <React.Fragment>
          AUTOMOTIVE
        </React.Fragment>
      ),
      description: (
        <React.Fragment>
          NSD markets to OEM's, Warranty and Service Contract Companies, Agents and Brokers, and Dealerships in North
          America.
        </React.Fragment>
      ),
      detail: (
        <React.Fragment>
          <div style={{textAlign: 'left'}}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={6}>
                <img style={{
                  width: '100%',
                  borderRadius: "6px",
                  margin: "0 10px 0 0",
                  boxShadow:
                    "0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)"
                }} src={require('../../../assets/img/girlcar.jpg')}/>
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <div>
                  <h4 style={{color: 'inherit'}}>
                    NSD leverages our technology and connection to the end-user consumer in a time of need. We bring and
                    keep
                    your consumer in your brand and promote loyalty.
                  </h4>
                </div>
                <div style={{textAlign: 'left'}}>
                  <h4>
                    <ul>
                      <li>
                        Integration into OEM or Dealership Network and Service team. Pre-load preferred providers into
                        our
                        Roadside Network.
                      </li>
                      <li>
                        Guiding consumers to your reputable, preferred, and owned network of service and repair
                        facilities.
                      </li>
                      <li>
                        Developing web connections to your platforms for seamless transition of vehicles and consumers
                        to
                        your
                        locations.
                      </li>
                      <li>
                        Mitigating losses by controlling the vehicle and consumer experience.
                      </li>
                      <li>
                        Capturing valuable vehicle data for service and re-sale capabilities.
                      </li>
                      <li>
                        Suite of Ancillary F&I Products that can be bundled, branded and private-labeled to your needs.
                        Increase
                        and continual revenue stream.
                      </li>
                    </ul>
                  </h4>
                </div>
              </Grid>
            </Grid>
          </div>
        </React.Fragment>
      )
    },
    {
      image: (
        <img style={{width: 40}} src={require('../../../assets/img/insurance_yellow.png')}/>
      ),
      showTile: true,
      title: (
        <React.Fragment>
          INSURANCE
        </React.Fragment>
      ),
      description: (
        <React.Fragment>
          Our years of experience gives us the foresight and flexibility of tailoring a specific roadside benefit plan
          to compliment your current benefits and services.
        </React.Fragment>
      ),
      detail: (
        <React.Fragment>
          <div style={{textAlign: 'left'}}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <h4>
                  In today's highly competitive marketplace, companies are constantly looking for ways to stay ahead of
                  the curve. With our innovative 24-Hour Towing and Roadside Assistance product we can help increase
                  your
                  overall benefit package without increasing overhead and staffing. This partnership will also provide
                  your customer with peace-of-mind in knowing they are just a click away from a nationwide, 24-hour
                  dispatch towing & roadside service. Also, after a vehicle is involved in an accident and towed to a
                  temporary storage facility, it begins to incur high storage fees. NSD's Accident and Secondary Towing
                  Network will assist in moving the vehicle to a preferred location of your choice, saving your company
                  time and money.
                </h4>
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <img style={{
                  width: '100%',
                  borderRadius: "6px",
                  margin: "0 10px 0 0",
                  boxShadow:
                    "0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)"
                }} src={require('../../../assets/img/guygirl.jpg')}/>
                <h4 style={{
                  ...title,
                  display: "inline-block",
                  position: "relative",
                  margin: 0,
                  color: 'inherit',
                  textDecoration: "none"
                }}>
                  Towing and Roadside Assistance for your Policyholders
                </h4>
                <h4>
                  <ul>
                    <li>Experience in the Insurance Industry since 1962</li>
                    <li>
                      Current clientele includes over 9,000 producers (Insurance Agencies and Companies, Managing
                      General
                      Agencies, and Affinity Groups)
                    </li>
                    <li>
                      Integration to Carrier Data Base allowing seamless approval process and verification
                    </li>
                    <li>
                      Transparency and real-time data provided to monitor results
                    </li>
                    <li>
                      Unique & customized algorithms that drive service levels & client performance
                    </li>
                    <li>
                      Pro-active features to keep consumer involved in the process & exceed expectations
                    </li>
                  </ul>
                </h4>
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <img style={{
                  width: '100%',
                  borderRadius: "6px",
                  margin: "0 10px 0 0",
                  boxShadow:
                    "0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)"
                }} src={require('../../../assets/img/car_accident.jpg')}/>
                <h4 style={{
                  ...title,
                  display: "inline-block",
                  position: "relative",
                  margin: 0,
                  color: 'inherit',
                  textDecoration: "none"
                }}>
                  Accident and Secondary Towing Network
                </h4>
                <h4>
                  <ul>
                    <li>
                      Settle and Reduce Fees (storage, payout, transport and administrative)
                    </li>
                    <li>
                      Ability to transport vehicles to brand-specific preferred repair facilities within your network
                    </li>
                    <li>
                      Dedicated team to secure the vehicle release and convey information to you and the consumer
                    </li>
                    <li>
                      Quick resolution and same-day turnaround on requests made by 2pm EST
                    </li>
                    <li>
                      Reduction of Rental Fees and Cycle Time
                    </li>
                    <li>
                      Consumer access, customized notifications, and consumer interaction promoted throughout the
                      service
                      process
                    </li>
                  </ul>
                </h4>
              </Grid>
            </Grid>
          </div>
        </React.Fragment>
      )
    },
    {
      image: (
        <img style={{width: 80}} src={require('../../../assets/img/fleet_yellow.png')}/>
      ),
      showTile: true,
      title: (
        <React.Fragment>
          FLEET MANAGEMENT
        </React.Fragment>
      ),
      description: (
        <React.Fragment>
          NSD gives your fleet member valuable features while delivering an ongoing and direct brand connection with
          your Fleet Group for advertising, service, and maintenance.
        </React.Fragment>
      ),
      detail: (
        <React.Fragment>
          <div style={{textAlign: 'left'}}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={6}>
                <img style={{
                  width: '100%',
                  borderRadius: "6px",
                  margin: "0 10px 0 0",
                  boxShadow:
                    "0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)"
                }} src={require('../../../assets/img/fleet_man.jpg')}/>
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <ul>
                  <li>
                    Notifications come directly from your Fleet Management Software or application into our Network.
                  </li>
                  <li>
                    Immediate response to service requests and roadside occurrences.
                  </li>
                  <li>
                    Control costs by using our Preferred Network and establishing budget and quality control parameters
                    for service.
                  </li>
                  <li>
                    Providing reliable, integrated, professional tow providers who can deliver superior quality of
                    service.
                  </li>
                  <li>
                    Integration into your platform to determine preferred repair facilities and body shops to deliver
                    fleet vehicles.
                  </li>
                </ul>
              </Grid>
            </Grid>
          </div>
        </React.Fragment>
      )
    },
    {
      image: (
        <img style={{width: 80}} src={require('../../../assets/img/telematics_yellow.png')}/>
      ),
      showTile: true,
      title: (
        <React.Fragment>
          OBD/TELEMATICS
        </React.Fragment>
      ),
      description: (
        <React.Fragment>
          NSD partners with OBD (On Board Device) Manufacturers and Telematics companies to develop a seamless
          integration and create a futuristic roadside experience for the consumer.
        </React.Fragment>
      ),
      detail: (
        <React.Fragment>
          <div style={{textAlign: 'left'}}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={6}>
                <img style={{
                  width: '100%',
                  borderRadius: "6px",
                  margin: "0 10px 0 0",
                  boxShadow:
                    "0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)"
                }} src={require('../../../assets/img/mechanic.jpg')}/>
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <h4>
                  What if you could know exactly when your vehicle needs an oil change or other service? What if you
                  could pinpoint the exact location of your vehicle at all times?
                </h4>
                <h4>
                  <ul>
                    <li>
                      Futuristic On-Demand, App-Based Roadside assistance with real-time notifications driven back to
                      the consumer of provider info, ETA's, and customer satisfaction.
                    </li>
                    <li>
                      Notifications are relayed to NSD in real-time. NSD gets exact location, vehicle diagnostics and
                      other key information to assist in a time of need.
                    </li>
                    <li>
                      Crash Detection and Emergency Response signals are sent directly to our Network improving response
                      time and arrival on scene.
                    </li>
                    <li>
                      Revenue generating opportunity with tie-backs and referrals to participating dealerships, service
                      locations, and repair facilities.
                    </li>
                  </ul>
                </h4>
              </Grid>
            </Grid>
          </div>
        </React.Fragment>
      )
    },
    {
      image: (
        <img style={{width: 80}} src={require('../../../assets/img/hand_yellow.png')}/>
      ),
      showTile: true,
      title: (
        <React.Fragment>
          CONSUMER FINANCE
        </React.Fragment>
      ),
      description: (
        <React.Fragment>
          Let NSD's Roadside Assistance Program give your company a competitive advantage.
        </React.Fragment>
      ),
      detail: (
        <React.Fragment>
          <div style={{textAlign: 'left'}}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={6}>
                <img style={{
                  width: '100%',
                  borderRadius: "6px",
                  margin: "0 10px 0 0",
                  boxShadow:
                    "0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)"
                }} src={require('../../../assets/img/handshake.jpg')}/>
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <h4>
                  Tax Preparation Companies, Loan Consolidation Firms, Credit Card Counseling Companies and more all are
                  looking for a competitive advantage. Let NSD's Roadside Assistance program assist by offering a
                  constant engagement with your consumer. Let us allow you to be the hero in a time of need.
                </h4>
                <h4>
                  <ul>
                    Roadside integrated seamlessly within your application driving consumers to use the app more
                    frequently
                  </ul>
                  <ul>
                    Gives you the ability to continually engage with your consumer throughout the year
                  </ul>
                  <ul>
                    Increase revenue, brand awareness and loyalty with a product that has a tremendous perceived value
                    in the marketplace
                  </ul>
                </h4>
              </Grid>
            </Grid>
          </div>
        </React.Fragment>
      )
    },
    {
      image: (
        <img style={{width: 40}} src={require('../../../assets/img/checklist_yellow.png')}/>
      ),
      showTile: true,
      title: (
        <React.Fragment>
          SPECIALTY
        </React.Fragment>
      ),
      description: (
        <React.Fragment>
          Large retailers, Consumer Electronic Stores, Online Distributors, etc. all work with NSD.
        </React.Fragment>
      ),
      detail: (
        <React.Fragment>
          <div style={{textAlign: 'left'}}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={6}>
                <img style={{
                  width: '100%',
                  borderRadius: "6px",
                  margin: "0 10px 0 0",
                  boxShadow:
                    "0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)"
                }} src={require('../../../assets/img/program.jpg')}/>
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <h4>
                  <ul>
                    <li>
                      Do consumers frequently visit your site to buy products, goods, or services. Why not offer an
                      additional product? Why not include a highly valuable product into your portfolio?
                    </li>
                    <li>
                      Roadside integrated seamlessly within your application driving consumers to use the app more
                      frequently
                    </li>
                    <li>
                      Gives you the ability to continually engage with your consumer throughout the year
                    </li>
                    <li>
                      Increase revenue, brand awareness and loyalty with a product that has a tremendous perceived value
                      in the marketplace
                    </li>
                  </ul>
                </h4>
              </Grid>
            </Grid>
          </div>
        </React.Fragment>
      )
    }
  ]
}
