import React from "react";

export default {
  image: require('../../../assets/img/AM-Best-best-ratings.jpg'),
  description: [
    (
      <React.Fragment>
        Founded in 1899, <a href={"http://www.ambest.com/"} target={"_blank"}>A.M. Best Company</a> is a full-service
        credit rating
        organization dedicated to serving the
        insurance industry. Policyholders refer to Best's ratings and analysis as a means of assessing the financial
        strength and creditworthiness of risk-bearing entities and investment vehicles.
      </React.Fragment>
    )
  ]
}
