export default {
  title: 'Titanium Bundle',
  items: [
    {
      id: "windshield-protection",
      "title": "Windshield Protection",
      image: require("assets/img/windshield.jpg"),
      "description": [
        "Our repair professionals can repair many types of chips and breaks (like those shown here), including cracks up to six inches long, approximately the size of a dollar bill. If the bill covers the damage it is likely that the windshield can be saved, without replacement."
      ]
    },
    {
      id: "roadside-assistance",
      "title": "Roadside Assistance",
      "image": require("assets/img/Roadside-Assistance.jpg"),
      "description": [
        "The Nation Safe Drivers Auto Club provides real peace-of-mind at affordable rates. When on the road you'll know that you are covered and protected by an organization that cares and has been caring since 1962! Our customer representatives respond quickly to provide you the best service and protection available anywhere. We at NSD have earned a solid reputation for fast and courteous service. You can count on the company with \"Safe\" as its middle name!"
      ]
    },
    {
      id: "tire-and-wheel-protection",
      "title": "Tire and Wheel Protection",
      image: require("assets/img/car-tire.jpg"),
      "description": [
        "The NSD Tire & Wheel Protection Plan picks up where the factory warranty and service contract leaves off! Includes 3, 5, 7 Years of Coverage with UNLIMITED MILEAGE. Full coverage of all mounting and balancing associated with the repair of any tire repaired or replaced, under contract. NSD will also absorb the cost of taxes associated with the replacement of a tire or wheel."
      ]
    },
    {
      id: "pdr-travel-program",
      "title": "Paintless Dent Repair",
      image: require("assets/img/paintless-dent-repair.jpg"),
      "description": [
        "It is almost impossible to prevent your new car from eventually having a minor dent or ding. With NSD's PDR Travel program you are ultimately protected from occasional dent and dings. Protect your new car buying experience today!",
        "The PDR Travel Program is designed to enhance your vehicle ownership experience. Whether you are leasing or buying you will enjoy the confidence that comes with knowing those irritating dents will be taken care of."
      ]
    }
  ]
}
