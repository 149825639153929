import React from 'react';
import { useParams } from 'react-router';
import { makeStyles } from '@material-ui/core';
import NSD_1 from './img/NSD_1.jpg';
import NSD_2 from './img/NSD_2.jpg';
import NSD_3 from './img/NSD_3.jpg';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    height: '100%',
    border: '1px solid black',
    overflowY: 'scroll'
  },
  img: {
    width: '100%',
    display: 'block'
  }
}));

const Theme = () => {
  const classes = useStyles();
  const { theme } = useParams();

  let src;
  if (theme === '1') {
    src = NSD_1;
  } else if (theme === '2') {
    src = NSD_2;
  } else if (theme === '3') {
    src = NSD_3;
  }

  return (
    <img className={classes.img} src={src} />
  );
};

Theme.propTypes = {
};

export default Theme;
