import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Fab,
  Grid,
  Hidden,
  IconButton,
  InputAdornment,
  Typography
} from "@material-ui/core";
import {ArrowBack as BackIcon, Search as SearchIcon} from '@material-ui/icons'
import classNames from "classnames";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import productsStyle from "../../../assets/jss/material-kit-react/views/claimCentersSections/productsStyle.jsx";
import Fade from 'react-reveal/Fade';
import {default as content} from '../../../content/ClaimsCenter/Sections/products'
import Slide from "react-reveal/Slide";
import ProductDetails from "./ProductDetails";

class Products extends React.Component {

  constructor(props) {
    super(props)
    this.props = props;
    this.state = {
      searchText: "",
      selectedProduct: null,
      hideProducts: false,
      showProductDetails: false
    }
    this.backToProducts = this.backToProducts.bind(this);
  }

  componentDidMount() {
    window.addEventListener("resetProductPage", this.backToProducts);
  }

  componentWillUnmount() {
    window.removeEventListener("resetProductPage", this.backToProducts);
  }

  updateData = (newValue) => {
    this.setState({searchText: newValue.currentTarget.value})
    window.scrollTo({
      top: 700,
      left: 0,
      behavior: 'smooth'
    });
  }

  getListOfProducts = () => {
    let returnProducts = [];
    const {classes} = this.props;
    content.products.map((product, productIndex) => {
      if (this.showProduct(product)) {

        let moreButton = null;
        if (!this.state.hideProducts) {
          if (product.fileLink) {
            moreButton = (
              <CardActions className={classes.cardActions}>
                <a style={{width: '100%'}} href={product.fileLink} target={"_blank"}>
                  <Button
                      fullWidth
                      color="primary"
                      variant="outlined"
                      size="small"
                  >
                    Download Form
                  </Button>
                </a>
              </CardActions>
            );
          }
          else if (product.externalLink) {
            moreButton = (
                <CardActions className={classes.cardActions}>
                  <a style={{width: '100%'}} { ...product.externalLink }>
                    <Button
                        fullWidth
                        color="primary"
                        variant="outlined"
                        size="small"
                    >
                      Learn More
                    </Button>
                  </a>
                </CardActions>
            );
          }
          else {
            moreButton = (
              <CardActions className={classes.cardActions}>
                <Button
                    fullWidth
                    color="primary"
                    variant="outlined"
                    size="small"
                    onClick={() => {
                      this.setState({
                        hideProducts: !this.state.hideProducts,
                        selectedProduct: product,
                        searchText: product.name
                      });
                      window.scrollTo({
                        top: 400,
                        left: 0,
                        behavior: 'smooth'
                      });
                      setTimeout(function () {
                        this.setState({showProductDetails: !this.state.showProductDetails})
                      }.bind(this), 750)
                    }}>Learn More</Button>
              </CardActions>
            );
          }
        }

        returnProducts.push(
          <Grid key={`product${productIndex + 1}`} item xs={12} sm={12} md={this.state.hideProducts ? 12 : 6}
                lg={this.state.hideProducts ? 12 : 3} xl={this.state.hideProducts ? 12 : 3}
                className={classNames({[classes.equalHMRWrap]: true, [classes.eqWrap]: true})}>
            <Fade bottom>
              <div className={classNames({[classes.equalHMR]: true, [classes.eq]: true})}>
                <Card
                  className={classNames({[classes.card]: true, [classes.selectedProduct]: this.state.hideProducts})}>
                  <CardHeader
                    avatar={
                      <React.Fragment>
                        {
                          this.state.hideProducts &&
                          <Hidden lgUp>
                            <IconButton
                              color={"primary"}
                              onClick={this.backToProducts}
                            >
                              <BackIcon/>
                            </IconButton>
                          </Hidden>
                        }
                        <img src={product.img} style={{height: 50}}/>
                      </React.Fragment>
                    }
                    action={
                      this.state.hideProducts &&
                      <React.Fragment>
                        <Hidden mdDown>
                          <Button
                            variant={"outlined"}
                            color={"primary"}
                            onClick={this.backToProducts}
                          >
                            Back to products
                          </Button>
                        </Hidden>

                      </React.Fragment>
                    }
                    title={product.name}/>
                  {
                    !this.state.hideProducts ?
                      <CardContent className={classes.cardContent}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>
                          {product.description}
                        </Typography>
                      </CardContent>
                      :
                      null
                  }
                  { moreButton }
                </Card>
              </div>
            </Fade>
          </Grid>
        )
      }
    })

    if (returnProducts.length === 0) {
      returnProducts.push(
        <Grid item xs={12} sm={12} md={6} lg={12} xl={21} style={{textAlign: 'center'}}>
          <div style={{height: 200}}>
            <h3 className={classes.description}>Sorry, we couldn't find any products base on your search. Trying
              changing your search and trying again.</h3>
          </div>
        </Grid>
      )
    }

    return returnProducts;
  }

  showProduct = (product) => {
    return product.name.toLowerCase().indexOf(this.state.searchText.toLowerCase()) >= 0 || product.description.toString().toLowerCase().indexOf(this.state.searchText.toLowerCase()) >= 0
  }

  backToProducts = () => {
    this.setState({
      showProductDetails: !this.state.showProductDetails
    });
    setTimeout(function () {
      this.setState({
        hideProducts: !this.state.hideProducts,
        selectedProduct: null,
        searchText: ''
      })
      window.scrollTo({
        top: 400,
        left: 0,
        behavior: 'smooth'
      });
    }.bind(this), 1000)
  }

  render() {
    const {classes} = this.props;
    return (
      <div className={classes.section} style={{color: '#999'}}>
        {
          this.state.hideProducts &&
          <Hidden lgUp>
            <Fab
              color="primary"
              aria-label="back"
              className={classes.fab} onClick={this.backToProducts}
            >
              <BackIcon/>
            </Fab>
          </Hidden>
        }
        <Grid container>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6} style={{padding: 10}}>
            <div style={{height: this.state.hideProducts ? 0 : 50, transitionDelay: 0, transition: 'height .5s'}}>
              <Fade when={!this.state.hideProducts}>
                <CustomInput
                  labelText="Search products"
                  id="searchText"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    startAdornment: <InputAdornment position="start"><SearchIcon/></InputAdornment>,
                    value: this.state.searchText,
                    onChange: this.updateData
                  }}
                />
              </Fade>
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}
                xl={12} style={{padding: 10}}>
            <div className={classNames({[classes.equalHMRWrap]: true, [classes.eqWrap]: true})}>
              {this.getListOfProducts()}
            </div>
          </Grid>
          {
            this.state.hideProducts && <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Slide timeout={500} delay={500} right when={this.state.showProductDetails}>
                <div>
                  <ProductDetails details={this.state.selectedProduct}/>
                </div>
              </Slide>
            </Grid>
          }
        </Grid>
      </div>
    );
  }
}

export default withStyles(productsStyle)(Products);
