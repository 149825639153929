import React from "react";

export default {
  "name": "COMMERCIAL INSURANCE",
  image: require('../../../assets/img/insurance-commercial-640.jpg'),
  "description": [
    (
      <React.Fragment>
        <b>Restaurants</b>
        <br/>
        National Insurance Underwriters (NIU) has become one of the largest underwriters of Hospitality Insurance in the
        market place. NIU recognizes that restaurants, café, bakeries and local tavern proprietors have unique business
        requirements. We specialize in casual dining, fine dining, take out, family style, cafes, pizza / sub shops and
        delis. We offer a Comprehensive Package Program or Monoline Liability in 30 states.
      </React.Fragment>
    ),
    (
      <React.Fragment>
        <b>Quick Service Restaurants</b>
        <br/>
        Our "A XII" rated market, competitive commissions and specially designed products provide all that is necessary
        for you to become the Quick Service and Franchise Restaurant insurance agent of choice. We bring Independent
        Agents a unique and comprehensive package of products, services and support to assist in expanding your book of
        business. Some of our clients include are Wendy's, Popeye's, Long John Silvers, Five Guys Burgers and Fries and
        Hardee's.
      </React.Fragment>
    ),
    (
      <React.Fragment>
        <b>Sports Bars and Taverns</b>
        <br/>
        NIU offer coverage for Nightclubs, Taverns, and Sports Bars. Coverage Available: General Liability, Liquor
        Liability, HNOA, and A&B on most risk.

        We also offer select programs for retail store, office exposure, lessor risk, and artisan contractors in certain
        states.
      </React.Fragment>
    )
  ]
}
