import { cardTitle, title } from "assets/jss/material-kit-react.jsx";
import imagesStyle from "assets/jss/material-kit-react/imagesStyles.jsx";
import {
  primaryColor,
  secondaryColor,
  warningColor,
  successColor,
  infoColor,
  darkColor,
  dangerColor,
  roseColor,
  grayColor
} from "assets/jss/material-kit-react.jsx";

const teamStyle = {
  section: {
    textAlign: "center"
  },
  raised: {
    borderRadius: "6px",
    boxShadow:
      "0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)"
  },
  title: {
    ...title,
    marginBottom: "1rem",
    marginTop: "30px",
    minHeight: "32px",
    textDecoration: "none"
  },
  ...imagesStyle,
  itemGrid: {
    marginLeft: "auto",
    marginRight: "auto"
  },
  cardTitle,
  smallTitle: {
    color: "#6c757d"
  },
  description: {
    color: "#999"
  },
  justifyCenter: {
    justifyContent: "center !important"
  },
  bold: {
    fontSize: '1.1em',
    fontFamily: 'bold'
  },
  primaryColor: {
    color: primaryColor
  },
  secondaryColor: {
    color: secondaryColor
  },
  warningColor: {
    color: warningColor
  },
  successColor: {
    color: successColor
  },
  infoColor: {
    color: infoColor
  },
  darkColor: {
    color: darkColor
  },
  dangerColor: {
    color: dangerColor
  },
  roseColor: {
    color: roseColor
  },
  grayColor: {
    color: grayColor
  },
  socials: {
    marginTop: "0",
    width: "100%",
    transform: "none",
    left: "0",
    top: "0",
    height: "100%",
    lineHeight: "41px",
    fontSize: "20px",
    color: "#999"
  },
  margin5: {
    margin: "5px"
  },
  parallax: {
    height: "90vh",
    maxHeight: "1000px",
    overflow: "hidden",
    position: "relative",
    backgroundPosition: "center center",
    backgroundSize: "cover",
    margin: "0",
    padding: "0",
    border: "0",
    display: "flex",
    alignItems: "center"
  },
  filter: {
    "&:before": {
      background: "rgba(0, 0, 0, 0.75)"
    },
    "&:after,&:before": {
      position: "absolute",
      zIndex: "1",
      width: "100%",
      height: "100%",
      display: "block",
      left: "0",
      top: "0",
      content: "''"
    }
  },
  small: {
    height: "380px"
  }
};

export default teamStyle;
