import {container, title} from "assets/jss/material-kit-react.jsx";
import {secondaryColor} from "../../../material-kit-react";

var tinycolor2 = require('tinycolor2');
const availabilityPageStyle = {
  container: {
    zIndex: "12",
    color: "#FFFFFF",
    ...container
  },
  description: {
    color: "#999"
  },
  formContainer: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    alignContent: 'center',
    justifyContent: 'center'
  },
  formCard: {
    margin: 10,
    maxWidth: 275,
    width: '100%'
  },
  expansionPanelHeading: {
    fontSize: "0.9375rem",
    flexBasis: '33.33%',
    flexShrink: 0,
  },
  secondaryExpansionPanelHeading: {
    fontSize: "0.9375rem",
  },
  formCardHeader: {
    textAlign: 'center'
  },
  formAction: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    alignContent: 'center',
    justifyContent: 'center'
  },
  centerCardContainer: {
    margin: '50px 0px 0px 0px',
    padding: '50px 0px 50px 0px',
    width: '100%',
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    backgroundColor: tinycolor2(secondaryColor).brighten(15).toHexString(),
    justifyContent: 'center'
  },
  note: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    bottom: "10px",
    color: "#c0c1c2",
    display: "block",
    fontWeight: "400",
  },
  mainInformationContainer: {
    padding: "10px 15% 10px 15%",
    display: "block",
    width: '100%',
    textAlign: 'center'
  },
  title: {
    ...title,
    display: "inline-block",
    position: "relative",
    marginTop: "30px",
    minHeight: "32px",
    textDecoration: "none"
  },
  whiteText: {
    color: "#FFFFFF",
  },
  subtitle: {
    fontSize: "1.313rem",
    maxWidth: "500px",
    margin: "10px auto 0"
  },
  main: {
    background: "#FFFFFF",
    position: "relative",
    zIndex: "3"
  },
  mainRaised: {
    margin: "-60px 30px 0px",
    borderRadius: "6px",
    boxShadow:
      "0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)"
  }
};

export default availabilityPageStyle;
