import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import classNames from "classnames";

import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import {Background, Parallax} from 'react-parallax';
import teamStyle from "assets/jss/material-kit-react/views/landingPageSections/about.jsx";
import Fade from 'react-reveal/Fade';
import content from '../../../content/NIUW/Sections/about'


class AboutNIU extends React.Component {
  render() {
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true
    };
    const {classes} = this.props;
    const parallaxClasses = classNames({
      [classes.parallax]: true,
      [classes.filter]: true,
      [classes.small]: false
    });
    return (
      <div className={classes.section}>
        <Parallax bgImage={require("assets/img/nsd.jpg")} bgStyle={{
          "&:before": {
            background: "rgba(0, 0, 0, 0.75)"
          },
          "&:after,&:before": {
            position: "absolute",
            zIndex: "1",
            width: "100%",
            height: "100%",
            display: "block",
            left: "0",
            top: "0",
            content: "''"
          }
        }} strength={500}>
          <Background className={parallaxClasses}></Background>
          <Fade bottom>
            <div style={{minHeight: 500, padding: 15}}>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12} lg={12} xl={12}>
                  <h2 className={classes.title} style={{color: '#fff'}}>{content.title}</h2>
                  <h4 className={classes.title} style={{color: '#fff'}}>{content.subTitle}</h4>
                </GridItem>
                {
                  content.sections.map((section, index) => (
                    <GridItem key={`section${index}`} xs={12} sm={12} md={12} lg={6} xl={6}>
                      <div style={{
                        textAlign: 'center'
                      }}>
                        <h3>
                          {section}
                        </h3>
                      </div>
                    </GridItem>
                  ))
                }
              </GridContainer>
            </div>
          </Fade>
        </Parallax>
      </div>
    );
  }
}

export default withStyles(teamStyle)(AboutNIU);
