import React from "react";

export default {
  "title": "INSURANCE PRODUCTS",
  "description": (
    <React.Fragment>
      <h3>What Type of Insurance Do You Need?</h3>
      <br/>
      In most states we can provide your agency with an array of Personal Lines and Commercial Lines products, made
      available on our website.
    </React.Fragment>
  ),
  form: {
    title: "Licensing Form",
    link: "/files/forms/NIUFL_2020.pdf"
  }
}
