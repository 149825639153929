import React from "react";

export default {
  "title": "NATIONAL INSURANCE UNDERWRITERS",
  "subTitle": "Building strong relationships one policy at a time.",
  "subTitleTwo": (
    <React.Fragment>
      We are a premier provider of insurance products and services for independent insurance agents throughout the
      United States.
    </React.Fragment>
  ),
  form: {
    title: "Licensing Form",
    link: "/files/forms/NIUFL_2020.pdf"
  }
}
