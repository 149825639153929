import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import {Hidden, List, ListItem, withStyles} from "@material-ui/core";
import footerStyle from "assets/jss/material-kit-react/components/footerStyle.jsx";
import {Link} from "react-router-dom";
import locationMapImg from '../../assets/img/footer-map.png'

function Footer({...props}) {
  const {classes, whiteFont, niuFl, niuw, futureRoadside} = props;
  const footerClasses = classNames({
    [classes.footer]: true,
    [classes.footerWhiteFont]: whiteFont
  });
  const aClasses = classNames({
    [classes.a]: true,
    [classes.footerWhiteFont]: whiteFont
  });

  const mapMargin = props.claimsCenter || futureRoadside || niuw || niuFl ? 150 : 250;
  return (
    <footer className={footerClasses}>
      <Hidden smDown>
        {
          props.claimsCenter ?
            <div className={classes.container}>
              <div style={{display: 'flex', justifyContent: 'center'}}>
                <div style={{flex: 1, display: 'flex', justifyContent: 'center', marginTop: 50}}>
                  Return all documentation to the Claims Center:
                  <br/>
                  5600 Broken Sound Blvd NW Boca Raton, FL, 33487, United States
                  <br/>
                  888-684-9327
                  <br/>
                  Copyright &copy; {1900 + new Date().getYear()}, NIU of Florida, Inc. All rights reserved.
                  <br/>
                </div>
                <div style={{display: 'flex', flexDirection: 'row-reverse', alignItems: 'flex-end'}}>
                  <div>
                    <a
                      href={'https://www.bbb.org/south-east-florida/business-reviews/towing-automotive/nation-motor-club-in-boca-raton-fl-92010909#sealclick'}
                      target={'_blank'}>
                      <img src={require('assets/img/black-seal-96-50.png')}/>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            :
            futureRoadside ?
              <div className={classes.container} style={{marginTop: 50}}>
                Copyright &copy; {1900 + new Date().getYear()}, NSD Group, LLC. All Rights Reserved.
                <br/>5600 Broken Sound Blvd NW Boca Raton, FL, 33487, United States. 1 (800) 338-2680
              </div>
              :
              niuw ?
                <div className={classes.container}>
                  <div className={classes.left} style={{marginLeft: 250}}>
                    <List className={classes.list}>
                      <ListItem className={classes.inlineBlock} button>
                        <a
                          href={"/niuw-glossary"}
                          className={classes.block}
                        >
                          Glossary
                        </a>
                      </ListItem>
                      <ListItem className={classes.inlineBlock} button>
                        <a
                          href={"/niuw-our-partners"}
                          className={classes.block}
                        >
                          Our Partners
                        </a>
                      </ListItem>
                    </List>
                    Copyright &copy; {1900 + new Date().getYear()}, NSD Group, LLC. All Rights Reserved. 5600 Broken Sound Blvd NW Boca Raton, FL, 33487, United States<br/>
                    33431. 1 (800) 338-2680 Ext. 507<br/>
                  </div>
                  <div className={classes.right}>
                    <a
                      href={'https://www.bbb.org/south-east-florida/business-reviews/towing-automotive/nation-motor-club-in-boca-raton-fl-92010909#sealclick'}
                      target={'_blank'}>
                      <img src={require('assets/img/black-seal-96-50.png')}/>
                    </a>
                  </div>
                </div>
                :
                niuFl ?
                  <div className={classes.container}>
                    <div style={{display: 'flex', justifyContent: 'center'}}>
                      <div style={{flex: 1, display: 'flex', justifyContent: 'center', marginTop: 50}}>
                        <br/>
                        Copyright &copy; {1900 + new Date().getYear()}, NIU of Florida, Inc.
                        5600 Broken Sound Blvd NW Boca Raton, FL, 33487, United States
                        <br/>
                      </div>
                      <div style={{display: 'flex', flexDirection: 'row-reverse', alignItems: 'flex-end'}}>
                        <div>
                          <a
                            href={'https://www.bbb.org/south-east-florida/business-reviews/towing-automotive/nation-motor-club-in-boca-raton-fl-92010909#sealclick'}
                            target={'_blank'}>
                            <img src={require('assets/img/black-seal-96-50.png')}/>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  :
                  <div className={classes.container}>
                    <div className={classes.left} style={{marginLeft: 50}}>
                      <List className={classes.list}>
                        <ListItem className={classes.inlineBlock} button>
                          <a
                            href={"/availability"}
                            className={classes.block}
                          >
                            Availability
                          </a>
                        </ListItem>
                        <ListItem className={classes.inlineBlock} button>
                          <a
                            href={"/cellular/product-details/cellular"}
                            className={classes.block}
                          >
                            Cellular & Wireless
                          </a>
                        </ListItem>
                        <ListItem className={classes.inlineBlock} button>
                          <a
                              href={"/privacy"}
                              className={classes.block}
                          >
                            Privacy
                          </a>
                        </ListItem>
                        <ListItem className={classes.inlineBlock} button>
                          <a
                              target={'_blank'}
                              href={"https://www.cigna.com/legal/compliance/machine-readable-files"}
                              className={classes.block}
                              title={'This link leads to the machine-readable files that are made available in response to the federal Transparency in Coverage Rule and includes negotiated service rates and out-of-network allowed amounts between health plans and healthcare providers. The machine readable files are formatted to allow researchers, regulators, and application developers to more easily access and analyze data.'}
                          >
                            Transparency In Coverage
                          </a>
                        </ListItem>
                      </List>

                      <div className={classes.right}>
                        Copyright &copy; {1900 + new Date().getYear()}, NSD Group, LLC. All Rights Reserved.
                        <br/>5600 Broken Sound Blvd NW Boca Raton, FL, 33487, United States. 1 (800) 338-2680
                      </div>
                    </div>
                  </div>
        }
        <div style={{marginRight: mapMargin}}>
          <a href={'https://goo.gl/maps/zp67u'} target={'_blank'}>
            <img src={locationMapImg} alt={'Location Map'}/>
          </a>
        </div>
      </Hidden>
      <Hidden mdUp>
        {
          props.claimsCenter ?
            <div style={{width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
              <div style={{flex: 1, display: 'flex', justifyContent: 'center', marginTop: 50}}>
                Return all documentation to the Claims Center:
                <br/>
                5600 Broken Sound Blvd NW Boca Raton, FL, 33487, United States
                <br/>
                888-684-9327
                <br/>
                Copyright &copy; {1900 + new Date().getYear()}, NIU of Florida, Inc. All rights reserved.
                <br/>
              </div>
              <br/>
              <div>
                <a href={'https://goo.gl/maps/zp67u'} target={'_blank'}>
                  <img src={locationMapImg} alt={'Location Map'}/>
                </a>
              </div>
              <br/>
              <div>
                <div>
                  <a
                    href={'https://www.bbb.org/south-east-florida/business-reviews/towing-automotive/nation-motor-club-in-boca-raton-fl-92010909#sealclick'}
                    target={'_blank'}>
                    <img src={require('assets/img/black-seal-96-50.png')}/>
                  </a>
                </div>
              </div>
            </div>
            :
            futureRoadside ?
              <div style={{width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                <div style={{marginTop: 50}}>
                  Copyright &copy; {1900 + new Date().getYear()}, NSD Group, LLC. All Rights Reserved.
                  <br/>5600 Broken Sound Blvd NW Boca Raton, FL, 33487, United States. 1 (800) 338-2680
                </div>
                <br/>
                <div>
                  <a href={'https://goo.gl/maps/zp67u'} target={'_blank'}>
                    <img src={locationMapImg} alt={'Location Map'}/>
                  </a>
                </div>
              </div>
              :
              niuw ?
                <div style={{width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                  <List className={classes.list}>
                    <ListItem className={classes.inlineBlock} button>
                      <a
                        href={"/niuw-glossary"}
                        className={classes.block}
                      >
                        Glossary
                      </a>
                    </ListItem>
                    <ListItem className={classes.inlineBlock} button>
                      <a
                        href={"/niuw-our-partners"}
                        className={classes.block}
                      >
                        Our Partners
                      </a>
                    </ListItem>
                  </List>
                  Copyright &copy; {1900 + new Date().getYear()}, NSD Group, LLC. All Rights Reserved. 5600 Broken Sound Blvd NW Boca Raton, FL, 33487, United States. 1 (800) 338-2680 Ext. 507<br/>
                  <br/>
                  <div>
                    <a href={'https://goo.gl/maps/zp67u'} target={'_blank'}>
                      <img src={locationMapImg} alt={'Location Map'}/>
                    </a>
                  </div>
                  <br/>
                  <div>
                    <a
                      href={'https://www.bbb.org/south-east-florida/business-reviews/towing-automotive/nation-motor-club-in-boca-raton-fl-92010909#sealclick'}
                      target={'_blank'}>
                      <img src={require('assets/img/black-seal-96-50.png')}/>
                    </a>
                  </div>
                </div>
                :
                niuFl ?
                  <div style={{width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                    <div style={{flex: 1, display: 'flex', justifyContent: 'center', marginTop: 50}}>
                      <br/>
                      Copyright &copy; {1900 + new Date().getYear()}, NIU of Florida, Inc.
                      5600 Broken Sound Blvd NW Boca Raton, FL, 33487, United States
                      <br/>
                    </div>
                    <br/>
                    <div>
                      <a href={'https://goo.gl/maps/zp67u'} target={'_blank'}>
                        <img src={locationMapImg} alt={'Location Map'}/>
                      </a>
                    </div>
                    <br/>
                    <div>
                      <a
                        href={'https://www.bbb.org/south-east-florida/business-reviews/towing-automotive/nation-motor-club-in-boca-raton-fl-92010909#sealclick'}
                        target={'_blank'}>
                        <img src={require('assets/img/black-seal-96-50.png')}/>
                      </a>
                    </div>
                  </div>
                  :
                  <div>
                    <div style={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      marginRight: 50,
                      marginLeft: 50
                    }}>
                      <List className={classes.list}>
                        <ListItem className={classes.inlineBlock} button>
                          <a
                            href={"/availability"}
                            className={classes.block}
                          >
                            Availability
                          </a>
                        </ListItem>
                        <ListItem className={classes.inlineBlock} button>
                          <a
                            href={"/cellular/product-details/cellular"}
                            className={classes.block}
                          >
                            Cellular & Wireless
                          </a>
                        </ListItem>
                        <ListItem className={classes.inlineBlock} button>
                          <a
                            href={"/privacy"}
                            className={classes.block}
                          >
                            Privacy
                          </a>
                        </ListItem>
                        <ListItem className={classes.inlineBlock} button>
                          <a
                              target={'_blank'}
                              href={"https://www.cigna.com/legal/compliance/machine-readable-files"}
                              className={classes.block}
                              title={'This link leads to the machine-readable files that are made available in response to the federal Transparency in Coverage Rule and includes negotiated service rates and out-of-network allowed amounts between health plans and healthcare providers. The machine readable files are formatted to allow researchers, regulators, and application developers to more easily access and analyze data.'}
                          >
                            Transparency In Coverage
                          </a>
                        </ListItem>
                      </List>
                      <br/>
                      <div>
                        Copyright &copy; {1900 + new Date().getYear()}, NSD Group, LLC. All Rights Reserved.
                        <br/>5600 Broken Sound Blvd NW Boca Raton, FL, 33487, United States 1 (800) 338-2680
                      </div>
                      <br/>
                      <div>
                        <a href={'https://goo.gl/maps/zp67u'} target={'_blank'}>
                          <img src={locationMapImg} alt={'Location Map'}/>
                        </a>
                      </div>
                    </div>
                  </div>
        }
      </Hidden>

    </footer>
  );
}

Footer.propTypes = {
  classes: PropTypes.object.isRequired,
  whiteFont: PropTypes.bool
};

export default withStyles(footerStyle)(Footer);
