import React, {useEffect, useState} from "react";
import {Animated} from "react-animated-css";
import PropTypes from 'prop-types'

const CustomAnimation = (props) => {
  const {animationIn, animationOut, isVisible, delay, children, delayShowOnly, ...rest} = props;
  const [displayItem, setDisplayItem] = useState();

  useEffect(() => {
    if (delay) {
      setTimeout(() => {
        setDisplayItem(
          <Animated {...rest} animationIn={animationIn} animationOut={animationOut} isVisible={isVisible}>
            {children}
          </Animated>
        )
      }, delay)
    } else {
      setDisplayItem(
        <Animated {...rest} animationIn={animationIn} animationOut={animationOut} isVisible={isVisible}>
          {children}
        </Animated>
      )
    }
  }, [isVisible])

  return (
    <React.Fragment>
      {displayItem}
    </React.Fragment>
  )
}

CustomAnimation.propTypes = {
  isVisible: PropTypes.bool,
  delayShowOnly: PropTypes.bool,
  animationOut: PropTypes.string,
  animationIn: PropTypes.string,
  delay: PropTypes.number,
};

export default CustomAnimation;
